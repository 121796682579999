<template>
  <div class="cursor-pointer">
    <svg
      :class="button ? 'button-icon' : 'icon'"
      v-if="!haveUnread"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19 18H5C4.45 18 4 17.55 4 17V8L10.94 12.34C11.59 12.75 12.41 12.75 13.06 12.34L20 8V17C20 17.55 19.55 18 19 18ZM12 11L4 6H20L12 11Z"
        fill="#354052"
      />
    </svg>

    <span v-else>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.8574 10C19.9874 10 21.0174 9.61 21.8574 8.98V18C21.8574 19.1 20.9574 20 19.8574 20H3.85742C2.75742 20 1.85742 19.1 1.85742 18V6C1.85742 4.9 2.75742 4 3.85742 4H13.9574C13.8974 4.32 13.8574 4.66 13.8574 5C13.8574 6.48 14.5074 7.79 15.5274 8.71L11.8574 11L5.15742 6.81C4.58742 6.46 3.85742 6.86 3.85742 7.53C3.85742 7.82 4.00742 8.09 4.25742 8.25L11.3274 12.67C11.6474 12.87 12.0674 12.87 12.3874 12.67L17.1574 9.69C17.6974 9.88 18.2574 10 18.8574 10ZM15.8574 5C15.8574 6.66 17.1974 8 18.8574 8C20.5174 8 21.8574 6.66 21.8574 5C21.8574 3.34 20.5174 2 18.8574 2C17.1974 2 15.8574 3.34 15.8574 5Z"
          fill="#44A4A5"
        />
        <circle cx="18.8574" cy="5" r="3" fill="#F04A43" />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: "MessageIcon",
  props: {
    haveUnread: Boolean,
    button: Boolean
  }
};
</script>

<style scoped lang="scss">
svg.icon {
  path {
    @apply transition-all duration-200;
    fill: #354052;
  }

  &:hover {
    path {
      fill: #44a4a5;
    }
  }
}

svg.button-icon {
  path {
    fill: #44a4a5;
  }

  &:hover {
    path {
      fill: #44a4a5;
    }
  }
}
</style>

<template>
  <div
    class="fixed w-screen h-screen flex justify-center items-center z-500 bg-black bg-opacity-50"
    @click.exact="$emit('close')"
  >
    <span class="absolute close-position cursor-pointer">
      <IconToastClose />
    </span>
    <img class="rounded-lg" :src="image" alt="image" />
  </div>
</template>

<script>
import IconToastClose from "../icons/IconToastClose";
export default {
  name: "ChatImageFullScreen",
  components: { IconToastClose },
  props: {
    image: null
  }
};
</script>

<style scoped>
.z-500 {
  z-index: 500;
}
img {
  max-height: 70vh;
  max-width: 80vw;
}
.close-position {
  right: 2rem;
  top: 2rem;
}
</style>

<template>
  <modal
    @before-open="beforeOpen"
    name="booking-options-modal"
    width="500"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div
      class="bg-white w-modal max-h-90 py-6 flex flex-col justify-center items-center rounded-lg"
      :dir="arabic ? 'rtl' : 'ltr'"
    >
      <div class="w-full overflow-y-auto">
        <div v-if="isLoading" class="flex justify-center w-full mt-6 mb-6">
          <img class="w-20 animate-bounce" src="../../../assets/images/ayadi-loader.svg" alt="Ayadi" />
        </div>
        <div v-else>
          <div class="flex justify-between items-start mx-6 mb-4">
            <p class="text-18 font-semibold w-full" :class="arabic ? 'text-right' : 'text-left'">
              {{ $t("booking_options_modal.title") }}
            </p>
            <div class="cursor-pointer px-1 py-1" @click="$modal.hide('booking-options-modal')">
              <IconCloseGray />
            </div>
          </div>

          <div class="flex justify-between items-start mx-6 mb-4">
            <div class="flex flex-row justify-start items-center">
              <p
                class="mr-4 text-sm font-semibold cursor-pointer pb-2 transition-all duration-300"
                :class="{ 'active-tab': activeTab === 'one_session' }"
                @click="selectTab('one_session')"
              >
                {{ $t("booking_options_modal.book_one_session") }}
              </p>
              <p
                v-if="packagesOptions.length > 0"
                class="mr-4 text-sm font-semibold cursor-pointer pb-2 transition-all duration-300"
                :class="{ 'active-tab': activeTab === 'packages' }"
                @click="selectTab('packages')"
              >
                {{ $t("booking_options_modal.save_with_packages") }}
              </p>
            </div>
          </div>
          <div class="subTitle px-6"><span v-html="getTip()"></span></div>
          <div v-if="activeTab === 'one_session'" class="flex flex-col mx-6">
            <div v-if="oneSessionOptions.length > 0">
              <div
                v-for="oneSessionOption in oneSessionOptions"
                @click="selectOption('one_session', oneSessionOption.value)"
                class="flex flex-col justify-start items-center rounded-2xl custom-shadow-xl mb-4 option"
                :class="{ active: selectedOneSession === oneSessionOption.value }"
                :key="oneSessionOption.id"
              >
                <div v-if="oneSessionOption.recommended" class="badge recommended" :dir="arabic ? 'rtl' : 'ltr'">
                  {{ $t("booking_options_modal.recommended") }}
                </div>
                <div v-if="oneSessionOption.oldPrice" class="badge savePrice" :dir="arabic ? 'rtl' : 'ltr'">
                  {{
                    $t("booking_options_modal.save", {
                      quantity: oneSessionOption.oldPrice - oneSessionOption.price,
                      currency: oneSessionOption.currency
                    })
                  }}
                </div>
                <div class="header">
                  <div class="title">{{ oneSessionOption.title }}</div>
                  <div class="priceWrapper">
                    <div v-if="oneSessionOption.oldPrice" class="oldPrice">
                      {{ oneSessionOption.oldPrice }} {{ oneSessionOption.currency }}
                    </div>
                    <div class="price">{{ oneSessionOption.price }} {{ oneSessionOption.currency }}</div>
                  </div>
                </div>
                <div class="details" v-if="selectedOneSession === oneSessionOption.value">
                  <div v-for="option in oneSessionOption.options" :key="option.id" class="detailsItem">
                    {{ option.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex flex-col mx-6">
            <div
              v-for="packageOption in packagesOptions"
              @click="selectOption('packages', packageOption.value)"
              class="flex flex-col justify-start items-center rounded-2xl custom-shadow-xl mb-4 option"
              :class="{ active: selectedPackages === packageOption.value }"
              :key="packageOption.id"
            >
              <div v-if="packageOption.recommended" class="badge recommended" :dir="arabic ? 'rtl' : 'ltr'">
                {{ $t("booking_options_modal.recommended") }}
              </div>
              <div v-if="packageOption.oldPrice" class="badge savePrice" :dir="arabic ? 'rtl' : 'ltr'">
                {{
                  $t("booking_options_modal.save", {
                    quantity: packageOption.oldPrice - packageOption.price,
                    currency: packageOption.currency
                  })
                }}
              </div>
              <div class="header">
                <div class="titleWrapper">
                  <div class="title">{{ packageOption.title }}</div>

                  <div class="subTitlePackage">
                    {{ packageOption.subTitle }}
                  </div>
                </div>
                <div class="priceWrapper">
                  <div v-if="packageOption.oldPrice" class="oldPrice">
                    {{ packageOption.oldPrice }} {{ packageOption.currency }}
                  </div>
                  <div class="price">{{ packageOption.price }} {{ packageOption.currency }}</div>
                </div>
              </div>
              <div class="details" v-if="selectedPackages === packageOption.value">
                <div v-for="option in packageOption.options" :key="option.id" class="detailsItem">
                  {{ option.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="footer px-6">
            <div class="divider"></div>
            <div class="tip">{{ $t("booking_options_modal.tip") }}</div>
            <MainButton :disabled="applyIsActive" @click="apply" type="large">{{ getButtonCTA }}</MainButton>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import sessionsClient from "@/services/sessionsClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import storageClient from "@/services/storageClient";
import TherapistClient from "@/services/therapistClient";
import MainButton from "@/components/ui-kit/MainButton";
import {
  checkPreviousSessions,
  mapPackageOptions,
  mapSingleSessionsOptions,
  checkAvailabilityPackage,
  getPreSelectPackage,
  getPreSelectSession
} from "@/utils/packages";
import { mapState } from "vuex";

export default {
  name: "BookingOptionsModal",
  components: {
    IconCloseGray,
    MainButton
  },
  data() {
    return {
      activeTab: "one_session",
      selectedOneSession: null,
      selectedPackages: null,
      oneSessionOptions: [],
      packagesOptions: [],
      therapistProfileId: null,
      isLoading: true,
      hasPreviousSessions: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("sessions", ["order", "coupon", "bookedPackages"]),
    ...mapState("common", ["currencies"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    applyIsActive() {
      if (
        (this.activeTab === "one_session" && this.selectedOneSession) ||
        (this.activeTab === "packages" && this.selectedPackages)
      ) {
        return false;
      }
      return true;
    },

    getButtonCTA() {
      if (this.activeTab === "packages") {
        return this.$t("booking_options_modal.confirm_package");
      } else {
        return this.$t("booking_options_modal.continue_single");
      }
    }
  },

  methods: {
    async beforeOpen(value) {
      this.isLoading = true;
      this.oneSessionOptions = [];
      this.packagesOptions = [];

      if (this.user) {
        await this.$store.dispatch("sessions/setBookedPackages");

        const validPackages = await checkAvailabilityPackage(this.therapistProfile.id, this.bookedPackages);

        if (validPackages) {
          await this.$store.dispatch("sessions/setBookedPackagesForTherapist", this.validPackages);
          this.$modal.hide("booking-options-modal");
          this.$modal.show("therapist-availability");
          return;
        }
      }

      const { data: packageOptions } = await sessionsClient.getPricePackages(this.therapistProfile?.id);
      if (this.bookedPackages) {
        const hasPrevSession = checkPreviousSessions(this.therapistProfile.id, this.bookedPackages);
        this.hasPreviousSessions = hasPrevSession;
      }

      this.packagesOptions = await mapPackageOptions(packageOptions, this.hasPrevSession);
      this.oneSessionOptions = await mapSingleSessionsOptions(packageOptions, this.hasPrevSession);

      if (value && value.params) {
        this.activeTab = value.params.sessionType;
        if (value.params.sessionType === "one_session") {
          this.selectedOneSession = value.params.value;
        } else {
          this.selectedPackages = value.params.value;
        }
        if (value.params.selectedOption) {
          if (value.params.selectedOption.type === "package") {
            this.selectedPackages = value.params.selectedOption.value;
            this.activeTab = "packages";
          } else {
            this.selectedOneSession = value.params.selectedOption.value;
            this.activeTab = "one_session";
          }
        }
        if (value.params?.preSelectTab) {
          this.activeTab = value.params?.preSelectTab;
        }
        if (value.params?.preSelectQuery) {
          const type = value.params?.preSelectQuery?.type;
          const count = value.params?.preSelectQuery?.count;
          const session_type = value.params?.preSelectQuery?.session_type;
          const typeModal = value.params?.preSelectQuery?.typeModal;
          const session_id = value.params?.preSelectQuery?.session_id;

          if (this.user) {
            if (type === "package") {
              if (count && this.packagesOptions && this.packagesOptions.length > 0) {
                const preSelect = getPreSelectPackage(this.packagesOptions, {
                  count,
                  type
                });
                if (preSelect && preSelect.type === "package") {
                  await this.$store.dispatch("sessions/selectPackage", preSelect);
                  if (typeModal && typeModal === "review") {
                    this.$modal.hide("booking-options-modal");
                    this.$modal.show("proceed-to-payment-package-modal");
                  } else {
                    this.selectedPackages = preSelect.value;
                    this.activeTab = "packages";
                  }
                } else {
                  this.activeTab = "packages";
                }
              } else {
                if (this.packagesOptions && this.packagesOptions.length > 0) {
                  this.activeTab = "packages";
                } else {
                  this.activeTab = "one_session";
                }
              }
            } else if (type === "session") {
              if (typeModal && typeModal === "review" && session_id) {
                const { data } = await TherapistClient.getSession(this.therapistProfile.id, session_id);
                if (data) {
                  this.$modal.show("proceed-to-payment-modal", data);
                } else {
                  this.$modal.show("booking-options-modal");
                }
              }
              if (session_type) {
                const preSelect = getPreSelectSession(this.oneSessionOptions, {
                  sessionType: session_type,
                  type: type
                });

                if (preSelect) {
                  this.selectedOneSession = preSelect.value;
                  this.activeTab = "one_session";
                } else {
                  this.activeTab = "one_session";
                }
              } else {
                this.activeTab = "one_session";
              }
            }
          }
        }
        if (value.params?.profile) {
          this.therapistProfileId = value.params?.profile;
        }
      }
      this.isLoading = false;
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },

    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    selectTab(tabName) {
      this.activeTab = tabName;
    },

    getTip() {
      if (this.activeTab === "one_session") {
        if (this.hasPreviousSessions) {
          return this.$t("booking_options_modal.tips.single_more");
        } else {
          return this.$t("booking_options_modal.tips.single_less");
        }
      } else {
        if (this.hasPreviousSessions) {
          return this.$t("booking_options_modal.tips.packages_more");
        } else {
          return this.$t("booking_options_modal.tips.packages_less");
        }
      }
    },

    async apply() {
      this.$modal.hide("booking-options-modal");
      const value = this.activeTab === "one_session" ? this.selectedOneSession : this.selectedPackages;
      if (this.activeTab === "one_session") {
        await this.$store.dispatch("sessions/selectPackage", null);
      } else {
        const selectedPackage = this.packagesOptions.find((option) => option.value === this.selectedPackages);
        await this.$store.dispatch("sessions/selectPackage", selectedPackage);
      }
      if (this.user) {
        if (this.activeTab === "one_session") {
          this.$modal.show("therapist-availability", { sessionType: this.activeTab, value });
          return;
        }
        this.$modal.show("proceed-to-payment-package-modal");
      } else {
        const payload = {
          modal: this.activeTab === "one_session" ? "therapist-availability" : "proceed-to-payment-package-modal",
          additionalInfo: {
            sessionType: this.activeTab,
            value
          }
        };

        await this.$store.dispatch("therapistSlots/setShowNextModal", payload);

        this.$modal.show("sign-up-select-auth-modal", {
          sessionType: this.activeTab,
          value
        });
      }
    },

    selectOption(type, option) {
      if (type === "one_session") {
        this.selectedOneSession = option;
      } else {
        this.selectedPackages = option;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #44a4a5;
  cursor: pointer;
}

.subTitle {
  font-size: 16px;
  line-height: 24px;
  color: #434a57;
}

.titleWrapper {
  display: flex;
  flex-direction: column;

  .subTitlePackage {
    color: #939aa8;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;

    @include for-phone {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.option {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;

  .badge {
    position: absolute;
    color: white;
    border-radius: 8px;
    padding: 2px 8px;
    top: -13px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

    @include for-phone {
      font-size: 10px;
    }

    &.recommended {
      left: 24px;
      background: #623cea;
    }

    &.savePrice {
      right: 24px;
      background: #db2955;
    }

    &[dir="rtl"] {
      &.recommended {
        right: 24px;
        left: initial;
      }

      &.savePrice {
        left: 24px;
        right: initial;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 1rem;
    width: 100%;

    @include for-phone {
      font-size: 14px;
      line-height: 20px;
    }

    .detailsItem {
      &::before {
        content: "";
        border: 1px solid #434a57;
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 0.8rem;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #434a57;

    @include for-phone {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .priceWrapper {
    display: flex;
    gap: 8px;
  }

  .oldPrice {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: line-through;
    color: #939aa8;

    @include for-phone {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #347e7f;

    @include for-phone {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &.active {
    background: #e8fcf9;
    border: 1px solid #44a4a5;

    .title {
      color: #347e7f;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .divider {
    @apply mx-auto bg-divider h-px;
    width: 70%;
    margin: 0.5rem 0 1.5rem 0;
  }

  .tip {
    margin-bottom: 0.5rem;
    color: #939aa8;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>

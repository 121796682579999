import axios from "axios";

export default class CommonService {
  static async getWhiteList() {
    return await axios.get("/api/white-lists");
  }

  static async getCurrencyList() {
    return await axios.get("/api/v2/currencies");
  }

  static async getIP() {
    return await axios.get(
      `https://pro.ip-api.com/json/?key=${process.env.VUE_APP_IP_API_API_KEY}&fields=countryCode,query,currency`
    );
  }

  static async getPreflight() {
    return await axios.get(
      `https://api.ayadihealth.dev/api/preflight?platform=web&user_type=client&version=${process.env.VUE_APP_VERSION}`
    );
  }
}

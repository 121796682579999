<template>
  <modal :width="420" height="500" :adaptive="true" name="language" class="bg-none">
    <div class="bg-white h-full sm:w-11/12 sm:mx-auto flex flex-col items-start py-6 px-8 rounded-12px">
      <p class="text-18 font-semibold mb-2" :class="{ 'w-full text-right': arabic }">{{ $t("navbar.settings") }}</p>
      <div class="flex flex-row justify-start items-center mb-4">
        <p
          class="mr-4 text-sm font-semibold cursor-pointer pb-2 transition-all duration-300"
          :class="{ 'active-tab': settingsActiveTab === 'language' }"
          @click="$store.dispatch('ui/setActiveTab', 'language')"
        >
          {{ $t("main.languages") }}
        </p>
        <p
          class="mr-4 text-sm font-semibold cursor-pointer pb-2 transition-all duration-300"
          :class="{ 'active-tab': settingsActiveTab === 'currency' }"
          @click="$store.dispatch('ui/setActiveTab', 'currency')"
        >
          {{ $t("navbar.currency") }}
        </p>
      </div>
      <div v-if="settingsActiveTab === 'language'" class="w-full h-full">
        <div
          @click="selectEnglish"
          class="w-full mb-2 rounded-lg px-6 py-4 transition-all duration-200 hover:bg-bgLight cursor-pointer flex flex-row justify-between items-center"
          :class="{ 'flex-row-reverse': arabic, 'bg-bgLight': isEnglish }"
        >
          <p class="text-base font-semibold">English (US)</p>
          <img src="../assets/images/IconCheck.svg" alt="check" v-if="isEnglish" />
        </div>
        <div
          @click="selectArabic"
          class="w-full mb-6 rounded-lg px-6 py-4 transition-all duration-200 hover:bg-bgLight cursor-pointer flex flex-row justify-between items-center"
          :class="{ 'flex-row-reverse': arabic, 'bg-bgLight': !isEnglish }"
        >
          <p class="text-base font-semibold">العربية</p>
          <img src="../assets/images/IconCheck.svg" alt="check" v-if="!isEnglish" />
        </div>
      </div>
      <div v-else class="w-full h-full overflow-y-scroll">
        <div
          v-for="item in currencies"
          :key="item.id"
          @click="selectCurrency(item.to)"
          class="w-full mb-2 rounded-lg px-6 py-4 transition-all duration-200 hover:bg-bgLight cursor-pointer flex flex-row justify-between items-center"
          :class="{ 'flex-row-reverse': arabic, 'bg-bgLight': currency === item.to }"
        >
          <p class="text-base font-semibold">{{ item.title }} - {{ item.code_localized }}</p>
          <img src="../assets/images/IconCheck.svg" alt="check" v-if="currency === item.to" />
        </div>
      </div>
      <div class="flex w-full justify-between" :class="{ 'flex-row-reverse': arabic }">
        <SecondaryButton @click="$modal.hide('language')" :label="$t('main.close')" medium />
        <div></div>
      </div>
    </div>
  </modal>
</template>

<script>
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import storageClient from "@/services/storageClient";
import { mapState } from "vuex";

export default {
  name: "LocalePreferenceModal",
  components: { SecondaryButton },
  data() {
    return {
      isEnglish: true,
      currency: storageClient.getCurrency() || "USD"
    };
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    ...mapState("common", ["currencies"]),
    ...mapState("auth", ["user"]),
    ...mapState("ui", ["settingsActiveTab"])
  },
  methods: {
    async selectEnglish() {
      const trackData = {
        "Old value": storageClient.getLanguage(),
        "New Value": "en"
      };

      window.webengage.track("settings_language_updated", trackData);
      this.$mixpanel.track("settings_language_updated", trackData);

      storageClient.setLanguage("en");
      this.$i18n.locale = "en";
      this.isEnglish = true;
      localStorage.setItem("changed-lang", "1");
      if (this.$route.name === "TherapistProfile") {
        await this.$router
          .push({ name: "TherapistProfile", params: { id: this.$route.params.id, locale: "en" } })
          .catch(() => {});
        await this.$router.go(0);
      } else if (this.$route.name === "TherapistList") {
        await this.$router.push({ name: "TherapistList", params: { locale: "en" } }).catch(() => {});
        await this.$router.go(0);
      } else {
        await this.$router.go(0);
      }
    },
    async selectArabic() {
      const trackData = {
        "Old value": storageClient.getLanguage(),
        "New Value": "ar"
      };

      window.webengage.track("settings_language_updated", trackData);
      this.$mixpanel.track("settings_language_updated", trackData);

      storageClient.setLanguage("ar");
      this.$i18n.locale = "ar";
      this.isEnglish = false;
      localStorage.setItem("changed-lang", "1");
      if (this.$route.name === "TherapistProfile") {
        await this.$router
          .push({ name: "TherapistProfile", params: { id: this.$route.params.id, locale: "ar" } })
          .catch(() => {});
        await this.$router.go(0);
      } else if (this.$route.name === "TherapistList") {
        await this.$router.push({ name: "TherapistList", params: { locale: "ar" } }).catch(() => {});
        await this.$router.go(0);
      } else {
        await this.$router.go(0);
      }
    },

    async selectCurrency(currency) {
      const trackData = {
        "Old value": storageClient.getCurrency(),
        "New value": currency
      };

      window.webengage.track("settings_currency_updated", trackData);
      this.$mixpanel.track("settings_currency_updated", trackData);

      this.currency = currency;
      storageClient.setCurrency(currency);
      if (storageClient.getToken()) {
        await this.$store.dispatch("auth/updateProfile", { currency });
      }
      await this.$router.go(0);
    }
  },
  async created() {
    this.isEnglish = storageClient.getLanguage() ? storageClient.getLanguage() === "en" : true;

    if (storageClient.getToken()) {
      await this.$store.dispatch("auth/getProfile");
      if (this.user?.currency) {
        this.currency = this.user?.currency;
      } else {
        this.currency = storageClient.getCurrency();
        await this.$store.dispatch("auth/updateProfile", this.currency);
      }
    }

    const getCurrency = setInterval(() => {
      this.currency = storageClient.getCurrency();
      if (this.currency) {
        clearInterval(getCurrency);
      }
    }, 1000);
  }
};
</script>

<style scoped></style>

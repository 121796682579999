import dayjs from "dayjs";

import { calculatePrice } from "./price";
import i18n from "../i18n";

export const checkAvailabilityPackage = (therapistId, packages) => {
  if (!packages) {
    return null;
  }
  const foundedPackage = packages.find((packageItem) => packageItem.therapist_id === therapistId);

  if (!foundedPackage) {
    return false;
  }
  if (foundedPackage?.total_sessions_count === foundedPackage?.used_sessions_count) {
    return false;
  }
  const notValidDatePackages = dayjs().isAfter(dayjs(foundedPackage.valid_until));

  if (notValidDatePackages) {
    return false;
  }

  return foundedPackage;
};

export const checkPreviousSessions = (therapistId, packages) => {
  const foundedPackage = packages.find((packageItem) => packageItem.therapist_id === therapistId);

  return !!foundedPackage;
};

export const mapPackageOptions = (packages, hasPreviousSessions) => {
  const result = [];

  packages.map((option, index) => {
    if (option.type !== "session") {
      const packageOption = {
        ...option,
        id: index,
        title: i18n.t(`booking_options_modal.packages.${option.count}_packages`),
        subTitle: i18n.t("booking_options_modal.session.50min_each"),
        price: option.price_object_package_discounted
          ? calculatePrice(
              option.price_object_package_discounted.amount,
              option.price_object_package_discounted.divider
            )
          : calculatePrice(option.price_object_package_full.amount, option.price_object_package_full.divider),
        oldPrice: option.price_object_package_discounted
          ? calculatePrice(option.price_object_package_full.amount, option.price_object_package_full.divider)
          : null,
        currency: option.price_object_package_full.code_localized,
        value: option.count,
        options: getAdditionalPackagesOptions(option.count, hasPreviousSessions),
        recommended: option.is_popular
      };
      result.push(packageOption);
    }
  });

  return result;
};

export const mapSingleSessionsOptions = (sessions, hasPreviousSessions) => {
  const result = [];

  sessions.map((option, index) => {
    if (option.type === "session") {
      const oneSessionOption = {
        ...option,
        id: index,
        title:
          option.duration === 25
            ? i18n.t("booking_options_modal.session.25")
            : i18n.t("booking_options_modal.session.50"),
        price: calculatePrice(option.price_object.amount, option.price_object.divider),
        oldPrice: null,
        currency: option.price_object.code_localized,
        value: option.duration,
        options: getAdditionalSessionOptions(option.duration, hasPreviousSessions),
        recommended: option.is_popular
      };

      result.push(oneSessionOption);
    }
  });
  return result;
};

const getAdditionalSessionOptions = (option, hasPreviousSessions) => {
  if (hasPreviousSessions) {
    if (option === 50) {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.discuss_your_progress")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.learn_a_new_coping_skill")
        }
      ];
    } else {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.check_in_with_your_expert")
        }
      ];
    }
  } else {
    if (option === 50) {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.talk_about_your_goals")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.agree_on_a_therapy_plan")
        }
      ];
    } else {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.meet_your_expert")
        }
      ];
    }
  }
};

const getAdditionalPackagesOptions = (option, hasPreviousSessions) => {
  if (hasPreviousSessions) {
    if (option === 12) {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.achieve_long_lasting_change")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.self_evaluate_emotional_state")
        },
        {
          id: 3,
          title: i18n.t("booking_options_modal.additional_info.master_working_with_new_skills")
        }
      ];
    } else if (option === 6) {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.improve_your_mental_state")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.create_meaningful_change")
        },
        {
          id: 3,
          title: i18n.t("booking_options_modal.additional_info.manage_your_emotions_better")
        }
      ];
    } else {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.continue_therapy")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.uncover_more_insights")
        }
      ];
    }
  } else {
    if (option === 12) {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.reduce_stress")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.improve_relationships")
        },
        {
          id: 3,
          title: i18n.t("booking_options_modal.additional_info.increase_self_awareness")
        }
      ];
    } else if (option === 6) {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.know_yourself_better")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.learn_new_coping_skills")
        },
        {
          id: 3,
          title: i18n.t("booking_options_modal.additional_info.understand_your_emotional_triggers")
        }
      ];
    } else {
      return [
        {
          id: 1,
          title: i18n.t("booking_options_modal.additional_info.explore_therapy")
        },
        {
          id: 2,
          title: i18n.t("booking_options_modal.additional_info.get_initial_insights")
        }
      ];
    }
  }
};

export const getPreSelectPackage = (options, { count, type }) => {
  return options.find((option) => option.type === type && option.value.toString() === count.toString());
};

export const getPreSelectSession = (options, { sessionType, type }) => {
  return options.find((option) => option.type === type && option.sessionType === sessionType);
};

<template>
  <div class="w-full h-screen py-10 max-h-screen bg-gradient flex flex-col items-center justify-center">
    <modal class="h-auto" name="exit-alert" :width="400" height="auto">
      <div class="bg-white h-auto px-8 py-6 rounded-md">
        <h5 class="text-18" :class="{ 'w-full text-right': arabic }">{{ $t("sessions.leave_session") }}</h5>
        <p class="mt-4 mb-8" :class="{ 'w-full text-right': arabic }">{{ $t("sessions.are_u_sure") }}</p>
        <div class="flex flex-row items-center justify-between" :class="{ 'flex-row-reverse': arabic }">
          <SecondaryButton @click="$modal.hide('exit-alert')" medium :label="$t('sessions.no_stay')" />
          <MainButton
            @click="$router.push({ name: 'NoUpcomingSessions' }).catch(() => {})"
            class="mx-2 custom-button"
            type="medium"
          >
            {{ $t("sessions.yes_leave") }}
          </MainButton>
        </div>
      </div>
    </modal>
    <ChatWelcomeRoom
      :session-start-time="activeSession.session_start"
      :session-end-time="activeSession.session_end"
      :therapist-name="getTherapistName"
      v-if="showWelcomeRoom"
      @leave="logout"
      @join-session="joinSession"
    />
    <div v-if="!showWelcomeRoom" class="rounded-lg w-1/2 flex flex-col items-baseline chat-container relative">
      <div
        class="h-16 p-3 sticky top-0 rounded-t-lg bg-white w-full flex items-center justify-between"
        :class="{ 'flex-row-reverse': arabic }"
      >
        <div class="flex items-center" :class="{ 'flex-row-reverse': arabic }">
          <span class="ml-1 mr-2 p-2 cursor-pointer" :class="{ 'ml-2 mr-1': arabic }" @click.prevent="leaveSession">
            <IconArrowRight class="transform" :class="{ 'rotate-0': arabic, 'rotate-180': !arabic }" />
          </span>
          <div class="flex flex-col" :class="{ 'items-end': arabic }">
            <div class="flex items-center" :class="{ 'justify-end flex-row-reverse': arabic }">
              <div
                class="h-2 w-2 rounded-full border border-lightGray mr-2"
                :class="{ 'bg-green-500 border-none': isUserJoined, 'mr-0 ml-2': arabic }"
              ></div>
              <p class="text-base font-semibold">
                {{ getTherapistName }}
              </p>
            </div>

            <countdown
              class="flex flex-row items-start justify-start"
              :class="{ 'flex-row-reverse': arabic }"
              v-if="!sessionGoing && !isSessionEnded"
              @end="endSessionNotStarted"
              :time="timeRemaining"
              :transform="transform"
            >
              <template slot-scope="props" :class="{ 'flex flex-row-reverse': arabic }">
                <p class="font-semibold text-12 text-ayadiRed" :dir="direction">
                  {{ $t("sessions.session_starts_in") }}
                </p>
                <p class="font-semibold text-12 text-ayadiRed ml-1" :class="{ 'ml-0 mr-1': arabic }">
                  {{ props.minutes }} : {{ props.seconds }}
                </p>
              </template>
            </countdown>

            <countdown
              class="flex flex-row items-start justify-start"
              :class="{ 'flex-row-reverse': arabic }"
              v-if="sessionGoing && !isSessionEnded"
              ref="lastCountdown"
              @progress="handleCountdownProgress"
              @end="endSessionGoing"
              :time="sessionTimeRemaining"
              :transform="transform"
            >
              <template slot-scope="props">
                <p
                  class="font-semibold text-success text-12"
                  :dir="direction"
                  :class="{ 'text-warning': yellowCountdown }"
                >
                  {{ $t("sessions.session_ends_in") }}
                </p>
                <p
                  class="font-semibold ml-1 text-success text-12"
                  :class="{ 'text-warning': yellowCountdown, 'ml-0 mr-1': arabic }"
                >
                  {{ props.minutes }} : {{ props.seconds }}
                </p>
              </template>
            </countdown>
            <p v-if="sessionEnded || isSessionEnded" class="text-12 font-semibold text-ayadiRed">
              {{ $t("sessions.session_ended") }}
            </p>
          </div>
        </div>
        <MainButton v-if="sessionEnded || isSessionEnded" type="medium" @click="leaveSession">
          {{ $t("sessions.leave_session") }}
        </MainButton>

        <SecondaryButton v-else medium @click="leaveSession" :label="$t('sessions.leave_session')" />
      </div>
      <div ref="chat" class="w-full h-full bg-textGray-600 overflow-auto flex flex-col items-center p-4">
        <div v-if="showTypingIndicator" class="typing">
          <div class="text-sm bg-black-60% text-white rounded-6px py-1 px-3" :dir="direction">
            {{ getTherapistName }} {{ $t("sessions.typing") }}
          </div>
        </div>
        <div v-if="showScrollToBottom" class="scroll-button">
          <div
            v-if="unreadCount > 0"
            class="bg-ayadiRed rounded-full absolute unread z-50 mb-8 w-4 flex justify-center items-center text-12 text-white"
            :class="{ 'w-8': unreadCount > 99 }"
          >
            {{ unreadCount > 99 ? "99+" : unreadCount }}
          </div>
          <div
            @click="scrollToBottom"
            class="absolute bg-white rounded-full h-10 w-10 flex justify-center items-center cursor-pointer"
          >
            <IconArrowRight class="transform rotate-90" />
          </div>
        </div>
        <div
          class="flex-grow w-full flex flex-row mb-3 try-flex"
          :class="[
            message.author === authorEmail
              ? arabic
                ? 'justify-start to-left'
                : 'justify-end to-right'
              : arabic
              ? 'justify-end to-right'
              : 'justify-start to-left',
            { arabic }
          ]"
          v-for="(message, index) in messages"
          :ref="index"
          :key="message.id"
        >
          <div
            v-if="message.author === authorEmail"
            class="message h-full p-3 mb-3 rounded-lg w-auto chat-message"
            :class="{ arabic: arabic }"
          >
            <div class="flex flex-row justify-start items-baseline" :class="{ 'flex-row-reverse': arabic }">
              <p class="text-sm safari-fix" :dir="direction">{{ message.body }}</p>

              <div class="text-12 w-18 h-full text-right" :class="{ 'mr-4': arabic, 'ml-4': !arabic }">
                {{ time(message.dateUpdated) }}
              </div>
            </div>
          </div>

          <div v-else class="message h-full p-3 mb-3 rounded-lg w-auto chat-message">
            <div class="flex flex-row justify-start items-start" :class="{ 'flex-row-reverse': arabic }">
              <div
                v-if="!activeSession.therapist.avatar"
                class="w-8 h-8 text-12 bg-tileOutline rounded-md flex justify-center items-center"
                :class="{ 'ml-4': arabic, 'mr-4': !arabic }"
              >
                {{ getTherapistName[0] }}
              </div>
              <img
                v-else
                :src="activeSession.therapist.avatar"
                :style="{ 'background-color': activeSession.therapist.avatar_bg_color }"
                alt="avatar"
                class="w-8 h-8 rounded-md object-cover"
                :class="{ 'ml-4': arabic, 'mr-4': !arabic }"
              />
              <div class="flex flex-col">
                <div class="flex flex-row justify-between" :class="{ 'flex-row-reverse': arabic }">
                  <p
                    v-if="message.author !== authorEmail"
                    :dir="direction"
                    class="text-12 font-semibold text-lightGray"
                  >
                    {{ getTherapistName }}
                  </p>
                  <div
                    class="ml-4 text-12 w-18 h-full text-right text-lightGray"
                    :class="{ 'mr-4': arabic, 'ml-4': !arabic }"
                  >
                    {{ time(message.dateUpdated) }}
                  </div>
                </div>
                <p class="text-sm safari-fix" :dir="direction" :class="{ 'w-full text-right': arabic }">
                  {{ message.body }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div ref="footer"></div>
      </div>
      <!-- Input Field -->
      <div class="sticky bottom-0 p-3 rounded-b-lg bg-white w-full" @keydown="typing">
        <div class="w-full relative flex justify-center items-center" @keydown.enter.exact.prevent="sendMessage">
          <textarea-autosize
            :dir="direction"
            :placeholder="$t('sessions.type_here')"
            id="input"
            class="w-full py-2 bg-tileOutline text-sm border border-tileOutline resize-none focus:bg-tileOutline"
            :class="{ 'pl-10 pr-3': arabic, 'pl-3 pr-10': !arabic }"
            autocomplete="off"
            v-model="text"
            :min-height="5"
            rows="1"
            :max-height="142"
          />
          <IconSend
            @click="sendMessage"
            class="absolute right-0 top-50%"
            :class="{ 'left-0 translate-arabic top-25%': arabic, 'translate-center': !arabic }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Client from "twilio-chat";
import { mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import isToday from "dayjs/plugin/isToday";
import IconSend from "@/components/icons/IconSend";
import IconArrowRight from "@/components/icons/IconArrowRight";
import _ from "lodash";
import countdown from "@chenfengyuan/vue-countdown";
import MainButton from "@/components/ui-kit/MainButton";
import ChatWelcomeRoom from "@/components/chat/ChatWelcomeRoom";
import storageClient from "@/services/storageClient";

dayjs.extend(isToday);
export default {
  name: "Chat",
  components: {
    ChatWelcomeRoom,
    MainButton,
    IconArrowRight,
    IconSend,
    SecondaryButton,
    countdown
  },
  data() {
    return {
      members: null,
      client: null,
      messages: [],
      channels: [],
      text: "",
      authorEmail: "",
      chatChannel: null,
      isUserJoined: false,
      showScrollToBottom: false,
      unreadCount: 0,
      showTypingIndicator: false,
      sessionNotStarted: true,
      sessionGoing: false,
      yellowCountdown: false,
      showWelcomeRoom: true,
      wantToLeave: false,
      isSessionEnded: false
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("sessions", ["chatRoom", "activeSession"]),
    ...mapGetters("auth", ["getUser"]),
    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    getTherapistName() {
      return this.activeSession?.therapist ? this.activeSession?.therapist?.full_name || "Anonymous" : "Anonymous";
    },

    timeRemaining() {
      if (new Date().getTime() > new Date(this.activeSession.session_start).getTime()) {
        return 1;
      }
      return +(new Date(this.activeSession.session_start) - new Date());
    },

    sessionTimeRemaining() {
      if (new Date().getTime() > new Date(this.activeSession.session_end).getTime()) {
        return 1;
      }

      return +(new Date(this.activeSession.session_end) - new Date());
    },

    sessionEnded() {
      return new Date().getTime() > new Date(this.activeSession.session_end).getTime();
    }
  },
  methods: {
    async chatInitialize() {
      const client = await Client.create(this.chatRoom.access_token);

      this.chatChannel = await client.getChannelBySid(this.chatRoom.room_id);

      this.chatChannel.on("messageAdded", (message) => {
        this.messages.push(message.state);
        setTimeout(() => {
          if (!this.showScrollToBottom || message.state.author === this.authorEmail) {
            this.scrollToBottom();
          } else {
            this.unreadCount++;
          }
        }, 200);
      });

      await this.checkPatientJoined();

      this.chatChannel.on("typingStarted", (member) => {
        if (!(member?.identity === this.user?.uuid)) {
          this.showTypingIndicator = true;
        }
      });

      this.chatChannel.on("memberJoined", async () => {
        await this.checkPatientJoined();
      });

      this.chatChannel.on("typingEnded", (member) => {
        if (!(member?.identity === this.user?.uuid)) {
          this.showTypingIndicator = false;
        }
      });

      const messages = await this.chatChannel.getMessages(1000);

      this.messages = messages.items.map((m) => m.state);

      await this.showMessages();

      this.scrollToBottom();

      return this.chatChannel;
    },

    async checkPatientJoined() {
      // todo joined and left

      let membersCount = await this.chatChannel.getMembersCount();
      if (membersCount < 2) {
        return;
      }
      const member = await this.chatChannel.getMemberByIdentity(this.activeSession.therapist.email);
      if (member) {
        setInterval(async () => {
          let descriptor = await member.getUserDescriptor();
          this.isUserJoined = descriptor.online;
        }, 2000);
      }
    },

    sendMessage() {
      if (!this.text) {
        return;
      }

      let trimmed = this.text.replace(/^\s+/, "").replace(/\s+$/, "");

      if (!trimmed) {
        this.text = "";
        return;
      }

      this.chatChannel.sendMessage(this.text);
      this.text = "";
    },

    time(time) {
      let date = dayjs(time);

      if (date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")) {
        return date.format("hh:mm A");
      } else {
        return dayjs().diff(date, "day") + "d";
      }
    },

    async showMessages() {
      const messages = await this.chatChannel.getMessages(1000);
      this.messages = messages.items;
    },

    scrollToBottom() {
      if (this.$refs.footer) {
        this.$refs.footer.scrollIntoView({ behavior: "auto", inline: "end" });
      }
    },

    typing() {
      this.chatChannel.typing();
    },

    /* CountDown */
    endSessionNotStarted() {
      this.sessionNotStarted = false;
      this.sessionGoing = true;
      this.wantToLeave = false;
    },

    endSessionGoing() {
      this.$notify({ group: "end-session", text: "You need to set all required fields", classes: "toast-error" });
      this.isSessionEnded = true;
    },

    handleCountdownProgress(data) {
      if (data.minutes < 5) {
        this.yellowCountdown = true;
      }

      if (data.minutes === 5 && data.seconds === 0) {
        this.$notify({ group: "warning-session", text: "You need to set all required fields", classes: "toast-error" });
      }
      if (data.minutes === 0 && data.seconds === 0) {
        this.$notify({ group: "end-session", text: "You need to set all required fields", classes: "toast-error" });
        this.$refs.lastCountdown.abort();
        this.isSessionEnded = true;
        this.sessionGoing = false;
      }
    },

    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      return props;
    },

    scrollHandle() {
      let chat = this.$refs.chat;
      setTimeout(() => {
        this.showScrollToBottom = !(chat.scrollHeight - chat.scrollTop - chat.clientHeight <= 200);
        if (!this.showScrollToBottom) {
          this.unreadCount = 0;
        }
      }, 0);
    },

    async joinSession() {
      this.showWelcomeRoom = false;

      _.throttle(() => {});
      await this.chatInitialize();
      let chat = this.$refs.chat;
      chat.addEventListener("scroll", this.scrollHandle, { passive: true });
    },

    leaveSession() {
      if (this.sessionGoing) {
        this.$modal.show("exit-alert");
      } else {
        return this.$router.push({ name: "NoUpcomingSessions" }).catch(() => {});
      }
    },

    async logout() {
      await this.$store.dispatch("auth/logout");
    }
  },

  async created() {
    let session = await store.getters["sessions/getActiveSession"];
    if (!session) {
      return this.$router.push({ name: "NoUpcomingSessions" }).catch(() => {});
    }

    if (session) {
      if (session.type === "chat") {
        await store.dispatch("sessions/getChatRoom", session.id);
        await store.dispatch("auth/getProfile");
        this.authorEmail = this.user.email;

        if (new Date().getTime() > new Date(this.activeSession.session_start).getTime()) {
          this.sessionNotStarted = false;
          this.sessionGoing = true;
        }

        if (new Date().getTime() > new Date(this.activeSession.session_end).getTime()) {
          this.sessionNotStarted = false;
          this.sessionGoing = false;
          this.isSessionEnded = true;
        }
        return;
      } else {
        return this.$router.push({ name: "Video" });
      }
    }
    return this.$router.push({ name: "NoUpcomingSessions" }).catch(() => {});
  },

  async beforeRouteEnter(to, from, next) {
    await store.dispatch("sessions/fetchSessions");
    return next();
  },

  beforeDestroy() {
    if (this.$refs.chat) {
      this.$refs.chat.removeEventListener("scroll", this.scrollHandle);
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-container {
  @apply h-full;
}

.try-flex {
  flex: 0 0 auto;
}

.chat-message {
  @apply safari-fix;
  p {
    @apply break-words;
    max-width: 320px;
    white-space: pre-line;
  }
}
.to-left {
  .message {
    @apply bg-white;
  }
}

.to-right {
  .message {
    @apply bg-ayadi-900 text-white;
  }
}

.arabic.to-left {
  .message {
    @apply bg-ayadi-900 text-white;
  }
}

.to-right.arabic {
  .message {
    @apply bg-white text-textBlue;
  }
}

#input {
  @apply transition-all duration-200;
  border-radius: 12px;
  outline: none;
}

#input:focus {
  @apply border-ayadi-900;
}

.top-50\% {
  top: 50%;
}

.translate-center {
  transform: translate(-50%, -50%);
}

.translate-arabic {
  @apply pl-4 pr-0;
  transform: translate(0, -50%);
}

.scroll-button {
  @apply flex justify-center items-center;
  flex: 1 0 auto;
  top: 90%;
  transform: translate(-150%, -100%);
  left: 100%;
  position: absolute;
  div {
    position: sticky;
    bottom: 0.5rem;
    right: 0.5rem;
    box-shadow: 0 2px 10px rgba(25, 1, 52, 0.12);
  }
}

.safari-fix {
  overflow-wrap: break-word;
  white-space: pre-line;
  word-break: break-word;
}

.typing {
  @apply flex justify-center;
  flex: 1 0 auto;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 200;
  position: absolute;
  div {
    @apply text-center sticky;
    bottom: 0.5rem;
    min-width: 160px;
  }
}

.bg-black-60\% {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(40px);
}

.unread {
  transform: translateX(200%);
  &.w-8 {
    transform: translateX(100%);
  }
}

.top-25\% {
  top: 25%;
}
</style>

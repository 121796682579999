import Vue from "vue";
import Vuex from "vuex";
import * as auth from "@/store/modules/auth";
import * as sessions from "@/store/modules/sessions";
import * as therapistProfiles from "@/store/modules/therapist-profiles";
import * as therapistSlots from "@/store/modules/therapist-slots";
import * as common from "@/store/modules/common";
import * as ui from "@/store/modules/ui";
import * as chat from "@/store/modules/chat";
import * as therapistFilterDynamic from "@/store/modules/therapist-filter-dynamic";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    sessions,
    therapistProfiles,
    therapistSlots,
    common,
    ui,
    chat,
    therapistFilterDynamic
  }
});

<template>
  <modal
    @before-open="getBookedSlot"
    name="payment-modal"
    width="432"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div
      class="bg-white w-modal max-h-90 py-6 flex flex-col justify-center items-center rounded-lg"
      :dir="arabic ? 'rtl' : 'ltr'"
    >
      <div class="w-full overflow-y-auto">
        <div class="flex justify-between items-start mx-6 mb-4">
          <p class="text-18 font-semibold w-full" :class="arabic ? 'text-right' : 'text-left'">
            {{ $t("payments.payment_type") }}
          </p>
          <div class="cursor-pointer px-1 py-1" @click="$modal.hide('payment-modal')">
            <IconCloseGray />
          </div>
        </div>

        <div v-if="!isChargeable" class="flex justify-start items-center mx-6 p-3 rounded-2xl custom-shadow-xl mb-4">
          <img :class="arabic ? 'ml-3' : 'mr-3'" src="../../../assets/images/IconRefresh.svg" alt="icon" />
          <div v-if="!arabic">
            <p class="font-semibold text-lightGray">You may be charged a slightly different amount.</p>
            <a
              href="https://help.ayadihealth.co/en/articles/5746022-why-am-i-charged-more-than-originally-communicated"
              target="_blank"
              class="font-semibold text-ayadi-900 text-sm no-underline"
              @click="trackLearnMore"
            >
              Learn why
            </a>
          </div>
          <div v-else>
            <p class="font-semibold text-lightGray">
              المبلغ تقريبي ويمكن أن يختلف بشكل بسيط عند اجراء عملية الشراء.
              <a
                href="https://help.ayadihealth.co/ar/articles/5746022-why-am-i-charged-more-than-originally-communicated"
                target="_blank"
                class="font-semibold text-ayadi-900 text-sm no-underline"
                @click="trackLearnMore"
              >
                اضغط هنا
              </a>
              لمعرفة المزيد.
            </p>
          </div>
        </div>

        <div
          @click="enterCard"
          class="flex justify-start items-center cursor-pointer p-6 custom-shadow-xl rounded-2xl mx-6 payment_method"
        >
          <img src="../../../assets/images/IconCard.svg" :class="arabic ? 'ml-4' : 'mr-4'" alt="" />
          <div class="flex w-full justify-start items-center">
            <p class="text-18 font-semibold w-full" :class="arabic ? 'text-right' : 'text-left'">
              {{ $t("payments.other_cards") }}
            </p>
            <IconArrowRight :class="{ 'transform rotate-180': arabic }" color="#939AA8" />
          </div>
        </div>

        <div
          v-if="showKnet"
          @click="createKnet"
          class="flex justify-start items-center p-6 rounded-2xl custom-shadow-xl cursor-pointer mx-6 mt-4 payment_method"
        >
          <img :class="arabic ? 'ml-4' : 'mr-4'" class="w-56px" src="../../../assets/images/knet.png" alt="knet" />
          <div class="flex w-full justify-start items-center">
            <p class="text-18 font-semibold w-full" :class="arabic ? 'text-right' : 'text-left'">
              {{ $t("payments.kuwait_card") }}
            </p>
            <IconArrowRight :class="{ 'transform rotate-180': arabic }" color="#939AA8" />
          </div>
        </div>

        <div class="flex justify-start items-start mx-5 mt-5">
          <img src="../../../assets/images/IconShield.svg" :class="arabic ? 'ml-2' : 'mr-2'" alt="shield" />

          <p class="font-semibold text-12 text-lightGray">
            {{ $t("payments.redirected") }}
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import storageClient from "@/services/storageClient";
import SessionsClient from "@/services/sessionsClient";
import { mapState } from "vuex";
import { AnalyticsTrackData } from "@/services/analytics";
import IconArrowRight from "@/components/icons/IconArrowRight";

export default {
  name: "PaymentModal",
  components: {
    IconArrowRight,
    IconCloseGray
  },
  data() {
    return {
      isLoading: false,
      slot: null
    };
  },

  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("sessions", ["order", "coupon"]),
    ...mapState("common", ["currencies"]),
    price() {
      let price;
      if (this.slot.type === "video") {
        price =
          this.therapistProfile.video_price_in_kwd_object.amount /
          this.therapistProfile.video_price_in_kwd_object.divider;
      } else {
        price =
          this.therapistProfile.video_small_price_in_kwd_price_object.amount /
          this.therapistProfile.video_small_price_in_kwd_price_object.divider;
      }

      return price;
    },
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    showKnet() {
      return storageClient.getCurrency() === "KWD";
    },
    // todo rework later
    isChargeable() {
      const currentCurrency = storageClient.getCurrency();
      for (let i = 0; i < this.currencies.length; i++) {
        const currency = this.currencies[i];
        if (currency.to === currentCurrency) {
          return currency.is_chargeable;
        }
      }
      return false;
    },

    getPrice() {
      if (!this.slot) {
        return "";
      }

      return this.slot.type === "video"
        ? this.therapistProfile.video_price_object.amount / this.therapistProfile.video_price_object.divider +
            " " +
            this.therapistProfile.video_price_object.currency
        : this.therapistProfile.video_small_price_object.amount /
            this.therapistProfile.video_small_price_object.divider +
            " " +
            this.therapistProfile.video_small_price_object.currency;
    },

    getCouponPrice() {
      const price = this.coupon.value_object;
      return price.amount / price.divider + " " + price.code_localized;
    }
  },

  methods: {
    getBookedSlot() {
      this.slot = storageClient.getBookingSlot();
    },
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";

      const trackData = Object.assign(
        AnalyticsTrackData.getStandardTherapistProperties(this.therapistProfile),
        AnalyticsTrackData.getStandardSlotProperties(this.slot, this.price)
      );

      this.$mixpanel.track("payment_selection_view", trackData);
      this.$intercom.trackEvent("payment_selection_view", trackData);
      window.webengage.track("payment_selection_view", trackData);
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    enterCard() {
      const trackData = Object.assign(
        {
          "Payment method": "Credit card"
        },
        AnalyticsTrackData.getStandardSlotProperties(this.slot, this.price),
        AnalyticsTrackData.getStandardTherapistProperties(this.therapistProfile)
      );

      this.$mixpanel.track("booking_paymentMethod_select", trackData);
      this.$intercom.trackEvent("booking_paymentMethod_select", trackData);
      window.webengage.track("booking_paymentMethod_select", trackData);

      this.$modal.hide("payment-modal");
      this.$modal.show("card-modal");
    },
    async createKnet() {
      const trackData = Object.assign(
        {
          "Payment method": "Knet"
        },
        AnalyticsTrackData.getStandardSlotProperties(this.slot, this.price),
        AnalyticsTrackData.getStandardTherapistProperties(this.therapistProfile)
      );

      this.$mixpanel.track("booking_paymentMethod_select", trackData);
      this.$intercom.trackEvent("booking_paymentMethod_select", trackData);
      window.webengage.track("booking_paymentMethod_select", trackData);

      this.$modal.hide("payment-modal");
      this.$modal.show("loader-modal");

      const { data } = await SessionsClient.createOrder({ type: "knet", session_id: this.slot.session_id });
      const link = document.getElementById("link");

      link.href = data.url;
      link.click();

      await this.checkOrderStatus(data.id);
    },

    async checkOrderStatus(id) {
      const checkOrderStatus = setInterval(async () => {
        await this.$store.dispatch("sessions/getOrder", id);

        if (this.order && this.order?.status === "failed") {
          this.$modal.hide("loader-modal");
          this.$modal.show("payment-failed");
          clearInterval(checkOrderStatus);
        }

        if (this.order && this.order?.status === "ready") {
          this.$modal.hide("loader-modal");
          await this.$router.push({ name: "PaymentSuccess", query: { orderId: id + "" } }).catch(() => {});
          clearInterval(checkOrderStatus);
        }
      }, 5000);
    },

    trackLearnMore() {
      const trackData = Object.assign(
        {
          Currency: storageClient.getCurrency()
        },
        AnalyticsTrackData.getStandardSlotProperties(this.slot, this.price),
        AnalyticsTrackData.getStandardTherapistProperties(this.therapistProfile)
      );

      this.$mixpanel.track("booking_exchangeRateDisclaimer_click", trackData);
      this.$intercom.trackEvent("booking_exchangeRateDisclaimer_click", trackData);
      window.webengage.track("booking_exchangeRateDisclaimer_click", trackData);
    }
  }
};
</script>

<style scoped>
.w-56px {
  width: 56px;
}
</style>

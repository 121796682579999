<template>
  <div class="scroll-button">
    <div
      v-if="unreadCount > 0"
      class="bg-ayadiRed rounded-full absolute unread z-50 mb-8 w-6 h-6 flex justify-center items-center text-12 text-white"
      :class="{ 'w-small': unreadCount > 99 }"
    >
      {{ unreadCount > 99 ? "99+" : unreadCount }}
    </div>
    <div
      @click="$emit('scroll-to-bottom')"
      class="absolute bg-white rounded-full h-10 w-10 flex justify-center items-center cursor-pointer"
    >
      <IconArrowRight class="transform rotate-90" />
    </div>
  </div>
</template>

<script>
import IconArrowRight from "../icons/IconArrowRight";
export default {
  name: "ScrollToBottom",
  components: { IconArrowRight },
  props: {
    unreadCount: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-button {
  @apply flex justify-center items-center;
  flex: 1 0 auto;
  top: 90%;
  transform: translate(-150%, -100%);
  left: 50%;
  position: absolute;
  div {
    position: sticky;
    bottom: 0.5rem;
    right: 0.5rem;
    box-shadow: 0 2px 10px rgba(25, 1, 52, 0.12);
  }
}

.unread {
  transform: translateX(120%);
  &.w-small {
    font-size: 8px;
  }
}
</style>

<template>
  <div class="w-full">
    <input
      ref="password"
      @focus="$emit('focus')"
      @blur="$emit('blur', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      type="password"
    />
    <IconPasswordEye
      @click="toggleEye"
      class="cursor-pointer"
      :class="arabic ? 'eye-arabic' : 'eye'"
      :is-open="!isOpen"
    />
  </div>
</template>

<script>
import IconPasswordEye from "@/components/icons/IconPasswordEye";
import storageClient from "@/services/storageClient";

//todo bind type

export default {
  name: "PasswordInput",
  components: {
    IconPasswordEye
  },

  data() {
    return {
      isOpen: false
    };
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    toggleEye() {
      let input = this.$refs.password;
      if (!this.isOpen) {
        input.setAttribute("type", "text");
      } else {
        input.setAttribute("type", "password");
      }
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style scoped>
.eye {
  position: absolute;
  right: 0.5rem;
  top: 2.55rem;
}
.eye-arabic {
  position: absolute;
  left: 0.5rem;
  top: 2.55rem;
}
</style>

<template>
  <div>
    <label
      class="rounded-full p-1/2 w-11 flex h-6 relative select-none transition-all duration-300 ease-in-out"
      :class="{
        'bg-ayadi-900': checked,
        'bg-textGray-400': !checked,
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled
      }"
    >
      <span
        v-if="arabic"
        class="h-5 w-5 rounded-full bg-white absolute flex justify-center items-center transform transition-all duration-300 ease-in-out checkbox-shadow"
        :class="checked ? 'translate-x-left' : 'translate-x-right'"
      >
        <transition name="fade" mode="out-in">
          <img
            v-if="!checked"
            class="h-3 w-3"
            src="../../assets/images/Checkbox/unchecked.svg"
            alt="x"
            key="unchecked"
          />
          <img v-else class="h-3 w-3" src="../../assets/images/Checkbox/checkmark.svg" alt="x" key="checked" />
        </transition>
      </span>
      <span
        v-else
        class="h-5 w-5 rounded-full bg-white absolute flex justify-center items-center transform transition-all duration-300 ease-in-out checkbox-shadow"
        :class="checked ? 'translate-x-right' : 'translate-x-left'"
      >
        <transition name="fade" mode="out-in">
          <img
            v-if="!checked"
            class="h-3 w-3"
            src="../../assets/images/Checkbox/unchecked.svg"
            alt="x"
            key="unchecked"
          />
          <img v-else class="h-3 w-3" src="../../assets/images/Checkbox/checkmark.svg" alt="x" key="checked" />
        </transition>
      </span>
      <span class="hidden">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";

export default {
  name: "AyadiCheckbox",
  props: {
    checked: {
      required: true,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  }
};
</script>

<style lang="scss" scoped>
.p-1\/2 {
  padding: 0.125rem;
}

.left-2px {
  left: 4px;
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transform: scale(0.96);
  transition: all 0.15s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0.3;
  transform: scale(1);
}

.checkbox-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.translate-x-right {
  transform: translateX(20px);
}

.translate-x-left {
  transform: translateX(0px);
}

.w-11 {
  width: 2.75rem;
}
</style>

<template>
  <div class="bg-white w-full rounded-2xl profile-section-shadow" :dir="direction">
    <div class="py-5 px-4 border-b">
      <p class="text-18 font-semibold">{{ $t("profiles.therapist_info") }}</p>
      <p class="text-textGray-500">{{ $t("profiles.personal_details") }}</p>
    </div>
    <div class="w-full">
      <TherapistInformationRow is-odd :content="$t('profiles.years', { years: therapistProfile.experience })">
        {{ $t("profiles.experience") }}
      </TherapistInformationRow>
      <TherapistInformationRow :content="therapistProfile.qualification_tags">
        {{ $t("profiles.qualifications") }}
      </TherapistInformationRow>
      <TherapistInformationRow is-odd :content="sessionTypes" type="chips">
        {{ $t("profiles.session_types") }}</TherapistInformationRow
      >
      <TherapistInformationRow :content="languages" type="chips">
        {{ $t("profiles.languages") }}
      </TherapistInformationRow>
      <TherapistInformationRow is-odd :content="therapistProfile.speciality" type="chips">
        {{ $t("profiles.specialize_in") }}
      </TherapistInformationRow>
      <TherapistInformationRow class="rounded-b-2xl" :content="therapistProfile.treatment" type="chips">
        {{ $t("profiles.approach") }}
      </TherapistInformationRow>
    </div>
  </div>
</template>

<script>
import TherapistInformationRow from "@/components/therapist-profile/TherapistInformationRow";
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";
export default {
  name: "TherapistInfo",
  components: { TherapistInformationRow },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    sessionTypes() {
      const types = [];
      if (this.therapistProfile.is_video_small_session_offered) {
        types.push(this.$t("profiles.25_min"));
      }
      if (this.therapistProfile.is_video_session_offered) {
        types.push(this.$t("profiles.50_min"));
      }
      return types;
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    languages() {
      const langs = this.therapistProfile.languages.map((l) => {
        return l.charAt(0).toUpperCase() + l.slice(1);
      });
      return langs;
    }
  }
};
</script>

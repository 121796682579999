<template>
  <div class="h-screen bg-bgDark">
    <div class="h-full flex justify-center items-center">
      <div
        class="flex flex-row justify-between items-start bg-white rounded-md p-8 w-sign-in auth-form-wrapper relative"
      >
        <div class="absolute logo-position">
          <LogoLight />
        </div>
        <form @submit.prevent="login" v-if="!loggedIn" class="px-4 h-full w-full form-height">
          <div class="h-full flex flex-col justify-between items-center">
            <div class="w-full">
              <h5 class="text-center" :dir="arabic ? 'rtl' : 'ltr'">{{ $t("auth.welcome") }}</h5>
              <p class="text-center mt-4">{{ $t("auth.in_order") }}</p>
              <InputWrapper :class="{ 'items-end': arabic }" :label="$t('auth.email')" :error-message="emailErrorText">
                <input
                  :dir="arabic ? 'rtl' : 'ltr'"
                  slot="input"
                  type="text"
                  @focus="emailErrorText = ''"
                  v-model="email"
                />
              </InputWrapper>

              <InputWrapper
                class="w-full"
                :class="{ 'items-end': arabic }"
                :label="$t('auth.password')"
                :error-message="passwordErrorText"
              >
                <PasswordInput
                  :dir="arabic ? 'rtl' : 'ltr'"
                  v-model="password"
                  @focus="passwordErrorText = ''"
                  slot="input"
                />
              </InputWrapper>

              <div class="flex justify-between">
                <p>
                  <router-link :to="{ name: 'ForgotPassword' }" class="no-underline font-extrabold">
                    {{ $t("auth.forgot_password") }}
                  </router-link>
                </p>

                <p v-if="signIn">
                  {{ $t("auth.dont_have_account") }}
                  <span @click="signIn = false" class="font-extrabold no-underline text-ayadi-900 cursor-pointer">{{
                    $t("auth.sign_up")
                  }}</span>
                </p>

                <p v-else>
                  {{ $t("auth.have_account") }}
                  <span @click="signIn = true" class="font-extrabold no-underline text-ayadi-900 cursor-pointer">
                    {{ $t("auth.sign_in") }}
                  </span>
                </p>
              </div>
            </div>
            <MainButton class="w-full" type="medium">{{ signIn ? $t("auth.sign_in") : $t("auth.sign_up") }}</MainButton>

            <div class="flex justify-between items-center w-full">
              <div class="bg-lightGray h-px w-40"></div>
              <p class="text-lightGray font-semibold w-full text-center mx-2">{{ $t("auth.continue") }}</p>
              <div class="bg-lightGray h-px w-40"></div>
            </div>
            <div class="flex items-stretch w-full space-x-2">
              <div
                class="h-10 w-full bg-facebook rounded-md flex justify-center items-center cursor-pointer"
                @click="FacebookAuth"
              >
                <img src="../assets/images/facebook.svg" alt="facebook" />
              </div>
              <div
                class="h-10 w-full bg-google rounded-md flex justify-center items-center cursor-pointer"
                @click="GoogleAuth"
              >
                <img src="../assets/images/google.svg" alt="google" />
              </div>
              <div
                class="h-10 w-full bg-apple rounded-md flex justify-center items-center cursor-pointer"
                @click="AppleAuth"
              >
                <img src="../assets/images/apple.svg" alt="apple" />
              </div>
            </div>
          </div>
        </form>
        <div v-else class="flex items-center flex-col w-full">
          <h2 class="text-lg text-center font-extrabold">Waiting for authorization ...</h2>
          <div class="py-20 h-80 flex justify-center items-center w-full h-full bg-white">
            <img class="w-20 animate-bounce" src="../assets/images/ayadi-loader.svg" alt="Ayadi" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoLight from "@/components/ui-kit/LogoLight";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import PasswordInput from "@/components/ui-kit/PasswordInput";
import MainButton from "@/components/ui-kit/MainButton";
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";
import store from "@/store";
export default {
  name: "CircleAuth",
  components: { MainButton, PasswordInput, InputWrapper, LogoLight },
  data() {
    return {
      email: "",
      password: "",
      emailErrorText: "",
      passwordErrorText: "",
      loggedIn: false,
      signIn: false
    };
  },

  head: {
    title() {
      return {
        inner: "Ayadi",
        separator: "|",
        complement: this.arabic ? "تسجيل الدخول" : "Login"
      };
    }
  },
  computed: {
    ...mapState("sessions", ["activeSession"]),
    ...mapState("auth", ["notRegisteredFromSocial", "notifications", "user"]),
    ...mapState("common", ["ip", "currentIPData"]),
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },
  methods: {
    async oauth() {
      window.location = `https://api.ayadihealth.co/api/oauth-circle/auth?api_token=${storageClient.getToken()}&user_type=client`;
    },
    async login() {
      if (this.email.length < 5) {
        this.emailErrorText = this.$t("auth.email_required");
      }
      if (this.password.length < 8) {
        this.passwordErrorText = this.$t("auth.password_required");
      }
      if (!this.passwordErrorText && !this.emailErrorText) {
        if (this.signIn) {
          await this.$store.dispatch("auth/login", { email: this.email, password: this.password });
        } else {
          await this.$store.dispatch("auth/signUp", { email: this.email, password: this.password });
        }

        await this.oauth();
      }
    },

    async GoogleAuth() {
      let googleUser;
      try {
        googleUser = await this.$gAuth.signIn();

        const response = await googleUser?.getAuthResponse();

        await store.dispatch("auth/socialAuth", {
          driver: "google",
          token: response.access_token,
          lang: storageClient.getLanguage()
        });
        await this.$store.dispatch("auth/getProfile");
        await this.oauth();
      } catch (e) {
        console.error(e);
      }
    },

    async AppleAuth() {
      window.AppleID.auth.init({
        clientId: process.env.VUE_APP_APPLE_APP_ID,
        redirectURI: `https://${process.env.VUE_APP_HOST}/apple/sign-in/apple-redirect`,
        scope: process.env.VUE_APP_APPLE_AUTH_SCOPE || "",
        usePopup: true //or false defaults to false
      });

      let data = await window.AppleID.auth.signIn();
      await store.dispatch("auth/socialAuth", {
        driver: "apple",
        token: data.authorization.id_token,
        lang: storageClient.getLanguage()
      });
      await this.$store.dispatch("auth/getProfile");

      await this.oauth();
    },

    async FacebookAuth() {
      window.sessionStorage.clear();
      if (window?.cookieStore) {
        await window?.cookieStore?.getAll().then((cookies) =>
          cookies.forEach((cookie) => {
            window.cookieStore.delete(cookie.name);
          })
        );
      }

      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();

      window.FB.getLoginStatus(async function (response) {
        if (response.status === "connected") {
          await store.dispatch("auth/socialAuth", { driver: "facebook", token: response.authResponse.accessToken });
        } else {
          window.FB.login(async function (response) {
            if (response.authResponse) {
              await store.dispatch("auth/socialAuth", {
                driver: "facebook",
                token: response.authResponse.accessToken,
                lang: storageClient.getLanguage()
              });
            } else {
              this.$notify({
                group: "errors",
                text: "User cancelled login or did not fully authorize",
                classes: "toast-error"
              });
            }
          });
        }
      });

      setTimeout(async () => {
        await store.dispatch("auth/getProfile");

        await this.oauth();
      }, 2000);
    },

    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID, //You will need to change this
          cookie: true, // This is important, it's not enabled by default,
          scope: "public_profile,email",
          version: "v13.0"
        });
      };
    },

    async loadFacebookSDK(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
  },
  async created() {
    this.loggedIn = !!storageClient.getToken();

    if (this.loggedIn) {
      await this.oauth();
    }

    await this.loadFacebookSDK(document, "script", "facebook-jssdk");
    await this.initFacebook();
  }
};
</script>

<style scoped>
.w-sign-in {
  width: 450px;
}
</style>

<template>
  <div class="h-8 w-8 rounded-full bg-ayadi-900 flex justify-center items-center" :class="{ 'ml-0': arabic }">
    <img class="w-3 h-auto" src="../../assets/images/full_arrow_right.svg" alt="arrow-right" />
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";

export default {
  name: "IconCircleArrowRight",

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  }
};
</script>

<style scoped></style>

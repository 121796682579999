import dayjs from "dayjs";

export const dayBefore = (day) => {
  if (day === "Mon") {
    return "Sun";
  } else if (day === "Tue") {
    return "Mon";
  } else if (day === "Wed") {
    return "Tue";
  } else if (day === "Thu") {
    return "Wed";
  } else if (day === "Fri") {
    return "Thu";
  } else if (day === "Sat") {
    return "Fri";
  } else if (day === "Sun") {
    return "Sat";
  }
};

export const timeStringToNumber = (time) => {
  const hours = time.slice(0, 2);
  return Number(hours);
};

export const numberTimeToString = (value) => {
  if (value < 10) {
    return `0${value}:00`;
  }
  return `${value}:00`;
};

export const getMonthDuration = (date) => {
  const selectedDate = dayjs(date);
  const today = dayjs();

  return selectedDate.diff(today, "month");
};

import Vue from "vue";
import dayjs from "dayjs";
import { calculatePrice } from "./price";
import { capitalize } from "./text";

export const getSessionProperties = (slot) => {
  const properties = {
    pricing: slot.properties.price || "",
    "session type": slot?.type || "",
    "session date": slot?.start || "",
    "Booking flow type": "session"
  };

  return properties;
};

export const getTherapistProperties = (therapist) => {
  const properties = {
    "Therapist name": therapist.name_en || null,
    "Has intro video": therapist?.video_preview_ar || therapist?.video_preview_en || false,
    "Therapist speciality": therapist?.skill_en || "",
    "Therapist video price":
      therapist?.video_price_in_kwd_object?.amount / therapist?.video_price_in_kwd_object?.divider,
    "Therapist years of experience": therapist?.experience || "",
    "Therapist education": therapist?.qualification_en || "",
    "Therapist language": capitalize(therapist?.languages, false),
    "Therapist gender": therapist?.gender.toLowerCase(),
    "Therapist video small price":
      therapist?.video_small_price_in_kwd_price_object?.amount /
      therapist?.video_small_price_in_kwd_price_object?.divider
  };

  return properties;
};

export const getStandardSlotProperties = (slot, price) => {
  return {
    "Device type": "Web",
    "User type": "Client",
    pricing: price,
    "Slot ID": slot.session_id,
    "Session date": slot.start,
    "Hours until session": dayjs(slot.start).diff(dayjs(), "hour"),
    "session type": slot.type
  };
};

export const getPackagesProperties = (packages) => {
  const properties = {
    "Package order ID": packages.id || "",
    "Price in KWD": calculatePrice(packages.price_in_kwd_object.amount, packages.price_in_kwd_object.divider),
    "Package progress": `${packages.used_sessions_count}/${packages.total_sessions_count}`,
    "Package deadline": packages?.valid_until || ""
  };

  return properties;
};

export const getUTMProperties = (query) => {
  const properties = {
    ...(query?.utm_source && { source: query.utm_source }),
    ...(query?.utm_medium && { medium: query.utm_medium }),
    ...(query?.utm_campaign && { campaign: query.utm_campaign }),
    ...(query?.utm_content && { content: query.utm_content })
  };

  return properties;
};

export const getHoursUntilSession = (sessionTime) => {
  const nowTime = dayjs();
  const formattedSessionTime = dayjs(sessionTime);
  return formattedSessionTime.diff(nowTime, "hour");
};

export const getBookingOptionName = (count) => {
  switch (count) {
    case 12:
      return "12x50-min package";
    case "twelve_sessions_full":
      return "12x50-min package";
    case 6:
      return "6x50-min package";
    case "six_sessions_full":
      return "6x50-min package";
    case 3:
      return "3x50-min package";
    case "three_sessions_full":
      return "3x50-min package";
    default:
      return "";
  }
};

export const sendAllEvents = ({ event_name, track_data }) => {
  window.webengage.track(event_name, track_data);
  Vue.prototype.$mixpanel.track(event_name, track_data);
  Vue.prototype.$intercom.trackEvent(event_name, track_data);
  window.gtag("event", event_name, track_data);
};

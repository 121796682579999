<template>
  <div>
    <a href="" class="hidden" target="_blank" ref="link" id="link"></a>
    <modal
      name="packages-success-bought-modal"
      width="450"
      height="auto"
      :adaptive="true"
      :clickToClose="false"
      @before-open="beforeOpen"
      @opened="blockScroll"
      @closed="unblockScroll"
    >
      <div
        class="bg-white w-modal max-h-90 p-6 flex flex-col justify-center items-center rounded-lg"
        :dir="arabic ? 'rtl' : 'ltr'"
      >
        <div class="w-full overflow-y-auto">
          <div class="flex justify-between items-start w-full mb-4">
            <div class="cursor-pointer px-1 py-1" @click="goBack">
              <IconCloseGray />
            </div>
          </div>

          <div class="px-5 flex flex-col justify-start items-start">
            <img class="image" src="../../../assets/images/congratulations.png" alt="congratulations" />
            <div class="title">{{ $t("packages_success_bought.title") }}</div>

            <div class="flex flex-col gap-y-6 justify-between items-start text">
              <div>{{ $t("packages_success_bought.p-1") }}</div>
              <div>
                <span class="highlight">{{ $t("packages_success_bought.next") }}</span>
                {{ $t("packages_success_bought.p-2") }}
              </div>
              <div>{{ $t("packages_success_bought.p-3", { name: therapistProfile.full_name }) }}</div>
              <div class="font-semibold">{{ $t("packages_success_bought.ready") }}</div>
            </div>
          </div>
          <div class="flex justify-between w-full items-center mt-6">
            <SecondaryButton @click="goBack" medium :label="$t('packages_success_bought.book_later')" />
            <MainButton @click="bookSession">
              {{ $t("packages_success_bought.book") }}
            </MainButton>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import storageClient from "@/services/storageClient";
import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import { sendAllEvents, getBookingOptionName, getTherapistProperties, getPackagesProperties } from "@/utils/events";
import { checkAvailabilityPackage } from "@/utils/packages";
import { calculatePrice } from "@/utils/price";

import { mapState } from "vuex";

export default {
  name: "PackagesSuccessBoughtModal",
  components: {
    SecondaryButton,
    MainButton,
    IconCloseGray
  },
  data() {
    return {
      validPackages: false
    };
  },

  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("sessions", ["bookedPackages"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    async beforeOpen() {
      await this.$store.dispatch("sessions/setBookedPackages");
      this.validPackages = await checkAvailabilityPackage(this.therapistProfile.id, this.bookedPackages);
      await this.$store.dispatch("sessions/setBookedPackagesForTherapist", this.validPackages);

      if (!this.validPackages) {
        this.goBack();
        return;
      }

      if (this.validPackages && this.therapistProfile) {
        const trackData = {
          "Booking option": getBookingOptionName(this.validPackages.total_sessions_count),
          ...getTherapistProperties(this.therapistProfile),
          ...getPackagesProperties(this.validPackages)
        };
        sendAllEvents({ event_name: "package_confirmation_view", track_data: trackData });
      }
    },

    async bookSession() {
      this.$modal.hide("packages-success-bought-modal");

      if (this.validPackages && this.therapistProfile) {
        const trackData = {
          "Booking option": getBookingOptionName(this.validPackages.total_sessions_count),
          "Price in KWD": calculatePrice(
            this.validPackages.price_in_kwd_object.amount,
            this.validPackages.price_in_kwd_object.divider
          ),
          ...getTherapistProperties(this.therapistProfile),
          ...getPackagesProperties(this.validPackages)
        };
        sendAllEvents({ event_name: "package_confirmationBookSession_click", track_data: trackData });

        this.$modal.show("therapist-availability");
      }
    },

    goBack() {
      if (this.validPackages && this.therapistProfile) {
        const trackData = {
          "Booking option": getBookingOptionName(this.validPackages.total_sessions_count),
          "Price in KWD": calculatePrice(
            this.validPackages.price_in_kwd_object.amount,
            this.validPackages.price_in_kwd_object.divider
          ),
          ...getTherapistProperties(this.therapistProfile)
        };
        sendAllEvents({ event_name: "package_ConfirmationClose_click", track_data: trackData });
      }
      this.$modal.hide("packages-success-bought-modal");
    }
  }
};
</script>

<style lang="scss" scoped>
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  .highlight {
    font-weight: 600;
  }
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  width: 100%;
  margin-bottom: 1.5rem;
}

.image {
  max-height: 250px;
}
</style>

<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center text-lg font-semibold" :class="{ 'flex-row-reverse': arabic }">
      {{ data.data.title }}
    </div>
    <div class="flex" :class="{ 'flex-row-reverse': arabic }">{{ data.data.description }}</div>

    <div
      class="choose-btn mt-2 self-start"
      :class="{ 'flex-row-reverse self-end specialty': arabic }"
      @click="showModal"
    >
      <img src="../../assets/images/filter/visibility.svg" />
      {{ data.data.expanded_view_button_text || $t("filter.expand_button", { title: data.data.short_title }) }}
    </div>

    <div class="flex flex-col flex-wrap mb-8" :class="{ 'justify-end self-end': arabic }">
      <div v-if="data.value.length > 0">
        <div v-if="arabic" class="block selectedWrapper" :class="{ descriptionText: arabic }">
          <span class="" :class="{ 'flex-row-reverse': arabic }"
            >{{ $t("filter.modals.selected") }} {{ data.value.length }}:</span
          >
          {{ getSelectedTitles() }}
        </div>
        <div v-else class="block selectedWrapper">
          <span>{{ data.value.length }} {{ $t("filter.modals.selected") }}:</span>
          {{ getSelectedTitles() }}
        </div>
        <div class="flex clearSelections" :class="{ 'justify-end': arabic }" @click="setToDefault()">
          {{ $t("filter.modals.clear_selection") }}
        </div>
      </div>
    </div>
    <portal to="additional-portal-for-filter">
      <modal
        :name="data.data.q_param"
        height="auto"
        :width="650"
        :adaptive="true"
        @before-open="beforeOpenModal"
        @closed="onModalClose"
      >
        <div class="bg-white w-modal flex flex-col justify-start items-start rounded-lg max-h-animation px-6 py-6">
          <div class="flex mb-2 flex-col w-full" :dir="direction">
            <div class="flex justify-between items-center w-full mb-2" :dir="direction">
              <div class="text-lg font-semibold">{{ data.data.title }}</div>
              <div class="font-semibold reset-filters-btn" @click="setToDefault()">{{ $t("filter.modals.clear") }}</div>
            </div>
            <InputWrapper class="searchInputWrapper" :class="{ rtl: arabic }" showSearchIcon>
              <input
                :dir="arabic ? 'rtl' : 'ltr'"
                autofocus
                type="text"
                slot="input"
                ref="input"
                :placeholder="$t('filter.options.search')"
                v-on:input="debounceInput"
                class="searchInput"
                v-model="search"
              />
            </InputWrapper>
          </div>
          <div v-if="!search && filteredOptions.length === 0" class="flex justify-center w-full mt-6 mb-6">
            <img class="w-20 animate-bounce" src="../../assets/images/ayadi-loader.svg" alt="Ayadi" />
          </div>
          <div class="w-full" v-else>
            <div
              v-if="filteredOptions.length > 0"
              class="flex flex-col w-full overflow-y-scroll mx-auto modalChildContainer"
            >
              <input-checkbox
                v-for="item in filteredOptions"
                :key="`${item.id}_${data.key}`"
                :id="`${item.id}_${data.key}`"
                :name="item.title"
                :label="item.title"
                :description="item.description"
                @change="onChange"
                :value="item.id"
                v-model="data.value"
                :dir="direction"
              ></input-checkbox>
            </div>
            <div v-else class="flex flex-col w-full overflow-y-scroll mx-auto modalChildContainer">
              <FilterOptionsNotFound @onClear="onClearSearch" />
            </div>
          </div>
          <div class="flex flex-row w-full justify-between px-6 py-6" :class="{ 'flex-row-reverse': arabic }">
            <SecondaryButton @click="closeModal()" class="mt-4 w-20" :label="$t('feedback.back')" />

            <MainButton @click="applyFilter()" class="mt-4" :disabled="!isChanged" :dir="direction"
              >{{ $t("filter.modals.apply") }}
              <span v-if="isChanged && selectedNewOptions > 0" :class="{ 'mr-1': arabic, 'ml-1': !arabic }"
                >({{ totalTherapistsDynamic }})</span
              ></MainButton
            >
          </div>
        </div>
      </modal>
    </portal>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
import { mapState } from "vuex";
import { mountPortalTarget } from "portal-vue";
import _ from "lodash";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import MainButton from "@/components/ui-kit/MainButton";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import FilterOptionsNotFound from "@/components/therapists/FilterOptionsNotFound";
import InputCheckbox from "@/components/ui-kit/InputCheckbox";
import { getFilterEvents } from "../../utils/filter";

export default {
  name: "DynamicMultipleChoiceModal",
  components: {
    InputCheckbox,
    SecondaryButton,
    MainButton,
    InputWrapper,
    FilterOptionsNotFound
  },

  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      initialSelectedOptions: [],
      search: "",
      filteredOptions: [],
      isChanged: false,
      selectedNewOptions: 0
    };
  },
  setup() {
    mountPortalTarget(
      {
        name: "additional-portal-for-filter"
      },
      "#additional-portal-for-filter-target"
    );
  },
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    ...mapState("therapistFilterDynamic", ["totalTherapistsDynamic"])
  },
  methods: {
    async onChange(value) {
      await this.$store.dispatch("therapistFilterDynamic/setFilterOptionValue", {
        key: this.data.key,
        value: value
      });
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapistsDynamic", 1);

      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_change", trackData);
      this.$mixpanel.track("therapistFilters_change", trackData);
      this.$intercom.trackEvent("therapistFilters_change", trackData);

      this.checkNewSelectedOptions();
    },
    onModalClose() {
      this.$emit("showParentModal");
    },
    showModal() {
      this.$modal.show(this.data.data.q_param);
      this.$emit("hideParentModal");
      window.webengage.track("therapistList_shortcut_click");
      this.$mixpanel.track("therapistList_shortcut_click");
      this.$intercom.trackEvent("therapistList_shortcut_click");
    },

    async closeModal() {
      await this.$store.dispatch("therapistFilterDynamic/setFilterOptionValue", {
        key: this.data.key,
        value: this.initialSelectedOptions
      });
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapistsDynamic", 1);
      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_change", trackData);
      this.$mixpanel.track("therapistFilters_change", trackData);
      this.$intercom.trackEvent("therapistFilters_change", trackData);

      this.filteredOptions = JSON.parse(JSON.stringify(this.data.data.choices));
      this.search = "";
      this.$emit("showParentModal");
      this.$modal.hide(this.data.data.q_param);
      this.$modal.show("therapist-filter-modal-dynamic");
    },

    async applyFilter() {
      this.search = "";
      this.$emit("showParentModal");
      this.$modal.hide(this.data.data.q_param);
      this.$modal.show("therapist-filter-modal-dynamic");
    },

    async setToDefault() {
      this.search = "";
      this.filteredOptions = JSON.parse(JSON.stringify(this.data.data.choices));

      await this.$store.dispatch("therapistFilterDynamic/setFilterOptionValue", {
        key: this.data.key,
        value: []
      });
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapistsDynamic", 1);
      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_change", trackData);
      this.$mixpanel.track("therapistFilters_change", trackData);
      this.$intercom.trackEvent("therapistFilters_change", trackData);

      this.checkNewSelectedOptions();
    },

    beforeOpenModal() {
      const initialSelects = JSON.parse(JSON.stringify(this.data.value));
      this.initialSelectedOptions = initialSelects;

      this.checkActiveOptions();
    },

    checkActiveOptions() {
      if (JSON.stringify(this.initialSelectedOptions.sort()) !== JSON.stringify(this.data.value.sort())) {
        this.isChanged = true;
        return;
      }

      this.isChanged = false;
    },

    getSelectedTitles() {
      if (this.data.value.length > 0) {
        const selectedTitles = [];
        if (this.filteredOptions.length > 0) {
          this.data.value.forEach((selectedItem) => {
            const filteredItem = this.filteredOptions.find((item) => item.id === selectedItem);
            selectedTitles.push(filteredItem?.title);
          });
        }

        return selectedTitles.join(", ");
      }
    },

    checkNewSelectedOptions() {
      if (JSON.stringify(this.initialSelectedOptions.sort()) !== JSON.stringify(this.data.value.sort())) {
        this.selectedNewOptions = this.data.value.length;
      }
      this.checkActiveOptions();
    },

    onClearSearch() {
      this.search = "";
      this.filteredOptions = JSON.parse(JSON.stringify(this.data.data.choices));
    },

    debounceInput: _.debounce(function (e) {
      if (!e.target.value) {
        this.filteredOptions = JSON.parse(JSON.stringify(this.data.data.choices));
        return;
      }

      const filteredOptions = this.data.data.choices.filter((item) =>
        item?.title.toLowerCase().includes(e.target.value.toLowerCase())
      );

      this.filteredOptions = JSON.parse(JSON.stringify(filteredOptions));
    }, 500)
  },

  mounted() {
    this.filteredOptions = JSON.parse(JSON.stringify(this.data.data.choices));
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";
.searchInputWrapper {
  width: 100%;
  height: 40px;

  position: relative;

  .searchInput {
    padding-left: 16px;
    padding-right: 40px;
  }
}

.modalChildContainer {
  min-height: 350px;
  max-height: 400px;

  @include for-phone {
    min-height: 200px;
    max-height: 300px;
  }
}

.reset-filters-btn {
  color: #44a4a5;
  cursor: pointer;
}

.filterWrapper {
  width: 100%;
}

.descriptionText {
  text-align: end;
}

.bottomBtnSection {
  background: white;
  z-index: 100;
  padding: 10px 24px;
  width: 100%;
  left: 0;
}
.modalContainer {
  max-height: calc(90vh - 200px);
}
.reset-filters-btn {
  color: #44a4a5;
  cursor: pointer;
}

.color-gray {
  color: #6f7786;
}

.selected-time-wrapper {
  span {
    color: #44a4a5;
    margin: 0 5px;
  }
}

.selectedWrapper {
  span {
    color: #939aa8;
  }
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 8px 0 4px 0;
}

.clearSelections {
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  color: #44a4a5;
  font-weight: 600;
}

.choose-btn {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #f8f9fa;
  border: 1px solid #354052;
  border-radius: 4px;
  padding: 14px 16px;
  min-width: 168px;
  cursor: pointer;
  gap: 8px;

  &:hover {
    background: #f5f7f9;
  }

  &.small {
    min-width: 202px;
  }
}
</style>

import axios from "axios";

export default class TherapistClient {
  static async getTherapistProfile(id) {
    return await axios.get(`/api/client/v3/therapists/${id}`);
  }

  static async getAvailableSlots(id, type, start) {
    return await axios.get(`/api/client/therapist/${id}/sessions?type=${type}&days=${3}&start=${start}`);
  }

  static async createOrder(params) {
    return await axios.post("/api/client/v2/orders", params);
  }

  static async checkOrder(orderId) {
    return await axios.get(`/api/client/v2/orders/${orderId}`);
  }

  static async getSession(therapistId, sessionId) {
    return await axios.get(`/api/client/v2/therapists/${therapistId}/sessions/${sessionId}`);
  }
}

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export function convertUTCDateToLocalDate(d) {
  dayjs.extend(utc);
  return dayjs.utc(d).format();
}

export const convertUTCDateToLocalDateToHM = (d) => {
  dayjs.extend(utc);
  return dayjs.utc(d).format("HH:mm");
};

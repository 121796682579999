<template>
  <div class="h-full flex justify-center items-center">
    <div class="relative">
      <div class="absolute logo-position">
        <LogoLight />
      </div>
      <SignUpComponent
        v-if="!showEmailForm"
        @toSignIn="toSignIn"
        @signUpViaEmail="signUpViaEmail"
        @onSignUp="onSignUp"
        presentedFrom="Home Page"
      />
      <div v-else class="signViaEmail">
        <SignInViaEmailForm @goBack="goBack" @onSignUp="onSignUp" />
      </div>
    </div>
  </div>
</template>

<script>
import LogoLight from "@/components/ui-kit/LogoLight";
import storageClient from "@/services/storageClient";
import SignUpComponent from "@/components/forms/SignUpComponent";
import SignInViaEmailForm from "@/components/forms/SignInViaEmailForm";
export default {
  name: "SignUp",
  components: { LogoLight, SignUpComponent, SignInViaEmailForm },

  data() {
    return {
      showEmailForm: false
    };
  },

  head: {
    title() {
      return {
        inner: this.arabic ? "أيادي" : "Ayadi",
        separator: "|",
        complement: this.arabic ? "أيادي" : "Signup"
      };
    }
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    toSignIn() {
      return this.$router.push({ name: "SignIn" }).catch(() => {});
    },
    signUpViaEmail() {
      this.showEmailForm = true;
    },
    goBack() {
      this.showEmailForm = false;
    },
    onSignUp() {
      return this.$router.push({ name: "NoUpcomingSessions" }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.signViaEmail {
  width: 440px;

  @include for-phone {
    width: 100%;
    min-width: 325px;
    max-width: initial;
  }
}
</style>

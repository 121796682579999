import { fetchTherapistByFiler, getDifferentFilters } from "../../utils/filter";
export const namespaced = true;

export const state = {
  filterOptions: [],
  filterOptionsApplied: [],
  defaultValues: [],
  sortOptions: [],
  activeFilters: [],
  search: "",
  sort_order: "",
  sort_by: "",
  therapists: [],
  totalTherapistsDynamic: 0,
  totalTherapists: 0,
  page: 1,
  totalPages: 1,
  isLoading: false,
  lastEnteredTherapist: null
};

export const mutations = {
  SET_FILTER_OPTIONS(state, value) {
    state.filterOptions = value;
  },

  SET_FILTER_OPTIONS_APPLIED(state, value) {
    state.filterOptionsApplied = value;
  },

  SET_FILTER_OPTION_VALUE(state, value) {
    state.filterOptions.find((filter) => filter.key === value.key).value = value.value;
  },

  SET_ORDER_OPTIONS(state, value) {
    state.sortOptions = value;
  },

  SET_SEARCH(state, value) {
    state.search = value;
  },

  SET_LAST_ENTERED_THERAPIST(state, value) {
    state.lastEnteredTherapist = value;
  },

  SELECT_ORDER(state, value) {
    state.sort_order = value.sort_order;
    state.sort_by = value.sort_by;
  },

  SET_DEFAULT_VALUES_FILTERS(state, value) {
    state.defaultValues = value;
  },

  SET_ACTIVE_FILTERS(state, value) {
    if (value) {
      state.activeFilters = value;
      return;
    }

    const activeFilters = getDifferentFilters(state.filterOptions, state.defaultValues);

    state.activeFilters = activeFilters;
  },

  APPLY_FILTERS(state) {
    const defaultState = JSON.parse(JSON.stringify(state.filterOptions));
    state.filterOptionsApplied = defaultState;
  },

  SET_TO_SELECTED_FILTERS(state) {
    const selectedFilters = JSON.parse(JSON.stringify(state.filterOptionsApplied));
    state.filterOptions = selectedFilters;
  },

  RESET_FILTERS(state) {
    const defaultState = JSON.parse(JSON.stringify(state.defaultValues));
    const defaultStateApplied = JSON.parse(JSON.stringify(state.defaultValues));
    state.filterOptions = defaultState;
    state.filterOptionsApplied = defaultStateApplied;
  },

  FETCH_THERAPISTS(state, payload) {
    state.therapists = payload?.data?.data;
    state.totalTherapists = payload?.data?.count;
    state.totalTherapistsDynamic = payload?.data?.count;
    state.totalPages = payload?.data?.num_pages;
    state.page = payload?.page;
  },

  FETCH_THERAPISTS_DYNAMIC(state, payload) {
    state.totalTherapistsDynamic = payload;
  }
};

export const actions = {
  setFilterOptions({ commit }, value) {
    commit("SET_FILTER_OPTIONS", value);
  },

  setFilterOptionsApplied({ commit }, value) {
    commit("SET_FILTER_OPTIONS_APPLIED", value);
  },

  setFilterOptionValue({ commit }, value) {
    commit("SET_FILTER_OPTION_VALUE", value);
  },

  setOrderOptions({ commit }, value) {
    commit("SET_ORDER_OPTIONS", value);
  },

  setDefaultValuesFilter({ commit }, value) {
    commit("SET_DEFAULT_VALUES_FILTERS", value);
  },

  selectOrder({ commit }, value) {
    commit("SELECT_ORDER", value);
  },

  setActiveFilters({ commit }, value) {
    commit("SET_ACTIVE_FILTERS", value);
  },

  setSearch({ commit }, value) {
    commit("SET_SEARCH", value);
  },

  setLastEnteredTherapist({ commit }, value) {
    commit("SET_LAST_ENTERED_THERAPIST", value);
  },

  applyFilters({ commit }, value) {
    commit("APPLY_FILTERS", value);
  },

  setToSelectedFilters({ commit }, value) {
    commit("SET_TO_SELECTED_FILTERS", value);
  },

  resetFilters({ commit }) {
    commit("RESET_FILTERS");
    commit("SET_ACTIVE_FILTERS", 0);
  },

  resetAllFilter({ commit }) {
    commit("RESET_FILTERS");
    commit("SET_SEARCH", "");
    commit("SET_ACTIVE_FILTERS", 0);
  },

  async fetchTherapists({ commit, state }, page) {
    state.isLoading = true;
    try {
      const result = await fetchTherapistByFiler(state, page);
      commit("FETCH_THERAPISTS", result);
      state.isLoading = false;
    } catch (e) {
      commit("FETCH_THERAPISTS", { data: [], page: page });
      state.isLoading = false;
    }
  },

  async fetchTherapistsDynamic({ commit, state }, page) {
    try {
      const result = await fetchTherapistByFiler(state, page);
      commit("FETCH_THERAPISTS_DYNAMIC", result.data.count);
    } catch (e) {
      commit("FETCH_THERAPISTS_DYNAMIC", null);
    }
  }
};

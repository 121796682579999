import sessionsClient from "@/services/sessionsClient";
import * as utcToLocal from "@/utils/utcDateToLocalDate";

export const namespaced = true;

export const state = {
  sessions: null,
  activeSession: null,
  videoRoom: null,
  chatRoom: null,
  lastSession: null,
  questions: null,
  //todo: create order module
  order: null,
  coupon: null,
  selectedPackageOption: null,
  selectedPackage: null,
  bookedPackages: null,
  loadingPackages: true,
  bookedPackagesForTherapist: null,
  package_order: null,
  availabilityPaymentMethods: null
};

export const mutations = {
  SELECT_PACKAGE_OPTION(state, value) {
    state.selectedPackageOption = value;
  },

  SET_PACKAGE_ORDER(state, order) {
    state.package_order = order;
  },

  SET_BOOKED_PACKAGES(state, value) {
    state.bookedPackages = value;
    state.loadingPackages = false;
  },

  SELECT_PACKAGE(state, value) {
    state.selectedPackage = value;
  },

  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },

  SET_ACTIVE_SESSION(state, session) {
    state.activeSession = session;
  },

  SET_VIDEO_ROOM(state, room) {
    state.videoRoom = room;
  },

  SET_CHAT_ROOM(state, room) {
    state.chatRoom = room;
  },
  SET_LAST_SESSION(state, session) {
    state.lastSession = session;
  },

  SET_FEEDBACK_QUESTIONS(state, questions) {
    state.questions = questions;
  },

  SET_ORDER(state, order) {
    state.order = order;
  },

  SET_COUPON(state, coupon) {
    state.coupon = coupon;
  },

  SET_LOADING_PACKAGES(state, value) {
    state.loadingPackages = value;
  },

  SET_BOOKED_PACKAGES_FOR_THERAPIST(state, value) {
    state.bookedPackagesForTherapist = value;
  },

  SET_AVAILABILITY_PAYMENT_METHODS(state, value) {
    state.availabilityPaymentMethods = value;
  }
};

export const actions = {
  async fetchSessions({ commit }) {
    let { data } = await sessionsClient.getAllSessions();
    let sessions = data.data;
    sessions.forEach((ses) => {
      ses.session_start = utcToLocal.convertUTCDateToLocalDate(ses.session_start);
      ses.session_end = utcToLocal.convertUTCDateToLocalDate(ses.session_end);
    });

    if (sessions.length > 0) {
      sessions[0].session_start = utcToLocal.convertUTCDateToLocalDate(sessions[0].session_start);
      sessions[0].session_end = utcToLocal.convertUTCDateToLocalDate(sessions[0].session_end);
      commit("SET_ACTIVE_SESSION", sessions[0]);
    } else {
      commit("SET_ACTIVE_SESSION", null);
    }

    commit("SET_SESSIONS", sessions);
  },

  async getVideoRoom({ commit }, id) {
    let { data } = await sessionsClient.getVideoRoom(id);
    commit("SET_VIDEO_ROOM", data);
  },

  async getChatRoom({ commit }, id) {
    let { data } = await sessionsClient.getChatRoom(id);
    commit("SET_CHAT_ROOM", data);
  },

  async getPastSessions({ commit }, page) {
    let { data } = await sessionsClient.getPastSessionsPaginated(page ? page : 1);
    let sessions = data.data;
    sessions.forEach((ses) => {
      ses.session_start = utcToLocal.convertUTCDateToLocalDate(ses.session_start);
      ses.session_end = utcToLocal.convertUTCDateToLocalDate(ses.session_end);
    });
    commit("SET_LAST_SESSION", sessions[0]);
  },

  async getFeedbackQuestions({ commit }) {
    const { data } = await sessionsClient.getFeedbackQuestions();

    commit("SET_FEEDBACK_QUESTIONS", data);
  },

  async getOrder({ commit }, id) {
    const { data } = await sessionsClient.getOrder(id);

    commit("SET_ORDER", data);
  },

  // todo: refactoring from component if possible
  setCoupon({ commit }, coupon) {
    commit("SET_COUPON", coupon);
  },

  selectPackageOption({ commit }, value) {
    commit("SELECT_PACKAGE_OPTION", value);
  },

  selectPackage({ commit }, value) {
    commit("SELECT_PACKAGE", value);
  },

  async setBookedPackages({ commit }) {
    commit("SET_LOADING_PACKAGES", true);
    const { data } = await sessionsClient.checkPackages();
    commit("SET_BOOKED_PACKAGES", data);
  },

  setBookedPackagesForTherapist({ commit }, value) {
    commit("SET_BOOKED_PACKAGES_FOR_THERAPIST", value);
  },

  async checkPackageOrder({ commit }, orderId) {
    try {
      const { data } = await sessionsClient.getOrderPackages(orderId);

      commit("SET_PACKAGE_ORDER", data);
    } catch (e) {
      commit("SET_PACKAGE_ORDER", {});
    }
  },

  async getAvailabilityPaymentMethods({ commit }, price) {
    try {
      const { data } = await sessionsClient.getAvailablePayments(price);

      commit("SET_AVAILABILITY_PAYMENT_METHODS", data);
    } catch (e) {
      commit("SET_AVAILABILITY_PAYMENT_METHODS", null);
    }
  }
};

export const getters = {
  getVideoRoomID: (state) => state.activeSession.id,
  getSessions: (state) => state.sessions,
  getActiveSession: (state) => state.activeSession
};

<template>
  <div class="w-screen bg-gray-500 bg-opacity-50 absolute top-0 left-0" @click.self="close">
    <ChatImageFullScreen class="fixed" v-if="showFullScreen" :image="selectedImage" @close="closeImage" />
    <transition name="slide-fade">
      <div
        v-if="showChat"
        class="w-1/2 h-screen overflow-hidden bg-white flex flex-col text-textBlue sm:w-3/4"
        :dir="direction"
      >
        <ChatHeader
          :therapist-name="therapistName"
          :therapist-avatar="therapistAvatar"
          :avatar_bg_color="therapistProfile.avatar_bg_color"
          @close="close"
        />
        <div ref="chat" class="px-4 w-full h-full overflow-y-scroll bg-textGray-600 relative">
          <ChatHeaderMessage class="mb-6" />
          <ScrollObserver v-if="hasPrevPage && !loading" @intersect="getMessages" />
          <div v-if="messages.length > 0">
            <div v-for="(m, index) in messages" :key="m.state.sid">
              <p class="w-full text-center my-5 text-lightGray" v-if="dateDividers[index]">
                {{ getDateDivider(dateDividers[index]) }}
              </p>
              <ChatAuthorMessage
                v-if="m.type === 'text' && m.state.author === user.uuid"
                :id="m.state.sid"
                :message="m.body"
                :timestamp="m.state.timestamp"
                dir="ltr"
              />

              <ChatParticipantMessage
                @mouseenter="readMessage(m.state.index)"
                v-if="m.type === 'text' && m.state.author !== user.uuid"
                :id="m.state.sid"
                :message="m.body"
                :timestamp="m.state.timestamp"
                :name="therapistName"
              >
                <img
                  class="w-8 h-8 rounded-6px border border-divider"
                  :class="arabic ? 'ml-3' : 'mr-3'"
                  :src="therapistAvatar"
                  :avatar_bg_color="therapistProfile.avatar_bg_color"
                  alt="avatar"
                />
              </ChatParticipantMessage>

              <ChatAuthorMediaMessage
                v-if="m.type === 'media' && isImage(m) && m.state.author === user.uuid"
                :id="m.state.sid"
                :timestamp="m.state.timestamp"
              >
                <!--borntodievych+123@gmail.com-->
                <img
                  @click="openImage(m.media.state.sid)"
                  :ref="m.media.state.sid"
                  src="../../assets/images/default_photo.png"
                  alt="image"
                  class="w-32 h-32 object-cover cursor-pointer"
                />
              </ChatAuthorMediaMessage>

              <ChatParticipantMediaMessage
                @mouseenter="readMessage(m.state.index)"
                v-if="m.type === 'media' && isImage(m) && m.state.author !== user.uuid"
                :id="m.state.sid"
                :timestamp="m.state.timestamp"
                :name="therapistName"
              >
                <img
                  slot="avatar"
                  class="w-8 h-8 rounded-6px border border-divider"
                  :class="arabic ? 'ml-3' : 'mr-3'"
                  :src="therapistAvatar"
                  :avatar_bg_color="therapistProfile.avatar_bg_color"
                  alt="avatar"
                />
                <img
                  @click="openImage(m.media.state.sid)"
                  :ref="m.media.state.sid"
                  src="../../assets/images/default_photo.png"
                  alt="image"
                  class="w-32 h-32 object-cover cursor-pointer"
                />
              </ChatParticipantMediaMessage>

              <ChatAuthorMediaMessage
                v-if="m.type === 'media' && isVideo(m) && m.state.author === user.uuid"
                :id="m.state.sid"
                :timestamp="m.state.timestamp"
              >
                <video controls :ref="m.media.state.sid"></video>
              </ChatAuthorMediaMessage>

              <ChatParticipantMediaMessage
                @mouseenter="readMessage(m.state.index)"
                v-if="m.type === 'media' && isVideo(m) && m.state.author !== user.uuid"
                :id="m.state.sid"
                :timestamp="m.state.timestamp"
                :name="therapistName"
              >
                <img
                  slot="avatar"
                  class="w-8 h-8 rounded-6px border border-divider"
                  :class="arabic ? 'ml-3' : 'mr-3'"
                  :src="therapistAvatar"
                  :avatar_bg_color="therapistProfile.avatar_bg_color"
                  alt="avatar"
                />
                <video controls :ref="m.media.state.sid"></video>
              </ChatParticipantMediaMessage>
            </div>
          </div>

          <div ref="footer"></div>
        </div>
        <div v-if="typingIndicator" class="typing">
          <div class="text-sm bg-black-60% text-white rounded-6px py-1 px-3" :dir="direction">
            {{ getTherapistName }} {{ $t("sessions.typing") }}
          </div>
        </div>
        <ScrollToBottom :unread-count="+unread" v-if="showScrollToBottom" @scroll-to-bottom="scrollToBottom" />
        <ChatMessageInput
          @track-click="trackInputClick"
          @typing="typing"
          @send-message="sendMessage"
          @send-file="sendFile"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import ChatHeader from "@/components/chat/ChatHeader";
import ChatMessageInput from "@/components/chat/ChatMessageInput";
import ChatHeaderMessage from "@/components/chat/ChatHeaderMessage";
import ChatParticipantMessage from "@/components/chat/ChatParticipantMessage";
import ChatAuthorMediaMessage from "@/components/chat/ChatAuthorMediaMessage";
import ChatAuthorMessage from "@/components/chat/ChatAuthorMessage";
import dayjs from "dayjs";
import ScrollObserver from "@/components/ScrollObserver";
import ChatParticipantMediaMessage from "@/components/chat/ChatParticipantMediaMessage";
import ChatImageFullScreen from "@/components/chat/ChatImageFullScreen";
import storageClient from "@/services/storageClient";
import ScrollToBottom from "@/components/chat/ScrollToBottom";
import { mapState } from "vuex";
import { Client as ConversationsClient } from "@twilio/conversations";

export default {
  name: "Messenger",
  components: {
    ScrollToBottom,
    ChatImageFullScreen,
    ScrollObserver,
    ChatAuthorMessage,
    ChatParticipantMessage,
    ChatHeaderMessage,
    ChatMessageInput,
    ChatHeader,
    ChatParticipantMediaMessage,
    ChatAuthorMediaMessage
  },

  props: {
    activeChat: String,
    therapistName: String,
    therapistAvatar: String,
    sessionStart: null
  },

  data() {
    return {
      showChat: false,
      day: dayjs(),
      text: "",
      conversationClient: null,
      conversations: null,
      activeConversation: null,
      messages: [],
      showScrollToBottom: false,
      hasPrevPage: false,
      prevPage: null,
      dateDividers: [],
      loading: true,
      selectedImage: null,
      showFullScreen: false,
      typingIndicator: false,
      unread: 0
    };
  },

  computed: {
    ...mapState("chat", ["chatList", "chatToken", "therapistProfile", "activeConversationIndex"]),
    ...mapState("auth", ["user"]),

    getTherapistName() {
      return this.therapistProfile?.full_name ? this.therapistProfile.full_name : "Therapist";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  },

  methods: {
    close() {
      this.showChat = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },

    typing() {
      this.activeConversation.typing();
    },
    typingStarted() {
      this.typingIndicator = true;
    },
    typingEnded() {
      this.typingIndicator = false;
    },

    async readMessage(index) {
      await this.activeConversation.advanceLastReadMessageIndex(index);
    },

    async getMessages() {
      let initialPrev = this.hasPrevPage;
      let lastMessage;

      if (initialPrev) {
        if (this.messages.length > 0 && this.messages[0]) {
          lastMessage = document.getElementById(this.messages[0]?.state.sid);
        }
      }
      const getMessages = !this.hasPrevPage ? await this.activeConversation.getMessages() : await this.prevPage();

      if (getMessages.hasPrevPage) {
        this.hasPrevPage = true;
        this.prevPage = getMessages.prevPage;
      } else {
        this.hasPrevPage = false;
        this.prevPage = null;
      }
      this.messages = [...getMessages.items, ...this.messages];
      if (initialPrev) {
        this.$refs.chat.scrollTop = lastMessage.offsetTop;
      }

      this.dateDividers = null;
      this.dateDividers = {};

      if (this.messages.length > 0 && this.messages[0]) {
        let date = dayjs(this.messages[0].state.timestamp).format("DD MM YYYY");

        this.dateDividers["0"] = dayjs(this.messages[0].state.timestamp).format("DD MMM YYYY");
        for (let i = 0; i < this.messages.length; i++) {
          if (date !== dayjs(this.messages[i].state.timestamp).format("DD MM YYYY")) {
            this.dateDividers[i] = dayjs(this.messages[i].state.timestamp).format("DD MMM YYYY");
            date = dayjs(this.messages[i].state.timestamp).format("DD MM YYYY");
          }
        }

        for (const m of this.messages) {
          if (m.type === "media") {
            const url = await m.media.getContentTemporaryUrl();
            const id = m.media.state.sid + "";

            if ((this.isImage(m) || this.isVideo(m)) && this.$refs && this.$refs[id]) {
              this.$refs[id][0].src = url;
            }
          }
        }
      }
    },
    async sendMessage(message) {
      const trackData = {
        "Device type": "Web",
        "Therapist Name": this.therapistProfile?.full_name,
        Sender: "client",
        Recipient: "therapist",
        "String length": message.length,
        time: dayjs().unix()
      };

      window.webengage.track("dialogue_message_sent", trackData);
      this.$mixpanel.track("dialogue_message_sent", trackData);
      this.$intercom.trackEvent("dialogue_message_sent", trackData);
      await this.activeConversation.sendMessage(message);
    },
    async sendFile(media) {
      if (!media) {
        return;
      }
      if (media.size > 10000000) {
        this.$notify({
          group: "errors",
          text: this.$t("messenger.file_size"),
          classes: "toast-error"
        });

        const trackData = {
          "Device type": "Web",
          "Therapist Name": this.therapistProfile?.full_name,
          Sender: "client",
          Recipient: "therapist",
          "File type": media.type,
          time: dayjs().unix()
        };

        window.webengage.track("dialogue_fileAttachment_failed", trackData);
        this.$mixpanel.track("dialogue_fileAttachment_failed", trackData);
        this.$intercom.trackEvent("dialogue_fileAttachment_failed", trackData);

        return;
      }
      const formData = new FormData();
      formData.append("file", media);

      const trackDataFileSuccess = {
        "Device type": "Web",
        "Therapist Name": this.therapistProfile?.full_name,
        Sender: "client",
        Recipient: "therapist",
        "File type": media.type,
        time: dayjs().unix()
      };

      window.webengage.track("dialogue_fileAttachment_success", trackDataFileSuccess);
      this.$mixpanel.track("dialogue_fileAttachment_success", trackDataFileSuccess);
      this.$intercom.trackEvent("dialogue_fileAttachment_success", trackDataFileSuccess);

      const trackData = {
        "Device type": "Web",
        "Therapist Name": this.therapistProfile?.full_name,
        Sender: "client",
        Recipient: "therapist",
        "Number of attachments": 1,
        "File size KB": media.size / 1000,
        time: dayjs().unix()
      };

      window.webengage.track("dialogue_message_sent", trackData);
      this.$mixpanel.track("dialogue_message_sent", trackData);
      this.$intercom.trackEvent("dialogue_message_sent", trackData);

      await this.activeConversation.sendMessage(formData);
    },
    getDateDivider(date) {
      return date === dayjs().format("DD MMM YYYY") ? this.$t("messenger.today") : date;
    },

    async onMessageAdded(message) {
      this.messages.push(message);

      const chat = this.$refs.chat;
      if (chat && chat?.scrollHeight && chat?.scrollTop && chat?.clientHeight) {
        this.showScrollToBottom = !(chat.scrollHeight - chat.scrollTop - chat.clientHeight <= 200);
      }

      if (message.type === "media") {
        const url = await message.media.getContentTemporaryUrl();
        const id = message.media.state.sid + "";

        if (this.isImage(message) || this.isVideo(message)) {
          this.$refs[id][0].src = url;
        }
      }

      if (message.state.author !== this.user.uuid) {
        this.unread = await this.activeConversation.getUnreadMessagesCount();
      }

      if (message.state.author === this.user.uuid) {
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      }
      return null;
    },

    isVideo(m) {
      return m.state.media.state.contentType.startsWith("video");
    },
    isImage(m) {
      return m.state.media.state.contentType.startsWith("image");
    },
    openImage(id) {
      this.selectedImage = this.$refs[id][0].src;
      this.showFullScreen = true;
    },
    closeImage() {
      this.showFullScreen = false;
      this.selectedImage = null;
    },

    async scrollHandle() {
      let chat = this.$refs.chat;
      setTimeout(async () => {
        if (chat && chat.scrollHeight) {
          this.showScrollToBottom = !(chat.scrollHeight - chat.scrollTop - chat.clientHeight <= 200);
          if (!this.showScrollToBottom) {
            await this.activeConversation.setAllMessagesRead();
            this.unread = await this.activeConversation.getUnreadMessagesCount();
          }
        }
      }, 0);
    },
    async scrollToBottom() {
      if (this.$refs.footer) {
        const element = this.$refs.chat;
        element.classList.add("scroll-smooth");
        element.scrollTop = element.scrollHeight;
        element.scrollTop = element.scrollHeight;
        element.classList.remove("scroll-smooth");

        // think about that
        // this.$refs.footer.scrollIntoView({ block: "center", inline: "nearest", behavior: "smooth" });
      }
    },

    trackInputClick() {
      const trackData = {
        "Device type": "Web",
        "Therapist Name": this.therapistProfile?.full_name,
        Sender: "client",
        Recipient: "therapist",
        time: dayjs().unix()
      };

      window.webengage.track("dialogue_textField_click", trackData);
      this.$mixpanel.track("dialogue_textField_click", trackData);
      this.$intercom.trackEvent("dialogue_textField_click", trackData);
    }
  },

  async created() {
    if (this.chatList && this.chatList.length > 0) {
      const lastTherapistId = this.chatList[this.chatList.length - 1]?.therapist.id;
      await this.$store.dispatch("chat/getTherapistProfile", lastTherapistId);
    }
  },

  async mounted() {
    await this.$store.dispatch("chat/getChatToken");
    await this.$store.dispatch("chat/getChatList");

    const trackData = {
      "Device type": "Web",
      "Therapist Name": this.therapistProfile?.full_name,
      Sender: "client",
      Recipient: "therapist",
      "Session date": this.sessionStart,
      "Hours until session": this.sessionStart ? dayjs(this.sessionStart).diff(dayjs(), "hour") : "null",
      time: dayjs().unix()
    };

    window.webengage.track("dialogue_view", trackData);
    this.$mixpanel.track("dialogue_view", trackData);
    this.$intercom.trackEvent("dialogue_view", trackData);

    this.showChat = true;

    this.conversationClient = await ConversationsClient.create(this.chatToken);

    this.activeConversation = await this.conversationClient.getConversationBySid(this.activeChat);

    this.activeConversation.on("messageAdded", this.onMessageAdded);

    this.activeConversation.on("typingStarted", (member) => {
      if (!(member?.identity === this.user?.uuid)) {
        this.typingStarted();
      }
    });

    this.activeConversation.on("typingEnded", (member) => {
      if (!(member?.identity === this.user?.uuid)) {
        this.typingEnded();
      }
    });

    this.unread = await this.activeConversation.getUnreadMessagesCount();

    await this.getMessages();
    await this.scrollToBottom();

    if (this.$refs && this.$refs.chat) {
      this.$refs.chat.addEventListener("scroll", this.scrollHandle, { passive: true });
    }

    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },

  beforeDestroy() {
    if (this.$refs.chat) {
      this.$refs.chat.removeEventListener("scroll", this.scrollHandle);
    }
    if (this.activeConversation) {
      this.activeConversation.off("messageAdded", this.onMessageAdded);
      this.activeConversation.off("typingStarted", this.typingStarted);
      this.activeConversation.off("typingEnded", this.typingEnded);
    }
  }
};
</script>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}

.grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.row-span-7 {
  grid-row: span 7 / span 7;
}

.chat-container {
  @apply h-full;
}

.typing {
  @apply flex justify-center;
  flex: 1 0 auto;
  top: 90%;
  left: 25%;
  transform: translate(-50%, -100%);
  z-index: 200;
  position: absolute;
  div {
    @apply text-center sticky;
    bottom: 0.5rem;
    min-width: 160px;
  }
}

.bg-black-60\% {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(40px);
}

.scroll-smooth {
  scroll-behavior: smooth;
}
</style>

<template>
  <span @click="$emit('click')">
    <svg
      class="eye-icon transition-all duration-300"
      height="18"
      v-if="isOpen"
      viewBox="0 0 47 41"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4667 4C12.8 4 3.69067 10.6347 0 20C3.69067 29.3653 12.8 36 23.4667 36C34.1333 36 43.2427 29.3653 46.9333 20C43.2427 10.6347 34.1333 4 23.4667 4ZM23.4667 30.6667C17.5787 30.6667 12.8 25.888 12.8 20C12.8 14.112 17.5787 9.33333 23.4667 9.33333C29.3547 9.33333 34.1333 14.112 34.1333 20C34.1333 25.888 29.3547 30.6667 23.4667 30.6667ZM23.4667 13.6C19.9253 13.6 17.0667 16.4587 17.0667 20C17.0667 23.5413 19.9253 26.4 23.4667 26.4C27.008 26.4 29.8667 23.5413 29.8667 20C29.8667 16.4587 27.008 13.6 23.4667 13.6Z"
      />
    </svg>
    <svg
      class="eye-close-icon transition-all duration-300"
      height="18"
      v-else
      viewBox="0 0 47 41"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M10.4832 32.3972L7 37L9.39222 38.8103L15.0026 31.3966L17.1343 28.5827C17.1339 28.5823 17.1334 28.582 17.1329 28.5816L19.7051 25.1825C19.7062 25.1833 19.7073 25.1841 19.7084 25.1849L27.4405 14.9785C27.4377 14.9763 27.4349 14.9741 27.4321 14.9719L30.0043 11.573C30.0077 11.5756 30.0111 11.5783 30.0145 11.5809L34.0092 6.30786C34.0042 6.30552 33.9991 6.30317 33.994 6.30083L37.3922 1.81033L35 0L31.0848 5.17364C28.6817 4.41132 26.1225 4.00003 23.4667 4.00003C12.8 4.00003 3.69067 10.6347 0 20C2.04703 25.1945 5.76106 29.549 10.4832 32.3972ZM14.978 26.4576L17.7226 22.8309C17.3025 21.978 17.0667 21.0171 17.0667 20C17.0667 16.4587 19.9253 13.6 23.4667 13.6C23.8637 13.6 24.2521 13.636 24.6288 13.7047L27.3767 10.0737C26.1658 9.59587 24.8467 9.33336 23.4667 9.33336C17.5787 9.33336 12.8 14.112 12.8 20C12.8 22.4266 13.6116 24.6647 14.978 26.4576ZM23.4667 36C20.9059 36 18.4348 35.6176 16.107 34.9069L19.8078 30.0218C20.9491 30.439 22.1815 30.6667 23.4667 30.6667C29.3547 30.6667 34.1333 25.888 34.1333 20C34.1333 17.6738 33.3875 15.5207 32.122 13.7671L36.6838 7.74552C41.2948 10.5955 44.9202 14.8915 46.9333 20C43.2427 29.3654 34.1333 36 23.4667 36ZM23.4667 26.4C23.1712 26.4 22.8805 26.3801 22.5958 26.3416L29.3375 17.4426C29.678 18.2254 29.8667 19.0902 29.8667 20C29.8667 23.5414 27.008 26.4 23.4667 26.4Z"
        fill-rule="evenodd"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "IconPasswordEye",

  props: {
    isOpen: Boolean
  }
};
</script>

<style scoped>
.eye-icon {
  fill: #78cec9;
}

.eye-close-icon {
  fill: #7f8fa4;
}

span:hover .eye-icon {
  fill: #44a4a5;
}

span:hover .eye-close-icon {
  fill: #434a57;
}
</style>

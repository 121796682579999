export const namespaced = true;

export const state = {
  settingsActiveTab: "language"
};

export const mutations = {
  SET_ACTIVE_TAB(state, tab) {
    state.settingsActiveTab = tab;
  }
};

export const actions = {
  setActiveTab({ commit }, tab) {
    commit("SET_ACTIVE_TAB", tab);
  }
};

<template>
  <div>
    <modal
      :width="420"
      height="auto"
      :adaptive="true"
      name="download-apps"
      @opened="blockScroll"
      @closed="unblockScroll"
    >
      <div class="bg-white px-8 py-6 rounded-xl" :dir="direction">
        <div>
          <p class="text-18 font-semibold">{{ $t("profiles.almost_there") }}</p>
          <p class="my-4">
            {{ $t("profiles.install_app", { name: therapistProfile.full_name }) }}
          </p>
        </div>

        <div class="flex flex-row justify-center space-x-6 mt-6">
          <a
            :class="arabic ? 'ml-3' : 'mr-3'"
            href="https://apps.apple.com/ae/app/ayadi/id1503638078"
            @click="clickAppStore"
            target="_blank"
          >
            <img src="../../assets/images/Authorization/AppStore.svg" alt="app store" />
          </a>
          <a
            :class="arabic ? 'mr-3' : 'ml-3'"
            href="https://play.google.com/store/apps/details?id=com.bloom.ayadi"
            target="_blank"
            @click="clickGooglePlay"
          >
            <img src="../../assets/images/Authorization/GooglePlay.svg" alt="google play" />
          </a>
        </div>

        <SecondaryButton class="mt-4" :label="$t('profiles.close')" medium @click="$modal.hide('download-apps')" />
      </div>
    </modal>
    <TherapistSlotsModal />
    <BookingOptionsModal />
    <BookSessionByPackagesModal />
    <SignUpSelectAuthModal />
    <SignUpViaEmailModal />
    <SignInModal />
    <ProfilePictureModal />
    <ChoseNameModal />
    <PaymentFailedModal />
    <TabbyInformationModal />
    <SelectionPaymentModal />
    <ProceedToPaymentModal />
    <ProceedToPaymentPackageModal />
    <ForgotPasswordModal />
    <NotAvailableModal />
    <LoaderModal />
    <PaymentModal />
    <CardModal />
    <PackagesSuccessBoughtModal />
    <ApplyCouponModal />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import storageClient from "@/services/storageClient";
import TherapistSlotsModal from "@/components/therapist-profile/modals/TherapistSlotsModal";
import SignUpSelectAuthModal from "@/components/therapist-profile/modals/SignUpSelectAuthModal";
import SignUpViaEmailModal from "@/components/therapist-profile/modals/SignUpViaEmailModal";
import ProfilePictureModal from "@/components/therapist-profile/modals/ProfilePictureModal";
import ChoseNameModal from "@/components/therapist-profile/modals/ChoseNameModal";
import SelectionPaymentModal from "@/components/therapist-profile/modals/SelectionPaymentModal";
import TabbyInformationModal from "@/components/therapist-profile/modals/TabbyInformationModal";
import PaymentFailedModal from "@/components/therapist-profile/modals/PaymentFailedModal";
import ProceedToPaymentModal from "@/components/therapist-profile/modals/ProceedToPaymentModal";
import ProceedToPaymentPackageModal from "@/components/therapist-profile/modals/ProceedToPaymentPackageModal";
import SignInModal from "@/components/therapist-profile/modals/SignInModal";
import ForgotPasswordModal from "@/components/therapist-profile/modals/ForgotPasswordModal";
import NotAvailableModal from "@/components/therapist-profile/modals/NotAvailableModal";
import LoaderModal from "@/components/therapist-profile/modals/LoaderModal";
import PaymentModal from "@/components/therapist-profile/modals/PaymentModal";
import CardModal from "@/components/therapist-profile/modals/CardModal";
import PackagesSuccessBoughtModal from "@/components/therapist-profile/modals/PackagesSuccessBoughtModal";
import ApplyCouponModal from "@/components/therapist-profile/modals/ApplyCouponModal";
import BookingOptionsModal from "@/components/therapist-profile/modals/BookingOptionsModal";
import BookSessionByPackagesModal from "@/components/therapist-profile/modals/BookSessionByPackagesModal";

export default {
  name: "TherapistProfileModals",
  components: {
    ApplyCouponModal,
    CardModal,
    PackagesSuccessBoughtModal,
    PaymentModal,
    LoaderModal,
    NotAvailableModal,
    ForgotPasswordModal,
    SelectionPaymentModal,
    TabbyInformationModal,
    PaymentFailedModal,
    SignInModal,
    ProceedToPaymentModal,
    ProceedToPaymentPackageModal,
    ChoseNameModal,
    ProfilePictureModal,
    SignUpSelectAuthModal,
    SignUpViaEmailModal,
    TherapistSlotsModal,
    BookingOptionsModal,
    BookSessionByPackagesModal,
    SecondaryButton
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },
    clickGooglePlay() {
      window.gtag("event", "therapistProfile_googlePlayStore_click");
    },
    clickAppStore() {
      window.gtag("event", "therapistProfile_appleAppStore_click");
    }
  }
};
</script>

<template>
  <div class="h-full flex justify-center items-center">
    <div
      class="col-start-4 col-span-2 row-span-1 flex flex-row justify-between items-start bg-white rounded-md p-8 auth-form-wrapper relative sign-in-window"
    >
      <div class="absolute logo-position">
        <LogoLight />
      </div>
      <form
        @submit.prevent="resetPassword"
        class="flex flex-col justify-between items-center px-4 px-4 w-1/2 h-full form-height"
      >
        <div class="w-full">
          <h5 class="text-center mb-4">{{ $t("auth.password_change") }}</h5>
          <InputWrapper class="w-full" :label="$t('auth.new_password')" :error-message="passwordErrorText">
            <PasswordInput
              :dir="arabic ? 'rtl' : 'ltr'"
              v-model="password"
              @focus="passwordErrorText = ''"
              slot="input"
            />
          </InputWrapper>
          <InputWrapper
            class="w-full"
            :label="$t('auth.confirm_new_password')"
            :error-message="passwordConfirmErrorText"
          >
            <PasswordInput
              :dir="arabic ? 'rtl' : 'ltr'"
              v-model="passwordConfirm"
              @focus="passwordConfirmErrorText = ''"
              slot="input"
            />
          </InputWrapper>
        </div>
        <MainButton class="w-full">{{ $t("auth.confirm") }}</MainButton>
      </form>
      <div class="flex flex-col justify-center items-center">
        <img src="../../assets/images/Authorization/NewPasswordIllustration.svg" alt="new password" />
      </div>
    </div>
  </div>
</template>

<script>
import InputWrapper from "@/components/ui-kit/InputWrapper";
import PasswordInput from "@/components/ui-kit/PasswordInput";
import authClient from "@/services/authClient";
import MainButton from "@/components/ui-kit/MainButton";
import LogoLight from "@/components/ui-kit/LogoLight";
import storageClient from "@/services/storageClient";
export default {
  name: "NewPassword",
  components: {
    LogoLight,
    MainButton,
    InputWrapper,
    PasswordInput
  },

  data() {
    return {
      password: "",
      passwordConfirm: "",
      passwordError: false,
      passwordErrorText: "",
      passwordConfirmError: false,
      passwordConfirmErrorText: ""
    };
  },

  props: {
    token: String,
    email: String
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    async resetPassword() {
      if (this.password.length < 8 || this.passwordConfirm.length < 8) {
        if (this.password.length < 8) {
          this.passwordError = true;
          this.passwordErrorText = this.$t("auth.password_required");
        }

        if (this.passwordConfirm.length < 8) {
          this.passwordConfirmError = true;
          this.passwordConfirmErrorText = this.$t("auth.confirm_password_required");
        }
        return;
      }

      if (this.password !== this.passwordConfirm) {
        this.passwordConfirmError = true;
        this.passwordConfirmErrorText = this.$t("auth.password_equal");
        return;
      }

      let options = {
        token: this.token,
        email: this.email,
        password: this.password
      };
      await authClient.resetPassword(options).then(() => {
        this.$router.push({ name: "SignIn" }).catch(() => {});
      });
    }
  }
};
</script>

<style scoped>
form {
  height: 350px;
}
</style>

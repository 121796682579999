<template>
  <div class="bg-white w-full rounded-2xl profile-section-shadow transition-all duration-300" :dir="direction">
    <div class="w-full">
      <div class="flex p-4 sm:flex-wrap">
        <div class="flex w-full">
          <img
            class="h-24 w-24 rounded-6px"
            :style="{ 'background-color': therapistProfile.avatar_bg_color }"
            :src="therapistProfile.avatar"
            :alt="therapistProfile.name + ' avatar'"
          />

          <div class="w-full" :class="arabic ? 'mr-5' : 'ml-5'">
            <p class="text-2xl font-semibold">{{ arabic ? therapistProfile.name_ar : therapistProfile.name_en }}</p>
            <p class="text-lightGray">{{ $t("profiles.member_since", { date: registrationDate }) }}</p>
          </div>
        </div>

        <div class="w-full flex flex-col justify-center items-end sm:items-center">
          <p v-if="showAvailability" class="font-semibold text-ayadi-900 mb-2 sm:mt-6">
            {{ $t("profiles.available_next_week") }}
          </p>
          <MainButton id="book_session_top" @click="openSlots" :disabled="btnIsActive">
            {{ $t("profiles.book") }}
          </MainButton>
        </div>
      </div>

      <!-- About Section -->

      <div class="w-full py-4 rounded-b-2xl bg-graySection flex justify-center items-start">
        <div class="w-3/4 flex justify-center items-start">
          <p class="font-semibold text-textGray-500 text-justify whitespace-no-wrap" :class="arabic ? 'ml-4' : 'mr-4'">
            {{ $t("profiles.about_me") }}
          </p>
          <p>
            {{ therapistProfile.bio }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainButton from "@/components/ui-kit/MainButton";
import dayjs from "dayjs";
import storageClient from "@/services/storageClient";
import { checkAvailabilityPackage } from "@/utils/packages";

export default {
  name: "TherapistShortInfo",
  components: { MainButton },
  props: {
    isLoading: {
      type: Boolean
    }
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("sessions", ["bookedPackages", "loadingPackages"]),
    ...mapState("therapistSlots", ["videoSlots", "videoSmallSlots"]),
    ...mapState("auth", ["user"]),
    registrationDate() {
      return dayjs(this.therapistProfile.registration_date).format("MMM YYYY");
    },

    showAvailability() {
      return !(this.videoSmallSlots.length < 1 && this.videoSlots.length < 1);
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    btnIsActive() {
      if (!this.user) {
        return false;
      }
      if (this.loadingPackages) return true;
      return false;
    }
  },

  methods: {
    async openSlots() {
      window.gtag("event", "therapistProfile_checkAvailability_click");
      if (!this.user) {
        this.$modal.show("booking-options-modal");
        return;
      }
      const hasValidPackages = await checkAvailabilityPackage(this.therapistProfile.id, this.bookedPackages);

      if (hasValidPackages) {
        await this.$store.dispatch("sessions/setBookedPackagesForTherapist", hasValidPackages);
        this.$modal.show("therapist-availability", { validPackage: true });
      } else {
        await this.$store.dispatch("sessions/setBookedPackagesForTherapist", null);
        this.$modal.show("booking-options-modal");
      }
    }
  },

  async created() {
    await this.$store.dispatch("therapistSlots/resetStartDateToDefault");

    await this.$store.dispatch("therapistSlots/getAvailableSlots", {
      id: this.therapistProfile.id,
      type: "video"
    });

    await this.$store.dispatch("therapistSlots/getAvailableSlots", {
      id: this.therapistProfile.id,
      type: "video_small"
    });
  }
};
</script>

<style scoped lang="scss"></style>

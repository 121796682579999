<template>
  <div class="flex-grow w-full flex flex-row mb-3 try-flex">
    <div class="message h-full p-3 mb-3 rounded-lg w-auto chat-message message-shadow" :class="{ arabic: arabic }">
      <div class="flex flex-row justify-start items-start">
        <slot></slot>
        <div>
          <p class="mb-px pb-px text-lightGray font-semibold text-12">{{ name }}</p>
          <p class="text-sm safari-fix" :dir="direction">
            {{ message }}
          </p>
        </div>
        <div class="text-12 w-18 h-full text-right" :class="{ 'mr-4': arabic, 'ml-4': !arabic }">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";

export default {
  name: "ChatParticipantMessage",
  props: {
    message: {
      type: String,
      required: true
    },

    timestamp: {
      type: Date,
      required: true
    },

    name: String
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    time() {
      let date = dayjs(this.timestamp);

      if (date.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")) {
        return date.format("HH:mm");
      } else {
        // return Math.ceil(dayjs().diff(date, "day")) + "d";
        return Math.ceil(dayjs().diff(date, "day") || 1) + "d";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.message-shadow {
  box-shadow: 0 2px 10px rgba(25, 1, 52, 0.12);
}

.safari-fix {
  overflow-wrap: break-word;
  white-space: pre-line;
  word-break: break-word;
}

.try-flex {
  flex: 0 0 auto;
}

.chat-message {
  @apply safari-fix;
  p {
    @apply break-words;
    max-width: 320px;
    white-space: pre-line;
  }
}
.to-left {
  .message {
    @apply bg-white;
  }
}

.to-right {
  .message {
    @apply bg-ayadi-900 text-white;
  }
}

.arabic.to-left {
  .message {
    @apply bg-ayadi-900 text-white;
  }
}

.to-right.arabic {
  .message {
    @apply bg-white text-textBlue;
  }
}
</style>

<template>
  <modal
    @before-open="getPicture"
    name="chose-name-modal"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white w-modal px-8 py-6 flex flex-col justify-center items-center rounded-lg">
      <div class="flex justify-between items-start w-full mb-4" :class="{ 'flex-row-reverse': arabic }">
        <p class="text-18 font-semibold">{{ $t("booking_flow.tell_us") }}</p>
        <div class="cursor-pointer px-1 py-1" @click="$modal.hide('chose-name-modal')">
          <IconCloseGray />
        </div>
      </div>

      <img class="size-30 mt-6 mb-2" :src="picture" alt="picture" />

      <InputWrapper :label="$t('booking_flow.name')" :placeholder="$t('booking_flow.real_name')">
        <input
          :dir="arabic ? 'rtl' : 'ltr'"
          autofocus
          :placeholder="$t('booking_flow.real_name')"
          type="text"
          slot="input"
          v-model="name"
        />
      </InputWrapper>
      <div class="w-full flex flex-row justify-between items-center mt-4">
        <SecondaryButton @click="goBack" :label="$t('booking_flow.back')" medium />
        <MainButton @click="goForward" :disabled="!isNameValid">{{ $t("booking_flow.continue") }}</MainButton>
      </div>
    </div>
  </modal>
</template>
<script>
import storageClient from "@/services/storageClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
export default {
  name: "ChoseNameModal",
  components: { SecondaryButton, MainButton, IconCloseGray, InputWrapper },

  data() {
    return {
      picture: null,
      name: ""
    };
  },

  computed: {
    isNameValid() {
      return this.name.length >= 1;
    },
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    getPicture() {
      const images = require.context("../../../assets/images/avatars", false, /\.png$/);
      this.picture = images(`./${storageClient.getRegistrationFlowPicture()}.png`);
    },

    goBack() {
      this.$modal.hide("chose-name-modal");
      this.$modal.show("profile-picture-modal");
    },

    goForward() {
      let trimmed = this.name.replace(/^\s+/, "").replace(/\s+$/, "");

      if (!trimmed) {
        this.name = "";
        return;
      }
      storageClient.setRegistrationFlowName(this.name);
      this.$modal.hide("chose-name-modal");
      this.$modal.show("sign-up-modal");
    },
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style scoped>
.size-30 {
  width: 7.5rem;
  height: 7.5rem;
}
</style>

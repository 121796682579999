<template>
  <div class="h-full flex justify-center items-center">
    <modal @closed="$store.dispatch('auth/notRegisteredFromSocial', false)" name="not-social" width="500">
      <div class="bg-white px-8 py-6 rounded-md" :dir="arabic ? 'rtl' : 'ltr'">
        <h5>{{ $t("social_modal.email") }}</h5>
        <p class="my-4">
          {{ $t("social_modal.not_signed_up") }}
        </p>

        <p class="mb-4">{{ $t("social_modal.download") }}</p>
        <div class="flex flex-row justify-center mt-6">
          <a
            :class="arabic ? 'ml-4' : 'mr-4'"
            href="https://apps.apple.com/ae/app/ayadi/id1503638078"
            target="_blank"
            @click="clickAppStore"
          >
            <img src="../../assets/images/Authorization/AppStore.svg" alt="app store" />
          </a>
          <a
            :class="arabic ? 'mr-4' : 'ml-4'"
            href="https://play.google.com/store/apps/details?id=com.bloom.ayadi"
            target="_blank"
            @click="clickGooglePlay"
          >
            <img src="../../assets/images/Authorization/GooglePlay.svg" alt="google play" />
          </a>
        </div>
        <div class="flex flex-row items-center justify-between mt-2">
          <SecondaryButton @click="$modal.hide('not-social')" medium :label="$t('main.close')" />
        </div>
      </div>
    </modal>
    <div class="relative">
      <div class="absolute logo-position">
        <LogoLight />
      </div>

      <SignInForm
        v-if="!showResetPasswordForm"
        @toSignUp="toSignUp"
        @onSignIn="onSignIn"
        @onResetPassword="toggleResetPasswordForm(true)"
      />
      <div v-else class="resetPasswordWrapper">
        <ForgotPasswordForm @goBack="toggleResetPasswordForm(false)" />
      </div>
    </div>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
import LogoLight from "@/components/ui-kit/LogoLight";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import SignInForm from "@/components/forms/SignInForm";
import ForgotPasswordForm from "@/components/forms/ForgotPasswordForm";

export default {
  name: "SignIn",
  components: {
    SecondaryButton,
    SignInForm,
    ForgotPasswordForm,
    LogoLight
  },
  data() {
    return {
      showResetPasswordForm: false
    };
  },

  head: {
    title() {
      return {
        inner: this.arabic ? "أيادي" : "Ayadi",
        separator: "|",
        complement: this.arabic ? "تسجيل الدخول" : "Login"
      };
    }
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    onSignIn() {
      return this.$router.push({ name: "NoUpcomingSessions" }).catch(() => {});
    },
    toSignUp() {
      return this.$router.push({ name: "SignUp" }).catch(() => {});
    },
    clickGooglePlay() {
      window.gtag("event", "therapistProfile_googlePlayStore_click");
    },
    clickAppStore() {
      window.gtag("event", "therapistProfile_appleAppStore_click");
    },

    toggleResetPasswordForm(value) {
      this.showResetPasswordForm = value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.resetPasswordWrapper {
  width: 440px;

  @include for-phone {
    width: 100%;
    min-width: 325px;
    max-width: initial;
  }
}
</style>

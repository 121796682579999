<template>
  <modal
    name="loader-modal"
    height="auto"
    width="360"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white p-6 flex flex-col justify-center items-center rounded-lg">
      <div class="py-20 h-80 flex justify-center items-center w-full h-full bg-white">
        <img class="w-20 animate-bounce" src="../../../assets/images/ayadi-loader.svg" alt="Ayadi" />
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "LoaderModal",
  methods: {
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style scoped></style>

export default {
  getToken() {
    return localStorage.getItem("token");
  },

  setToken(token) {
    localStorage.setItem("token", token);
  },

  removeToken() {
    localStorage.removeItem("token");
  },

  setLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  seShowSuccessPackageModal(value) {
    localStorage.setItem("show-success-package-modal", value);
  },

  getShowSuccessPackageModal() {
    return localStorage.getItem("show-success-package-modal");
  },

  getLanguage() {
    return localStorage.getItem("language");
  },

  setRegistrationFlowPicture(picture) {
    localStorage.setItem("registration-flow-picture", picture);
  },

  getRegistrationFlowPicture() {
    return localStorage.getItem("registration-flow-picture");
  },

  setRegistrationFlowName(name) {
    localStorage.setItem("registration-flow-name", name);
  },

  getRegistrationFlowName() {
    return localStorage.getItem("registration-flow-name");
  },

  setSingUpFrom(value) {
    localStorage.setItem("signup-from", value);
  },

  setPresentedFrom(name) {
    localStorage.setItem("presented-from", name);
  },

  getSingUpFrom() {
    return localStorage.getItem("signup-from");
  },

  getPresentedFrom() {
    return localStorage.getItem("presented-from");
  },

  setBookingSlot(slot) {
    localStorage.setItem("booking-slot", JSON.stringify(slot));
  },

  getBookingSlot() {
    return JSON.parse(localStorage.getItem("booking-slot"));
  },
  setCurrency(currency) {
    localStorage.setItem("currency", currency);
  },

  getCurrency() {
    return localStorage.getItem("currency");
  },

  getKochavaDeviceId() {
    return localStorage.getItem("kochava_device_id");
  },

  setKochavaDeviceId(kochava_device_id) {
    localStorage.setItem("kochava_device_id", kochava_device_id);
  }
};

<template>
  <div class="h-full flex justify-center items-center">
    <div
      class="col-start-4 col-span-2 row-span-1 flex flex-row justify-between items-start bg-white rounded-md p-8 auth-form-wrapper relative sign-in-window"
    >
      <div class="absolute logo-position">
        <LogoLight />
      </div>
      <form
        @submit.prevent="forgotPassword"
        class="flex flex-col justify-between items-center px-4 px-4 w-1/2 h-full form-height"
      >
        <div class="w-full">
          <h5 class="text-center">{{ $t("auth.forgot_your_password") }}</h5>
          <p class="mt-6 mb-3 hint-text">
            {{ $t("auth.forgot_password_hint") }}
          </p>
          <InputWrapper :label="$t('auth.email')" :error-message="emailError">
            <input :dir="arabic ? 'rtl' : 'ltr'" slot="input" type="text" @focus="emailError = ''" v-model="email" />
          </InputWrapper>
        </div>
        <div class="w-full">
          <p class="text-center" v-if="counting">
            {{ $t("auth.link_sent") }}
          </p>
          <MainButton :disabled="counting" class="mt-3 w-full"> {{ counterText }} </MainButton>
        </div>
      </form>
      <div class="flex flex-col justify-start items-center">
        <img src="../../assets/images/Authorization/ForgotPasswordIllustration.svg" alt="forgot password" />
        <p class="mt-6">
          {{ $t("auth.forgot_password") }}
          <router-link class="font-extrabold no-underline text-center" :to="{ name: 'SignIn' }">
            {{ $t("auth.go_back") }}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import InputWrapper from "@/components/ui-kit/InputWrapper";
import authClient from "@/services/authClient";
import MainButton from "@/components/ui-kit/MainButton";
import storageClient from "@/services/storageClient";
import LogoLight from "@/components/ui-kit/LogoLight";
import dayjs from "dayjs";

export default {
  name: "ForgotPassword",
  components: {
    LogoLight,
    MainButton,
    InputWrapper
  },

  data() {
    return {
      email: "",
      emailError: "",
      countDown: 59,
      counting: false
    };
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    counterText() {
      return this.counting
        ? this.$t("auth.send_again") + (10 > this.countDown ? "0" + this.countDown : "" + this.countDown)
        : this.$t("auth.confirm");
    }
  },

  methods: {
    async forgotPassword() {
      if (this.email.length < 7) {
        this.emailError = this.$t("auth.email_required");
        return;
      }
      await authClient.forgotPassword({ email: this.email });
      this.countDownTimer();
    },

    countDownTimer() {
      this.counting = true;
      let counter = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          this.counting = false;
          this.countDown = 59;
          clearInterval(counter);
        }
      }, 1000);
    }
  },

  mounted() {
    window.webengage.user.setAttribute("User type", "Client");
    const trackData = {
      referrer: document.referrer,
      Language: storageClient.getLanguage(),
      "Device type": "Web",
      time: dayjs().unix()
    };
    window.webengage.track("forgotPassword_click", trackData);

    setTimeout(() => {
      this.$mixpanel.register({
        "User type": "Client"
      });

      this.$mixpanel.track("forgotPassword_click", trackData);
      this.$intercom.trackEvent("forgotPassword_click", trackData);
    }, 1000);
  }
};
</script>

<style scoped></style>

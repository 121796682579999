<template>
  <div class="w-6 h-6 bg-white flex justify-center items-center rounded-full cursor-pointer" @click="$emit('click')">
    <svg
      :class="{ 'transform rotate-180': !closed }"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.76693 5.26016L5.00026 2.02682L8.23359 5.26016C8.55859 5.58516 9.0836 5.58516 9.4086 5.26016C9.7336 4.93516 9.7336 4.41016 9.4086 4.08516L5.58359 0.260156C5.25859 -0.0648441 4.73359 -0.0648441 4.40859 0.260156L0.583594 4.08516C0.258594 4.41016 0.258594 4.93516 0.583594 5.26016C0.908594 5.57682 1.44193 5.58516 1.76693 5.26016Z"
        fill="#434A57"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconVideoToggle",
  props: {
    closed: Boolean
  }
};
</script>

<style scoped></style>

<template>
  <div class="bg-bgLight min-h-screen">
    <NavBar :showButtonBack="true" />
    <div class="fit-width-content flex flex-col justify-start items-start mx-auto">
      <div class="self-left mt-4" :class="{ 'self-end': arabic }">
        <h1 class="text-2xl self-left sm:text-lg" :class="{ 'text-right': arabic }">
          {{ $t("therapist-list.title") }}
        </h1>
        <p class="mt-2" :class="{ 'text-right': arabic }">{{ $t("therapist-list.description") }}</p>
        <p class="font-semibold mt-4" :class="{ 'text-right': arabic }">{{ $t("therapist-list.additional-info") }}</p>
      </div>

      <TherapistFilter />

      <div v-if="!isLoading && therapists && therapists.length > 0" class="w-full">
        <TherapistListItem v-for="therapist in therapists" :key="therapist.id" :data="therapist" />
        <div class="pagination-container">
          <paginate
            v-model="storePage"
            :force-page="page"
            :page-count="totalPages"
            :margin-pages="1"
            :page-range="getPageRange()"
            :prev-text="getPrevBtn()"
            :prev-class="'pagination-btn-prev'"
            :next-class="'pagination-btn-next'"
            :page-class="'pagination-page-link'"
            :disabled-class="'pagination-btn-more'"
            :next-text="getNextBtn()"
            :container-class="'pagination-wrapper'"
            :click-handler="onPaginate"
            hide-prev-next
            :dir="direction"
          >
          </paginate>
        </div>
      </div>

      <div v-if="isLoading" class="flex justify-center w-full mt-6 mb-6">
        <img class="w-20 animate-bounce" src="../assets/images/ayadi-loader.svg" alt="Ayadi" />
      </div>
      <div
        v-else-if="therapists && therapists.length === 0"
        class="flex flex-col justify-center items-center w-full mt-6 mb-6 not-found"
      >
        <img src="../assets/images/filter/404.svg" alt="Ayadi" />
        <div class="subTitle">{{ $t("filter.not_found.title") }}</div>
        <MainButton @click="resetFilter()" class="mt-4">{{ $t("filter.not_found.btn") }}</MainButton>
      </div>

      <DarkFooter class="mx-auto my-6" no-image />
    </div>
    <AdditionalProfileUserInfoModal />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Paginate from "vuejs-paginate";

import NavBar from "@/components/NavBar";
import DarkFooter from "@/components/DarkFooter";
import MainButton from "@/components/ui-kit/MainButton";
import TherapistListItem from "@/components/therapists/TherapistListItem";
import TherapistFilter from "@/components/therapists/TherapistFilter";
import AdditionalProfileUserInfoModal from "@/components/modals/AdditionalProfileUserInfoModal";
import storageClient from "@/services/storageClient";

export default {
  name: "TherapistList",
  head: {
    title() {
      return {
        inner: this.arabic ? "اكتشف اكثر من 50 استشاري نفسي عرب على ايادي" : "Browse 50+ Arab therapists on Ayadi"
      };
    }
  },
  components: {
    DarkFooter,
    NavBar,
    TherapistListItem,
    TherapistFilter,
    Paginate,
    MainButton,
    AdditionalProfileUserInfoModal
  },
  props: {
    orderId: null
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },

  watch: {
    user: {
      handler: "trackPage",
      immediate: true
    }
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    ...mapState("auth", ["user"]),
    ...mapState("therapistFilterDynamic", ["therapists", "page", "totalPages", "isLoading", "lastEnteredTherapist"]),
    storePage: {
      get() {
        return this.page;
      },
      set() {}
    }
  },

  methods: {
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    async scrollToTherapist() {
      if (this.lastEnteredTherapist) {
        setTimeout(async () => {
          const lastTherapistElement = window.document.getElementById(`therapist-${this.lastEnteredTherapist.id}`);
          if (lastTherapistElement) {
            lastTherapistElement.scrollIntoView();
            await this.$store.dispatch("therapistFilterDynamic/setLastEnteredTherapist", null);
            return;
          }
          this.scrollToTherapist();
        }, 100);
      }
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },
    async resetFilter() {
      this.$mixpanel.track("therapistList_filterReset_click");
      await this.$store.dispatch("therapistFilterDynamic/resetAllFilter");
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapists", 1);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getPageRange() {
      if (this.windowWidth < 500) {
        return 1;
      }
      return 3;
    },
    async onPaginate(value) {
      this.$kochava.sendEvent("Search", {
        name: "search",
        user_id: this.user?.id || null,
        now_date: new Date()
      });
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapists", value);
    },
    getPrevBtn() {
      return `<div class='btn-prev-wrapper'>${this.$t(
        "pagination.back"
      )} <div class="btn-prev-wrapper-icon"><svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2929 1.08977C10.6834 0.699244 11.3166 0.699244 11.7071 1.08977L15.7071 5.08977C16.0976 5.48029 16.0976 6.11346 15.7071 6.50398L11.7071 10.504C11.3166 10.8945 10.6834 10.8945 10.2929 10.504C9.90238 10.1135 9.90238 9.48029 10.2929 9.08977L12.5858 6.79688H1C0.447716 6.79687 -2.41411e-08 6.34916 0 5.79688C2.41411e-08 5.24459 0.447716 4.79688 1 4.79688H12.5858L10.2929 2.50398C9.90238 2.11346 9.90238 1.48029 10.2929 1.08977Z" fill="#44A4A5"/>
</svg>
</div></div>`;
    },
    getNextBtn() {
      return `<div class='btn-next-wrapper'>${this.$t(
        "pagination.next"
      )} <div class="btn-next-wrapper-icon"><svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2929 1.08977C10.6834 0.699244 11.3166 0.699244 11.7071 1.08977L15.7071 5.08977C16.0976 5.48029 16.0976 6.11346 15.7071 6.50398L11.7071 10.504C11.3166 10.8945 10.6834 10.8945 10.2929 10.504C9.90238 10.1135 9.90238 9.48029 10.2929 9.08977L12.5858 6.79688H1C0.447716 6.79687 -2.41411e-08 6.34916 0 5.79688C2.41411e-08 5.24459 0.447716 4.79688 1 4.79688H12.5858L10.2929 2.50398C9.90238 2.11346 9.90238 1.48029 10.2929 1.08977Z" fill="#44A4A5"/>
</svg>
</div></div>`;
    },
    async trackPage() {
      if (this.user && this.user?.show_basic_details_prompt === true) {
        this.$modal.show("additional-profile-user-info-modal");
        const signUpFrom = await storageClient.getSingUpFrom();
        const presentedFrom = await storageClient.getPresentedFrom();

        const trackData = {
          presented_from: presentedFrom || "",
          signup_from: signUpFrom || ""
        };

        this.$mixpanel.track("singup_userdata_view", trackData);
      }

      const trackData = {
        user_id: this.user?.id || null,
        now_date: new Date()
      };

      this.$mixpanel.track("view_therapist_list", trackData);

      this.$kochava.sendEvent("View", {
        name: "view_therapist_list",
        user_id: this.user?.id || null,
        now_date: new Date()
      });
    }
  },

  async mounted() {
    window.location.href = `https://go.ayadihealth.co/${this.arabic ? "ar" : "en"}/therapists`;
    await this.$store.dispatch("common/getCurrencies");

    if (this.lastEnteredTherapist) {
      const promiseTherapist = new Promise((resolve) => {
        this.$store.dispatch("therapistFilterDynamic/fetchTherapists", this.lastEnteredTherapist?.page);
        resolve();
      });
      promiseTherapist.then(() => this.scrollToTherapist());
    } else {
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapists", 1);
    }

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style lang="scss" scoped>
.not-found {
  img {
    height: 430px;
    width: 100%;
  }
  .subTitle {
    font-weight: 600;
    color: #6f7786;
    font-size: 14px;
    line-height: 20px;
  }
}
.pagination-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 2em;
  border-top: 1px solid #eeedf2;
}
</style>

<style lang="scss">
@import "@/styles/media.scss";

.pagination-wrapper {
  display: flex;
  margin-top: 1em;

  .pagination-btn-next {
    position: absolute;
    right: 0;

    a {
      color: #44a4a5;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
    }
  }

  .pagination-btn-prev {
    position: absolute;
    left: 0;

    a {
      color: #44a4a5;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
    }
  }

  .btn-prev-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;

    .btn-prev-wrapper-icon {
      margin-right: 1em;
      transform: rotateY(180deg);
    }
  }

  .btn-next-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .btn-next-wrapper-icon {
      margin-left: 1em;
    }
  }

  &[dir="rtl"] {
    .pagination-btn-next {
      left: 0;
      right: inherit;

      .btn-next-wrapper-icon {
        margin-right: 1em;
        transform: rotateY(180deg);
      }
    }

    .pagination-btn-prev {
      right: 0;
      left: inherit;

      .btn-prev-wrapper-icon {
        margin-left: 1em;
        transform: rotateY(360deg);
      }
    }
  }
}

.pagination-btn-more {
  a {
    color: #6f7786;
    font-size: 14px;
    text-decoration: none;
    line-height: 20px;
    font-weight: 600;
  }
}

.pagination-page-link {
  position: relative;
  margin: 0 30px;

  a {
    color: #6f7786;
    font-size: 14px;
    text-decoration: none;
    line-height: 20px;
    font-weight: 600;

    &:hover {
      color: #44a4a5;
    }
  }
  &.active {
    a {
      color: #44a4a5;
    }

    &:after {
      @apply bg-ayadi-900;
      content: "";
      width: 40px;
      height: 2px;
      position: absolute;
      top: -17px;
      left: -17px;
    }
  }

  @include for-phone {
    margin: 0 15px;
  }
}
</style>

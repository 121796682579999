import commonService from "@/services/commonService";

export const namespaced = true;

export const state = {
  currentIPData: {},
  currencies: [],
  isLoadingApp: false,
  preflight: null
};

export const mutations = {
  SET_CURRENT_IP(state, ip) {
    state.currentIPData = ip;
  },

  SET_CURRENCIES(state, currencies) {
    state.currencies = currencies;
  },

  SET_LOADING_APP(state, value) {
    state.isLoadingApp = value;
  },

  SET_PREFLIGHT(state, value) {
    state.preflight = value;
  }
};

export const actions = {
  async getWhiteList({ commit }) {
    const ip = await commonService.getIP();
    +commit("SET_CURRENT_IP", ip.data);
  },

  async getCurrencies({ commit }) {
    const { data } = await commonService.getCurrencyList();
    commit("SET_CURRENCIES", data);
  },

  async getPreflight({ commit }) {
    const { data } = await commonService.getPreflight();
    commit("SET_PREFLIGHT", data);
  },

  setLoadingApp({ commit }, value) {
    commit("SET_LOADING_APP", value);
  }
};

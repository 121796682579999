<template>
  <div class="flex flex-col self-center mt-auto mb-auto items-center">
    <img src="../../assets/images/filter/noOptions.svg" />
    <div class="title">{{ $t("filter.options.title") }}</div>
    <MainButton @click="$emit('onClear')" class="mt-4 button" :dir="direction">{{
      $t("filter.options.btn")
    }}</MainButton>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";

import MainButton from "@/components/ui-kit/MainButton";

export default {
  name: "FilterOptionsNotFound",
  components: { MainButton },
  data() {
    return {};
  },
  computed: {
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  color: #7f8fa4;
}

.button {
  padding: 10px 24px;
  width: 140px;
}
</style>

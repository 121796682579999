<template>
  <div>
    <modal
      @before-open="beforeOpenModal"
      name="selection-payment-modal"
      :class="hideModal && 'hideModal'"
      width="432px"
      height="auto"
      :adaptive="true"
      :clickToClose="false"
      @opened="blockScroll"
      @closed="unblockScroll"
    >
      <div
        class="bg-white max-h-90 py-6 flex flex-col justify-center items-center rounded-lg"
        :dir="arabic ? 'rtl' : 'ltr'"
      >
        <div class="w-full overflow-y-auto" v-if="availabilityPaymentMethods && availabilityPaymentMethods.length > 0">
          <div class="flex justify-between items-start mx-6 mb-4">
            <p class="text-18 font-semibold w-full" :class="arabic ? 'text-right' : 'text-left'">
              {{ $t("payments.payment_type") }}
            </p>
            <div class="cursor-pointer px-1 py-1" @click="$modal.hide('selection-payment-modal')">
              <IconCloseGray />
            </div>
          </div>
          <div class="flex justify-start items-center mx-6 p-3 rounded-2xl mb-4">
            <img :class="arabic ? 'ml-3' : 'mr-3'" src="../../../assets/images/IconRefresh.svg" alt="icon" />
            <div v-if="!arabic">
              <p class="font-semibold text-lightGray">You may be charged a slightly different amount.</p>
              <a
                href="https://help.ayadihealth.co/en/articles/5746022-why-am-i-charged-more-than-originally-communicated"
                target="_blank"
                class="font-semibold text-ayadi-900 text-sm no-underline"
              >
                Learn why
              </a>
            </div>
            <div v-else>
              <p class="font-semibold text-lightGray">
                المبلغ تقريبي ويمكن أن يختلف بشكل بسيط عند اجراء عملية الشراء.
                <a
                  href="https://help.ayadihealth.co/ar/articles/5746022-why-am-i-charged-more-than-originally-communicated"
                  target="_blank"
                  class="font-semibold text-ayadi-900 text-sm no-underline"
                >
                  اضغط هنا
                </a>
                لمعرفة المزيد.
              </p>
            </div>
          </div>

          <div class="paymentsWrapper">
            <div
              class="paymentItem"
              v-for="paymentMethod in availabilityPaymentMethods.filter(
                (item) => item.kind === 'checkout_card' || item.kind === 'tabby_link'
              )"
              :key="paymentMethod.kind"
            >
              <img
                :class="arabic ? 'ml-4' : 'mr-4'"
                class="w-56px paymentIcon cursor-pointer"
                :src="paymentMethod.icon"
                :alt="paymentMethod.payment_system"
                @click="continuePayment(paymentMethod.kind)"
              />
              <div class="textWrapper">
                <div class="title cursor-pointer" @click="continuePayment(paymentMethod.kind)">
                  {{ paymentMethod.name }}
                </div>
                <div class="description" v-if="paymentMethod.subtitle">
                  {{ paymentMethod.subtitle }}
                </div>
                <div
                  class="cta"
                  @click="openLink(paymentMethod.payment_system, paymentMethod.learn_more_url)"
                  v-if="paymentMethod.learn_more_title && paymentMethod.learn_more_url"
                >
                  {{ paymentMethod.learn_more_title }}
                </div>
              </div>
              <div class="arrow" :dir="arabic ? 'rtl' : 'ltr'" @click="continuePayment(paymentMethod.kind)">
                <IconArrowRight :class="{ 'transform rotate-180': arabic }" color="#939AA8" />
              </div>
            </div>
          </div>
          <div class="flex justify-start items-center mx-5 mt-5">
            <img src="../../../assets/images/IconShield.svg" :class="arabic ? 'ml-2' : 'mr-2'" alt="shield" />

            <p class="tip">
              {{ $t("payments.redirected") }}
            </p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import IconArrowRight from "@/components/icons/IconArrowRight";
import { mapState } from "vuex";
import { calculatePrice } from "../../../utils/price";

export default {
  name: "SelectionPaymentModal",
  components: {
    IconCloseGray,
    IconArrowRight
  },
  data() {
    return {
      onProceedPayment: null,
      tabbyModalHtml: null,
      price: null,
      currency: null,
      hideModal: false
    };
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("sessions", ["coupon", "availabilityPaymentMethods"]),
    ...mapState("common", ["preflight"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  },
  methods: {
    beforeOpenModal(value) {
      this.$modal.hide("loader-modal");
      if (value.params) {
        this.onProceedPayment = value.params.cb;
        this.price = value.params.price;
        this.currency = value.params.currency;
        return;
      }
    },
    async continuePayment(method) {
      if (this.onProceedPayment) {
        this.closeModal();
        this.$modal.show("loader-modal");
        await this.onProceedPayment(method);
      }
    },

    showModal() {
      this.hideModal = false;
    },

    async openLink(payment_type, link) {
      if (payment_type === "tabby") {
        this.openTabbyDescription(link);
        return;
      }

      window.open(link);
    },

    async openTabbyDescription(link) {
      this.hideModal = true;
      if (this.coupon?.value_object?.amount) {
        this.$modal.show("tabby-information-modal", {
          onBack: this.showModal,
          price: calculatePrice(this.coupon?.value_object?.amount, this.coupon?.value_object?.divider),
          currency: this.coupon?.value_object.currency,
          link
        });
        return;
      }
      this.$modal.show("tabby-information-modal", {
        onBack: this.showModal,
        price: this.price,
        currency: this.currency,
        link
      });
    },
    blockScroll() {
      this.hideModal = false;
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      this.hideModal = false;
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    closeModal() {
      this.$modal.hide("selection-payment-modal");
    }
  }
};
</script>

<style scoped lang="scss">
.paymentsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.paymentItem {
  box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1rem;
  position: relative;

  .paymentIcon {
    cursor: pointer;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    max-width: 220px;
    gap: 1px;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #354052;
    }

    .description {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #354052;
    }

    .cta {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #44a4a5;
      cursor: pointer;
    }
  }

  .arrow {
    svg {
      position: absolute;
      top: 50%;
      right: 27px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    &[dir="rtl"] {
      svg {
        right: inherit;
        left: 27px;
        transform: rotate(180deg) translateY(-50%);
      }
    }
  }
}

.tip {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #939aa8;
}

.hideModal {
  display: none;
}

.paymentsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
</style>

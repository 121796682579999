<template>
  <div class="h-screen w-screen bg-gradient absolute top-0 left-0 z-100 flex justify-center items-center">
    <div class="flex flex-col justify-center items-center test-video-content text-center">
      <img class="mb-6" src="../../assets/images/AyadiLogoOnlyIcon.svg" alt="ayadi" />

      <countdown
        v-if="sessionNotStarted"
        :time="timeRemaining"
        :transform="transform"
        @progress="openLink"
        @end="compareTime"
      >
        <template slot-scope="props">
          <p class="text-white text-18 font-semibold" :dir="direction">
            {{ $t("sessions.your_chat", { name: therapistName }) }}
          </p>
          <p class="text-white text-2xl font-semibold mb-4" :dir="direction">
            {{ props.days }} {{ $t("sessions.days") }} : {{ props.hours }} {{ $t("sessions.hrs") }} :
            {{ props.minutes }} {{ $t("sessions.min") }} : {{ props.seconds }} {{ $t("sessions.sec") }}
          </p>
        </template>
      </countdown>

      <div v-if="sessionGoing">
        <p class="text-white text-18 font-semibold" :dir="direction">
          {{ $t("sessions.your_chat", { name: therapistName }) }}
        </p>
        <p class="text-white text-2xl font-semibold mb-4">{{ $t("sessions.please_join") }}</p>
      </div>

      <div v-if="sessionEnded">
        <p class="text-white text-18 font-semibold mb-4" :dir="direction">
          {{ $t("sessions.not_available", { name: therapistName }) }}
        </p>
      </div>

      <p class="text-white text-18 font-semibold" :dir="direction">{{ $t("sessions.chat_duration") }}</p>
      <div class="flex items-center justify-center mb-4" :class="{ 'flex-row-reverse': arabic }">
        <img src="../../assets/images/IconChatCircle.svg" alt="chat" />
        <p class="text-white text-2xl font-semibold ml-2" :dir="direction" :class="{ 'mr-2 ml-0': arabic }">
          {{ $t("sessions.25_min_chat") }}
        </p>
      </div>

      <div class="flex flex-row justify-between items-center">
        <button
          v-if="!linkIsDisabled"
          @click="$emit('join-session')"
          class="custom-button cursor-pointer px-4 py-2 my-0 mr-3"
        >
          {{ $t("sessions.join_session") }}
        </button>
        <div
          v-tooltip.hover="{
            content: $t('main.blocked_button'),
            class: 'tooltip-custom'
          }"
          v-else
        >
          <!--          :disabled="linkIsDisabled"-->
          <button
            @click="$emit('join-session')"
            :disabled="linkIsDisabled"
            class="custom-button cursor-pointer px-4 py-2 my-0 mr-3"
          >
            {{ $t("sessions.join_session") }}
          </button>
        </div>
        <SecondaryButton @click="$emit('leave')" class="ml-3" :label="$t('main.logout')" medium />
      </div>
    </div>
    <footer-info class="absolute bottom-0" no-image />
  </div>
</template>

<script>
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import countdown from "@chenfengyuan/vue-countdown";
import store from "@/store";
import dayjs from "dayjs";
import storageClient from "@/services/storageClient";
import FooterInfo from "@/components/FooterInfo";

export default {
  name: "ChatWelcomeRoom",
  components: {
    FooterInfo,
    SecondaryButton,
    countdown
  },
  props: {
    therapistName: {
      type: String,
      default: "TherapistName"
    },

    sessionStartTime: {
      type: null
    },

    sessionEndTime: null
  },

  data() {
    return {
      sessionNotStarted: true,
      sessionGoing: false,
      sessionEnded: false,
      linkIsDisabled: true
    };
  },

  computed: {
    timeRemaining() {
      if (new Date().getTime() > new Date(this.sessionStartTime).getTime()) {
        return 0;
      }
      return new Date(this.sessionStartTime) - new Date();
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  },

  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      return props;
    },

    openLink(data) {
      if (data.hours === 0 && data.days === 0 && data.minutes < 5) {
        this.linkIsDisabled = false;
      }
    },

    logout() {
      store.dispatch("auth/logout");
    },

    compareTime() {
      setTimeout(() => {
        if (dayjs().isBefore(dayjs(this.sessionStartTime))) {
          this.sessionNotStarted = true;
          this.sessionGoing = false;
          this.sessionEnded = false;
        } else if (dayjs().isAfter(dayjs(this.sessionEndTime))) {
          this.sessionNotStarted = false;
          this.sessionGoing = false;
          this.sessionEnded = true;
          this.linkIsDisabled = true;
        } else {
          this.sessionNotStarted = false;
          this.sessionGoing = true;
          this.sessionEnded = false;
          this.linkIsDisabled = false;
        }
      }, 1000);
    }
  },

  mounted() {
    this.compareTime();
  }
};
</script>

<style lang="scss" scoped>
.z-100 {
  z-index: 100;
}
</style>

<template>
  <label
    class="flex flex-col justify-center items-start font-semibold relative w-full mb-2 transition-all duration-200"
    :class="{ 'text-disabledColor': disabled, 'input-error': errorMessage }"
  >
    {{ label }}
    <slot name="input"></slot>
    <p class="text-ayadiRed text-xs font-normal" v-if="errorMessage">{{ errorMessage }}</p>
    <img v-if="showDeletingIcon" src="../../assets/images/icons/close.svg" class="close" @click="$emit('onClear')" />
    <img
      src="../../assets/images/icons/searchGreen.svg"
      class="search"
      :class="{ revert: !showDeletingIcon }"
      v-if="showSearchIcon"
      @click="$emit('onSearch')"
    />
  </label>
</template>

<script>
import storageClient from "@/services/storageClient";
export default {
  name: "InputWrapper",
  props: {
    label: String,
    type: String,
    submitValue: String,
    required: Boolean,
    caption: Boolean,
    customCaption: String,
    isPhoneNumber: Boolean,
    isSelect: Boolean,
    isDatePicker: Boolean,
    selectOptions: Array,
    isRadio: Boolean,
    radioValues: Array,
    inputValue: String,
    isTextarea: Boolean,
    isForNow: Boolean,
    showDeletingIcon: Boolean,
    showSearchIcon: Boolean,
    placeholder: {
      type: String
    },
    characters: {
      type: Number,
      default: 600
    },
    multiple: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    inputClass: Boolean,
    disabled: Boolean,
    value: null,
    selected: String,
    textareaValue: String,
    inputError: Boolean,
    errorMessage: String,
    pattern: String,
    isPassword: Boolean
  },
  data() {
    return {
      isOpen: false,
      date: null,
      OpenIndicator: {
        render: (createElement) => createElement("span", "▼")
      },
      phoneNumber: "",
      uploadedFile: "",
      radioModel: this.selected,
      radioChecked: ""
    };
  },

  computed: {
    isSubmit() {
      return this.type === "submit";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    toggleEye() {
      let input = this.$el.getElementsByTagName("input");
      if (!this.isOpen) {
        input[0].setAttribute("type", "text");
      } else {
        input[0].setAttribute("type", "password");
      }
      this.isOpen = !this.isOpen;
    },

    selectBlur() {
      return false;
    },

    handleInput(value) {
      this.$emit("input", value);
    },

    handlePhoneInput() {
      let phoneNumber = {
        number: this.$refs.phone.results.formattedNumber,
        isValid: this.$refs.phone.results.isValid
      };
      this.$emit("phone-change", phoneNumber);
    },

    handlePhoneNonformatted(value) {
      let phone = {
        code: value.countryCallingCode,
        number: value.nationalNumber
      };
      this.$emit("phone-change-nonformatted", phone);
    },

    handleSelectInput() {
      this.$emit("input", this.$refs.select.$data._value);
    },

    handleDateInput() {
      this.$emit("input", this.date);
    },

    handleRadio(e) {
      this.$emit("input", e.target.value);
    }
  },

  watch: {
    radioModel() {
      this.$emit("input", this.radioModel);
    }
  },

  mounted() {
    this.radioModel = this.selected;
  },

  updated() {
    this.radioModel = this.selected;
  }
};
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.search {
  position: absolute;
  left: 15px;
  cursor: pointer;

  &.revert {
    left: auto;
    right: 15px;
  }
}

textarea {
  min-height: 100px;
}

input:focus,
input:active,
textarea:focus,
textarea:active {
  //noinspection CssInvalidAtRule
  @apply border-ayadi-900 text-textBlue bg-white;
  outline: none;
}

label:focus-within {
  //noinspection CssInvalidAtRule
  @apply text-ayadi-900;
  input {
    //noinspection CssInvalidAtRule
    @apply text-textBlue;
  }
}

input[type="submit"] {
  //noinspection CssInvalidAtRule
  @apply bg-ayadi-900 text-white border-none font-semibold cursor-pointer transition-all duration-200 font-semibold text-base;
}

input[type="submit"]:hover {
  //noinspection CssInvalidAtRule
  @apply bg-ayadi-800 text-white;
}

input[type="submit"]:focus,
input[type="submit"]:active {
  //noinspection CssInvalidAtRule
  @apply bg-ayadi-800 border border-ayadi-800 text-white;
}

input:disabled {
  //noinspection CssInvalidAtRule
  @apply border-disabledColor;
}

input[type="submit"]:disabled {
  //noinspection CssInvalidAtRule
  @apply bg-disabledColor border-disabledColor;
  &:hover {
    //noinspection CssInvalidAtRule
    @apply bg-disabledColor border-disabledColor;
  }
}

.disabled-button,
.disabled {
  //noinspection CssInvalidAtRule
  @apply bg-disabledColor border-disabledColor;
}

.eye {
  position: absolute;
  right: 0.5rem;
  top: 2.55rem;
}
</style>

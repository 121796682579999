<template>
  <div class="flex flex-col w-full mb-8">
    <div class="flex items-center text-lg font-semibold" :class="{ 'flex-row-reverse': arabic }">
      {{ data.data.title }}
    </div>
    <div class="flex" :class="{ 'flex-row-reverse descriptionText': arabic }">
      {{ $t("filter.time.description", { time: getDateNow() }) }}
    </div>
    <div class="flex font-semibold my-2" :class="{ 'flex-row-reverse': arabic }">
      {{ data.value[0] }} - {{ data.value[1] }}
    </div>
    <div class="px-2">
      <vue-slider
        v-model="data.value"
        @change="onChange"
        :lazy="true"
        :min="data.data.range_params.min"
        :max="data.data.range_params.max"
        :data="getTimeRangeTest"
        :interval="1"
        :marks="true"
        :hide-label="true"
        :enable-cross="false"
        :direction="direction"
      ></vue-slider>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import dayjs from "dayjs";
import storageClient from "@/services/storageClient";
import { getFilterEvents } from "../../utils/filter";

export default {
  name: "DynamicTimeRangeSlider",
  components: {
    VueSlider
  },

  props: {
    data: {
      type: Object
    }
  },
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    getTimeRangeTest() {
      const timeRange = [];

      for (let i = 0; i <= this.data.data.range_params.max; i++) {
        timeRange.push(dayjs().hour(i).minute(0).format("HH:mm"));
        if (i === 24) {
          timeRange.push("24:00");
        }
      }

      return timeRange;
    }
  },
  methods: {
    async onChange(value) {
      await this.$store.dispatch("therapistFilterDynamic/setFilterOptionValue", {
        key: this.data.key,
        value: value
      });
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapistsDynamic", 1);

      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_change", trackData);
      this.$mixpanel.track("therapistFilters_change", trackData);
      this.$intercom.trackEvent("therapistFilters_change", trackData);
    },

    getTimeFormat(value) {
      return dayjs().hour(value.toString()).minute(0).format("HH:mm");
    },

    getDateNow() {
      return dayjs(new Date()).format("HH:mm");
    }
  }
};
</script>

<style lang="scss" scoped></style>

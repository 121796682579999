<template>
  <div id="dropdown" v-v-click-outside="clickOutside" class="relative">
    <p @click="toggleDropdown" class="text-sm font-semibold flex items-center cursor-pointer">
      <slot></slot> <img class="mb-px" src="../../assets/images/DropdownIndicator.svg" alt="<" />
    </p>

    <div
      v-if="showDropdown"
      class="absolute mt-8 top-0 bg-white rounded w-40 shadow-md py-1"
      :class="arabic ? 'left-0' : 'right-0'"
    >
      <p
        v-for="(option, index) in options"
        :key="`${option.title}_${index}`"
        class="px-3 py-2 cursor-pointer hover:bg-textGray-100 font-semibold transition-all duration-200"
        :class="{ 'text-ayadi-900': option.title === active }"
        @click="choseActive(option.title)"
      >
        {{ option.title }}
      </p>
    </div>
  </div>
</template>

<script>
import vClickOutside from "vue-click-outside";

import storageClient from "@/services/storageClient";

export default {
  name: "DropdownMenu",
  directives: { vClickOutside },
  props: {
    options: {
      type: Array,
      required: true
    },
    selectedByDefault: String
  },
  data() {
    return {
      showDropdown: false,
      active: null
    };
  },

  watch: {
    active() {
      this.$emit("input", this.active);
    }
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },
  methods: {
    toggleDropdown() {
      if (!this.showDropdown) {
        this.$emit("onOpen");
      }
      this.showDropdown = !this.showDropdown;
    },

    choseActive(option) {
      this.active = option;
      this.toggleDropdown();
    },

    clickOutside(event) {
      if (event.target.id !== "dropdown" && this.showDropdown) {
        this.showDropdown = false;
      }
    }
  },

  created() {
    this.active = this.selectedByDefault || this.options[0];
  },

  mounted() {
    this.popupItem = this.$el;
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center mt-4" :class="{ 'flex-row-reverse': arabic }">
      <AyadiCheckbox :checked="data.value || false">
        <input :ref="data.short_title" type="checkbox" @click="toggleSwitch()" />
      </AyadiCheckbox>
      <p class="ml-2 font-semibold text-base select-none" :class="{ 'mr-2 ml-0': arabic }">
        {{ data.data.title }}
      </p>
    </div>
    <div class="flex items-center text-sm mt-4 mb-6 color-gray" :class="{ 'flex-row-reverse': arabic }">
      {{ data.data.description }}
    </div>
  </div>
</template>

<script>
import AyadiCheckbox from "@/components/ui-kit/AyadiCheckbox";
import storageClient from "@/services/storageClient";
import { getFilterEvents } from "../../utils/filter";

export default {
  name: "DynamicCheckbox",
  components: {
    AyadiCheckbox
  },

  props: {
    data: {
      type: Object
    }
  },
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  },
  methods: {
    async toggleSwitch() {
      await this.$store.dispatch("therapistFilterDynamic/setFilterOptionValue", {
        key: this.data.key,
        value: !this.data.value
      });
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapistsDynamic", 1);

      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_change", trackData);
      this.$mixpanel.track("therapistFilters_change", trackData);
      this.$intercom.trackEvent("therapistFilters_change", trackData);
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="bg-white w-modal px-8 py-6 flex flex-col justify-center items-center rounded-lg">
    <div class="flex items-start w-full mb-4" :class="{ 'flex-row-reverse': arabic, 'justify-between': isModal }">
      <div class="cursor-pointer px-1 py-1" @click="$emit('goBack')">
        <img src="../../assets/images/Authorization/Back.svg" alt="picture" />
      </div>
      <p class="text-18 font-semibold" :class="{ 'title-window': !isModal }">
        {{ $t("booking_flow.continue_with_email") }}
      </p>
      <div v-if="isModal" class="cursor-pointer px-1 py-1" @click="$modal.hide('sign-up-via-email-modal')">
        <IconCloseGray />
      </div>
    </div>

    <InputWrapper
      :label="$t('booking_flow.name')"
      :placeholder="$t('booking_flow.real_name')"
      :error-message="nameErrorText"
    >
      <input
        :dir="arabic ? 'rtl' : 'ltr'"
        autofocus
        type="text"
        slot="input"
        @blur="onBlurName"
        @focus="nameErrorText = ''"
        v-model="name"
      />
    </InputWrapper>

    <InputWrapper :class="{ 'items-end': arabic }" :label="$t('auth.email')" :error-message="emailErrorText">
      <input
        :dir="arabic ? 'rtl' : 'ltr'"
        slot="input"
        type="text"
        @blur="onBlurEmail"
        @focus="emailErrorText = ''"
        v-model="email"
      />
    </InputWrapper>

    <InputWrapper
      class="w-full passwordInput"
      :class="{ 'items-end': arabic }"
      :label="$t('auth.password')"
      :error-message="passwordErrorText"
    >
      <PasswordInput
        :dir="arabic ? 'rtl' : 'ltr'"
        v-model="password"
        @blur="onBlurPassword"
        @focus="passwordErrorText = ''"
        slot="input"
        ref="passwordInputRef"
      />
    </InputWrapper>

    <div class="w-full mb-6">
      <BaseInput :label="$t('auth.birthday')" class="w-full" :inputError="!!birthDateError" is-date-picker>
        <div
          slot="datepicker"
          class="border w-full my-1 rounded border-borderGray relative"
          :class="{ 'datePicker-error': birthDateError }"
        >
          <span v-if="birthDate" @click.prevent="birthDate = null" class="clear-date">
            <IconClose class="cursor-pointer clear-date absolute z-20" />
          </span>

          <VueDatePicker
            :value="new Date(Date.now())"
            v-model="birthDate"
            format="YYYY-M-D"
            format-header=""
            color="#44A4A5"
            :max-date="new Date()"
            :visible-years-number="80"
            placeholder=""
            @onChange="clearBirthDateError"
            @onOpen="changed = true"
          />
        </div>
      </BaseInput>
      <p class="text-ayadiRed text-xs font-normal w-full" v-if="birthDateError">{{ birthDateError }}</p>
    </div>
    <div class="w-full mb-8">
      <p class="text-base font-semibold" :class="{ 'text-red-700': genderErrorText }">
        {{ $t("booking_flow.i_identify_as") }}
      </p>
      <div class="flex justify-between genderWrapper">
        <button
          @click="selectGender('female')"
          :class="{ 'bg-gray-200': gender === 'female' }"
          class="bg-white flex justify-between hover:bg-gray-100 focus:outline-none text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow"
        >
          <img src="../../assets/images/Authorization/Female.png" class="w-6 mr-2 sm:hidden" alt="female" />
          {{ $t("booking_flow.female") }}
        </button>
        <button
          @click="selectGender('male')"
          :class="{ 'bg-gray-200': gender === 'male' }"
          class="bg-white flex justify-between hover:bg-gray-100 focus:outline-none text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow"
        >
          <img src="../../assets/images/Authorization/Male.png" class="w-6 mr-2 sm:hidden" alt="male" />
          {{ $t("booking_flow.male") }}
        </button>
        <button
          @click="selectGender('unspecified')"
          :class="{ 'bg-gray-200': gender === 'unspecified' }"
          class="bg-white hover:bg-gray-100 focus:outline-none text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow"
        >
          {{ $t("booking_flow.other") }}
        </button>
      </div>
    </div>

    <MainButton @click="signUp" class="w-full signup_method" id="email_signup">{{ $t("auth.sign_up") }}</MainButton>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { AnalyticsTrackData } from "@/services/analytics";
import dayjs from "dayjs";

import BaseInput from "@/components/ui-kit/BaseInput";
import storageClient from "@/services/storageClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import IconClose from "@/components/icons/IconClose";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import PasswordInput from "@/components/ui-kit/PasswordInput";
import MainButton from "@/components/ui-kit/MainButton";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import getClientAvatarByGender from "@/utils/getClientAvatarByGender";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

export default {
  name: "SignInViaEmailForm",
  components: { MainButton, PasswordInput, IconCloseGray, IconClose, InputWrapper, VueDatePicker, BaseInput },

  data() {
    return {
      email: "",
      password: "",
      emailErrorText: "",
      nameErrorText: "",
      genderErrorText: "",
      passwordErrorText: "",
      birthDateError: "",
      name: "",
      birthDate: null,
      gender: null
    };
  },

  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState("auth", ["user", "notifications"]),
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    clearBirthDateError() {
      this.birthDateError = "";
    },

    selectGender(value) {
      this.genderErrorText = "";
      this.gender = value;
    },

    onBlurName() {
      if (this.name.length > 0) {
        this.name = this.name.replace(/^\s+|\s+$/gm, "");
      }
    },

    onBlurEmail() {
      if (this.email.length > 0) {
        this.email = this.email.replace(/^\s+|\s+$/gm, "");
      }
    },

    onBlurPassword(value) {
      this.password = value;
      if (this.password && this.password?.length > 0) {
        const editedPassword = this.password.replace(/ /g, "");
        this.$refs.passwordInputRef.$refs.password.value = editedPassword;
        this.password = editedPassword;
      }
    },

    async signUp() {
      // prettier-ignore
      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const mediumRegex = new RegExp(
        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
      );

      if (this.name.length < 2) {
        this.nameErrorText = this.$t("auth.name_required");
        return;
      }

      if (this.email.length < 5 || !this.email.toLowerCase().match(regexEmail)) {
        this.emailErrorText = this.$t("auth.email_required");
        return;
      }

      if (!mediumRegex.test(this.password)) {
        this.passwordErrorText = this.$t("auth.password_required");
        return;
      }

      if (!this.birthDate) {
        this.birthDateError = this.$t("auth.date_of_birth_required");
        return;
      }

      if (!this.gender) {
        this.genderErrorText = this.$t("auth.gender_required");
        return;
      }

      try {
        await this.$store.dispatch("auth/setLoading", true);
        await this.$store.dispatch("auth/signUp", { email: this.email, password: this.password });

        await this.$store.dispatch("auth/updateProfile", {
          name: this.name,
          full_name: this.name,
          name_type: "REAL",
          gender: this.gender,
          birth_date: this.birthDate,
          avatar_id: getClientAvatarByGender(this.gender),
          time: dayjs().unix()
        });
        await this.$store.dispatch("auth/setLoading", false);
        if (this.user) {
          this.$mixpanel.identify(this.user.id);

          const trackData = {
            "Device type": "Web",
            "Sign-up Method": "therapist profile",
            signup_from: "Email"
          };
          window.webengage.track("successful_Registration", trackData);
          this.$mixpanel.track("successful_Registration", trackData);
          storageClient.setSingUpFrom("Email");

          this.$kochava.registerIdentityLink("User ID", this.user.id);
          this.$kochava.registerIdentityLink("User Email", this.user.email);

          const kochava_device_id = this.$kochava.getDeviceId();

          AnalyticsTrackData.sendKochavaId(kochava_device_id);

          this.$kochava.sendEvent("Registration Complete", {
            name: "sign_up",
            user_id: this.user?.id || null,
            now_date: new Date()
          });
        }

        this.$emit("onSignUp");
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.size-30 {
  width: 7.5rem;
  height: 7.5rem;
}

.clear-date {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.datePicker-error {
  border-color: rgba(240, 74, 67, var(--border-opacity));
}

.title-window {
  margin-left: 5em;

  @include for-phone {
    margin-left: 2em;
  }
}

.passwordInput {
  min-width: 220px;
  width: 100%;
}

.genderWrapper {
  @include for-phone {
    width: 100%;
    min-width: 240px;
  }
}
</style>

<style>
.vd-picker__input-icon {
  margin-top: 0 !important;
  height: 100%;
  align-self: center;
}
</style>

<template>
  <modal
    @before-open="getBookedSlot"
    name="apply-coupon-modal"
    width="432px"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div
      class="bg-white w-modal max-h-90 p-6 flex flex-col justify-center items-center rounded-lg overflow-y-auto"
      :dir="arabic ? 'rtl' : 'ltr'"
    >
      <div class="flex justify-between items-start w-full mb-4">
        <p class="text-18 font-semibold w-full">{{ $t("coupons.apply_coupon") }}</p>
        <div class="cursor-pointer px-1 py-1" @click="closeCouponModal">
          <IconCloseGray />
        </div>
      </div>

      <img src="../../../assets/images/illustrations/apply-coupon.svg" alt="Apply coupon" />
      <InputWrapper
        :error-message="couponErrors[0]"
        class="mb-6"
        :class="{ 'items-start': arabic }"
        :label="$t('coupons.coupon')"
      >
        <input
          @focus="couponErrors = []"
          :placeholder="$t('coupons.enter_coupon')"
          :dir="arabic ? 'rtl' : 'ltr'"
          slot="input"
          v-model="couponCode"
          autofocus
        />
      </InputWrapper>

      <div class="flex justify-end w-full">
        <MainButton @click="checkCoupon" type="large">{{ $t("coupons.redeem_coupon") }}</MainButton>
      </div>
    </div>
  </modal>
</template>

<script>
import storageClient from "@/services/storageClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import MainButton from "@/components/ui-kit/MainButton";
import sessionsClient from "@/services/sessionsClient";
import { AnalyticsTrackData } from "@/services/analytics";
import { mapState } from "vuex";

export default {
  name: "ApplyCouponModal",
  components: {
    MainButton,
    InputWrapper,
    IconCloseGray
  },
  data() {
    return {
      slot: null,
      couponErrors: [],
      couponCode: ""
    };
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("sessions", ["coupon"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    price() {
      let price;
      if (this.slot.type === "video") {
        price =
          this.therapistProfile.video_price_in_kwd_object.amount /
          this.therapistProfile.video_price_in_kwd_object.divider;
      } else {
        price =
          this.therapistProfile.video_small_price_in_kwd_price_object.amount /
          this.therapistProfile.video_small_price_in_kwd_price_object.divider;
      }

      return price;
    }
  },
  methods: {
    getBookedSlot() {
      this.slot = storageClient.getBookingSlot();
    },
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      const trackData = Object.assign(
        AnalyticsTrackData.getStandardTherapistProperties(this.therapistProfile),
        AnalyticsTrackData.getStandardSlotProperties(this.slot, this.price)
      );

      this.$mixpanel.track("booking_haveCoupon_click", trackData);
      this.$intercom.trackEvent("booking_haveCoupon_click", trackData);
      window.webengage.track("booking_haveCoupon_click", trackData);
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    closeCouponModal() {
      this.$modal.hide("apply-coupon-modal");
      this.$modal.show("proceed-to-payment-modal");
    },

    async checkCoupon() {
      if (this.couponErrors.length > 0) {
        return;
      }
      this.$mixpanel.track("booking_redeemCoupon_click");
      this.$intercom.trackEvent("booking_redeemCoupon_click");
      window.webengage.track("booking_redeemCoupon_click");
      try {
        const { data } = await sessionsClient.checkCoupon(this.slot.session_id, this.couponCode);
        await this.$store.dispatch("sessions/setCoupon", data.data);

        const trackData = {
          Coupon: this.coupon.code,
          "Coupon remaining uses for client": this.coupon.remaining_uses - 1,
          "Coupon total uses for client": this.coupon.max_uses,
          "Coupon promo group ID": this.coupon.promo_group_id,
          "Coupon promo scenario": this.coupon.promo_group_scenario
        };

        this.$mixpanel.track("booking_coupon_applied", trackData);
        this.$intercom.trackEvent("booking_coupon_applied", trackData);
        window.webengage.track("booking_coupon_applied", trackData);

        this.couponCode = "";

        this.closeCouponModal();
      } catch (e) {
        const error = e.response.data.message;
        if (error) {
          const trackData = {
            Coupon: this.couponCode,
            "Error text": error
          };

          this.$mixpanel.track("booking_coupon_failed", trackData);
          this.$intercom.trackEvent("booking_coupon_failed", trackData);
          window.webengage.track("booking_coupon_failed", trackData);

          switch (error) {
            case "invalid_coupon":
              this.couponErrors.push(this.$t("coupons.coupon_invalid"));
              break;
            case "already_redeemed":
              this.couponErrors.push(this.$t("coupons.coupon_already_redeemed"));
              break;
            case "campaign_is_ended":
              this.couponErrors.push(this.$t("coupons.coupon_campaign_ended"));
              break;
            case "cant_be_applied_to_this_therapist":
              this.couponErrors.push(this.$t("coupons.coupon_cant_be_applied_to_therapist"));
              break;
            case "coupon_is_expired":
              this.couponErrors.push(this.$t("coupons.coupon_expired"));
              break;
          }
        }
      }
    }
  }
};
</script>

<style scoped></style>

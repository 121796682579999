<template>
  <label :class="['input-checkbox', stateClasses]" :dir="direction">
    <input
      type="checkbox"
      :value="value"
      :checked="isSelected"
      :disabled="disabled"
      @change="toggleCheck"
      @focus="focused = true"
      @blur="focused = false"
    />

    <span v-if="!description" :class="['input-label']" :dir="direction">
      {{ label }}
    </span>

    <div class="fullLabel flex flex-col" v-else>
      <span class="title" :class="['input-label']" :dir="direction">
        {{ label }}
      </span>
      <div class="description" :dir="direction">
        {{ description }}
      </div>
    </div>
  </label>
</template>
<script>
import storageClient from "@/services/storageClient";

export default {
  name: "input-checkbox",
  model: {
    prop: "model",
    event: "change"
  },

  props: {
    model: {
      type: [String, Boolean, Object, Number, Array],
      required: true
    },
    value: {
      type: [String, Boolean, Object, Number],
      required: true
    },
    falseValue: {
      default: false
    },
    label: String,
    description: String,
    disabled: Boolean,
    checked: Boolean
  },

  data: () => ({
    focused: false
  }),

  computed: {
    isModelArray() {
      return Array.isArray(this.model);
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    isValueObject() {
      return this.value !== null && typeof this.value === "object";
    },
    isSelected() {
      const { model, value, isEqual, isValueObject, isModelArray } = this;

      if (isModelArray) {
        if (isValueObject) {
          return model.some((obj) => isEqual(obj, value));
        } else {
          return model.includes(value);
        }
      }

      if (isValueObject) {
        return isEqual(model, value);
      }

      return model === value;
    },
    stateClasses() {
      return {
        checked: this.isSelected,
        focused: this.focused,
        disabled: this.disabled
      };
    }
  },

  methods: {
    toggleCheck() {
      if (!this.disabled) {
        if (this.isModelArray) {
          this.handleArrayCheckbox();
        } else {
          this.handleSingleSelectCheckbox();
        }
      }
    },
    handleArrayCheckbox() {
      const newModel = this.model;

      if (!this.isSelected) {
        this.addValueToModel(newModel);
      } else {
        this.removeValueFromModel(newModel);
      }
    },
    handleSingleSelectCheckbox() {
      this.$emit("change", this.isSelected ? this.falseValue : this.value);
    },
    removeValueFromModel(newModel) {
      if (this.isValueObject) {
        newModel = newModel.filter((obj) => !this.isEqual(obj, this.value));
      } else {
        const index = newModel.indexOf(this.value);

        if (index !== -1) {
          newModel.splice(index, 1);
        }
      }

      this.$emit("change", newModel);
    },
    addValueToModel(newModel) {
      newModel.push(this.value);

      this.$emit("change", newModel);
    },
    isEqual(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    }
  }
};
</script>

<style lang="scss" scoped>
.fullLabel {
  .title {
    color: #354052;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .description {
    vertical-align: top;
    padding-left: 1em;
    line-height: 24px;
    font-size: 16px;
    color: #354052;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
  }
}

$input-check: (
  "size": 1.25em,
  "font-size": 16px,
  "label-color": black,
  "border-color": #dadada,
  "theme-color": #44a4a5,
  "error-color": #f56565,
  "border-width": 3px,
  "border-radius": 2px,
  "disabled-foreground": #ccc,
  "disabled-background": #f0f0f0
);

@mixin line-clamp($max-lines: 2) {
  display: -webkit-inline-box;
  -webkit-line-clamp: $max-lines;
  line-clamp: $max-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    word-wrap: normal;
  }
}

.input-checkbox {
  position: relative;
  display: inline-flex;
  line-height: 1.4;
  font-size: map-get($input-check, "font-size");
  flex-shrink: 0;
  color: map-get($input-check, "label-color");
  cursor: pointer;

  .input-label {
    vertical-align: top;
    padding-left: 1em;
    line-height: 24px;
    font-size: 16px;
    color: #354052;

    @include line-clamp();

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }

    &[dir="rtl"] {
      margin-right: 12px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 8px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &::before {
      width: map-get($input-check, "size");
      height: map-get($input-check, "size");
      border: map-get($input-check, "border-width") solid;
      border-color: map-get($input-check, "border-color");
      border-radius: map-get($input-check, "border-radius");
      background: white;
      transition: all 200ms;
    }

    &::after {
      opacity: 0;
      $checkmark-size: map-get($input-check, "size") - 2 * 0.25em;
      $checkmark-translate-y: (map-get($input-check, "size") / 2) - ($checkmark-size / 2.6);

      transform: translate(0.25em, $checkmark-translate-y) rotate(-45deg);
      width: $checkmark-size;
      height: $checkmark-size / 2;
      border: 0.125em solid white;
      border-top-style: none;
      border-right-style: none;
    }
  }

  input {
    outline: 0;
    opacity: 0;
    width: map-get($input-check, "size");
    flex-shrink: 0;

    &:active:not(:disabled) + .input-label::before {
      border-color: lighten(map-get($input-check, "theme-color"), 20%);
      background-color: lighten(map-get($input-check, "theme-color"), 20%);
    }
  }

  &.checked {
    .input-label::before {
      background: map-get($input-check, "theme-color");
      border-color: map-get($input-check, "theme-color");
    }

    .input-label::after {
      opacity: 1;
    }
  }

  &.focused {
    .input-label::before {
      border-color: lighten(map-get($input-check, "theme-color"), 10%);
    }

    &.checked {
      .input-label::before {
        background-color: lighten(map-get($input-check, "theme-color"), 10%);
      }
    }
  }

  &.disabled {
    color: map-get($input-check, "disabled-foreground");
    cursor: default;

    .input-label::before {
      border-color: currentColor;
    }

    &.checked {
      .input-label::before {
        background-color: currentColor;
      }
    }
  }

  &.error {
    .input-label::before {
      color: map-get($input-check, "error-color");
      border-color: currentColor;
    }

    &.checked {
      .input-label::before {
        border-color: currentColor;
        background-color: currentColor;
      }
    }

    &.focused {
      .input-label::before {
        border-color: lighten(map-get($input-check, "error-color"), 10%);
      }

      &.checked {
        .input-label::before {
          background-color: lighten(map-get($input-check, "error-color"), 10%);
        }
      }
    }

    input:active {
      + .input-label::before {
        border-color: lighten(map-get($input-check, "error-color"), 15%);
        background-color: lighten(map-get($input-check, "error-color"), 15%);
      }
    }
  }
}

.container {
  max-width: 768px;
  margin: 50px auto 50px;
}

.row {
  margin: 0.5rem 0 3rem;

  > div:first-child {
    margin-bottom: 0.5rem;
    font-size: 18px;
  }
}

.input-checkbox {
  + #{&} {
    margin-top: 8px;
  }
}

.fullLabel {
  .description {
    color: #6f7786;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &[dir="rtl"] {
      margin-right: 12px;
    }
  }
  .input-label {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &::after {
      top: 30%;
    }

    &[dir="rtl"] {
      margin-right: 12px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      &::after {
        top: 15px;
        right: 7px;
      }
    }
  }
}
</style>

<template>
  <div class="bg-white w-full px-4 py-3 flex justify-start items-center border-b border-divider">
    <img
      class="w-8 h-8 rounded-6px"
      :src="therapistAvatar"
      alt="avatar"
      :style="{ 'background-color': avatar_bg_color }"
    />
    <p class="w-full text-textBlue text-base font-semibold" :class="!arabic ? 'ml-3' : 'mr-3'">{{ therapistName }}</p>
    <span class="cursor-pointer" @click="$emit('close')">
      <IconCloseGray />
    </span>
  </div>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import storageClient from "@/services/storageClient";
export default {
  name: "ChatHeader",
  props: {
    therapistName: String,
    therapistAvatar: String,
    avatar_bg_color: String
  },
  components: {
    IconCloseGray
  },
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  }
};
</script>

<style scoped></style>

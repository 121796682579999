<template>
  <!--  :class="{ 'flex-row-reverse justify-end': arabic }"-->
  <div class="flex justify-center items-center h-16 w-full bg-white custom-shadow relative z-300" :dir="direction">
    <div class="fit-width-content grid grid-cols-3 sm:grid-cols-5">
      <div class="col-span-1 sm:col-span-3 flex justify-start items-center" :class="{ 'sm:hidden': !showTherapist }">
        <router-link v-if="showTherapist" :to="{ name: 'TherapistList' }" class="no-underline">
          <SecondaryButton medium :label="$t('navbar.back_to_counsellors')" />
        </router-link>

        <div v-if="showButtonBack" @click="backToWP" class="no-underline">
          <SecondaryButton medium :label="$t('navbar.back')" />
        </div>
        <span class="text-lg font-semibold" v-else-if="!showButtonBack && !showTherapist"
          >{{ $t("profiles.counsellors") }}
        </span>
      </div>

      <div class="col-span-1 flex justify-center items-center">
        <LogoDark />
      </div>

      <div class="flex justify-end items-center" :class="{ 'sm:col-span-4': !showTherapist }">
        <LanguageSwitcher />
      </div>
    </div>
  </div>
</template>

<script>
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import LogoDark from "@/components/ui-kit/LogoDark";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import storageClient from "@/services/storageClient";

export default {
  name: "NavBar",
  components: { LanguageSwitcher, SecondaryButton, LogoDark },
  props: {
    showTherapist: Boolean,
    showButtonBack: Boolean
  },
  computed: {
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },
  methods: {
    backToWP() {
      if (this.arabic) {
        window.location.href = "https://ayadihealth.co/ar/";
        return;
      }
      window.location.href = "https://ayadihealth.co/";
    }
  }
};
</script>

<style scoped>
.z-300 {
  z-index: 300;
}
</style>

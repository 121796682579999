<template>
  <div class="flex flex-wrap justify-center space-x-2">
    <span
      class="px-4 py-2 rounded-full ayadi-900 bg-gray-200 font-semibold text-sm flex align-center w-max transition duration-300 ease"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "Chip"
};
</script>

<style lang="scss" scoped></style>

import { render, staticRenderFns } from "./SingleCheckbox.vue?vue&type=template&id=045af238&"
import script from "./SingleCheckbox.vue?vue&type=script&lang=js&"
export * from "./SingleCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./SingleCheckbox.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
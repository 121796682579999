<template>
  <div class="checkbox">
    <input
      class="inp-cbx"
      :type="type"
      :name="name"
      :value="value"
      @input="handleCheckboxes"
      :id="id"
      v-model="model"
    />
    <label class="cbx flex flex-row items-center" :dir="direction" :for="id">
      <span class="icon" :class="{ rtl: arabic }">
        <svg width="12px" height="10px">
          <use xlink:href="#check"></use>
        </svg>
      </span>
      <span
        ><slot>{{ label }}</slot></span
      >
    </label>

    <svg class="inline-svg">
      <symbol id="check" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </symbol>
    </svg>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";

export default {
  name: "SingleCheckbox",
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    id: String,
    name: String,
    value: [String, Number],
    checked: null,
    label: String,
    type: {
      default: "checkbox",
      type: String
    }
  },

  methods: {
    handleCheckboxes(e) {
      if (this.checked?.length > 0 && this.checked[0] === e.target.value) {
        this.$emit("change", []);
      } else {
        this.$emit("change", [e.target.value]);
      }

      this.$emit("toggle");
    }
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    model: {
      get() {
        return this.checked;
      },
      set(val) {
        if (!val) {
          this.$emit("change", "");
        }

        this.$emit("toggle");
      }
    }
  }
};
</script>

<style>
.checkbox {
  display: flex;
}
.cbx {
  @apply select-none bg-textGray-100 text-checkboxText cursor-pointer p-2 m-1 rounded-full overflow-hidden border border-textGray-100 text-sm;
  transition: all 0.2s ease;
}

.cbx:hover {
  @apply border-ayadi-800;
}
.cbx .icon.rtl {
  margin-left: 6px;
}
.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  @apply relative rounded-full bg-textGray-200;
  width: 20px;
  height: 20px;
  transform: scale(1);
  transition: all 0.2s ease;
}
.cbx span:first-child svg {
  position: absolute;
  top: 5px;
  left: 4px;
  fill: none;
  stroke: #44a4a5;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:last-child {
  padding-left: 4px;
  line-height: 18px;
}
.cbx:hover span:first-child {
  border-color: #07f;
}
.inp-cbx {
  position: absolute;
  visibility: hidden;
}
.inp-cbx:checked + .cbx {
  @apply bg-ayadi-900 border-ayadi-900 text-white;
}

.inp-cbx:checked + .cbx:hover {
  @apply bg-ayadi-800 border-ayadi-800;
}

.inp-cbx:checked + .cbx span:first-child {
  @apply bg-white;
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
</style>

import dayjs from "dayjs";

import therapistClient from "@/services/therapistClient";
import * as utcToLocal from "@/utils/utcDateToLocalDate";

const DEFAULT_START_DATE = dayjs().add(1, "day").format("YYYY-MM-DD 00:00:00");

export const namespaced = true;

export const state = {
  videoSlots: [],
  videoSmallSlots: [],
  startVideo: DEFAULT_START_DATE,
  startVideoSmall: DEFAULT_START_DATE,
  order: {},
  createdOrder: null,
  sessionsIsLoading: false,
  showNextModal: null
};

export const mutations = {
  SET_LOADING(state, value) {
    state.sessionsIsLoading = value;
  },

  SET_VIDEO_SLOTS(state, payload) {
    if (payload.more) {
      state.videoSlots.push(...payload.slots);
      state.sessionsIsLoading = false;
      return;
    }
    state.videoSlots = payload.slots;
    state.sessionsIsLoading = false;
  },

  SET_VIDEO_SMALL_SLOTS(state, payload) {
    if (payload.more) {
      state.videoSmallSlots.push(...payload.slots);
      state.sessionsIsLoading = false;
      return;
    }
    state.videoSmallSlots = payload.slots;
    state.sessionsIsLoading = false;
  },

  SET_NEW_START_VIDEO(state, day) {
    state.startVideo = day;
  },

  SET_NEW_START_VIDEO_SMALL(state, day) {
    state.startVideoSmall = day;
  },

  SET_ORDER(state, order) {
    state.order = order;
  },

  SET_CREATE_ORDER(state, order) {
    state.createdOrder = order;
  },

  SET_SHOW_NEXT_MODAL(state, value) {
    state.showNextModal = value;
  }
};

export const actions = {
  async getAvailableSlots({ commit, state }, { id, type, more }) {
    commit("SET_LOADING", true);
    let showingMore = more || false;
    let startDate = type === "video" ? state.startVideo : state.startVideoSmall;
    if (!startDate) {
      startDate = DEFAULT_START_DATE;
    }

    const { data } = await therapistClient.getAvailableSlots(id, type, startDate);

    if (data.length === 0) {
      if (type === "video") {
        commit("SET_VIDEO_SLOTS", { slots: [] });
      } else {
        commit("SET_VIDEO_SMALL_SLOTS", { slots: [] });
      }
      commit("SET_LOADING", false);
      return;
    }

    // get next start date for pagination
    const days = Object.keys(data);
    const lastDate = days[days.length - 1];

    if (type === "video") {
      if (data[lastDate].next) {
        commit("SET_NEW_START_VIDEO", data[lastDate].next + " 00:00:00");
      } else {
        commit("SET_NEW_START_VIDEO", null);
      }
    } else {
      if (data[lastDate].next) {
        commit("SET_NEW_START_VIDEO_SMALL", data[lastDate].next + " 00:00:00");
      } else {
        commit("SET_NEW_START_VIDEO_SMALL", null);
      }
    }

    let content = [];

    const firstDay = Object.keys(data)[0];

    let filteredFirstDay = data[firstDay].sessions.filter(
      (i) => dayjs(utcToLocal.convertUTCDateToLocalDate(i.session_start)) > dayjs().add(1, "day")
    );
    if (filteredFirstDay.length < 1) {
      delete data[firstDay];
    } else {
      data[firstDay].sessions = filteredFirstDay;
    }

    for (const [key, value] of Object.entries(data)) {
      content.push({ date: key, sessions: value.sessions });
    }

    content.forEach((slot) => {
      slot.sessions.forEach((s) => {
        s.session_start = utcToLocal.convertUTCDateToLocalDate(s.session_start);
      });
    });

    if (type === "video") {
      commit("SET_VIDEO_SLOTS", { slots: content, more: showingMore });
    } else {
      commit("SET_VIDEO_SMALL_SLOTS", { slots: content, more: showingMore });
    }
  },

  async createOrder({ commit }, params) {
    const { data } = await therapistClient.createOrder(params);
    commit("SET_CREATE_ORDER", data);
  },

  resetStartDateToDefault({ commit }) {
    const default_date = dayjs().add(1, "day").format("YYYY-MM-DD 00:00:00");

    commit("SET_NEW_START_VIDEO", default_date);
    commit("SET_NEW_START_VIDEO_SMALL", default_date);
  },

  async checkOrder({ commit }, orderId) {
    try {
      const { data } = await therapistClient.checkOrder(orderId);

      if (data) {
        data.session.session_start = utcToLocal.convertUTCDateToLocalDate(data.session.session_start);
      }

      commit("SET_ORDER", data);
    } catch (e) {
      commit("SET_ORDER", {});
    }
  },

  setShowNextModal({ commit }, data) {
    commit("SET_SHOW_NEXT_MODAL", data);
  }
};

<template>
  <div class="w-6 h-6 flex justify-center items-center rounded-full cursor-pointer" @click="$emit('click')">
    <svg v-if="closed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 7H15C16.1046 7 17 7.89543 17 9V10.223V13.777V15C17 16.1046 16.1046 17 15 17H5C3.89543 17 3 16.1046 3 15V9C3 7.89543 3.89543 7 5 7ZM18.9959 15.1818C18.9009 17.3066 17.1482 19 15 19H5C2.79086 19 1 17.2091 1 15V9C1 6.79086 2.79086 5 5 5H15C17.1482 5 18.9009 6.69343 18.9959 8.81819L23 6V18L18.9959 15.1818ZM19 12.739L21 14.147V9.854L19 11.261V12.739Z"
        fill="#44A4A5"
      />
    </svg>

    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.71164 18.7877L1 21.4993L2.3288 22.8281L6.15722 18.9997L8.15722 16.9997L16.8695 8.28746L18.3475 6.80936L23 2.15692L21.6712 0.828125L16.9774 5.52188L15.449 7.0503L5.49963 16.9997L3.71164 18.7877ZM5 4.99969H14.6712L12.6712 6.99969H5C3.89543 6.99969 3 7.89513 3 8.99969V14.9997C3 15.4682 3.1611 15.8991 3.43092 16.24L2.01193 17.659C1.38246 16.9522 1 16.0206 1 14.9997V8.99969C1 6.79056 2.79086 4.99969 5 4.99969ZM15 16.9997H10.9856L8.98565 18.9997H15C17.1482 18.9997 18.9009 17.3063 18.9959 15.1815L23 17.9997V5.99969L19.5751 8.41027L17 10.9853V13.7767V14.9997C17 16.1043 16.1046 16.9997 15 16.9997ZM19 12.7387L21 14.1467V9.8537L19 11.2607V12.7387Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconCameraToggle",
  props: {
    closed: Boolean
  }
};
</script>

<style scoped></style>

<template>
  <div class="w-6 h-6 flex justify-center items-center rounded-full cursor-pointer" @click="$emit('click')">
    <svg v-if="closed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 5C14 3.897 13.103 3 12 3C10.897 3 10 3.897 10 5V11C10 12.103 10.897 13 12 13C13.103 13 14 12.103 14 11V5ZM8 5C8 2.791 9.791 1 12 1C14.209 1 16 2.791 16 5V11C16 13.209 14.209 15 12 15C9.791 15 8 13.209 8 11V5ZM20 11V9H18V11C18 14.309 15.309 17 12 17C8.691 17 6 14.309 6 11V9H4V11C4 15.0793 7.0539 18.4459 11 18.9381V22H13V18.9381C16.9461 18.4459 20 15.0793 20 11Z"
        fill="#44A4A5"
      />
    </svg>
    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1C14.1025 1 15.8263 2.62245 15.9877 4.68354L14 6.6712V5C14 3.897 13.103 3 12 3C10.897 3 10 3.897 10 5V10.6712L8.25716 12.414C8.09096 11.9744 8 11.4978 8 11V5C8 2.791 9.791 1 12 1ZM10.8582 12.6414L14 9.49963L16 7.49963L21.6712 1.82843L23 3.15722L16 10.1572L11.2311 14.9262L9.64049 16.5167L8.14547 18.0117L2.3288 23.8284L1 22.4996L6.59855 16.9011L8.01624 15.4834L9.43243 14.0672L10.8582 12.6414ZM6 11C6 12.0572 6.27469 13.0513 6.75645 13.9148L5.29931 15.3719C4.47768 14.1152 4 12.6134 4 11V9H6V11ZM11 18.9381C10.7258 18.9039 10.4559 18.8558 10.1911 18.7946L11.9857 17L12 17C15.309 17 18 14.309 18 11V10.9856L19.9856 9H20V11C20 15.0793 16.9461 18.4459 13 18.9381V22H11V18.9381Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconAudioToggle",
  props: {
    closed: Boolean
  }
};
</script>

<style scoped></style>

<template>
  <div
    class="sticky bottom-0 p-3 rounded-b-lg bg-white w-full input-shadow border-t border-divider"
    @keydown.enter.exact.prevent="sendMessage"
  >
    <div class="w-full relative flex justify-center items-center">
      <textarea-autosize
        :dir="direction"
        :placeholder="$t('sessions.type_here')"
        id="input"
        class="w-full py-2 bg-tileOutline rounded-12px text-sm border border-tileOutline resize-none focus:bg-tileOutline"
        :class="{ 'pl-20 pr-3': arabic, 'pl-3 pr-20': !arabic }"
        autocomplete="off"
        v-model="text"
        :min-height="5"
        rows="1"
        :max-height="142"
        @focus.native="$emit('track-click')"
      />
      <input class="hidden" ref="file" type="file" accept="image/*,video/*" @change="sendFile" />
      <IconSend
        @send-message="sendMessage"
        @send-file="selectFile"
        class="absolute right-0 top-50%"
        :class="{ 'left-0 right-auto translate-arabic top-50%': arabic, 'translate-center': !arabic }"
      />
    </div>
  </div>
</template>

<script>
import IconSend from "../icons/IconSend";
import storageClient from "@/services/storageClient";
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  name: "ChatMessageInput",
  components: { IconSend },
  data() {
    return {
      text: "",
      media: null
    };
  },

  computed: {
    ...mapState("chat", ["therapistProfile"]),
    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  },
  methods: {
    sendMessage() {
      if (!this.text) {
        return;
      }

      let trimmed = this.text.replace(/^\s+/, "").replace(/\s+$/, "");

      if (!trimmed) {
        this.text = "";
        return;
      }
      this.$emit("send-message", this.text);
      this.text = "";
    },

    selectFile() {
      const trackData = {
        "Device type": "Web",
        "Therapist Name": this.therapistProfile?.full_name,
        Sender: "client",
        Recipient: "therapist",
        time: dayjs().unix()
      };

      window.webengage.track("dialogue_attachment_click", trackData);
      this.$mixpanel.track("dialogue_attachment_click", trackData);
      this.$intercom.trackEvent("dialogue_attachment_click", trackData);
      this.$refs.file.click();
    },

    sendFile(e) {
      if (e.target.files[0]) {
        this.$emit("send-file", e.target.files[0]);
        this.$refs.file.value = null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.top-50\% {
  top: 50%;
}

.translate-center {
  transform: translate(-50%, -50%);
}

.translate-arabic {
  @apply pl-4 pr-0;
  transform: translate(0, -50%);
}

#input {
  @apply transition-all duration-200;
  border-radius: 12px;
  outline: none;
}

#input:focus {
  @apply border-ayadi-900;
}

.top-25\% {
  top: 25%;
}

.input-shadow {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.0669441);
}
</style>

<template>
  <div class="flex items-center justify-center">
    <svg
      @click="$emit('send-file')"
      class="mr-2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.72923 8.20328V16.8596C8.72923 18.5696 9.98104 20.0915 11.6829 20.2551C13.6301 20.4433 15.2747 18.9133 15.2747 16.9987V6.88601C15.2747 5.81419 14.5056 4.84056 13.442 4.73419C12.2147 4.61147 11.1838 5.56874 11.1838 6.77147V15.3624C11.1838 15.8124 11.552 16.1806 12.002 16.1806C12.452 16.1806 12.8201 15.8124 12.8201 15.3624V8.20328C12.8201 7.86783 13.0983 7.58965 13.4338 7.58965C13.7692 7.58965 14.0474 7.86783 14.0474 8.20328V15.2478C14.0474 16.3196 13.2783 17.2933 12.2147 17.3996C10.9874 17.5224 9.9565 16.5651 9.9565 15.3624V6.91056C9.9565 5.20056 11.2083 3.67874 12.9101 3.5151C14.8656 3.32692 16.502 4.85692 16.502 6.77147V16.8106C16.502 19.1587 14.7838 21.2615 12.4438 21.4824C9.75195 21.7278 7.50195 19.6333 7.50195 16.9987V8.20328C7.50195 7.86783 7.78013 7.58965 8.11559 7.58965C8.45104 7.58965 8.72923 7.86783 8.72923 8.20328Z"
        fill="#939AA8"
      />
    </svg>

    <svg
      @click="$emit('send-message')"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.4 17.4L18.85 9.92002C19.66 9.57002 19.66 8.43002 18.85 8.08002L1.4 0.600017C0.74 0.310017 0.00999999 0.800017 0.00999999 1.51002L0 6.12002C0 6.62002 0.37 7.05002 0.87 7.11002L15 9.00002L0.87 10.88C0.37 10.95 0 11.38 0 11.88L0.00999999 16.49C0.00999999 17.2 0.74 17.69 1.4 17.4Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconSend"
};
</script>

<style lang="scss" scoped>
svg {
  path {
    @apply transition-all duration-200;
    fill: #939aa8;
  }
}

div {
  cursor: pointer;
}

svg:hover {
  path {
    fill: #44a4a5;
  }
}

//div:hover {
//  svg {
//    path {
//      fill: red;
//    }
//  }
//}
</style>

<template>
  <div class="bg-gradient h-screen overflow-hidden">
    <EnableMicAndVideoModal></EnableMicAndVideoModal>
    <transition name="fade">
      <Messenger
        :active-chat="activeChat"
        :therapist-name="getTherapistName"
        :therapist-avatar="getTherapistAvatar"
        class="z-50"
        v-if="isChatOpen"
        @close="isChatOpen = false"
        :session-start="getSessionStart"
      />
    </transition>
    <WelcomeRoom
      @join-session="joinVideoSession"
      v-if="showPreview"
      :therapist-name="getTherapistName"
      :session-start-time="getSessionStart"
      :session-end-time="getSessionEnd"
      :session-type="getType"
      @logout="logout"
      :active-chat="activeChat"
      :is-active-chat="activeChat.length > 0"
      @open-chat="isChatOpen = true"
    >
      <div id="testVideo" class="relative z-30">
        <video class="object-cover" ref="testVideo" autoplay muted></video>
        <div v-if="!isVideoOn" class="bg-lightGray w-full h-full absolute inset-0 border-r-12 z-50"></div>
        <div class="flex flex-row justify-center items-center w-full absolute test-video-buttons">
          <div class="audio-note" v-if="!isAudioOn">{{ $t("sessions.muted") }}</div>

          <div
            @click="toggleTestAudio"
            class="video-button cursor-pointer p-2 mx-3"
            :class="isAudioOn ? 'video-button-on ' : 'video-button-off'"
          >
            <IconAudioToggle :closed="isAudioOn" />
          </div>

          <div
            @click="toggleTestVideo"
            class="video-button cursor-pointer p-2 mx-3"
            :class="isVideoOn ? 'video-button-on ' : 'video-button-off'"
          >
            <IconCameraToggle :closed="isVideoOn" />
          </div>
          <div class="video-note" v-if="!isVideoOn">{{ $t("sessions.camera_off") }}</div>
        </div>
      </div>
    </WelcomeRoom>

    <modal name="exit-alert" :width="400" height="auto" :adaptive="true" :minWidth="300">
      <div class="bg-white px-8 py-6 rounded-md">
        <h5 class="text-18" :class="{ 'w-full text-right': arabic }">{{ $t("sessions.leave_session") }}</h5>
        <p class="mt-4 mb-8" :class="{ 'w-full text-right': arabic }">{{ $t("sessions.are_u_sure") }}</p>
        <div class="flex flex-row items-center justify-between" :class="{ 'flex-row-reverse': arabic }">
          <SecondaryButton @click="$modal.hide('exit-alert')" medium :label="$t('sessions.no_stay')" />
          <button @click="$router.push({ name: 'NoUpcomingSessions' }).catch(() => {})" class="mx-2 custom-button">
            {{ $t("sessions.yes_leave") }}
          </button>
        </div>
      </div>
    </modal>

    <div v-if="!showPreview">
      <div class="w-full bg-gradient h-screen flex flex-row justify-center items-center">
        <div class="flex-1" id="remoteTrack" ref="remoteTrack">
          <div
            v-if="!remoteVideoEnabled && isParticipantConnected"
            class="w-full bg-gradient h-screen absolute z-30 flex justify-center items-center"
          >
            <div class="flex flex-col justify-center items-center">
              <div
                class="w-40 h-40 bg-lightGray uppercase flex justify-center items-center rounded-full text-60px border-4 border-ayadi-900 mb-6"
              >
                <img
                  v-if="this.activeSession.therapist.avatar"
                  :src="this.activeSession.therapist.avatar"
                  :style="{ 'background-color': this.activeSession.therapist.avatar_bg_color }"
                  class="w-full h-full object-cover rounded-full"
                  alt="avatar"
                />
                <span v-else>{{ this.activeSession.therapist.full_name.charAt(0) }}</span>
              </div>

              <div class="px-4 py-1 bg-black bg-opacity-50 rounded-lg text-white">
                {{ this.activeSession.therapist.full_name }}
              </div>
            </div>
          </div>

          <div
            class="text-center text-white flex flex-col justify-center items-center absolute-center z-20 w-full bg-gradient h-screen"
            v-if="!isParticipantConnected"
          >
            <img src="../assets/images/AyadiLogoOnlyIcon.svg" alt="Ayadi" />
            <p class="text-2xl mt-6 mb-20">
              {{ $t("sessions.waiting_for", { name: this.activeSession.therapist.full_name }) }}
            </p>
          </div>
        </div>
      </div>
      <div
        id="localTrack"
        ref="localTrack"
        class="relative z-30"
        :class="{ 'left-8': arabic, 'hidden-video': !isLocalVideoOpen }"
      >
        <div
          v-if="!isVideoOn"
          class="w-full h-full bg-black absolute z-20 border-r-6 flex justify-center items-center"
        ></div>
        <IconVideoToggle class="absolute top-right-8px z-50" :closed="isLocalVideoOpen" @click="toggleLocalVideoView" />

        <div class="video-caption text-ayadi-900 z-50">{{ $t("sessions.you") }}</div>
      </div>
      <div
        v-if="!showPreview"
        class="bg-white absolute w-full flex justify-between items-center h-20 px-16 video-nav z-50 sm:px-4"
        :class="{ 'flex-row-reverse w-full': arabic }"
      >
        <div class="flex-1 flex flex-row items-center justify-start" :class="{ 'flex-row-reverse w-full': arabic }">
          <SecondaryButton
            @click="leaveVideoSession"
            class="no-underline"
            medium
            :label="$t('sessions.leave_session')"
          />

          <countdown
            class="ml-6 flex flex-col items-start justify-center"
            :class="{ 'items-end ml-0 mr-6': arabic }"
            v-if="!sessionGoing"
            @end="endSessionNotStarted"
            :time="timeRemaining"
            :transform="transform"
          >
            <template slot-scope="props">
              <p class="font-semibold text-ayadiRed" :dir="direction">
                {{ $t("sessions.session_starts_in") }}
              </p>
              <p class="font-semibold">{{ props.minutes }} : {{ props.seconds }}</p>
            </template>
          </countdown>

          <countdown
            class="ml-6 flex flex-col items-start justify-center"
            :class="{ 'items-end ml-0 mr-6': arabic }"
            v-else
            ref="lastCountdown"
            @progress="handleCountdownProgress"
            @end="endSessionGoing"
            :time="sessionTimeRemaining"
            :transform="transform"
          >
            <template slot-scope="props">
              <p class="font-semibold" :dir="direction">
                {{ sessionEnded ? $t("sessions.session_ended") : $t("sessions.session_ends_in") }}
              </p>
              <p class="font-semibold" :class="{ 'text-warning': yellowCountdown, 'text-error': sessionEnded }">
                {{ props.minutes }} : {{ props.seconds }}
              </p>
            </template>
          </countdown>
        </div>
        <div class="flex flex-row justify-center items-center flex-1">
          <div class="audio-note left-center" v-if="!isAudioOn">{{ $t("sessions.muted") }}</div>

          <div
            @click="toggleAudio"
            class="video-button cursor-pointer p-2 mx-3"
            :class="isAudioOn ? 'video-button-on ' : 'video-button-off'"
          >
            <IconAudioToggle :closed="isAudioOn" />
          </div>
          <div
            @click="toggleVideo"
            class="video-button cursor-pointer p-2 mx-3"
            :class="isVideoOn ? 'video-button-on ' : 'video-button-off'"
          >
            <IconCameraToggle :closed="isVideoOn" />
          </div>
          <div class="video-note right-center" v-if="!isVideoOn">{{ $t("sessions.camera_off") }}</div>
        </div>
        <div class="flex-1 additionalSpace">
          <!--chat button-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import requiresAuth from "@/mixins/requiresAuth";
import WelcomeRoom from "@/components/video/WelcomeRoom";
import store from "@/store";
import countdown from "@chenfengyuan/vue-countdown";
import { mapState, mapGetters } from "vuex";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import storageClient from "@/services/storageClient";

import Twilio, { createLocalVideoTrack } from "twilio-video";
import Messenger from "@/components/chat/Messenger";
import IconVideoToggle from "@/components/icons/IconVideoToggle";
import IconAudioToggle from "../components/icons/IconAudioToggle";
import IconCameraToggle from "../components/icons/IconCameraToggle";
import EnableMicAndVideoModal from "@/components/modals/EnableMicAndVideoModal";

export default {
  name: "Main",
  components: {
    IconVideoToggle,
    Messenger,
    SecondaryButton,
    WelcomeRoom,
    countdown,
    IconCameraToggle,
    IconAudioToggle,
    EnableMicAndVideoModal
  },
  mixins: [requiresAuth],

  data() {
    return {
      isVideoOn: true,
      isAudioOn: true,
      showPreview: true,
      activeRoom: null,
      roomName: null,
      leaveRoute: null,
      wantToLeave: false,
      isParticipantConnected: false,
      localVideoTracks: null,
      sessionNotStarted: true,
      sessionGoing: false,
      yellowCountdown: false,
      roomID: "",
      permissionDenied: false,
      remoteVideoEnabled: true,
      isChatOpen: false,
      activeChat: "",
      isLocalVideoOpen: true
    };
  },

  computed: {
    ...mapState("sessions", ["sessions", "activeSession", "videoRoom"]),
    ...mapState("auth", ["user", "notifications"]),
    ...mapGetters("auth", ["getUser"]),
    ...mapState("chat", ["chatList", "chatToken"]),

    getTherapistName() {
      return this.activeSession?.therapist.full_name;
    },

    getTherapistAvatar() {
      return this.activeSession?.therapist.avatar;
    },

    getSessionStart() {
      return this.activeSession?.session_start;
    },

    getSessionEnd() {
      return this.activeSession?.session_end;
    },

    getType() {
      return this.activeSession?.type;
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    timeRemaining() {
      if (new Date().getTime() > new Date(this.activeSession.session_start).getTime()) {
        return 1;
      }
      return new Date(this.activeSession.session_start) - new Date();
    },

    sessionTimeRemaining() {
      if (new Date().getTime() > new Date(this.activeSession.session_end).getTime()) {
        return 1;
      }
      return new Date(this.activeSession.session_end) - new Date();
    },

    sessionEnded() {
      return new Date().getTime() > new Date(this.activeSession.session_end).getTime();
    }
  },

  methods: {
    async logout() {
      window.localStorage.setItem("isVideoOn", "1");

      await store.dispatch("auth/logout");
      //
      // setTimeout(async () => {
      //
      // }, 8000);
    },

    setMixpanel() {
      // this.$mixpanel.people.set({
      //   $name: this.user.name,
      //   $email: this.user.email,
      //   "user type": "patient",
      //   Gender: this.user.gender,
      //   Country: this.user.country,
      //   Language: storageClient.getLanguage(),
      //   Birthdate: this.user.date_of_birth,
      //   "Enabled Push Notification": this.notifications.is_push_notification_enabled,
      //   "Enabled eMail Notifications": this.notifications.is_email_notification_enabled
      // });
      //
      // this.$mixpanel.identify("patient_" + this.user.id);

      const trackData = {
        referrer: document.referrer,
        Language: storageClient.getLanguage(),
        "device type": "Web",
        email: this.user.email,
        "Therapist Name": this.activeSession.therapist.full_name,
        "Session Type": this.activeSession.type,
        "Session Price": this.activeSession.price
      };

      window.webengage.track("joinSession_click", trackData);
      this.$mixpanel.track("joinSession_click", trackData);
      this.$intercom.trackEvent("joinSession_click", trackData);
    },

    toggleLocalVideoView() {
      this.isLocalVideoOpen = !this.isLocalVideoOpen;

      this.$mixpanel.track("inSession_selfView_toggled", { "New value": this.isLocalVideoOpen ? "shown" : "hidden" });
      this.$intercom.trackEvent("inSession_selfView_toggled", {
        "New value": this.isLocalVideoOpen ? "shown" : "hidden"
      });
      window.webengage.track("inSession_selfView_toggled", { "New value": this.isLocalVideoOpen ? "shown" : "hidden" });
    },

    toggleTestVideo() {
      this.isVideoOn = !this.isVideoOn;
      if (!this.isVideoOn) {
        this.removeTestVideo();
      } else {
        this.createTestVideo();
      }
    },

    async createTestVideo() {
      const video = this.$refs.testVideo;
      if (navigator.mediaDevices.getUserMedia) {
        try {
          await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        } catch {
          this.permissionDenied = true;
        }

        try {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then((stream) => {
              if (stream && video) {
                video.srcObject = stream;
              }
            })
            .catch((e) => {
              if (e.message === "Permission denied") {
                this.permissionDenied = true;
              }
            });
        } catch (e) {
          console.error(e);
        }
      }
    },

    removeTestVideo() {
      if (!this.$refs.testVideo || !this.$refs.testVideo.srcObject) {
        return;
      }
      const stream = this.$refs.testVideo.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs.testVideo.srcObject = null;
    },

    toggleTestAudio() {
      this.isAudioOn = !this.isAudioOn;
    },

    async joinVideoSession() {
      await this.createTestVideo();

      if (this.permissionDenied) {
        this.$modal.show("enable-mic-video-modal");
        return;
      }

      this.removeTestVideo();

      this.setMixpanel();
      this.$intercom.shutdown();

      this.showPreview = false;

      const session = await this.$store.getters["sessions/getActiveSession"];
      await this.$store.dispatch("sessions/getVideoRoom", session.id);

      if (this.videoRoom.access_token && this.videoRoom.room_id) {
        await this.createChat();
      } else {
        this.$notify({
          group: "errors",
          text: "You can't connect to the room",
          classes: "toast-error"
        });
        this.wantToLeave = true;
        await this.$router.push({ name: "NoUpcomingSessions" }).catch(() => {});
      }
    },

    async createChat() {
      this.localVideoTracks = await createLocalVideoTrack();
      const localMediaContainer = this.$refs.localTrack;

      Twilio.connect(this.videoRoom.access_token, {
        name: this.videoRoom.sid
      })
        .then(
          (room) => {
            this.activeRoom = room;

            localMediaContainer.appendChild(this.localVideoTracks.attach());
            this.localTrack = true;

            this.activeRoom.participants.forEach((participant) => {
              this.participantConnected(participant);
              if (!participant.videoTracks.entries().next().value[1].isTrackEnabled) {
                this.remoteVideoEnabled = false;
              }
            });

            if (!this.isAudioOn) {
              if (this.activeRoom && this.activeRoom?.localParticipant) {
                this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
                  publication.track.disable();
                });
              }
            }

            if (!this.isVideoOn) {
              if (this.activeRoom && this.activeRoom?.localParticipant) {
                this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
                  publication.track.disable();
                });
              }
            }

            this.activeRoom.on("participantConnected", this.participantConnected);

            this.activeRoom.on("participantDisconnected", this.participantDisconnected);

            //remote mute events

            // room.participants.forEach((participant) => {
            //   participant.on("trackDisabled", this.handleTrackDisabled);
            //   participant.on("trackEnabled", this.handleTrackEnabled);
            //   // participant.tracks.forEach((publication) => {
            //   //   if (publication.isSubscribed) {
            //   //     this.handleTrackEnabled(publication.track);
            //   //   }
            //   //   publication.on("subscribed", this.handleTrackEnabled);
            //   // });
            // });

            // room.participants.forEach((participant) => {
            //   participant.tracks.forEach((publication) => {
            //     if (publication.isSubscribed) {
            //       this.handleTrackDisabled(publication.track);
            //     }
            //     publication.on("subscribed", this.handleTrackDisabled);
            //   });
            // });
            //
            // //remote unmute events
            //
            // room.participants.forEach((participant) => {
            //   participant.tracks.forEach((publication) => {
            //     if (publication.isSubscribed) {
            //       this.handleTrackEnabled(publication.track);
            //     }
            //     publication.on("subscribed", this.handleTrackEnabled);
            //   });
            // });

            room.on("disconnected", async (room, error) => {
              if (error) {
                console.log(`Unexpectedly disconnected: to Room: ${error}`);
                this.wantToLeave = true;
                window.location.href = "/no-upcoming-sessions";
              }
            });

            room.once("disconnected", async (room, error) => {
              if (error) {
                console.log(`Unexpectedly disconnected: to Room: ${error}`);
                this.wantToLeave = true;
                window.location.href = "/no-upcoming-sessions";
              }
            });
          },
          (error) => {
            console.log(error.message);
            this.wantToLeave = true;
          }
        )
        .catch((error) => {
          console.log(error.message);
          this.wantToLeave = true;
        });
    },
    handleTrackDisabled(track) {
      if (track.kind === "video") {
        this.remoteVideoEnabled = false;
      }
      // track.on("disabled", () => {
      //   if (track.kind === "video") {
      //     console.log("disabled");
      //     this.remoteVideoEnabled = false;
      //   }
      // });
    },

    handleTrackEnabled(track) {
      if (track.kind === "video") {
        this.remoteVideoEnabled = true;
      }
      // track.on("enabled", () => {
      //   if (track.kind === "video") {
      //     this.remoteVideoEnabled = true;
      //   }
      // });
    },

    participantConnected(participant) {
      const div = this.$refs.remoteTrack;

      participant.on("trackSubscribed", (track) => this.trackSubscribed(div, track));
      participant.on("trackUnsubscribed", this.trackUnsubscribed);
      participant.on("trackDisabled", this.handleTrackDisabled);
      participant.on("trackEnabled", this.handleTrackEnabled);

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          this.trackSubscribed(div, publication.track);
        }
      });

      this.isParticipantConnected = true;

      setTimeout(() => {
        if (!participant.videoTracks.entries().next().value[1].isTrackEnabled) {
          this.remoteVideoEnabled = false;
        } else {
          this.remoteVideoEnabled = true;
        }
      }, 2000);
    },

    participantDisconnected() {
      this.isParticipantConnected = false;
      this.remoteVideoEnabled = false;
    },

    trackSubscribed(div, track) {
      div.appendChild(track.attach());
    },

    trackUnsubscribed(track) {
      track.detach().forEach((element) => element.remove());
    },

    leaveVideoSession() {
      this.$modal.show("exit-alert");
      this.wantToLeave = true;
      // this.$router.push({ name: "Video" });
    },

    toggleAudio() {
      if (this.activeRoom && this.activeRoom?.localParticipant) {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          if (this.isAudioOn) {
            publication.track.disable();
          } else {
            publication.track.enable();
          }
        });
        this.isAudioOn = !this.isAudioOn;
      }
    },

    toggleVideo() {
      if (this.activeRoom && this.activeRoom?.localParticipant) {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          if (this.isVideoOn) {
            publication.track.disable();
          } else {
            publication.track.enable();
          }
        });
        this.isVideoOn = !this.isVideoOn;
      }
    },

    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      return props;
    },
    endSessionNotStarted() {
      this.sessionNotStarted = false;
      this.sessionGoing = true;
    },

    endSessionGoing() {
      this.$notify({ group: "end-session", classes: "toast-error" });
    },

    handleCountdownProgress(data) {
      if (data.minutes < 5) {
        this.yellowCountdown = true;
      }

      if (data.minutes === 5 && data.seconds === 0) {
        this.$notify({ group: "warning-session", classes: "toast-error" });
      }
      if (data.minutes === 0 && data.seconds === 0) {
        this.$notify({ group: "end-session", classes: "toast-error" });
        this.$refs.lastCountdown.abort();
      }
    }
  },

  async created() {
    await store.dispatch("sessions/fetchSessions");
    await store.dispatch("auth/getProfile");
    await store.dispatch("auth/getNotifications");
    let session = await store.getters["sessions/getActiveSession"];
    if (session) {
      if (session.type === "chat") {
        return this.$router.push({ name: "Chat" }).catch(() => {});
      } else {
        await this.createTestVideo();
        if (this.user) {
          this.$intercom.boot({
            user_id: this.user?.id || "",
            name: this.user.name,
            email: this.user.email,
            video_session_count: this.user.video_orders_count,
            chat_session_count: this.user.chat_orders_count,
            gender: this.user.gender,
            language: storageClient.getLanguage(),
            user: "patient"
          });
        }

        await this.$store.dispatch("chat/getChatList");
        await this.$store.dispatch("chat/getChatToken");

        this.chatList.forEach((chat) => {
          if (chat.therapist.email === this.activeSession?.therapist.email) {
            this.activeChat = chat.room_sid;
          }
        });

        return;
      }
    }

    return this.$router.push({ name: "NoUpcomingSessions" }).catch(() => {});
  },

  mounted() {
    this.createTestVideo();
    window.addEventListener("beforeunload", async () => {
      this.removeTestVideo();
      if (this.activeRoom) {
        if (this.localVideoTracks) {
          this.localVideoTracks.stop();
        }
        this.activeRoom.disconnect();
      }
    });
  },

  async beforeDestroy() {
    this.removeTestVideo();
    if (this.activeRoom) {
      if (this.localVideoTracks) {
        this.localVideoTracks.stop();
      }
      this.activeRoom.disconnect();
    }
  },

  beforeRouteLeave(to, from, next) {
    this.removeTestVideo();
    if (this.activeRoom) {
      if (this.localVideoTracks) {
        this.localVideoTracks.stop();
      }
      this.activeRoom.disconnect();
    }

    return next();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";
.additionalSpace {
  @include for-true-phone {
    display: none;
  }
}
</style>

<style lang="scss">
@import "@/styles/media.scss";

#localTrack {
  @apply absolute;
  min-width: 220px;
  max-width: 250px;
  min-height: 140px;
  right: 2rem;
  top: 2rem;
  border-radius: 6px;
  border: 2px solid #fff;
}

.left-8 {
  left: 2rem;
  right: auto;
}

#localTrack video {
  border-radius: 6px;
}

#remoteTrack {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.video-nav {
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
.video-avatar {
  @apply w-8 h-8 absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.video-caption {
  @apply absolute;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 3px;
  left: 0.5rem;
  bottom: 0.5rem;
  padding: 0.25rem;
}

.test-video-buttons {
  bottom: 1rem;
  left: 0;
  z-index: 99;
}

.audio-note {
  @apply absolute text-white px-3 py-2;
  background: rgba(0, 0, 0, 0.7);
  left: 5rem;
  border-radius: 8px;
}
.video-note {
  @apply absolute text-white px-3 py-2;
  background: rgba(0, 0, 0, 0.7);
  right: 4rem;
  border-radius: 8px;

  @include for-true-phone {
    display: none;
  }
}

.left-center {
  right: auto;
  left: calc(50% - 4rem);
  transform: translateX(-100%);
}

.right-center {
  left: auto;
  right: calc(50% - 4rem);
  transform: translateX(100%);
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#localTrack {
  @apply absolute;
  min-width: 220px;
  max-width: 250px;
  min-height: 140px;
  right: 2rem;
  top: 2rem;
  border-radius: 6px;
  border: 2px solid #fff;
}

#localTrack:hover {
  opacity: 1;
}

#remoteTrack video {
  height: 100%;
  object-fit: cover;
}

#testVideo,
#testVideo video {
  width: 600px;
  height: 350px;
}

#testVideo video {
  border-radius: 12px;
  filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.2));
}

.test-video-content {
  width: 380px;
}

.video-nav {
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
.video-avatar {
  @apply w-8 h-8 absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.video-caption {
  @apply absolute;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 3px;
  left: 0.5rem;
  bottom: 0.5rem;
  padding: 0.25rem;
}

.test-video-buttons {
  bottom: 1rem;
  left: 0;
  z-index: 99;
}

.audio-note {
  @apply absolute text-white px-3 py-2;
  background: rgba(0, 0, 0, 0.7);
  left: 5rem;
  border-radius: 8px;

  @include for-true-phone {
    display: none;
  }
}
.video-note {
  @apply absolute text-white px-3 py-2;
  background: rgba(0, 0, 0, 0.7);
  right: 4rem;
  border-radius: 8px;
}

.left-center {
  right: auto;
  left: calc(50% - 4rem);
  transform: translateX(-100%);
}

.right-center {
  left: auto;
  right: calc(50% - 4rem);
  transform: translateX(100%);
}

.left-8 {
  left: 2rem;
  right: auto;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-60px {
  font-size: 60px;
}

.top-right-8px {
  top: 0.75rem;
  right: 0.5rem;
}

.hidden-video {
  min-height: 54px !important;
  video {
    height: 0 !important;
  }
}
</style>

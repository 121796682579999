<template>
  <div class="min-h-screen bg-gradient flex flex-col justify-between items-center text-white">
    <img class="mt-16" src="../assets/images/LogoLight.svg" alt="Ayadi" />
    <div class="flex flex-col justify-center items-center mb-16">
      <p class="error-status font-bold">{{ errorStatus }}</p>
      <div class="text-center text-18 font-semibold" v-html="errorDescription"></div>
      <MainButton v-if="showButton" @click="redirect" class="mt-8">{{ buttonText }}</MainButton>
    </div>
    <FooterInfo no-image />
  </div>
</template>

<script>
import FooterInfo from "@/components/FooterInfo";
import MainButton from "@/components/ui-kit/MainButton";
export default {
  name: "HTTPError",
  components: { MainButton, FooterInfo },
  props: {
    errorStatus: {
      type: String,
      default: "404"
    }
  },

  computed: {
    errorDescription() {
      if (this.errorStatus === "404") {
        return "<p class='text-18'>" + this.$t("http_error.oops") + "</p>";
      }
      if (this.errorStatus === "403") {
        return "<p class='text-18'>You currently do not have permission to view this page<br /> due to your permission rights. </p>";
      }
      if (this.errorStatus === "503") {
        return "<p class='text-18'>Looks like we’re having some server issues.<br />Please wait a bit, and than try to reload the page.</p>";
      }

      return 0;
    },
    hasHistory() {
      return window.history.length > 2;
    },

    buttonText() {
      if (this.errorStatus === "404") {
        return this.$t("http_error.go_to_main");
      }
      return "Reload the Page";
    },

    showButton() {
      return this.errorStatus === "404" || this.errorStatus === "503";
    }
  },

  methods: {
    redirect() {
      if (this.errorStatus === "404") {
        return this.$router.push("/").catch(() => {});
      }
      if (this.hasHistory) {
        return this.$router.back();
      }
      this.$router.push("/").catch(() => {});
    }
  },

  created() {
    if (+this.errorStatus < 400) {
      this.router.push("/").catch(() => {});
    }
  }
};
</script>

<style scoped>
.error-status {
  font-size: 100px;
}
</style>

<template>
  <modal
    @before-open="getBookedSlot"
    name="book-session-by-packages-modal"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div
      class="bg-white w-modal max-h-90 p-6 flex flex-col justify-center items-center rounded-lg"
      :dir="arabic ? 'rtl' : 'ltr'"
    >
      <div v-if="isLoading" class="py-20 h-80 flex justify-center items-center w-full h-full bg-white">
        <img class="w-20 animate-bounce" src="../../../assets/images/ayadi-loader.svg" alt="Ayadi" />
      </div>
      <div v-else class="w-full overflow-y-auto">
        <div v-if="bookedPackagesForTherapist">
          <div class="flex justify-between items-start w-full mb-4">
            <p class="text-18 font-semibold w-full text-center">{{ $t("booking_session_by_packages.title") }}</p>
            <div class="cursor-pointer px-1 py-1" @click="$modal.hide('book-session-by-packages-modal')">
              <IconCloseGray />
            </div>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img
              class="img-size rounded-lg mt-6 mb-2"
              :src="bookedPackagesForTherapist.therapist.avatar"
              :style="{ 'background-color': therapistProfile.avatar_bg_color }"
              alt="avatar"
            />
            <p class="mb-6 font-semibold text-2xl">{{ bookedPackagesForTherapist.therapist_name }}</p>
          </div>
          <div class="grid grid-cols-2 w-full">
            <div class="col-span-1 flex flex-col justify-start" :class="arabic ? 'pr-16 sm:pr-4' : 'pl-16 sm:pl-4'">
              <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.date") }}</p>
              <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.time") }}</p>
              <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.duration") }}</p>
              <p class="text-lightGray text-base mb-3">{{ $t("booking_package_flow.sessions") }}</p>
            </div>
            <div class="col-span-1">
              <p class="font-semibold text-base mb-3">{{ getDate }}</p>
              <p class="font-semibold text-base mb-3">
                <span dir="ltr">{{ getTime }}</span>
              </p>
              <div class="font-semibold text-base mb-3 flex priceWrapper">
                {{ $t("booking_flow.50mins") }}
              </div>
              <p class="font-semibold text-base mb-3">
                {{
                  $t("booking_session_by_packages.total_packages", {
                    total: this.bookedPackagesForTherapist.total_sessions_count,
                    current:
                      this.bookedPackagesForTherapist.total_sessions_count -
                      this.bookedPackagesForTherapist.used_sessions_count
                  })
                }}
              </p>
            </div>
          </div>
          <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mb-4">
            <p class="text-center font-semibold">
              {{
                $t("booking_session_by_packages.you_will_have_packages", {
                  sessions: this.bookedPackagesForTherapist.available_sessions_count - 1
                })
              }}
            </p>
          </div>
          <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mb-4">
            <p class="text-center font-semibold">
              {{ $t("booking_session_by_packages.policy_tip") }}
              <a class="text-ayadi-900 no-underline" href="https://ayadihealth.co/privacy-policy/" target="_blank">
                {{ $t("booking_session_by_packages.cancellation_policy") }}
              </a>
            </p>
          </div>
          <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mb-4">
            <p class="text-center font-semibold">
              {{ $t("booking_session_by_packages.privacy_tip") }}
              <a class="text-ayadi-900 no-underline" href="https://ayadihealth.co/privacy-policy/" target="_blank">
                {{ $t("booking_package_flow.privacy_policy") }}
              </a>
            </p>
          </div>
          <div class="footerNavigation">
            <SecondaryButton @click="onBack()" medium :label="$t('main.close')"></SecondaryButton>
            <MainButton @click="createOrder" id="proceed_to_payment">
              {{ $t("booking_session_by_packages.confirm") }}
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from "vue";
import sessionsClient from "@/services/sessionsClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";
import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";

export default {
  name: "BookSessionByPackagesModal",
  components: { MainButton, SecondaryButton, IconCloseGray },

  data() {
    return {
      slot: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("common", ["currentIPData"]),
    ...mapState("therapistSlots", ["createdOrder"]),
    ...mapState("sessions", ["bookedPackagesForTherapist"]),
    ...mapState("auth", ["user"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    getDate() {
      if (!this.slot) {
        return "";
      }
      return dayjs(this.slot.start).format("dddd, MMM DD");
    },

    getTime() {
      if (!this.slot) {
        return "";
      }
      return dayjs(this.slot.start).format("h:mm A");
    }
  },

  methods: {
    displayPrice(value, currency) {
      return `${value} ${currency}`;
    },

    async getBookedSlot(value) {
      if (value.params) {
        this.slot = {
          type: value.params?.type,
          start: value.params?.session_start,
          session_id: value.params?.id
        };
        return;
      }
      this.slot = storageClient.getBookingSlot();
    },

    async createOrder() {
      const session = {
        session_id: this.slot.session_id,
        package_order_id: this.bookedPackagesForTherapist.id
      };

      try {
        await sessionsClient.bookSessionByPackage(session);

        const packageData = {
          current: this.bookedPackagesForTherapist.used_sessions_count + 1,
          total: this.bookedPackagesForTherapist.total_sessions_count
        };

        await this.$router
          .push({
            name: "PaymentByPackageSuccess",
            params: { order: this.slot, therapistProfile: this.therapistProfile, packages: packageData }
          })
          .catch(() => {});
      } catch (e) {
        Vue.notify({
          group: "errors",
          text: "Something went wrong",
          classes: "toast-error"
        });
      }
    },

    onBack() {
      this.$modal.hide("book-session-by-packages-modal");
      this.$modal.show("therapist-availability");
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style lang="scss" scoped>
.footerNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: white;
  width: 100%;
  padding-top: 1rem;
}
.priceWrapper {
  gap: 0.5rem;
}
.img-size {
  width: 7.5rem;
  height: 7.5rem;
}

.benefitsTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #434a57;
}

.benefitsItem {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #434a57;
  font-size: 14px;
  margin-top: 1rem;
  line-height: 20px;
}

.price {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #354052;

  &.discount {
    text-decoration-line: line-through;
    color: #939aa8;
    margin-right: 8px;
  }
}
</style>

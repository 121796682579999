<template>
  <div class="flex justify-center items-center">
    <div class="cursor-pointer flex justify-center items-center sm:hidden" :class="arabic ? 'ml-3' : 'mr-3'">
      <span @click="openSettings('language')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#354052" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"
          />
        </svg>
      </span>

      <p class="font-semibold mx-2" @click="openSettings('language')">{{ arabic ? "العربية" : "Eng(US)" }}</p>
      <div class="cursor-pointer flex justify-center items-center" @click="openSettings('currency')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 17C20 17.55 19.55 18 19 18H5C4.45 18 4 17.55 4 17V7C4 6.45 4.45 6 5 6H19C19.55 6 20 6.45 20 7V17ZM14 10C14.55 10 15 9.55 15 9C15 8.45 14.55 8 14 8H13V7.99C13 7.44 12.55 6.99 12 6.99C11.45 6.99 11 7.44 11 7.99V8H10C9.45 8 9 8.45 9 9V12C9 12.55 9.45 13 10 13H13V14H10C9.45 14 9 14.45 9 15C9 15.55 9.45 16 10 16H11C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16H14C14.55 16 15 15.55 15 15V12C15 11.45 14.55 11 14 11H11V10H14Z"
            fill="#354052"
          />
        </svg>
        <p class="font-semibold" :class="arabic ? 'mr-2' : 'ml-2'">{{ currency }}</p>
      </div>
    </div>
    <div class="hidden sm:block">
      <img @click="openSettings('language')" class="cursor-pointer" src="../assets/images/IconMenu.svg" alt="Menu" />
    </div>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
import { mapState } from "vuex";

export default {
  name: "IconLanguage",

  data() {
    return {
      currency: null,
      showSmallMenu: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("common", ["currencies"]),
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    openSettings(tab) {
      this.$store.dispatch("ui/setActiveTab", tab);
      this.$modal.show("language");
    }
  },
  created() {
    if (this.user) {
      if (this.user?.currency) {
        this.currency = this.user?.currency;
      }
    }
    const getCurrency = setInterval(() => {
      this.currency = storageClient.getCurrency();
      if (this.currency) {
        clearInterval(getCurrency);
        for (let i = 0; i < this.currencies.length; i++) {
          const currency = this.currencies[i];
          if (currency.to === this.currency) {
            this.currency = currency.code_localized;
            return;
          }
        }
      }
    }, 1000);
  }
};
</script>

<style scoped></style>

<template>
  <div class="bg-bgLight min-h-screen">
    <NavBar :showTherapist="true" />
    <div class="fit-width-content flex flex-col justify-start items-start mx-auto">
      <!--      <BreadCrumbles class="mt-6 mb-4" :therapist-name="therapistProfile.full_name" />-->
      <TherapistShortInfo class="my-6" />

      <div class="relative w-full video-h rounded-2xl overflow-hidden mt-2 mb-8" v-if="videoID">
        <iframe
          :src="`https://player.vimeo.com/video/${videoID}?h=1672af4895&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          class="test"
        ></iframe>
      </div>

      <TherapistInfo class="mb-6" />
      <TherapistCTA />
      <DarkFooter class="mx-auto my-6" no-image />
    </div>
    <TherapistProfileModals />
    <modal
      name="payment-failed"
      width="432"
      height="auto"
      :adaptive="true"
      :clickToClose="false"
      @opened="blockScroll"
      @closed="unblockScroll"
    >
      <div class="bg-white px-8 py-6 flex flex-col justify-center items-center rounded-lg" v-if="order">
        <div class="flex justify-between items-start w-full mb-4" :class="{ 'flex-row-reverse': arabic }">
          <p class="text-18 font-semibold">{{ $t("payment_error.failed") }}</p>
          <div class="cursor-pointer px-1 py-1" @click="closePaymentFailed">
            <IconCloseGray />
          </div>
        </div>

        <div class="w-full flex flex-col justify-center items-center">
          <img src="../assets/images/illustrations/looking-for-results.svg" alt="search" />
          <p class="mt-4 text-base">{{ $t("payment_error.bank") }}</p>
          <p class="text-base my-2 font-semibold">“{{ order.failed_reason }}”</p>
          <p class="mb-6 text-base">{{ $t("payment_error.try_or_know") }}</p>
          <MainButton @click="tryAgain" class="mb-2 w-full" type="medium">{{
            $t("payment_error.try_again")
          }}</MainButton>
          <SecondaryButton @click="talkToCare" class="w-full" :label="$t('payment_error.talk_to_customer')" medium />
        </div>
      </div>
    </modal>
    <AdditionalUserInfoModal />
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import NavBar from "@/components/NavBar";
import TherapistShortInfo from "@/components/therapist-profile/TherapistShortInfo";
import TherapistInfo from "@/components/therapist-profile/TherapistInfo";
import TherapistCTA from "@/components/therapist-profile/TherapistCTA";
import TherapistProfileModals from "@/components/therapist-profile/TherapistProfileModals";
import AdditionalUserInfoModal from "@/components/therapist-profile/modals/AdditionalUserInfoModal";
import DarkFooter from "@/components/DarkFooter";
import storageClient from "@/services/storageClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import TherapistClient from "@/services/therapistClient";
import { checkAvailabilityPackage } from "@/utils/packages";
import dayjs from "dayjs";
import sessionsClient from "@/services/sessionsClient";
import {
  checkPreviousSessions,
  mapPackageOptions,
  mapSingleSessionsOptions,
  getPreSelectPackage,
  getPreSelectSession
} from "@/utils/packages";
import { getTherapistProperties } from "../utils/events";

export default {
  name: "TherapistProfile",
  head: {
    script: [{ type: "text/javascript", src: "https://player.vimeo.com/api/player.js", async: true, body: true }],
    title() {
      return {
        inner: `Book your therapy session with ${this.therapistProfile.name_en} on Ayadi today`
      };
    },
    meta() {
      return [{ name: "description", content: this.therapistProfile.bio, id: "desc" }];
    }
  },
  components: {
    SecondaryButton,
    MainButton,
    DarkFooter,
    TherapistProfileModals,
    TherapistCTA,
    TherapistInfo,
    TherapistShortInfo,
    NavBar,
    IconCloseGray,
    AdditionalUserInfoModal
  },
  props: {
    orderId: null,
    therapistId: null,
    sessionId: null,
    sessionType: null,
    packagesOrderId: null,
    type: null,
    count: null,
    typeModal: null,
    session_type: null,
    session_id: null,
    paymentStatus: null
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      videoID: null,
      actionAfterFailed: "Try again"
    };
  },

  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("therapistSlots", ["order"]),
    ...mapState("auth", ["user", "isLoading"]),
    ...mapState("sessions", ["bookedPackages", "package_order"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    paymentFailedActionTrackData() {
      return {
        "Action after failed payment": this.actionAfterFailed,
        pricing: this.order.session.price_in_kwd_object.amount / this.order.session.price_in_kwd_object.divider,
        "Session type": this.order.session.type,
        "Session date": this.order.session.session_start,
        "Order id": this.order.id,
        "Slot id": this.order.session.id,
        "Hours until session": dayjs(this.order.session.session_start).diff(dayjs(), "hour"),

        "Therapist name": this.therapistProfile.name_en,
        "Has intro video": !!(this.therapistProfile?.video_preview_ar || this.therapistProfile?.video_preview_en),
        "Therapist speciality": this.therapistProfile.skill_en || "",
        "Therapist video price":
          this.therapistProfile.video_price_in_kwd_object.amount /
          this.therapistProfile.video_price_in_kwd_object.divider,
        "Therapist years of experience": this.therapistProfile.experience,
        "Therapist education": this.therapistProfile.qualification_en,
        "Therapist language": this.therapistProfile.languages.join(", "),
        "Therapist gender": this.therapistProfile.gender,
        "Therapist video small price":
          this.therapistProfile.video_small_price_in_kwd_price_object.amount /
          this.therapistProfile.video_small_price_in_kwd_price_object.divider
      };
    }
  },

  watch: {
    async arabic() {
      await store.dispatch("therapistProfiles/getTherapistProfile", this.$route.params.id);
    },
    user: {
      handler: "trackPage",
      immediate: true
    }
  },

  methods: {
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    tryAgain() {
      this.$modal.hide("payment-failed");
      this.$modal.show("proceed-to-payment-modal");

      this.actionAfterFailed = "Try again";

      window.webengage.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$mixpanel.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$intercom.trackEvent("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
    },

    talkToCare() {
      this.$intercom.showNewMessage(
        this.$t("payment_error.payment_error_message", { error: this.order.failed_reason })
      );
      this.$modal.hide("payment-failed");

      this.actionAfterFailed = "Talk to customer care";

      window.webengage.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$mixpanel.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$intercom.trackEvent("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
    },
    closePaymentFailed() {
      this.$modal.hide("payment-failed");
      this.actionAfterFailed = "Close";

      window.webengage.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$mixpanel.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$intercom.trackEvent("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
    },

    async trackPage() {
      // Mixpanel analytics

      if (this.user) {
        await this.$store.dispatch("sessions/setBookedPackages");

        this.$intercom.boot({
          user_id: this.user?.id || "",
          name: this.user.name,
          email: this.user.email,
          video_session_count: this.user.video_orders_count,
          chat_session_count: this.user.chat_orders_count,
          gender: this.user.gender,
          language: storageClient.getLanguage(),
          user: "patient"
        });

        setTimeout(() => {
          this.$mixpanel.identify(this.user?.id || "");

          this.$mixpanel.register({
            "User type": "Client"
          });
        }, 1800);
      } else {
        this.$intercom.boot({
          user: "patient",
          language: storageClient.getLanguage(),
          mixpanel_anonymous_id: this.$mixpanel.get_distinct_id()
        });
      }

      if (this.user && this.user?.show_basic_details_prompt === true && this.isLoading === false) {
        this.$modal.show("additional-user-info-modal");
        return;
      }

      if (this.therapistProfile) {
        this.$kochava.sendEvent("View", {
          name: "view_therapist_profile",
          user_id: this.user?.id || null,
          now_date: new Date(),
          content_type: "therapist",
          content_id: this.therapistProfile.id
        });
      }

      const trackAdditionalData = getTherapistProperties(this.therapistProfile);
      window.webengage.track("therapist_profile_view", trackAdditionalData);
      this.$mixpanel.track("therapist_profile_view", trackAdditionalData);
    }
  },

  async beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      return next({ name: "HTTPError" });
    }

    await store.dispatch("therapistProfiles/getTherapistProfile", to.params.id);

    return next();
  },

  async mounted() {
    window.location.href = `https://go.ayadihealth.co/${this.arabic ? "ar" : "en"}/therapists/${
      this.therapistProfile.id
    }`;

    window.webengage.user.setAttribute("User type", "Client");

    const listener = window.addEventListener("blur", () => {
      if (document.activeElement === document.querySelector("iframe")) {
        const trackAdditionalData = { ...getTherapistProperties(this.therapistProfile), Screen: "therapist profile" };
        window.webengage.track("therapist_introVideo_click", trackAdditionalData);
        this.$mixpanel.track("therapist_introVideo_click", trackAdditionalData);
      }
      window.removeEventListener("blur", listener);
    });

    if (this.arabic) {
      //
      if (this.therapistProfile.video_preview_ar) {
        const withoutLink = this.therapistProfile.video_preview_ar.substr(34);
        this.videoID = withoutLink.substr(0, withoutLink.indexOf("."));
      } else {
        this.videoID = null;
      }
      //
    } else {
      //
      if (this.therapistProfile.video_preview_en) {
        const withoutLink = this.therapistProfile.video_preview_en.substr(34);
        this.videoID = withoutLink.substr(0, withoutLink.indexOf("."));
      } //
      else {
        //
        if (this.therapistProfile.video_preview_ar) {
          const withoutLink = this.therapistProfile.video_preview_ar.substr(34);
          this.videoID = withoutLink.substr(0, withoutLink.indexOf("."));
        }
      } //
    }

    if (this.packagesOrderId) {
      this.$modal.show("loader-modal");

      const checkOrderPackagesInterval = setInterval(async () => {
        if (this.package_order?.status !== "failed" && this.package_order?.status !== "ready") {
          await this.$store.dispatch("sessions/checkPackageOrder", this.packagesOrderId);
        }

        if (this.package_order?.status === "failed") {
          this.$modal.hide("loader-modal");
          this.$modal.show("payment-failed-modal", { order: this.package_order });

          this.$notify({
            group: "errors",
            text: "Payment failed, please try again",
            classes: "toast-error"
          });
          clearInterval(checkOrderPackagesInterval);

          this.$router.replace({ query: null });
          return;
        }

        if (this.package_order?.status === "ready") {
          if (this.package_order?.client_id !== this.user.id) {
            this.$notify({
              group: "errors",
              text: "Failed",
              classes: "toast-error"
            });
            this.$modal.hide("loader-modal");
            clearInterval(checkOrderPackagesInterval);
            return;
          }
          clearInterval(checkOrderPackagesInterval);
          this.$modal.hide("loader-modal");
          this.$modal.show("packages-success-bought-modal");

          this.$router.replace({ query: null });
        }
      }, 2000);

      return;
    }

    if (this.paymentStatus === "failed" || this.paymentStatus === "cancel") {
      this.$router.replace({ query: null });
      this.$modal.show("payment-failed-modal");
    }

    if (this.sessionType) {
      if (this.sessionType === "video") {
        this.$modal.show("booking-options-modal", this.therapistProfile.id);
        return;
      } else if (this.sessionType === "video_small") {
        this.$modal.show("booking-options-modal", this.therapistProfile.id);
        return;
      }
    }

    if (this.sessionId) {
      try {
        const { data } = await TherapistClient.getSession(this.therapistId, this.sessionId);

        this.$modal.show("proceed-to-payment-modal", data);
      } catch {
        this.$modal.show("booking-options-modal", this.therapistProfile.id);
      }
    }

    if (!this.user) {
      if (storageClient.getToken()) {
        await this.$store.dispatch("auth/getProfile");
      }
    }

    if (this.type || this.count || this.session_type) {
      if (this.user) {
        await this.$store.dispatch("sessions/setBookedPackages");

        const validPackages = await checkAvailabilityPackage(this.therapistProfile.id, this.bookedPackages);

        await this.$store.dispatch("sessions/setBookedPackagesForTherapist", validPackages);
        const { data: packageOptions } = await sessionsClient.getPricePackages(this.therapistProfile?.id);

        if (this.bookedPackages) {
          const hasPrevSession = checkPreviousSessions(this.therapistProfile.id, this.bookedPackages);
          this.hasPreviousSessions = hasPrevSession;
        }

        const packagesOptions = await mapPackageOptions(packageOptions, this.hasPrevSession);

        if (this.typeModal === "products") {
          if (this.type === "package") {
            if (this.count) {
              const preSelect = getPreSelectPackage(packagesOptions, {
                count: this.count,
                type: this.type
              });
              if (preSelect) {
                await this.$store.dispatch("sessions/selectPackage", preSelect);
                this.$modal.show("booking-options-modal", { selectedOption: preSelect });
              } else {
                if (packagesOptions && packagesOptions.length > 0) {
                  this.$modal.show("booking-options-modal", {
                    preSelectTab: "packages"
                  });
                } else {
                  this.$modal.show("booking-options-modal");
                }
              }
            } else {
              if (packagesOptions && packagesOptions.length > 0) {
                this.$modal.show("booking-options-modal", {
                  preSelectTab: "packages"
                });
              } else {
                this.$modal.show("booking-options-modal");
              }
            }
          }

          if (this.type === "session") {
            if (this.session_type) {
              const oneSessionOptions = await mapSingleSessionsOptions(packageOptions, this.hasPrevSession);
              const preSelect = getPreSelectSession(oneSessionOptions, {
                sessionType: this.session_type,
                type: this.type
              });

              if (preSelect) {
                this.$modal.show("booking-options-modal", { selectedOption: preSelect });
              } else {
                if (oneSessionOptions && oneSessionOptions.length > 0) {
                  this.$modal.show("booking-options-modal", {
                    preSelectTab: "one_session"
                  });
                } else {
                  this.$modal.show("booking-options-modal");
                }
              }
            } else {
              this.$modal.show("booking-options-modal", {
                preSelectTab: "one_session"
              });
            }
          }
        } else if (this.typeModal === "review") {
          if (this.type === "package") {
            if (this.count) {
              const preSelect = getPreSelectPackage(packagesOptions, {
                count: this.count,
                type: this.type
              });

              await this.$store.dispatch("sessions/selectPackage", preSelect);

              if (preSelect) {
                this.$modal.show("proceed-to-payment-package-modal");
              } else {
                if (packagesOptions && packagesOptions.length > 0) {
                  this.$modal.show("booking-options-modal", {
                    preSelectTab: "packages"
                  });
                } else {
                  this.$modal.show("booking-options-modal");
                }
              }
            } else {
              this.$modal.show("booking-options-modal");
            }
          } else if (this.type === "session") {
            if (this.session_id) {
              const { data } = await TherapistClient.getSession(this.therapistProfile.id, this.session_id);
              if (data) {
                this.$modal.show("proceed-to-payment-modal", data);
              } else {
                this.$modal.show("booking-options-modal");
              }
            } else {
              this.$modal.show("booking-options-modal");
            }
          } else {
            this.$modal.show("booking-options-modal");
          }
        }
      } else {
        const payload = {
          modal: "booking-options-modal",
          additionalInfo: {
            preSelectQuery: {
              ...(this.session_type && { session_type: this.session_type }),
              ...(this.type && { type: this.type }),
              ...(this.count && { count: this.count }),
              ...(this.typeModal && { typeModal: this.typeModal }),
              ...(this.session_id && { session_id: this.session_id })
            }
          }
        };

        await this.$store.dispatch("therapistSlots/setShowNextModal", payload);

        this.$modal.show("sign-up-select-auth-modal");
      }
    }

    if (!this.orderId) {
      return;
    }

    this.$modal.show("loader-modal");
    await this.$store.dispatch("therapistSlots/checkOrder", this.orderId);

    const trackData = {
      "Device type": "Web",

      "Error text": this.order.failed_reason,

      // "Payment methods": "",

      pricing: this.order.session.price_in_kwd_object.amount / this.order.session.price_in_kwd_object.divider,
      "Session type": this.order.session.type,
      "Session date": this.order.session.session_start,
      "Order id": this.order.id,
      "Slot id": this.order.session.id,
      "Hours until session": dayjs(this.order.session.session_start).diff(dayjs(), "hour"),

      "Therapist name": this.therapistProfile.name_en,
      "Has intro video": !!(this.therapistProfile?.video_preview_ar || this.therapistProfile?.video_preview_en),
      "Therapist speciality": this.therapistProfile.skill_en || "",
      "Therapist video price":
        this.therapistProfile.video_price_in_kwd_object.amount /
        this.therapistProfile.video_price_in_kwd_object.divider,
      "Therapist years of experience": this.therapistProfile.experience,
      "Therapist education": this.therapistProfile.qualification_en,
      "Therapist language": this.therapistProfile.languages.join(", "),
      "Therapist gender": this.therapistProfile.gender,
      "Therapist video small price":
        this.therapistProfile.video_small_price_in_kwd_price_object.amount /
        this.therapistProfile.video_small_price_in_kwd_price_object.divider
    };

    if (this.order.status === "failed") {
      this.$modal.hide("loader-modal");
      this.$modal.show("payment-failed-modal");

      window.webengage.track("booking_paymentFailed_view", trackData);
      this.$mixpanel.track("booking_paymentFailed_view", trackData);
      this.$intercom.trackEvent("booking_paymentFailed_view", trackData);

      this.$notify({
        group: "errors",
        text: "Payment failed, please try again",
        classes: "toast-error"
      });
      clearInterval(checkOrderInterval);
      return;
    }

    if (this.order.status === "ready") {
      clearInterval(checkOrderInterval);
      await this.$router.push({ name: "PaymentSuccess", query: { orderId: this.orderId } }).catch(() => {});
    }
    const checkOrderInterval = setInterval(async () => {
      if (this.order.status !== "failed" && this.order.status !== "ready") {
        await this.$store.dispatch("therapistSlots/checkOrder", this.orderId);
      }

      if (this.order.status === "failed") {
        this.$modal.hide("loader-modal");
        this.$modal.show("proceed-to-payment-modal");

        window.webengage.track("booking_paymentFailed_view", trackData);
        this.$mixpanel.track("booking_paymentFailed_view", trackData);
        this.$intercom.trackEvent("booking_paymentFailed_view", trackData);

        this.$notify({
          group: "errors",
          text: "Payment failed, please try again",
          classes: "toast-error"
        });
        clearInterval(checkOrderInterval);
        return;
      }

      if (this.order.status === "ready") {
        clearInterval(checkOrderInterval);
        this.$modal.hide("loader-modal");
        await this.$router.push({ name: "PaymentSuccess", query: { orderId: this.orderId } }).catch(() => {});
      }
    }, 5000);
  }
};
</script>

<style scoped>
.video-h {
  @apply rounded-2xl;
  height: 500px;
  max-height: calc(50vw);
}
</style>

<template>
  <div class="bg-white px-8 py-6 flex flex-col justify-center items-center rounded-lg modalWrapper">
    <div class="flex justify-between items-start w-full" :class="{ 'flex-row-reverse': arabic }">
      <p class="text-18 font-semibold mb-2" :dir="arabic ? 'rtl' : 'ltr'">
        {{ isModal ? $t("booking_flow.last_step") : $t("auth.start") }}
      </p>
      <div v-if="isModal" class="cursor-pointer px-1 py-1" @click="$modal.hide('sign-up-select-auth-modal')">
        <IconCloseGray />
      </div>
    </div>
    <p class="mt-2 mb-4 text-sm flex w-full" :dir="arabic ? 'rtl' : 'ltr'">{{ $t("booking_flow.you_will_need") }}</p>
    <div class="w-full authBtnWrapper">
      <div
        v-if="preflight && !preflight.disable_apple_login"
        @click="AppleAuth"
        class="authBtn hover:bg-gray-100"
        id="apple_signup"
      >
        <img src="../../assets/images/Authorization/Apple.svg" alt="apple" />

        {{ $t("booking_flow.continue_with_apple") }}
      </div>
      <div
        v-if="preflight && !preflight.disable_facebook_login"
        @click="FacebookAuth"
        class="authBtn hover:bg-gray-100"
        id="facebook_signup"
      >
        <img src="../../assets/images/Authorization/Facebook.svg" alt="facebook" />

        {{ $t("booking_flow.continue_with_facebook") }}
      </div>
      <div
        v-if="preflight && !preflight.disable_google_login"
        @click="GoogleAuth"
        class="authBtn hover:bg-gray-100"
        id="google_signup"
      >
        <img src="../../assets/images/Authorization/Google.svg" alt="google" />

        {{ $t("booking_flow.continue_with_google") }}
      </div>
      <div @click="$emit('signUpViaEmail')" class="authBtn hover:bg-gray-100" id="apple_signup">
        <img src="../../assets/images/Authorization/Mail.svg" alt="email" />

        {{ $t("booking_flow.continue_with_email") }}
      </div>
    </div>
    <p class="mb-4 mt-4 text-center text-xs">
      {{ $t("auth.agree") }}
      <a class="text-ayadi-900 no-underline" href="https://ayadihealth.co/terms-of-use/" target="_blank">{{
        $t("auth.ayadis_terms")
      }}</a>
      {{ $t("auth.and") }}
      <a class="text-ayadi-900 no-underline" href="https://ayadihealth.co/privacy-policy" target="_blank">
        {{ $t("auth.privacy_policy") }}
      </a>
      <span v-if="arabic">{{ $t("auth.arabic") }}</span>
    </p>
    <div class="mb-6 h-px w-4/5 bg-divider"></div>
    <p>
      {{ $t("auth.have_account") }}
      <span @click="$emit('toSignIn')" class="text-ayadi-900 cursor-pointer font-semibold" id="signin_prompt">
        {{ $t("auth.have_account_sign_in") }}
      </span>
    </p>
  </div>
</template>

<script>
import Vue from "vue";
import IconCloseGray from "@/components/icons/IconCloseGray";
import storageClient from "@/services/storageClient";
import { getUTMProperties } from "@/utils/events";
import store from "@/store";
import dayjs from "dayjs";
import { mapState } from "vuex";
import { AnalyticsTrackData } from "@/services/analytics";

export default {
  name: "SignUpComponent",
  components: { IconCloseGray },
  data() {
    return {
      email: "",
      password: "",
      emailErrorText: "",
      passwordErrorText: "",
      name: ""
    };
  },

  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    presentedFrom: {
      type: String,
      default: ""
    }
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    ...mapState("auth", ["user"]),
    ...mapState("common", ["preflight"])
  },

  methods: {
    async GoogleAuth() {
      try {
        const googleUser = await this.$gAuth.signIn();

        const response = googleUser.getAuthResponse();

        await store.dispatch("auth/socialAuth", {
          driver: "google",
          token: response.access_token,
          lang: storageClient.getLanguage() || "en"
        });

        await this.$store.dispatch("auth/updateProfile", {
          name: this.name,
          full_name: this.name,
          name_type: "REAL"
        });

        await this.$store.dispatch("auth/getProfile");

        if (this.user) {
          this.$mixpanel.identify(this.user.id);
        }

        const trackData = {
          "Device type": "Web",
          "Sign-up Method": "therapist profile",
          signup_from: "Google",
          time: dayjs().unix()
        };
        window.webengage.track("successful_Registration", trackData);
        this.$mixpanel.track("successful_Registration", trackData);
        this.$intercom.trackEvent("successful_Registration", trackData);
        storageClient.setSingUpFrom("Google");
        this.initKochava();
        this.$emit("onSignUp");
      } catch (e) {
        console.error(e);
      }
    },

    async AppleAuth() {
      window.AppleID.auth.init({
        clientId: process.env.VUE_APP_APPLE_APP_ID,
        redirectURI: `https://${process.env.VUE_APP_HOST}/apple/sign-in/apple-redirect`,
        scope: process.env.VUE_APP_APPLE_AUTH_SCOPE || "",
        usePopup: true //or false defaults to false
      });
      try {
        let data = await window.AppleID.auth.signIn();
        await store.dispatch("auth/socialAuth", {
          driver: "apple",
          token: data.authorization.id_token,
          lang: storageClient.getLanguage() || "en"
        });

        await this.$store.dispatch("auth/updateProfile", {
          name: this.name,
          full_name: this.name,
          name_type: "REAL"
        });

        await this.$store.dispatch("auth/getProfile");

        if (this.user) {
          this.$mixpanel.identify(this.user.id);
        }

        const trackData = {
          "Device type": "Web",
          "Sign-up Method": "therapist profile",
          signup_from: "Apple",
          time: dayjs().unix()
        };
        window.webengage.track("successful_Registration", trackData);
        this.$mixpanel.track("successful_Registration", trackData);
        this.$intercom.trackEvent("successful_Registration", trackData);
        storageClient.setSingUpFrom("Apple");
        this.initKochava();
        this.$emit("onSignUp");
      } catch (e) {
        console.error(e);
      }
    },

    async FacebookAuth() {
      window.sessionStorage.clear();
      if (window?.cookieStore) {
        await window?.cookieStore?.getAll().then((cookies) =>
          cookies.forEach((cookie) => {
            window.cookieStore.delete(cookie.name);
          })
        );
      }

      await this.loadFacebookSDK(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID, //You will need to change this
          cookie: true, // This is important, it's not enabled by default,
          scope: "public_profile,email",
          version: "v13.0"
        });

        window.FB.getLoginStatus(async function (response) {
          try {
            if (response.status === "connected") {
              await store.dispatch("auth/socialAuth", { driver: "facebook", token: response.authResponse.accessToken });

              window.location.href = "/no-upcoming-sessions";
            } else {
              await window.FB.login(async function (response) {
                if (response.authResponse) {
                  await store.dispatch("auth/socialAuth", {
                    driver: "facebook",
                    token: response.authResponse.accessToken,
                    lang: storageClient.getLanguage() || "en"
                  });
                  setTimeout(async () => {
                    await this.$store.dispatch("auth/updateProfile", {
                      name: this.name,
                      full_name: this.name,
                      name_type: "REAL"
                    });
                    if (this.user) {
                      this.$mixpanel.identify(this.user.id);
                    }

                    const trackData = {
                      "Device type": "Web",
                      "Sign-up Method": "therapist profile",
                      signup_from: "Facebook",
                      time: dayjs().unix()
                    };
                    window.webengage.track("successful_Registration", trackData);
                    this.$mixpanel.track("successful_Registration", trackData);
                    this.$intercom.trackEvent("successful_Registration", trackData);
                    this.initKochava();
                    storageClient.setSingUpFrom("Facebook");
                    window.location.href = "/no-upcoming-sessions";
                  }, 1000);
                } else {
                  Vue.notify({
                    group: "errors",
                    text: "User cancelled login or did not fully authorize",
                    classes: "toast-error"
                  });
                }
              });
            }
          } catch (e) {
            console.error(e);
          }
        });
      };
    },

    async loadFacebookSDK(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },

    async initKochava() {
      if (this.user) {
        this.$kochava.registerIdentityLink("User ID", this.user.id);
        this.$kochava.registerIdentityLink("User Email", this.user.email);

        const kochava_device_id = this.$kochava.getDeviceId();

        AnalyticsTrackData.sendKochavaId(kochava_device_id);
      }
    },

    async initBeforeOpen() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.name = storageClient.getRegistrationFlowName();
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  },
  async mounted() {
    const trackData = {
      presented_from: this.presentedFrom || "",
      ...getUTMProperties(this.$route.query)
    };

    storageClient.setPresentedFrom(this.presentedFrom || "");
    window.webengage.track("signup_view", trackData);
    this.$mixpanel.track("signup_view", trackData);
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.modalWrapper {
  max-width: 430px;

  @include for-phone {
    margin: 0 10px;
  }
}

.authBtnWrapper {
  .authBtn {
    border: 2px solid #354052;
    filter: drop-shadow(0px 7px px 20px rgba(0, 0, 0, 0.07));
    border-radius: 4px;

    display: flex;
    justify-content: center;
    padding: 0.75em 1.5em 0.75em 3.5em;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    img {
      position: absolute;
      left: 24px;
      width: 21px;
      height: 21px;
    }

    @include for-phone {
      font-size: 13px;
    }
  }
}
</style>

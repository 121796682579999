import { render, staticRenderFns } from "./SlotsInfo.vue?vue&type=template&id=8ac5ddaa&scoped=true&"
import script from "./SlotsInfo.vue?vue&type=script&lang=js&"
export * from "./SlotsInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ac5ddaa",
  null
  
)

export default component.exports
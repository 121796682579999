import axios from "axios";

export default class AuthClient {
  static async login(user) {
    return await axios.post("/api/client/auth/login", user);
  }

  static async signUp(user) {
    return await axios.post("/api/client/auth/registration", user);
  }

  static async forgotPassword(email) {
    return await axios.post("/api/client/auth/forgot-password", email);
  }

  static async getProfile() {
    return await axios.get("/api/client/profile");
  }

  static async updateProfile(profile) {
    return await axios.post("/api/client/profile", profile);
  }

  static async resetPassword(password) {
    return await axios.post("/api/client/auth/reset", password);
  }

  static async socialAuth(driver, token, lang) {
    return await axios.post(`/api/client/auth/social/${driver}`, { token: token, lang: lang || "en" });
  }

  static async notificationSettings() {
    return await axios.get("/api/client/notification-settings");
  }
}

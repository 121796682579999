export const capitalize = (arr, isArabic) => {
  if (arr.length > 0) {
    const capitalizedArr = arr.map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
    });

    return isArabic ? capitalizedArr.join("، ") : capitalizedArr.join(", ");
  }
  return "";
};

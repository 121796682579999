const getClientAvatarByGender = (gender) => {
  if (gender === "female") {
    return "female_1";
  } else if (gender === "male") {
    return "male_1";
  } else {
    return "female_4";
  }
};

export default getClientAvatarByGender;

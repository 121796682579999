import axios from "axios";

export default class ChatClient {
  static async getChatList() {
    return await axios.get("/api/client/chat-rooms");
  }

  static async getChatToken() {
    return await axios.get("/api/client/chat-rooms-access");
  }
}

import ChatClient from "../../services/chatClient";
import therapistClient from "../../services/therapistClient";

export const namespaced = true;

export const state = {
  chatList: null,
  chatToken: null,
  activeConversationIndex: 0,
  therapistProfile: null
};
export const mutations = {
  SET_CHAT_LIST(state, list) {
    state.chatList = list;
  },

  SET_CHAT_TOKEN(state, token) {
    state.chatToken = token;
  },

  SET_ACTIVE_CONVERSATION(state, conversation) {
    state.activeConversationIndex = conversation;
  },

  SET_THERAPIST_PROFILE(state, profile) {
    state.therapistProfile = profile;
  }
};
export const actions = {
  async getChatList({ commit }) {
    const { data } = await ChatClient.getChatList();
    commit("SET_CHAT_LIST", data);
  },

  async getChatToken({ commit }) {
    const { data } = await ChatClient.getChatToken();
    commit("SET_CHAT_TOKEN", data.token);
  },

  async getTherapistProfile({ commit }, id) {
    const { data } = await therapistClient.getTherapistProfile(id);
    commit("SET_THERAPIST_PROFILE", data);
  },

  setActiveConversation({ commit }, conversation) {
    commit("SET_ACTIVE_CONVERSATION", conversation);
  }
};

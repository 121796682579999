<template>
  <div class="bg-bgLight min-h-screen">
    <NavBar :showTherapist="true" />

    <div class="fit-width-content flex flex-col justify-start items-center mx-auto" :dir="arabic ? 'rtl' : 'ltr'">
      <h2 class="mt-6 mb-4 font-semibold text-2xl sm:text-xl sm:text-center">
        {{ $t("booking_flow.looking_forward", { name: getTherapistName }) }}
      </h2>

      <div class="payment-block-width bg-white rounded-2xl p-6 custom-shadow mb-4">
        <div class="w-full px-4 py-3 rounded-2xl bg-bgLight flex flex-row justify-between items-start mb-6">
          <img
            class="rounded-lg h-16 w-16"
            :class="arabic ? 'ml-5' : 'mr-5'"
            :style="{ 'background-color': therapistProfile.avatar_bg_color }"
            :src="getAvatar"
            alt="therapist-avatar"
          />
          <div class="w-full" :class="arabic ? 'text-right' : 'text-left'" :dir="arabic ? 'rtl' : 'ltr'">
            <p>{{ $t("order_by_packages.see_you", { date: getSessionFullDate }) }}</p>
            <p class="my-6">{{ $t("order_by_packages.remind") }}</p>
            <p>
              <span class="font-semibold">{{ $t("order_by_packages.tip.title") }}</span>
              {{ $t("order_by_packages.tip.text") }}
            </p>
          </div>
        </div>

        <div class="grid grid-cols-2 w-full">
          <div class="col-span-1 flex flex-col justify-start" :class="arabic ? 'pr-16 sm:pr-10' : 'pl-16 sm:pl-10'">
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.date") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.time") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.duration") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("order_by_packages.session") }}</p>
          </div>
          <div class="col-span-1">
            <p class="font-semibold text-base mb-3">{{ getDate }}</p>
            <p class="font-semibold text-base mb-3">
              <span dir="ltr">{{ getTime }}</span>
            </p>
            <p class="font-semibold text-base mb-3">{{ getDuration }}</p>
            <p class="font-semibold text-base mb-3">
              {{ $t("order_by_packages.current_session", { current: getCurrentPackages, total: getTotalPackages }) }}
            </p>
          </div>
        </div>
      </div>

      <div class="payment-block-width bg-white rounded-2xl p-6 custom-shadow mb-4">
        <h2 class="text-18 font-semibold mb-4">{{ $t("order_by_packages.join_mobile.title") }}</h2>
        <p>{{ $t("order_by_packages.join_mobile.tip-1") }}</p>

        <div class="flex flex-row justify-center space-x-6 mt-6 mb-4">
          <a
            :class="arabic ? 'ml-3' : 'mr-3'"
            href="https://apps.apple.com/ae/app/ayadi/id1503638078"
            target="_blank"
            @click="clickAppStore"
          >
            <img src="../assets/images/Authorization/AppStore.svg" alt="app store" />
          </a>
          <a
            :class="arabic ? 'mr-3' : 'ml-3'"
            href="https://play.google.com/store/apps/details?id=com.bloom.ayadi"
            target="_blank"
          >
            <img src="../assets/images/Authorization/GooglePlay.svg" alt="google play" @click="clickGooglePlay" />
          </a>
        </div>

        <p class="mb-4">{{ $t("order_by_packages.join_mobile.tip-2") }}</p>

        <img class="mx-auto" v-if="!arabic" src="../assets/images/sign-in-mobile-en.png" alt="sign-in mobile" />
        <img class="mx-auto" v-else src="../assets/images/sign-in-mobile-ar.png" alt="sign-in mobile" />
      </div>

      <div class="payment-block-width bg-white rounded-2xl p-6 custom-shadow mb-4">
        <h2 class="text-18 font-semibold mb-4">{{ $t("order_by_packages.join_laptop.title") }}</h2>
        <p class="text-center mb-4">
          {{ $t("order_by_packages.join_laptop.date", { date: getDescDate }) }}
          <a href="https://ayadihealth.co" class="text-ayadi-900 no-underline font-semibold" target="_blank"
            >ayadihealth.co</a
          >.
        </p>

        <img v-if="!arabic" class="mx-auto" src="../assets/images/sign-in-web-en.png" alt="sign in web" />
        <img v-else class="mx-auto" src="../assets/images/sign-in-web-ar.png" alt="sign in web" />
        <div class="payment-divider"></div>
        <p class="text-center mb-6">{{ $t("order_by_packages.join_laptop.tip-1", { date: getDescDate }) }}</p>
        <MainButton v-clipboard="copyToClipboard" class="mx-auto mb-4" type="medium">{{
          $t("booking_flow.copy_link")
        }}</MainButton>
        <p class="font-semibold text-center">{{ $t("order_by_packages.join_laptop.tip-2") }}️</p>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

import NavBar from "@/components/NavBar";
import MainButton from "@/components/ui-kit/MainButton";
import router from "@/router";
import storageClient from "@/services/storageClient";

export default {
  name: "PaymentByPackageSuccess",
  components: { MainButton, NavBar },
  props: {
    therapistProfile: Object,
    order: Object,
    packages: Object
  },

  computed: {
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    getCurrentPackages() {
      if (!this.packages) {
        return "";
      }
      return this.packages?.current || "";
    },

    getTotalPackages() {
      if (!this.packages) {
        return "";
      }
      return this.packages?.total || "";
    },

    getDescDate() {
      if (!this.order) {
        return "";
      }
      return dayjs(this.order.start).format("MMM DD");
    },

    getSessionFullDate() {
      if (!this.order) {
        return "";
      }
      return `${this.getDescDate} ${this.getTime}`;
    },
    getDate() {
      if (!this.order) {
        return "";
      }
      return dayjs(this.order.start).format("dddd, MMM DD");
    },

    getTherapistName() {
      if (!this.therapistProfile) {
        return "";
      }

      return this.arabic ? this.therapistProfile?.name_ar : this.therapistProfile?.name_en;
    },

    getAvatar() {
      if (!this.therapistProfile) {
        return "";
      }
      return this.therapistProfile.avatar;
    },

    getTime() {
      if (!this.order) {
        return "";
      }
      return dayjs(this.order.start).format("h:mm A");
    },

    getDuration() {
      if (!this.order) {
        return "";
      }

      return this.order?.type === "video" ? this.$t("booking_flow.50mins") : this.$t("booking_flow.25mins");
    }
  },

  methods: {
    copyToClipboard() {
      this.$notify({ group: "success", text: this.$t("booking_flow.link_is_copied") });
      return "https://ayadihealth.co/";
    },
    clickGooglePlay() {
      window.gtag("event", "therapistProfile_googlePlayStore_click");
    },
    clickAppStore() {
      window.gtag("event", "therapistProfile_appleAppStore_click");
    }
  },

  async created() {
    try {
      if (!this.therapistProfile || !this.order) {
        router.push({ name: "HTTPError" }).catch(() => {});
      }

      document.getElementsByTagName("body")[0].style.overflow = "auto";
    } catch (e) {
      router.push({ name: "HTTPError" }).catch(() => {});
    }
  }
};
</script>

<style scoped>
.payment-block-width {
  width: 430px;
  max-width: 90vw;
}
.payment-divider {
  @apply mx-auto my-6 bg-divider h-px;
  width: 70%;
}
</style>

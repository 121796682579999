<template>
  <div class="container" v-if="isActive">
    <div class="py-20 h-80 flex justify-center items-center w-full h-full wrapper">
      <img class="w-20 animate-bounce" src="../../assets/images/ayadi-loader.svg" alt="Ayadi loader" />
    </div>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";

export default {
  name: "LoaderScreen",
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  props: {
    isActive: Boolean
  },

  updated() {
    if (!this.isActive) {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      return;
    }

    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  },

  unmounted() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  z-index: 999999;
  position: fixed;
  top: 0;
  width: 100%;
}

.wrapper {
  background: #ffffff9c;
}
</style>

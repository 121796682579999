<template>
  <div class="px-6 py-5 grid grid-cols-3 col-offset-4" :class="isOdd ? 'bg-graySection' : 'bg-white'">
    <div class="col-span-1">
      <p class="font-semibold text-textGray-500"><slot></slot></p>
    </div>

    <div class="col-span-2 pl-4">
      <p class="ml-1" v-if="isText">{{ content }}</p>
      <div v-else class="flex flex-row justify-start items-start flex-wrap">
        <TherapistInformationChips :is-odd="isOdd" v-for="(chips, index) in content" :key="index" class="mx-1 mb-2">
          {{ chips }}
        </TherapistInformationChips>
      </div>
    </div>
  </div>
</template>

<script>
import TherapistInformationChips from "@/components/therapist-profile/TherapistInformationChips";
export default {
  name: "TherapistInformationRow",
  components: { TherapistInformationChips },
  props: {
    isOdd: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text",
      validator: (value) => {
        return ["text", "chips"].indexOf(value) !== -1;
      }
    },

    content: {
      type: [String, Number, Array],
      required: true
    }
  },

  computed: {
    isText() {
      return typeof this.content !== "object";
    }
  }
};
</script>

<style scoped></style>

<template>
  <div id="app">
    <!--  todo  refactor this-->
    <notifications :duration="6000" width="350" :speed="300" position="top center" group="errors">
      <template slot="body" slot-scope="props">
        <div class="toast-error">
          <div class="mr-2">
            <IconToastExclamation />
          </div>
          <div class="text-left" v-html="props.item.text"></div>
          <div @click="props.close" class="ml-2 cursor-pointer">
            <IconToastClose />
          </div>
        </div>
      </template>
    </notifications>

    <notifications :duration="6000" width="350" :speed="300" position="top center" group="warning-session">
      <template slot="body" slot-scope="props">
        <div class="toast-warning flex flex-row justify-start">
          <div class="mr-2">
            <IconTimer />
          </div>
          <div class="text-left" :dir="arabic ? 'rtl' : 'ltr'">{{ $t("sessions.will_end") }} 5:00</div>
          <div @click="props.close" class="ml-2 cursor-pointer">
            <IconToastClose />
          </div>
        </div>
      </template>
    </notifications>
    <notifications :duration="6000" width="350" :speed="300" position="top center" group="end-session">
      <template slot="body" slot-scope="props">
        <div class="toast-error flex flex-row justify-start">
          <div class="mr-2">
            <IconTimer />
          </div>
          <div class="text-left">{{ $t("sessions.has_ended") }}</div>
          <div @click="props.close" class="ml-2 cursor-pointer">
            <IconToastClose />
          </div>
        </div>
      </template>
    </notifications>
    <notifications :duration="6000" width="350" :speed="300" position="top center" group="success">
      <template slot="body" slot-scope="props">
        <div class="toast-success">
          <div class="mr-2">
            <IconToastSuccess />
          </div>
          <div class="text-left" v-html="props.item.text"></div>
          <div @click="props.close" class="ml-2 cursor-pointer">
            <IconToastClose />
          </div>
        </div>
      </template>
    </notifications>
    <LocalePreferenceModal />
    <LoaderScreen :isActive="isLoadingApp" />
    <router-view />
  </div>
</template>

<script>
import IconToastExclamation from "@/components/icons/IconToastExclamation";
import IconToastClose from "@/components/icons/IconToastClose";
import IconTimer from "@/components/icons/IconTimer";
import IconToastSuccess from "@/components/icons/IconToastSuccess";
import LocalePreferenceModal from "@/components/LocalePreferenceModal";
import LoaderScreen from "@/components/ui-kit/LoaderScreen";
import storageClient from "@/services/storageClient";
import { mapState } from "vuex";
import { AnalyticsTrackData } from "@/services/analytics";
import axios from "axios";

export default {
  name: "App",
  components: {
    LocalePreferenceModal,
    IconToastSuccess,
    IconTimer,
    IconToastClose,
    IconToastExclamation,
    LoaderScreen
  },

  computed: {
    ...mapState("common", ["currentIPData", "currencies"]),
    ...mapState("auth", ["user"]),
    ...mapState("common", ["isLoadingApp"])
  },
  async created() {
    await this.$store.dispatch("common/getPreflight");
    await this.$store.dispatch("common/getWhiteList");
    await this.$store.dispatch("common/getCurrencies");

    if (this.currentIPData?.countryCode) {
      axios.defaults.headers["X-Country"] = this.currentIPData.countryCode;
    }

    if (!storageClient.getToken()) {
      this.$intercom.boot({
        user: "patient",
        mixpanel_anonymous_id: this.$mixpanel.get_distinct_id(),
        language: storageClient.getLanguage() || "en"
      });
      if (!storageClient.getCurrency()) {
        const mappedCurrencies = [];
        this.currencies.forEach((c) => {
          mappedCurrencies.push(c.to);
        });

        if (this.currentIPData?.currency && mappedCurrencies.indexOf(this.currentIPData.currency) !== -1) {
          storageClient.setCurrency(this.currentIPData.currency);
          axios.defaults.headers["X-Currency"] = this.currentIPData.currency;
          this.$router.go(0);
        } else {
          storageClient.setCurrency("USD");
        }
      }
    } else {
      if (this.user?.currency) {
        storageClient.setCurrency(this.user.currency);
      } else {
        storageClient.setCurrency("USD");
      }
    }

    if (!storageClient.getKochavaDeviceId() && this.user) {
      this.$kochava.registerIdentityLink("User ID", this.user.id);
      this.$kochava.registerIdentityLink("User Email", this.user.email);

      const kochava_device_id = this.$kochava.getDeviceId();

      AnalyticsTrackData.sendKochavaId(kochava_device_id);
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: "SF Pro Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<template>
  <div @before-open="initBeforeOpen" class="bg-white px-8 py-6 flex flex-col justify-center items-center rounded-lg">
    <div class="flex justify-between items-start w-full mb-4">
      <p class="text-18 font-semibold">{{ $t("auth.forgot_your_password") }}</p>
      <div class="cursor-pointer px-1 py-1" @click="$emit('goBack')">
        <IconCloseGray />
      </div>
    </div>

    <p class="my-3 hint-text">
      {{ $t("auth.forgot_password_hint") }}
    </p>
    <InputWrapper :label="$t('auth.email')" :error-message="emailError">
      <input
        :dir="arabic ? 'rtl' : 'ltr'"
        slot="input"
        type="text"
        @focus="emailError = ''"
        v-model="email"
        @blur="onBlurEmail"
      />
    </InputWrapper>

    <p class="text-center" v-if="counting">
      {{ $t("auth.link_sent") }}
    </p>
    <MainButton @click="forgotPassword" :disabled="counting || !!emailError || !email" class="mt-3 w-full">
      {{ counterText }}
    </MainButton>
  </div>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import MainButton from "@/components/ui-kit/MainButton";
import storageClient from "@/services/storageClient";
import authClient from "@/services/authClient";
import dayjs from "dayjs";
export default {
  name: "ForgotPasswordModal",
  components: { MainButton, IconCloseGray, InputWrapper },

  data() {
    return { email: "", emailError: "", countDown: 59, counting: false };
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    counterText() {
      return this.counting
        ? this.$t("auth.send_again") + (10 > this.countDown ? "0" + this.countDown : "" + this.countDown)
        : this.$t("auth.confirm");
    }
  },

  methods: {
    async forgotPassword() {
      // prettier-ignore
      const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.email.length < 7 || !this.email.toLowerCase().match(regexEmail)) {
        this.emailError = this.$t("auth.email_required");
        return;
      }
      await authClient.forgotPassword({ email: this.email });
      this.countDownTimer();
    },

    onBlurEmail() {
      if (this.email.length > 0) {
        this.email = this.email.replace(/ /g, "");
      }
    },

    countDownTimer() {
      this.counting = true;
      let counter = setInterval(() => {
        this.countDown--;
        if (this.countDown === 0) {
          this.counting = false;
          this.countDown = 59;
          clearInterval(counter);
        }
      }, 1000);
    },

    initBeforeOpen() {
      window.webengage.setAttribute("User type", "Client");
      const trackData = {
        referrer: document.referrer,
        Language: storageClient.getLanguage(),
        "Device type": "Web",
        time: dayjs().unix()
      };
      window.webengage.track("forgotPassword_click", trackData);

      setTimeout(() => {
        this.$mixpanel.register({
          "User type": "Client"
        });

        this.$mixpanel.track("forgotPassword_click", trackData);
        this.$intercom.trackEvent("forgotPassword_click", trackData);
      }, 1000);
    }
  }
};
</script>

import axios from "axios";
import { getMessage } from "@/utils/extractErrorMessage";
import store from "@/store";
import router from "@/router";
import storageClient from "@/services/storageClient";
import i18n from "@/i18n";
import Vue from "vue";
import { AnalyticsTrackData } from "@/services/analytics";

export async function setupAxios(vue) {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  axios.defaults.headers["Accept"] = "application/json";
  axios.defaults.headers["Content-Type"] = "application/json";
  axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

  axios.defaults.headers["Platform"] = "WEB";
  axios.defaults.headers["X-Currency"] = storageClient.getCurrency() || "USD";
  axios.defaults.headers["X-App-Version"] = process.env.VUE_APP_VERSION;

  const allowRoutes = [
    "TherapistProfile",
    "SessionAvailabilityNotLocale",
    "SessionAvailability",
    "CheckConfirmation",
    "CheckConfirmationNotLocale",
    "PaymentSuccess",
    "PaymentByPackageSuccess"
  ];

  const kochavaId = storageClient.getKochavaDeviceId();

  if (kochavaId) {
    axios.defaults.headers["X-Tracking-Device-Id"] = storageClient.getKochavaDeviceId() || "";
  }

  axios.interceptors.request.use((config) => {
    const locale = storageClient.getLanguage() === "ar" ? "ar" : "en";
    const token = localStorage.getItem("token");

    config.headers["Accept-Language"] = locale;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (e) => {
      let errorMessage = getMessage(e);
      let couponError;

      switch (errorMessage) {
        case "invalid_coupon":
          couponError = i18n.tc("coupons.coupon_invalid");
          break;
        case "already_redeemed":
          couponError = i18n.tc("coupons.coupon_already_redeemed");
          break;
        case "campaign_is_ended":
          couponError = i18n.tc("coupons.coupon_campaign_ended");
          break;
        case "cant_be_applied_to_this_therapist":
          couponError = i18n.tc("coupons.coupon_cant_be_applied_to_therapist");
          break;
        case "coupon_is_expired":
          couponError = i18n.tc("coupons.coupon_expired");
          break;
      }

      vue.notify({
        group: "errors",
        text: couponError || errorMessage,
        classes: "toast-error"
      });

      if (e.response?.status === 401) {
        store.dispatch("auth/logout");
        window.location.href = "/";
      }

      if (e.response?.status === 404) {
        if (router.history?.pending?.name && allowRoutes.includes(router.history.pending.name)) {
          const locale = storageClient.getLanguage() === "ar" ? "ar" : "en";
          router.push({ name: "TherapistList", params: { locale: locale || "en" } }).catch(() => {});
          return;
        }
        return router.push({ name: "HTTPError" }).catch(() => {});
      }

      if (e.response?.status === 403) {
        return store.dispatch("auth/logout");
      }
      if (e.response?.status === 503) {
        return router.push({ name: "HTTPError", query: { errorStatus: "503" } }).catch(() => {});
      }

      return Promise.reject(e);
    }
  );
}

export const setTrackingDevice = async () => {
  const kochavaId = storageClient.getKochavaDeviceId();

  if (kochavaId) {
    axios.defaults.headers["X-Tracking-Device-Id"] = storageClient.getKochavaDeviceId() || "";
  } else {
    const kochava_device_id = Vue.prototype.$kochava.getDeviceId();
    const kochavaId = await AnalyticsTrackData.sendKochavaId(kochava_device_id);

    axios.defaults.headers["X-Tracking-Device-Id"] = kochavaId.id;
  }
};

<template>
  <modal
    @before-open="getBookedSlot"
    name="proceed-to-payment-modal"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div
      class="bg-white w-modal max-h-90 p-6 flex flex-col justify-center items-center rounded-lg"
      :dir="arabic ? 'rtl' : 'ltr'"
    >
      <div v-if="isLoading" class="py-20 h-80 flex justify-center items-center w-full h-full bg-white">
        <img class="w-20 animate-bounce" src="../../../assets/images/ayadi-loader.svg" alt="Ayadi" />
      </div>
      <div v-else class="w-full overflow-y-auto">
        <div class="flex justify-between items-start w-full mb-4">
          <p class="text-18 font-semibold w-full text-center">{{ $t("booking_flow.session_details") }}</p>
          <div class="cursor-pointer px-1 py-1" @click="$modal.hide('proceed-to-payment-modal')">
            <IconCloseGray />
          </div>
        </div>
        <div class="flex flex-col justify-center items-center">
          <img
            class="img-size rounded-lg mt-6 mb-2"
            :src="therapistProfile.avatar"
            :style="{ 'background-color': therapistProfile.avatar_bg_color }"
            alt="avatar"
          />
          <p class="mb-6 font-semibold text-2xl">{{ therapistProfile.full_name }}</p>
        </div>
        <div class="grid grid-cols-2 w-full">
          <div class="col-span-1 flex flex-col justify-start" :class="arabic ? 'pr-16 sm:pr-4' : 'pl-16 sm:pl-4'">
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.date") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.time") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.duration") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.price") }}</p>
          </div>
          <div class="col-span-1">
            <p class="font-semibold text-base mb-3">{{ getDate }}</p>
            <p class="font-semibold text-base mb-3">
              <span dir="ltr">{{ getTime }}</span>
            </p>
            <p class="font-semibold text-base mb-3">{{ getDuration }}</p>
            <p class="font-semibold text-base mb-3 text-">
              <span :class="{ 'line-through': coupon }" dir="ltr">{{ getPrice }}</span>
              <span v-if="coupon" :class="arabic ? 'mr-2' : 'ml-2'" class="font-semibold text-success" dir="ltr">{{
                getCouponPrice
              }}</span>
            </p>
          </div>
        </div>
        <SecondaryButton
          v-if="!coupon"
          @click="applyCoupon"
          class="mx-auto mt-4 mb-6"
          :label="$t('coupons.i_have_coupon')"
          medium
        >
          <img :class="arabic ? 'ml-2' : 'mr-2'" slot="icon" src="../../../assets/images/icons/gift.svg" alt="gift" />
        </SecondaryButton>
        <CouponState class="mt-4" v-else />
        <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mt-6 mb-4">
          <p class="text-center font-semibold">
            {{ $t("booking_flow.cancel") }}
            <a
              class="text-ayadi-900 no-underline"
              href="https://help.ayadihealth.co/en/articles/4427507-what-is-your-cancellation-and-rescheduling-policy"
              target="_blank"
            >
              {{ $t("booking_flow.cancellation_policy") }}
            </a>
            <span v-if="arabic">{{ $t("booking_flow.cancel_arabic") }}</span>
          </p>
        </div>
        <div class="px-4 py-3 rounded-2xl bg-bgLight w-full">
          <p class="text-center font-semibold">
            {{ $t("booking_flow.privacy") }}
            <a class="text-ayadi-900 no-underline" href="https://ayadihealth.co/privacy-policy/" target="_blank">
              {{ $t("booking_flow.view_privacy") }}
            </a>
            <span v-if="arabic">{{ $t("booking_flow.privacy_arabic") }}</span>
          </p>
        </div>
        <MainButton @click="selectPayment" id="proceed_to_payment" class="mt-6 mx-auto">
          {{ $t("booking_flow.proceed") }}
        </MainButton>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from "vue";
import IconCloseGray from "@/components/icons/IconCloseGray";
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";
import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import CouponState from "@/components/therapist-profile/CouponState";
import { sendAllEvents, getStandardSlotProperties, getTherapistProperties } from "@/utils/events";

export default {
  name: "ProceedToPaymentModal",
  components: { CouponState, SecondaryButton, MainButton, IconCloseGray },

  data() {
    return {
      slot: null,
      isLoading: false,
      paymentDetails: null
    };
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("common", ["currentIPData"]),
    ...mapState("therapistSlots", ["createdOrder"]),
    ...mapState("sessions", ["coupon"]),
    ...mapState("auth", ["user"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    getDate() {
      if (!this.slot) {
        return "";
      }
      return dayjs(this.slot.start).format("dddd, MMM DD");
    },

    getTime() {
      if (!this.slot) {
        return "";
      }
      return dayjs(this.slot.start).format("h:mm A");
    },

    getDuration() {
      if (!this.slot) {
        return "";
      }

      return this.slot.type === "video" ? this.$t("booking_flow.50mins") : this.$t("booking_flow.25mins");
    },

    getPrice() {
      if (!this.slot) {
        return "";
      }

      return this.slot.type === "video"
        ? this.therapistProfile.video_price_object.amount / this.therapistProfile.video_price_object.divider +
            " " +
            this.therapistProfile.video_price_object.code_localized
        : this.therapistProfile.video_small_price_object.amount /
            this.therapistProfile.video_small_price_object.divider +
            " " +
            this.therapistProfile.video_small_price_object.code_localized;
    },

    getCouponPrice() {
      const price = this.coupon.value_object;
      return price.amount / price.divider + " " + price.code_localized;
    }
  },

  methods: {
    async getBookedSlot(value) {
      if (value.params) {
        this.slot = {
          type: value.params.type,
          start: value.params.session_start,
          session_id: value.params.id
        };
        return;
      }
      this.slot = storageClient.getBookingSlot();
    },

    async selectPayment() {
      let price = "";

      if (this.coupon) {
        price = this.coupon.value_object.amount / this.coupon.value_object.divider;
      } else {
        if (this.slot.type === "video") {
          price = this.therapistProfile.video_price_object.amount / this.therapistProfile.video_price_object.divider;
        } else {
          price =
            this.therapistProfile.video_small_price_object.amount /
            this.therapistProfile.video_small_price_object.divider;
        }
      }

      await this.$store.dispatch("sessions/getAvailabilityPaymentMethods", price);

      this.$modal.hide("proceed-to-payment-modal");
      this.$modal.show("selection-payment-modal", {
        addingCard: true,
        cb: (data) => this.createOrder(data),
        price,
        currency: this.therapistProfile?.video_price_object?.currency
      });
    },

    async createOrder(method) {
      if (method === "tabby_link") {
        try {
          const payload = {
            session_id: this.slot.session_id,
            type: "tabby_link"
          };

          if (this.coupon) {
            payload.promo_code = this.coupon.code;
          }

          await this.$store.dispatch("therapistSlots/createOrder", payload);

          if (this.therapistProfile && this.slot) {
            let price = null;

            if (this.slot.type === "video") {
              price =
                this.therapistProfile.video_price_in_kwd_object.amount /
                this.therapistProfile.video_price_in_kwd_object.divider;
            } else {
              price =
                this.therapistProfile.video_small_price_in_kwd_price_object.amount /
                this.therapistProfile.video_small_price_in_kwd_price_object.divider;
            }

            this.$kochava.sendEvent("Checkout Start", {
              name: "view_payment_methods",
              user_id: this.user?.id || null,
              now_date: new Date(),
              content_type: "therapist",
              content_id: this.therapistProfile?.id || null,
              quantity: 1,
              currency: this.therapistProfile?.video_price_in_kwd_object?.currency || null,
              price
            });
          }

          let price;
          if (this.slot.type === "video") {
            price =
              this.therapistProfile.video_price_in_kwd_object.amount /
              this.therapistProfile.video_price_in_kwd_object.divider;
          } else {
            price =
              this.therapistProfile.video_small_price_in_kwd_price_object.amount /
              this.therapistProfile.video_small_price_in_kwd_price_object.divider;
          }

          if (this.therapistProfile && this.slot) {
            const trackData = {
              ...getTherapistProperties(this.therapistProfile),
              ...getStandardSlotProperties(this.slot, price)
            };

            sendAllEvents({ event_name: "continue_payment_click", track_data: trackData });
          }

          window.location.assign(this.createdOrder.url);
        } catch (e) {
          Vue.notify({
            group: "errors",
            text: `Error: ${e}`,
            classes: "toast-error"
          });
          this.$modal.hide("loader-modal");
          this.$modal.hide("card-modal");
          this.$modal.hide("proceed-to-payment-modal");
        }
      } else {
        if (!this.user?.payment_method_source?.payment_id && !this.paymentDetails?.token) {
          this.$modal.hide("loader-modal");
          this.$modal.hide("proceed-to-payment-modal");
          this.$modal.show("card-modal", {
            onBackModal: "selection-payment-modal",
            addingCard: true,
            cb: (data) => this.continueOrder(data)
          });
          return;
        }
        try {
          const payload = {
            session_id: this.slot.session_id,
            type: this.user?.payment_method_source?.payment_id ? "source" : "card_token",
            ...(this.user?.payment_method_source?.payment_id && { token: this.user.payment_method_source.payment_id }),
            ...(this.paymentDetails?.token && { token: this.paymentDetails?.token })
          };

          if (this.coupon) {
            payload.promo_code = this.coupon.code;
          }

          await this.$store.dispatch("therapistSlots/createOrder", payload);

          if (this.therapistProfile && this.slot) {
            let price = null;

            if (this.slot.type === "video") {
              price =
                this.therapistProfile.video_price_in_kwd_object.amount /
                this.therapistProfile.video_price_in_kwd_object.divider;
            } else {
              price =
                this.therapistProfile.video_small_price_in_kwd_price_object.amount /
                this.therapistProfile.video_small_price_in_kwd_price_object.divider;
            }

            this.$kochava.sendEvent("Checkout Start", {
              name: "view_payment_methods",
              user_id: this.user?.id || null,
              now_date: new Date(),
              content_type: "therapist",
              content_id: this.therapistProfile?.id || null,
              quantity: 1,
              currency: this.therapistProfile?.video_price_in_kwd_object?.currency || null,
              price
            });
          }

          let price;
          if (this.slot.type === "video") {
            price =
              this.therapistProfile.video_price_in_kwd_object.amount /
              this.therapistProfile.video_price_in_kwd_object.divider;
          } else {
            price =
              this.therapistProfile.video_small_price_in_kwd_price_object.amount /
              this.therapistProfile.video_small_price_in_kwd_price_object.divider;
          }

          if (this.therapistProfile && this.slot) {
            const trackData = {
              ...getTherapistProperties(this.therapistProfile),
              ...getStandardSlotProperties(this.slot, price)
            };

            sendAllEvents({ event_name: "continue_payment_click", track_data: trackData });
          }

          window.location.assign(this.createdOrder.url);
        } catch (e) {
          Vue.notify({
            group: "errors",
            text: `Error: ${e}`,
            classes: "toast-error"
          });
          this.$modal.hide("loader-modal");
          this.$modal.hide("card-modal");
          this.$modal.hide("proceed-to-payment-modal");
        }
      }
    },

    async continueOrder(data) {
      this.paymentDetails = data;
      this.$modal.hide("card-modal");
      this.$modal.show("loader-modal");
      await this.createOrder();

      return;
    },

    blockScroll() {
      if (this.therapistProfile && this.slot) {
        let price = 0;
        if (this.slot.type === "video") {
          price =
            this.therapistProfile.video_price_in_kwd_object.amount /
            this.therapistProfile.video_price_in_kwd_object.divider;
        } else {
          price =
            this.therapistProfile.video_small_price_in_kwd_price_object.amount /
            this.therapistProfile.video_small_price_in_kwd_price_object.divider;
        }

        this.$kochava.sendEvent("View", {
          name: "view_order_review",
          user_id: this.user?.id || null,
          now_date: new Date(),
          content_type: "therapist",
          content_id: this.therapistProfile.id,
          quantity: 1,
          currency: this.therapistProfile.video_price_in_kwd_object.currency,
          price
        });
      }
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    applyCoupon() {
      this.$modal.hide("proceed-to-payment-modal");
      this.$modal.show("apply-coupon-modal");
    }
  }
};
</script>

<style scoped>
.img-size {
  width: 7.5rem;
  height: 7.5rem;
}
</style>

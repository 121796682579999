<template>
  <div class="flex flex-col justify-end items-center text-white mb-4 row-start-5">
    <img v-if="!noImage" src="../assets/images/Authorization/hippa.png" alt="hippa" />
    <div
      @click="$modal.show('language')"
      class="mt-3 flex flex-row cursor-pointer"
      :class="{ 'flex-row-reverse': arabic }"
    >
      <img class="mr-1" :class="{ 'mr-0 ml-1': arabic }" src="../assets/images/IconGlobe.svg" alt="Globe" />
      <p class="underline font-semibold">{{ arabic ? "العربية" : "Eng(US)" }}</p>
    </div>
    <p :dir="arabic ? 'rtl' : 'ltr'" class="my-2">
      {{ $t("auth.all_right_reserved", { year: getYear }) }}
      <span class="ml-1" dir="rtl" v-if="arabic">{{ " © " + getYear }}</span>
      V{{ getAppVersion }}
    </p>
    <p>
      <a class="text-white no-underline mx-2" href="https://ayadihealth.co/terms-of-use/" target="_blank">
        {{ $t("auth.terms_of_use") }}
      </a>
      <a class="text-white no-underline mx-2" href="https://ayadihealth.co/privacy-policy" target="_blank">
        {{ $t("auth.privacy_policy") }}
      </a>
    </p>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";
export default {
  name: "FooterInfo",
  props: {
    noImage: Boolean
  },
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    getYear() {
      return dayjs().year();
    },
    getAppVersion() {
      return process.env.VUE_APP_VERSION;
    }
  }
};
</script>

<style scoped></style>

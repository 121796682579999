<template>
  <div
    class="bg-white w-full rounded-2xl profile-section-shadow flex item-wrapper"
    :dir="direction"
    v-if="data.avatar"
    :id="getTherapistId"
  >
    <div class="avatarWrapper">
      <!-- <div
        class="relative video-wrapper video-h rounded-2xl overflow-hidden mt-2"
        v-if="data.video_preview_ar || data.video_preview_en"
      >
        <div @click="openTherapist()" class="profileWithVideo">
          <img :src="data.avatar" class="rounded-2xl profileImg" />
          <img src="../../assets/images/video/play_button.svg" class="playBtn" />
        </div>
      </div> -->
      <img
        :src="data.avatar"
        class="rounded-2xl cursor-pointer"
        :style="{ 'background-color': data.avatar_bg_color }"
        @click="openTherapist()"
      />
      <div class="mobileContent" :dir="direction">
        <div class="font-semibold text-lg cursor-pointer" @click="openTherapist()">
          {{ data.full_name }}
        </div>
        <div class="font-semibold text-sm member-since">
          {{ $t("profiles.member_since_short", { date: registrationDate }) }}
        </div>
        <div class="descriptionWrapper">
          <div class="descriptionItem">{{ getLanguages() }}</div>
          <div class="descriptionItem">{{ getPrice() }}</div>
          <div class="descriptionItem">{{ getExperience() }}</div>
        </div>
      </div>
    </div>
    <div v-if="data.speciality" class="flex mt-4 flex-wrap mt-2 specialityMobile">
      <Chip v-for="speciality in data.speciality" :key="speciality" class="chip mr-2 mt-2">{{ speciality }}</Chip>
    </div>
    <div class="continueButton">
      <MainButton class="items-center h-10" @click="openTherapist()"
        ><span class="text-white no-underline continue-btn">{{ $t("filter.view_profile") }} </span></MainButton
      >
    </div>
    <div class="ml-6 w-full content-wrapper">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="font-semibold text-lg cursor-pointer" @click="openTherapist()">
            {{ data.full_name }}
          </div>
          <div class="font-semibold text-sm member-since">
            {{ $t("profiles.member_since_short", { date: registrationDate }) }}
          </div>
        </div>

        <a :href="getProfileLink" v-on:click.prevent.stop="openTherapist()" @click="openTherapist()">
          <MainButton class="items-center h-10"
            ><span class="text-white no-underline continue-btn">{{ $t("filter.view_profile") }} </span></MainButton
          >
        </a>
      </div>
      <div class="mt-2">
        <div class="flex flex-col">
          <div class="meta" :dir="direction">
            <div class="item">{{ getLanguages() }}</div>
            <div class="item">{{ getPrice() }}</div>
            <div class="item">{{ getExperience() }}</div>
          </div>
          <div v-if="data.speciality" class="flex mt-4 flex-wrap mt-2 specialityWrapper">
            <Chip v-for="speciality in data.speciality" :key="speciality" class="chip mr-2 mt-2">{{ speciality }}</Chip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

import storageClient from "@/services/storageClient";
import MainButton from "@/components/ui-kit/MainButton";
import Chip from "@/components/ui-kit/Chip";
import { capitalize } from "@/utils/text";

export default {
  name: "TherapistListItem",
  components: { MainButton, Chip },

  props: {
    data: Object
  },
  data() {
    return {
      videoID: null,
      activeVideo: false
    };
  },
  computed: {
    ...mapState("common", ["currencies"]),
    ...mapState("therapistFilterDynamic", ["page"]),
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    getTherapistId() {
      return `therapist-${this.data.id}`;
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    currency() {
      return storageClient.getCurrency();
    },

    registrationDate() {
      return dayjs(this.data.registration_date).format("MMM YYYY");
    },

    languages() {
      const langs = this.therapistProfile.languages.map((l) => {
        return l.charAt(0).toUpperCase() + l.slice(1);
      });
      return langs;
    },

    getProfileLink() {
      return `${this.arabic ? "/ar" : "/en"}/therapists/${this.data.id}--${this.data?.full_name.replace(" ", "-")}`;
    },

    getCurrencyCode() {
      if (this.currencies) {
        const selectedCurrency = this.currencies.find((item) => item.to === this.currency);
        return selectedCurrency?.code_localized;
      }
      return "";
    }
  },
  methods: {
    getLanguages() {
      return capitalize(this.data.language, this.arabic);
    },

    getPrice() {
      const price = this.data?.from_price;
      if (price) {
        return `${price} ${this.getCurrencyCode}`;
      }
      return "";
    },

    getExperience() {
      if (this.data?.experience) {
        if (this.arabic) {
          return `${this.data.experience} ${
            this.data.experience > 10 ? this.$t("filter.experience.more") : this.$t("filter.experience.less")
          }`;
        }
        return `${this.data.experience} ${this.$t("filter.experience.title")}`;
      }
      return "";
    },

    async openTherapist() {
      await this.$store.dispatch("common/setLoadingApp", true);
      const therapistInfo = {
        id: this.data.id,
        page: this.page
      };

      await this.$store.dispatch("therapistFilterDynamic/setLastEnteredTherapist", therapistInfo);
      if (this.data?.id && this.data?.full_name) {
        await this.$router
          .push(`${this.arabic ? "/ar" : "/en"}/therapists/${this.data.id}--${this.data?.full_name.replace(" ", "-")}`)
          .catch(() => {})
          .finally(async () => await this.$store.dispatch("common/setLoadingApp", false));
        return;
      }
      await this.$store.dispatch("common/setLoadingApp", false);
      return null;
    },

    playVideo() {
      this.activeVideo = true;
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.member-since {
  color: #6f7786;
}

.item-wrapper {
  padding: 0.5em 1.5em 0.5em 0.5em;

  &:not(:first-child) {
    margin: 1em 0;
  }

  .content-wrapper {
    padding: 0.375em 0;
    margin-left: 1.5rem;

    @include for-phone {
      display: none;
    }
  }

  &[dir="rtl"] {
    padding: 0.5em 0.5em 0.5em 1.5em;

    .content-wrapper {
      margin-right: 1.5rem;
      margin-left: 0;
    }

    @include for-phone {
      padding: 0.5em;
    }
  }

  img {
    height: 140px;
    width: 140px;
    object-fit: cover;
  }

  @include for-phone {
    flex-direction: column;
    padding: 0.5em;
  }
}

.continue-btn {
  width: 155px;
  align-items: center;
}

.meta {
  display: flex;

  &[dir="ltr"] {
    .item:not(:first-child) {
      margin-left: 1em;
    }
    .item:not(:last-child):after {
      content: "\A";
      width: 0.375em;
      height: 0.375em;
      border-radius: 50%;
      background: #939aa8;
      display: inline-block;
      margin-left: 1em;
      margin-bottom: 0.125em;
    }
  }
  &[dir="rtl"] {
    .item:not(:first-child) {
      margin-right: em;
    }
    .item:not(:last-child):after {
      content: "\A";
      width: 0.375em;
      height: 0.375em;
      border-radius: 50%;
      background: #939aa8;
      display: inline-block;
      margin-right: 1em;
      margin-left: 1em;
      margin-bottom: 0.125em;
    }
  }
}

.chip {
  color: #44a4a5;
}

.specialityMobile {
  display: none;

  @include for-phone {
    display: flex;
    max-height: 182px;
    overflow: hidden;
  }
}

.continueButton {
  display: none;

  @include for-phone {
    display: flex;
    margin-top: 1rem;

    button {
      width: 100%;
    }
  }
}

.video-wrapper {
  width: 140px;
  height: 140px;

  @include for-phone {
    min-width: 140px;
  }
}

.profileWithVideo {
  width: 140px;
  height: 140px;
  position: relative;
  cursor: pointer;

  .profileImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .playBtn {
    position: absolute;
    width: 60px;
    height: 43px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.specialityWrapper {
  max-height: 90px;
  overflow: hidden;
}

.mobileContent {
  display: none;

  @include for-phone {
    display: block;

    &[dir="ltr"] {
      margin-left: 12px;
    }

    &[dir="rtl"] {
      margin-right: 12px;
    }
  }
}

.avatarWrapper {
  min-width: 140px;

  @include for-phone {
    display: flex;
    flex-direction: row;
  }
}

.descriptionWrapper {
  @include for-phone {
    margin-top: 8px;
    .descriptionItem {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>

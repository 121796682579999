<template>
  <modal
    @before-open="initBeforeOpen"
    width="360"
    name="sign-in-modal"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white px-8 py-6 flex flex-col justify-center items-center rounded-lg">
      <div class="flex justify-between items-start w-full mb-4" :class="{ 'flex-row-reverse': arabic }">
        <p class="text-18 font-semibold" :dir="arabic ? 'rtl' : 'ltr'">{{ $t("booking_flow.welcome_back") }}</p>
        <div class="cursor-pointer px-1 py-1" @click="$modal.hide('sign-in-modal')">
          <IconCloseGray />
        </div>
      </div>

      <div class="w-full">
        <InputWrapper :class="{ 'items-end': arabic }" :label="$t('auth.email')" :error-message="emailErrorText">
          <input :dir="arabic ? 'rtl' : 'ltr'" slot="input" type="text" @focus="emailErrorText = ''" v-model="email" />
        </InputWrapper>

        <InputWrapper
          class="w-full mb-1"
          :class="{ 'items-end': arabic }"
          :label="$t('auth.password')"
          :error-message="passwordErrorText"
        >
          <PasswordInput
            :dir="arabic ? 'rtl' : 'ltr'"
            v-model="password"
            @focus="passwordErrorText = ''"
            slot="input"
          />
        </InputWrapper>
        <p
          @click="forgotPassword"
          class="font-semibold text-xs text-ayadi-900 mb-4 cursor-pointer"
          :class="arabic ? 'text-left' : 'text-right'"
        >
          {{ $t("auth.forgot_password") }}
        </p>
      </div>
      <MainButton @click="signIn" class="w-full" type="medium">{{ $t("auth.sign_in") }}</MainButton>

      <div class="flex justify-between items-center w-full my-2" v-if="showSocialDivider">
        <div class="bg-lightGray h-px w-40"></div>
        <p class="text-lightGray font-semibold w-full text-center mx-2">{{ $t("auth.continue") }}</p>
        <div class="bg-lightGray h-px w-40"></div>
      </div>
      <div class="flex items-stretch w-full space-x-2" v-if="showSocialDivider">
        <div
          v-if="preflight && !preflight.disable_facebook_login"
          class="h-10 w-full bg-facebook rounded-md flex justify-center items-center cursor-pointer"
          @click="FacebookAuth"
        >
          <img src="../../../assets/images/facebook.svg" alt="facebook" />
        </div>
        <div
          v-if="preflight && !preflight.disable_google_login"
          class="h-10 w-full bg-google rounded-md flex justify-center items-center cursor-pointer"
          @click="GoogleAuth"
        >
          <img src="../../../assets/images/google.svg" alt="google" />
        </div>
        <div
          v-if="preflight && !preflight.disable_apple_login"
          class="h-10 w-full bg-apple rounded-md flex justify-center items-center cursor-pointer"
          @click="AppleAuth"
        >
          <img src="../../../assets/images/apple.svg" alt="apple" />
        </div>
      </div>
      <p class="mt-6">
        {{ $t("auth.dont_have_account") }}
        <span @click="openSignUpModal" class="text-ayadi-900 cursor-pointer font-semibold">{{
          $t("auth.sign_up")
        }}</span>
      </p>
    </div>
  </modal>
</template>

<script>
import Vue from "vue";
import IconCloseGray from "@/components/icons/IconCloseGray";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import PasswordInput from "@/components/ui-kit/PasswordInput";
import storageClient from "@/services/storageClient";
import MainButton from "@/components/ui-kit/MainButton";
import store from "@/store";
import { mapState } from "vuex";
import { setTrackingDevice } from "@/axios";
import dayjs from "dayjs";

export default {
  name: "SignInModal",
  components: { MainButton, PasswordInput, IconCloseGray, InputWrapper },

  data() {
    return {
      email: "",
      password: "",
      emailErrorText: "",
      passwordErrorText: ""
    };
  },

  computed: {
    ...mapState("auth", ["user", "notifications"]),
    ...mapState("common", ["preflight"]),
    ...mapState("therapistSlots", ["showNextModal"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    showSocialDivider() {
      if (!this.preflight) return false;
      if (
        this.preflight?.disable_facebook_login &&
        this.preflight?.disable_google_login &&
        this.preflight?.disable_apple_login
      )
        return false;

      return true;
    }
  },

  methods: {
    forgotPassword() {
      this.$modal.hide("sign-in-modal");
      this.$modal.show("forgot-password-modal");
    },

    openSignUpModal() {
      this.$modal.hide("sign-in-modal");
      this.$modal.show("sign-up-select-auth-modal");
    },

    async signIn() {
      if (this.email.length < 5) {
        this.emailErrorText = this.$t("auth.email_required");
      }
      if (this.password.length < 8) {
        this.passwordErrorText = this.$t("auth.password_required");
      }
      if (!this.passwordErrorText && !this.emailErrorText) {
        await this.$store.dispatch("auth/login", { email: this.email, password: this.password });
        this.analytics();

        this.$modal.hide("sign-in-modal");
        this.toNextModal();
      }
    },

    analytics() {
      const language = storageClient.getLanguage() || "en";

      //WebEngage Analytics Identify
      if (this.user) {
        if (this.user?.id) {
          window.webengage.user.login(this.user?.id);
        }

        window.webengage.user.setAttribute("name", this.user.name);
        window.webengage.user.setAttribute("we_first_name", this.user.name);
        window.webengage.user.setAttribute("we_email", this.user.email);
        window.webengage.user.setAttribute("User type", "Client");
        window.webengage.user.setAttribute("we_gender", this.user.gender);
        window.webengage.user.setAttribute("Country", this.user?.country);
        window.webengage.user.setAttribute("Language", language);
        window.webengage.user.setAttribute("we_birth_date", this.user.birth_date);
        window.webengage.user.setAttribute(
          "Enabled Push Notification",
          this.notifications.is_push_notification_enabled
        );
        window.webengage.user.setAttribute(
          "Enabled eMail Notifications",
          this.notifications.is_email_notification_enabled
        );
        window.webengage.user.setAttribute("Registration_Date", this.user.registration_date);
        window.webengage.user.setAttribute("VideoSessionsBooked", this.user.video_orders_count);
        window.webengage.user.setAttribute("VideoSmallSessionsBooked", this.user.video_small_orders_count);
        window.webengage.user.setAttribute("$name", this.user.name);
        window.webengage.user.setAttribute("ChatSessionsBooked", this.user.chat_orders_count);
        window.webengage.user.setAttribute("Device type", "Web");
        window.webengage.user.setAttribute("birthday", this.user.birth_date);
        window.webengage.user.setAttribute("Birthday", this.user.birth_date);
        window.webengage.user.setAttribute("email", this.user.email);
        window.webengage.user.setAttribute("$email", this.user.email);
        window.webengage.user.setAttribute("$created", this.user.registration_date);
        window.webengage.user.setAttribute("Platform", "web");
        window.webengage.user.setAttribute("createdAt", this.user.registration_date);
        window.webengage.user.setAttribute("gender", this.user.gender);
        window.webengage.user.setAttribute("Gender", this.user.gender);
        if (this.user?.id) {
          window.webengage.user.setAttribute("userId", this.user.id);
          //Mixpanel Analytics Identify
          this.$mixpanel.identify(this.user?.id || "");
        }

        this.$mixpanel.people.set({
          $name: this.user.name,
          $email: this.user.email,
          "User type": "Client",
          Gender: this.user.gender,
          Country: this.user.country,
          Language: language,
          Birthdate: this.user.birth_date,
          "Enabled Push Notification": this.notifications.is_push_notification_enabled,
          "Enabled eMail Notifications": this.notifications.is_email_notification_enabled,
          Registration_Date: new Date(this.user.registration_date.replace(/-/g, "/")).toISOString(),
          VideoSessionsBooked: this.user.video_orders_count,
          VideoSmallSessionsBooked: this.user.video_small_orders_count,
          ...(this.user?.id && { userId: this.user.id }),
          Name: this.user.name
        });

        // Event Tracking

        const trackData = {
          referrer: document.referrer,
          Language: language,
          "Device type": "Web",
          email: this.user.email,
          time: dayjs().unix()
        };

        window.webengage.track("signIn_click", trackData);
        this.$mixpanel.track("signIn_click", trackData);
        this.$intercom.trackEvent("signIn_click", trackData);
      }
    },

    async GoogleAuth() {
      let googleUser;
      try {
        googleUser = await this.$gAuth.signIn();

        const response = googleUser.getAuthResponse();

        await store.dispatch("auth/socialAuth", {
          driver: "google",
          token: response.access_token,
          lang: storageClient.getLanguage()
        });
        await this.$store.dispatch("auth/getProfile");
        this.analytics();
        this.$modal.hide("sign-in-modal");
        this.toNextModal();
      } catch (e) {
        // TODO add $notify
        console.error(e);
        return e;
      }
    },

    async AppleAuth() {
      try {
        window.AppleID.auth.init({
          clientId: process.env.VUE_APP_APPLE_APP_ID,
          redirectURI: `https://${process.env.VUE_APP_HOST}/apple/sign-in/apple-redirect`,
          scope: process.env.VUE_APP_APPLE_AUTH_SCOPE || "",
          usePopup: true //or false defaults to false
        });

        let data = await window.AppleID.auth.signIn();
        await store.dispatch("auth/socialAuth", {
          driver: "apple",
          token: data.authorization.id_token,
          lang: storageClient.getLanguage()
        });
        await this.$store.dispatch("auth/getProfile");

        this.analytics();
        this.$modal.hide("sign-in-modal");
        this.toNextModal();
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async toNextModal() {
      await setTrackingDevice();

      if (this.showNextModal?.modal) {
        this.$modal.show(this.showNextModal?.modal, this.showNextModal?.additionalInfo);
        return;
      }
      this.$modal.show("proceed-to-payment-modal");
    },

    async FacebookAuth() {
      window.sessionStorage.clear();
      if (window?.cookieStore) {
        await window?.cookieStore?.getAll().then((cookies) =>
          cookies.forEach((cookie) => {
            window.cookieStore.delete(cookie.name);
          })
        );
      }

      await this.loadFacebookSDK(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID, //You will need to change this
          cookie: true, // This is important, it's not enabled by default,
          scope: "public_profile,email",
          version: "v13.0"
        });

        window.FB.getLoginStatus(async function (response) {
          try {
            if (response.status === "connected") {
              await store.dispatch("auth/socialAuth", { driver: "facebook", token: response.authResponse.accessToken });
            } else {
              await window.FB.login(async function (response) {
                if (response.authResponse) {
                  await store.dispatch("auth/socialAuth", {
                    driver: "facebook",
                    token: response.authResponse.accessToken,
                    lang: storageClient.getLanguage()
                  });

                  setTimeout(async () => {
                    this.analytics();
                    this.$modal.hide("sign-in-modal");
                    this.toNextModal();
                  }, 1000);
                } else {
                  Vue.notify({
                    group: "errors",
                    text: "User cancelled login or did not fully authorize",
                    classes: "toast-error"
                  });
                }
              });
            }
          } catch (e) {
            console.error(e);
          }
        });
      };
    },

    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID, //You will need to change this
          cookie: true, // This is important, it's not enabled by default,
          scope: "public_profile,email",
          version: "v13.0"
        });
      };
    },

    async loadFacebookSDK(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    async initBeforeOpen() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      window.webengage.user.setAttribute("User type", "Client");
    }
  }
};
</script>

<style scoped></style>

<template>
  <div class="h-full flex justify-center items-center">
    <div
      class="col-start-4 col-span-2 row-span-1 flex flex-row justify-between items-start bg-white modal-width rounded-md p-6 relative"
    >
      <div class="absolute logo-position">
        <LogoLight />
      </div>
      <div class="flex flex-col justify-start items-center">
        <p class="text-18 font-semibold">{{ $t("not_available.will_be") }}</p>
        <img class="my-4" src="../../assets/images/NotAvailable.svg" alt="not available" />

        <p class="mb-3">
          {{ $t("not_available.gulf_region") }}
        </p>

        <p class="mb-4">
          {{ $t("not_available.working") }}
        </p>
        <MainButton
          ><a class="text-white no-underline" href="https://www.instagram.com/ayadihealth/">{{
            $t("not_available.follow")
          }}</a></MainButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import LogoLight from "@/components/ui-kit/LogoLight";
import MainButton from "@/components/ui-kit/MainButton";
export default {
  name: "NotAvailableInCountry",
  components: { MainButton, LogoLight }
};
</script>

<style scoped>
.modal-width {
  width: 430px;
}
</style>

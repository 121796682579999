<template>
  <svg
    @click="$emit('click')"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2501 0.758187C10.9251 0.433187 10.4001 0.433187 10.0751 0.758187L6.0001 4.82485L1.9251 0.749854C1.6001 0.424854 1.0751 0.424854 0.750098 0.749854C0.425098 1.07485 0.425098 1.59985 0.750098 1.92485L4.8251 5.99985L0.750098 10.0749C0.425098 10.3999 0.425098 10.9249 0.750098 11.2499C1.0751 11.5749 1.6001 11.5749 1.9251 11.2499L6.0001 7.17485L10.0751 11.2499C10.4001 11.5749 10.9251 11.5749 11.2501 11.2499C11.5751 10.9249 11.5751 10.3999 11.2501 10.0749L7.1751 5.99985L11.2501 1.92485C11.5668 1.60819 11.5668 1.07485 11.2501 0.758187Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconClose"
};
</script>

<style scoped lang="scss">
svg {
  @apply transition-all duration-200;
  path {
    fill: #e0e0e0;
  }
  &:hover {
    path {
      fill: #44a4a5;
    }
  }
}
</style>

<template>
  <div class="bg-white w-full rounded-2xl profile-section-shadow p-6 flex justify-between" :dir="direction">
    <div class="flex flex-col justify-start items-start">
      <p class="text-18 font-semibold mb-2">{{ $t("profiles.want_to_book", { name: therapistProfile.full_name }) }}</p>
      <p class="text-textGray-500 mb-8">
        {{ $t("profiles.check_availability_and_book") }}
      </p>
      <MainButton id="book_session_bottom" @click="openSlots" class="mb-8" :disabled="btnIsActive">{{
        $t("profiles.book")
      }}</MainButton>
    </div>
    <img
      class="h-40 w-40 rounded-2xl sm:h-20 sm:w-20"
      :class="arabic ? 'sm:mr-4' : 'sm:ml-4'"
      :src="therapistProfile.avatar"
      :style="{ 'background-color': therapistProfile.avatar_bg_color }"
      :alt="therapistProfile.full_name + ' avatar'"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainButton from "@/components/ui-kit/MainButton";
import storageClient from "@/services/storageClient";
import { checkAvailabilityPackage } from "@/utils/packages";

export default {
  name: "TherapistCTA",
  components: { MainButton },
  computed: {
    ...mapState("sessions", ["bookedPackages", "loadingPackages"]),
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("auth", ["user"]),
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    btnIsActive() {
      if (!this.user) {
        return false;
      }
      if (this.loadingPackages) return true;
      return false;
    }
  },
  methods: {
    async openSlots() {
      window.gtag("event", "therapistProfile_bookASession_click");

      if (!this.user) {
        this.$modal.show("booking-options-modal");
        return;
      }

      const hasValidPackages = await checkAvailabilityPackage(this.therapistProfile.id, this.bookedPackages);

      if (hasValidPackages) {
        await this.$store.dispatch("sessions/setBookedPackagesForTherapist", hasValidPackages);

        this.$modal.show("therapist-availability");
      } else {
        await this.$store.dispatch("sessions/setBookedPackagesForTherapist", null);
        this.$modal.show("booking-options-modal");
      }
    }
  }
};
</script>

<style scoped></style>

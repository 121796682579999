<template>
  <button
    class="bg-ayadi-900 text-center block text-white font-semibold text-sm transition-all duration-200 flex flex-row justify-center focus:outline-none hover:bg-ayadi-800 focus:bg-ayadi-800 active:outline-none"
    :class="({ 'cursor-default': disabled }, buttonSizeClass)"
    @click="$emit('click')"
    :disabled="disabled"
  >
    <slot name="icon" class="ml-2"></slot>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    type: {
      type: String,
      default: "large"
    },
    disabled: Boolean
  },

  computed: {
    buttonSizeClass() {
      return "-" + this.type;
    }
  }
};
</script>

<style scoped>
.-large {
  @apply px-6;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
}
.-medium {
  @apply px-4 py-2;
  border-radius: 4px;
}

.-small {
  @apply px-2 py-1;
  border-radius: 4px;
}

button:disabled {
  @apply bg-formStroke;
}
</style>

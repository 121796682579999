<template>
  <div class="w-full">
    <div class="w-full flex mt-4 justify-between items-baseline controlWrapper" :dir="direction">
      <div class="flex sm:grid sm:grid-cols-2 sm:gap-4 sm:w-full buttonsWrapper">
        <div @click="openFilterModal" class="filter-btn col-span-1 hover:bg-gray-100" id="filter" :dir="direction">
          <img src="../../assets/images/icons/filter.svg" alt="filter" />
          {{ $t("filter.title") }}
          <div class="filter-btn-count">
            <span>{{ activeFilters.length }}</span>
          </div>
        </div>
        <div
          v-if="!searchIsActive"
          class="filter-btn search-btn col-span-1 hover:bg-gray-100"
          id="search"
          :dir="direction"
          @click="activeSearch"
        >
          <img src="../../assets/images/icons/search.svg" alt="search" />
          {{ $t("filter.search") }}
        </div>
        <div v-else-if="!isMobile()">
          <InputWrapper
            :placeholder="$t('booking_flow.real_name')"
            class="searchInputWrapper"
            :class="{ rtl: arabic }"
            showDeletingIcon
            showSearchIcon
            @onClear="onClose"
          >
            <input
              :dir="arabic ? 'rtl' : 'ltr'"
              autofocus
              type="text"
              slot="input"
              ref="input"
              :placeholder="$t('filter.search_by_condition')"
              v-on:input="debounceInput"
              class="searchInput"
              v-model="searchModel"
            />
          </InputWrapper>
        </div>
      </div>
      <div v-if="isMobile() && searchIsActive" class="w-full">
        <InputWrapper
          :placeholder="$t('booking_flow.real_name')"
          class="searchInputWrapper mobile"
          :class="{ rtl: arabic }"
          showDeletingIcon
          showSearchIcon
          @onClear="onClose"
        >
          <input
            :dir="arabic ? 'rtl' : 'ltr'"
            autofocus
            type="text"
            slot="input"
            ref="input"
            :placeholder="$t('filter.search_by_condition')"
            v-on:input="debounceInput"
            class="searchInput"
            v-model="searchModel"
          />
        </InputWrapper>
      </div>
      <SortingMenu />
    </div>
    <TherapistFilterModalDynamic />
    <AdditionalFilterModal />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import _ from "lodash";

import store from "@/store";
import storageClient from "@/services/storageClient";
import TherapistFilterModalDynamic from "@/components/modals/TherapistFilterModalDynamic";
import AdditionalFilterModal from "@/components/modals/AdditionalFilterModal";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import SortingMenu from "@/components/therapists/SortingMenu";
import { getFilterEvents } from "@/utils/filter";
import { getFilterComponent, getFilterInitialValue } from "../../utils/filter";

export default {
  name: "TherapistFilter",

  components: {
    TherapistFilterModalDynamic,
    InputWrapper,
    SortingMenu,
    AdditionalFilterModal
  },

  props: {
    data: Object
  },

  data() {
    return {
      therapistName: "",
      searchIsActive: false,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    searchModel: {
      get() {
        return this.search;
      },
      set(val) {
        this.therapistName = val;
      }
    },
    ...mapState("therapistFilterDynamic", [
      "activeFilters",
      "filterOptions",
      "search",
      "totalTherapists",
      "sort_type",
      "sort_order"
    ]),
    ...mapState("auth", ["user"])
  },
  methods: {
    openFilterModal() {
      this.$modal.show("therapist-filter-modal-dynamic");
      return;
    },

    async setToDefault() {
      if (this.filterOptions.length === 0) {
        const { data } = await axios.get("/api/client/filters/therapists", {
          params: { currency: this.currency || "KWD" }
        });

        const fetchedFilters = data.filters.map((filter) => {
          return {
            component: getFilterComponent(filter),
            key: filter.q_param,
            value: getFilterInitialValue(filter),
            data: filter
          };
        });

        const defaultValues = JSON.parse(JSON.stringify(fetchedFilters));
        const initialValues = JSON.parse(JSON.stringify(fetchedFilters));

        await this.$store.dispatch("therapistFilterDynamic/setFilterOptions", fetchedFilters);
        await this.$store.dispatch("therapistFilterDynamic/setFilterOptionsApplied", initialValues);
        await this.$store.dispatch("therapistFilterDynamic/setDefaultValuesFilter", defaultValues);
        const oderByDefault = data.sort_options.find((item) => item.is_default === true);

        await this.$store.dispatch("therapistFilterDynamic/selectOrder", oderByDefault);
        await this.$store.dispatch("therapistFilterDynamic/setOrderOptions", data.sort_options);
      }
    },
    activeSearch() {
      this.searchIsActive = true;

      setTimeout(() => {
        if (this.$refs && this.$refs?.input) {
          this.$refs.input.focus();
        }
      }, 500);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    isMobile() {
      return this.windowWidth < 637;
    },
    async onClose() {
      window.webengage.track("therapistList_searchReset_click");
      this.$mixpanel.track("therapistList_searchReset_click");
      this.$intercom.trackEvent("therapistList_searchReset_click");

      this.therapistName = "";
      this.searchIsActive = false;

      if (this.search) {
        await store.dispatch("therapistFilterDynamic/setSearch", "");
        await store.dispatch("therapistFilterDynamic/fetchTherapists", 1);
      }
    },
    debounceInput: _.debounce(async function (e) {
      this.$kochava.sendEvent("Search", {
        name: "search",
        user_id: this.user?.id || null,
        now_date: new Date()
      });

      await store.dispatch("therapistFilterDynamic/setSearch", e.target.value);
      await store.dispatch("therapistFilterDynamic/fetchTherapists", 1);

      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_submit", trackData);
      this.$mixpanel.track("therapistFilters_submit", trackData);
      this.$intercom.trackEvent("therapistFilters_submit", trackData);
    }, 500)
  },

  async created() {
    this.setToDefault();
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.searchInputWrapper {
  width: 300px;
  height: 40px;

  position: relative;

  @include for-not-phone {
    margin-left: 16px;

    &.rtl {
      margin-right: 16px;
    }
  }

  .searchInput {
    padding-right: 35px;
    padding-left: 44px;
  }

  @include for-phone {
    width: 100%;
  }

  &.mobile {
    max-width: none;
    margin-left: inherit;
  }
}
.filter-btn {
  border: 1px solid #354052;
  color: #354052;
  background: #f8f9fa;
  filter: drop-shadow(0px 7px px 20px rgba(0, 0, 0, 0.07));
  border-radius: 4px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 24px 10px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  @include for-phone {
    &:not(:last-child) {
      margin-bottom: 0em;
    }
  }

  .filter-btn-count {
    position: absolute;
    top: -8px;
    right: -7px;
    width: 22px;
    height: 22px;
    color: white;
    background: #44a4a5;
    border: 1px solid white;
    border-radius: 50%;
    text-align: center;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  img {
    width: 14px;
    height: 14px;
  }

  &.search-btn {
    @include for-phone {
      white-space: nowrap;
    }
  }

  &[dir="ltr"] {
    img {
      margin-right: 8px;
    }

    @include for-not-phone {
      &.search-btn {
        margin-left: 16px;
      }
    }
  }

  &[dir="rtl"] {
    img {
      margin-left: 8px;
    }

    @include for-not-phone {
      &.search-btn {
        margin-right: 16px;
      }
    }
  }
}

.controlWrapper {
  @include for-phone {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 12px;
  }
}

.buttonsWrapper {
  @include for-phone {
    margin-bottom: 9px;
  }
}
</style>

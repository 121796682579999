<template>
  <div>
    <DropdownMenu
      v-model="filter"
      :selectedByDefault="getSelectedSort()"
      :options="sortOptions"
      @onOpen="onOpen"
      v-if="sortOptions.length > 0"
    >
      <div>
        {{ $t("filter.sort.sorted_by") }} <span class="text-ayadi-900">{{ filter }} </span>
      </div></DropdownMenu
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import storageClient from "@/services/storageClient";
import DropdownMenu from "../ui-kit/DropdownMenu.vue";

export default {
  name: "SortingMenu",
  data() {
    return {
      filter: ""
    };
  },
  computed: {
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    ...mapState("therapistFilterDynamic", ["sortOptions", "sort_order", "sort_by"])
  },
  methods: {
    onOpen() {
      const trackData = {
        "Sorted by": this.getSelectedSort()
      };

      window.webengage.track("therapistList_sorting_view", trackData);
      this.$mixpanel.track("therapistList_sorting_view", trackData);
      this.$intercom.trackEvent("therapistList_sorting_view", trackData);
    },
    getSelectedSort() {
      const option = this.sortOptions.find(
        (item) => item.sort_by === this.sort_by && item.sort_order === this.sort_order
      );
      return option.title;
    }
  },
  watch: {
    async filter() {
      const selectedOrderOption = this.sortOptions.find((item) => item.title === this.filter);
      await store.dispatch("therapistFilterDynamic/selectOrder", selectedOrderOption);
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapists", 1);
    }
  },
  components: { DropdownMenu }
};
</script>

<style lang="scss" scoped>
.sortText {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  span {
    @apply text-ayadi-900;

    &[dir="ltr"] {
      margin-left: 3px;
    }

    &[dir="rtl"] {
      margin-right: 3px;
    }
  }
}
</style>

<template>
  <div class="bg-bgLight min-h-screen">
    <NavBar :showTherapist="true" />

    <div class="fit-width-content flex flex-col justify-start items-center mx-auto" :dir="arabic ? 'rtl' : 'ltr'">
      <h2 class="mt-6 mb-4 font-semibold text-2xl sm:text-xl sm:text-center">
        {{ $t("booking_flow.looking_forward", { name: getTherapistName }) }}
      </h2>

      <div class="payment-block-width bg-white rounded-2xl p-6 custom-shadow mb-4">
        <div class="w-full px-4 py-3 rounded-2xl bg-bgLight flex flex-row justify-between items-start mb-6">
          <img
            class="rounded-lg h-16 w-16"
            :class="arabic ? 'ml-5' : 'mr-5'"
            :style="{ 'background-color': therapistProfile.avatar_bg_color }"
            :src="getAvatar"
            alt="therapist-avatar"
          />
          <div class="w-full font-semibold" :class="arabic ? 'text-right' : 'text-left'" :dir="arabic ? 'rtl' : 'ltr'">
            <p>"{{ $t("booking_flow.see_you", { date: getDescDate, time: getDescTime }) }}</p>
            <p class="my-6">{{ $t("booking_flow.will_be_able") }}</p>
            <p>{{ $t("booking_flow.day_before") }}”</p>
          </div>
        </div>

        <div class="grid grid-cols-2 w-full">
          <div class="col-span-1 flex flex-col justify-start" :class="arabic ? 'pr-16 sm:pr-10' : 'pl-16 sm:pl-10'">
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.date") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.time") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.duration") }}</p>
            <p class="text-lightGray text-base mb-3">{{ $t("booking_flow.price") }}</p>
          </div>
          <div class="col-span-1">
            <p class="font-semibold text-base mb-3">{{ getDate }}</p>
            <p class="font-semibold text-base mb-3">
              <span dir="ltr">{{ getTime }}</span>
            </p>
            <p class="font-semibold text-base mb-3">{{ getDuration }}</p>
            <p class="font-semibold text-base mb-3">
              <span :class="{ 'line-through': order.promo_code }" dir="ltr">{{ getPrice }}</span>
              <span
                v-if="order.promo_code"
                :class="arabic ? 'mr-2' : 'ml-2'"
                class="font-semibold text-success"
                dir="ltr"
                >{{ order.amount_object.amount / order.amount_object.divider }}
                {{ order.amount_object.code_localized }}</span
              >
            </p>
          </div>
        </div>
        <div class="flex flex-col items-center" v-if="order.promo_code">
          <div class="flex justify-center">
            <img
              class="mb-1"
              :class="arabic ? 'ml-2' : 'mr-2'"
              src="../assets/images/icons/gift_green.svg"
              alt="gift"
            />
            <p class="text-success font-semibold">{{ $t("coupons.coupon_applied") }}</p>
          </div>
          <p
            class="font-semibold text-success"
            v-if="order.promo_code.max_uses > 1 && order.promo_code.remaining_uses >= 1"
          >
            {{ $t("coupons.coupon_more_use", { uses: order.promo_code.remaining_uses, date: getCouponExpiryDate }) }}
          </p>
          <p
            class="font-semibold text-success"
            v-if="order.promo_code.max_uses > 1 && order.promo_code.remaining_uses === 0"
          >
            {{ $t("coupons.coupon_last_use", { uses: order.promo_code.max_uses }) }}
          </p>
        </div>
      </div>

      <div class="payment-block-width bg-white rounded-2xl p-6 custom-shadow mb-4">
        <h2 class="text-18 font-semibold mb-4">{{ $t("booking_flow.from_phone") }}</h2>
        <p>{{ $t("booking_flow.install_ayadi") }}</p>

        <div class="flex flex-row justify-center space-x-6 mt-6 mb-4">
          <a
            :class="arabic ? 'ml-3' : 'mr-3'"
            href="https://apps.apple.com/ae/app/ayadi/id1503638078"
            target="_blank"
            @click="clickAppStore"
          >
            <img src="../assets/images/Authorization/AppStore.svg" alt="app store" />
          </a>
          <a
            :class="arabic ? 'mr-3' : 'ml-3'"
            href="https://play.google.com/store/apps/details?id=com.bloom.ayadi"
            target="_blank"
          >
            <img src="../assets/images/Authorization/GooglePlay.svg" alt="google play" @click="clickGooglePlay" />
          </a>
        </div>

        <p class="mb-4">{{ $t("booking_flow.choose_sign_in") }}</p>

        <img class="mx-auto" v-if="!arabic" src="../assets/images/sign-in-mobile-en.png" alt="sign-in mobile" />
        <img class="mx-auto" v-else src="../assets/images/sign-in-mobile-ar.png" alt="sign-in mobile" />
      </div>

      <div class="payment-block-width bg-white rounded-2xl p-6 custom-shadow mb-4">
        <h2 class="text-18 font-semibold mb-4">{{ $t("booking_flow.from_laptop") }}</h2>
        <p class="text-center mb-4">
          {{ $t("booking_flow.date_sign_in", { date: getDescDate }) }}
          <a href="https://ayadihealth.co" class="text-ayadi-900 no-underline font-semibold" target="_blank"
            >ayadihealth.co</a
          >.
        </p>

        <img v-if="!arabic" class="mx-auto" src="../assets/images/sign-in-web-en.png" alt="sign in web" />
        <img v-else class="mx-auto" src="../assets/images/sign-in-web-ar.png" alt="sign in web" />
        <div class="payment-divider"></div>
        <p class="text-center mb-6">{{ $t("booking_flow.link", { date: getDescDate }) }}</p>
        <MainButton v-clipboard="copyToClipboard" class="mx-auto mb-4" type="medium">{{
          $t("booking_flow.copy_link")
        }}</MainButton>
        <p class="font-semibold text-center">{{ $t("booking_flow.sent_email") }}️</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";

import NavBar from "@/components/NavBar";
import MainButton from "@/components/ui-kit/MainButton";
import router from "@/router";
import storageClient from "@/services/storageClient";

import { AnalyticsTrackData } from "@/services/analytics";

export default {
  name: "PaymentSuccess",
  components: { MainButton, NavBar },
  props: {
    orderId: String
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("therapistSlots", ["order"]),

    getCouponExpiryDate() {
      return dayjs(this.order.promo_code.ends_at).format("DD MMM YYYY");
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    getDescDate() {
      if (!this.order) {
        return "";
      }
      return dayjs(this.order.session?.session_start).format("MMM DD");
    },
    getDescTime() {
      if (!this.order) {
        return "";
      }
      return dayjs(this.order.session?.session_start).format("h:mm A");
    },
    getDate() {
      if (!this.order) {
        return "";
      }
      return dayjs(this.order.session?.session_start).format("dddd, MMM DD");
    },

    getTherapistName() {
      if (!this.therapistProfile) {
        return "";
      }

      return this.arabic ? this.therapistProfile?.name_ar : this.therapistProfile?.name_en;
    },

    //todo: ask edem about therapist_id

    getAvatar() {
      if (!this.therapistProfile) {
        return "";
      }
      return this.therapistProfile.avatar;
    },

    getTime() {
      if (!this.order) {
        return "";
      }
      return dayjs(this.order.session?.session_start).format("h:mm A");
    },

    getDuration() {
      if (!this.order) {
        return "";
      }

      return this.order.session?.type === "video" ? this.$t("booking_flow.50mins") : this.$t("booking_flow.25mins");
    },

    getPrice() {
      if (!this.order) {
        return "";
      }

      return (
        this.order.session?.price_object.amount / this.order.session?.price_object.divider +
        " " +
        this.order.session?.price_object.code_localized
      );
    },
    getCouponPrice() {
      const promo = this.order.promo_code;
      if (promo.type === "percent") {
        return (
          Math.ceil(
            (this.order.session?.price_object.amount / this.order.session?.price_object.divider / 100) *
              (100 - promo.percentage)
          ) +
          " " +
          this.order.session?.price_object.currency
        );
      }
      let sessionPrice = this.order.session?.price_object.amount / this.order.session?.price_object.divider;
      let discountPrice = promo.value_object.amount / promo.value_object.divider;
      let discountedPrice = sessionPrice - discountPrice;
      return discountedPrice + " " + this.order.session?.price_object.currency;
    }
  },

  methods: {
    copyToClipboard() {
      this.$notify({ group: "success", text: this.$t("booking_flow.link_is_copied") });
      return "https://ayadihealth.co/";
    },
    clickGooglePlay() {
      window.gtag("event", "therapistProfile_googlePlayStore_click");
    },
    clickAppStore() {
      window.gtag("event", "therapistProfile_appleAppStore_click");
    }
  },

  async created() {
    try {
      await this.$store.dispatch("therapistSlots/checkOrder", this.orderId);
      await this.$store.dispatch("therapistProfiles/getTherapistProfile", this.order.therapist_id);

      const trackData = Object.assign(
        AnalyticsTrackData.getStandardTherapistProperties(this.therapistProfile),
        AnalyticsTrackData.getStandardSessionProperties(this.order)
      );

      this.$mixpanel.track("booking_confirmation_view", trackData);
      this.$intercom.trackEvent("booking_confirmation_view", trackData);
      window.webengage.track("booking_confirmation_view", trackData);

      document.getElementsByTagName("body")[0].style.overflow = "auto";
    } catch (e) {
      router.push({ name: "HTTPError" }).catch(() => {});
    }
  }
};
</script>

<style scoped>
.payment-block-width {
  width: 430px;
  max-width: 90vw;
}
.payment-divider {
  @apply mx-auto my-6 bg-divider h-px;
  width: 70%;
}
</style>

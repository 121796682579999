<template>
  <div id="preview" class="h-screen w-full flex justify-center items-center bg-gradient">
    <div class="flex flex-row justify-center items-center mobileWrapper">
      <slot></slot>
      <div class="flex flex-col justify-center items-center test-video-content text-center ml-20">
        <img class="mb-6 sm:hidden" src="../../assets/images/AyadiLogoOnlyIcon.svg" alt="ayadi" />
        <countdown
          v-if="sessionNotStarted"
          :time="timeRemaining || 1"
          :transform="transform"
          @progress="openLink"
          @end="compareTime"
        >
          <template slot-scope="props">
            <p class="text-white text-18 font-semibold sm:text-base" :dir="direction">
              {{ $t("25.your_video", { name: therapistName }) }}

              <!--              {{ $t("main.will_start", { name: therapistName }) }}-->
            </p>
            <p class="text-white text-2xl font-semibold sm:text-base" :dir="direction">
              {{ props.days }} {{ $t("sessions.days") }} : {{ props.hours }} {{ $t("sessions.hrs") }} :
              {{ props.minutes }} {{ $t("sessions.min") }} : {{ props.seconds }} {{ $t("sessions.sec") }}
            </p>
            <p class="text-white text-18 font-semibold mb-4" :dir="direction">{{ fullDate }}</p>
          </template>
        </countdown>

        <div v-if="sessionGoing">
          <p class="text-white text-18 font-semibold" :dir="direction">
            {{ $t("25.your_video", { name: therapistName }) }}
          </p>
          <p class="text-white text-2xl font-semibold mb-4 sm:text-base">
            {{ $t("main.already_started") }}
          </p>
        </div>

        <div v-if="sessionEnded">
          <p class="text-white text-18 font-semibold mb-4 sm:text-base" :dir="direction">
            {{ $t("main.not_available", { name: therapistName }) }}
          </p>
        </div>

        <div v-if="isActiveChat" class="text-white font-semibold">
          <p class="text-18">{{ $t("messenger.chat") }}</p>
          <p v-if="unread > 0">* {{ $t("messenger.have_unread", { count: unread }) }}</p>
          <p v-else>{{ $t("messenger.no_unread") }}</p>
          <SecondaryButton class="my-4" :label="$t('messenger.open_conversation')" @click="openChat" medium>
            <span slot="icon" class="mr-2">
              <MessageIcon :have-unread="unread > 0" button />
            </span>
          </SecondaryButton>
        </div>

        <p class="text-white text-18 font-semibold" :dir="direction">{{ $t("25.duration") }} :</p>

        <div class="flex items-center justify-center mb-4" :class="{ 'flex-row-reverse': arabic }">
          <img src="../../assets/images/IconVideoCircle.svg" alt="video" />
          <p class="text-white text-2xl font-semibold ml-2" :dir="direction" :class="{ 'mr-2 ml-0': arabic }">
            {{ sessionType === "video_small" ? $t("25.25_minutes") : $t("25.50_minutes") }}
          </p>
        </div>

        <div class="flex flex-row justify-between items-center">
          <button
            v-if="!sessionNotStarted"
            @click="$emit('join-session')"
            class="custom-button cursor-pointer px-4 py-2 my-0 mr-3"
          >
            {{ $t("sessions.join_session") }}
          </button>
          <button
            v-else-if="!linkIsDisabled"
            @click="$emit('join-session')"
            class="custom-button cursor-pointer px-4 py-2 my-0 mr-3"
          >
            {{ $t("sessions.join_session") }}
          </button>
          <div
            v-tooltip.hover="{
              content: $t('main.blocked_button'),
              class: 'tooltip-custom'
            }"
            v-else
          >
            <button :disabled="linkIsDisabled" class="custom-button cursor-pointer px-4 py-2 my-0 mr-3">
              {{ $t("sessions.join_session") }}
            </button>
          </div>
          <SecondaryButton medium @click="logout" :label="$t('main.logout')" />
        </div>
      </div>
    </div>
    <AdditionalProfileUserInfoModal />
    <FooterInfo no-image class="footer-welcome-room" />
  </div>
</template>

<script>
import dayjs from "dayjs";
require("dayjs/locale/ar");
if (storageClient.getLanguage() === "ar") {
  dayjs.locale("ar");
}

import { mapState } from "vuex";
import countdown from "@chenfengyuan/vue-countdown";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import FooterInfo from "@/components/FooterInfo";
import storageClient from "@/services/storageClient";
import { Client as ConversationsClient } from "@twilio/conversations";
import MessageIcon from "@/components/chat/MessageIcon";
import AdditionalProfileUserInfoModal from "@/components/modals/AdditionalProfileUserInfoModal";

export default {
  name: "WelcomeRoom",
  components: {
    MessageIcon,
    FooterInfo,
    SecondaryButton,
    countdown,
    AdditionalProfileUserInfoModal
  },
  props: {
    therapistName: {
      type: String,
      default: "TherapistName"
    },

    sessionStartTime: {
      type: null
    },

    isActiveChat: {
      type: Boolean,
      default: false
    },

    activeChat: String,

    sessionEndTime: null,
    sessionType: String
  },

  data() {
    return {
      sessionNotStarted: true,
      sessionGoing: false,
      sessionEnded: false,
      linkIsDisabled: true,
      conversationClient: null,
      chatRoom: null,
      unread: 0
    };
  },

  computed: {
    ...mapState("chat", ["chatToken"]),
    ...mapState("auth", ["user"]),
    timeRemaining() {
      if (new Date().getTime() > new Date(this.sessionStartTime).getTime()) {
        return 0;
      }

      return new Date(this.sessionStartTime) - new Date();
    },

    fullDate() {
      return dayjs(this.sessionStartTime).format("(MMM DD, h:mm A)");
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  },

  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      return props;
    },

    openLink(data) {
      if (!data) {
        this.linkIsDisabled = true;
        return;
      }
      if (data.hours === 0 && data.days === 0 && data.minutes < 5) {
        this.linkIsDisabled = false;
      } else {
        this.linkIsDisabled = true;
      }
    },

    logout() {
      this.$emit("logout");
    },

    openChat() {
      window.webengage.track("sessionPreview_inbox_click", {
        "Device type": "Web",
        "Notification count": this.unread || "0"
      });
      this.$mixpanel.track("sessionPreview_inbox_click", {
        "Device type": "Web",
        "Notification count": this.unread || "0",
        time: dayjs().unix()
      });
      this.$intercom.trackEvent("sessionPreview_inbox_click", {
        "Device type": "Web",
        "Notification count": this.unread || "0"
      });
      this.$emit("open-chat");
    },

    compareTime() {
      const counter = setInterval(() => {
        if (dayjs().isBefore(dayjs(this.sessionStartTime))) {
          clearInterval(counter);
          this.sessionNotStarted = true;
          this.sessionGoing = false;
          this.sessionEnded = false;
        } else if (dayjs().isAfter(dayjs(this.sessionEndTime))) {
          clearInterval(counter);
          this.sessionNotStarted = false;
          this.sessionGoing = false;
          this.sessionEnded = true;
        } else {
          if (this.sessionStartTime && this.sessionEndTime) {
            this.sessionNotStarted = false;
            clearInterval(counter);
          } else {
            this.sessionNotStarted = true;
          }

          this.sessionGoing = true;
          this.sessionEnded = false;
          this.linkIsDisabled = true;
        }
      }, 1000);
    }
  },

  async created() {
    if (this.user && this.user?.show_basic_details_prompt === true) {
      this.$modal.show("additional-profile-user-info-modal");
      const signUpFrom = await storageClient.getSingUpFrom();
      const presentedFrom = await storageClient.getPresentedFrom();

      const trackData = {
        presented_from: presentedFrom || "",
        signup_from: signUpFrom || ""
      };

      this.$mixpanel.track("singup_userdata_view", trackData);
    }
  },

  async mounted() {
    this.compareTime();

    await this.$store.dispatch("chat/getChatToken");

    this.conversationClient = await ConversationsClient.create(this.chatToken);

    if (this.activeChat) {
      this.chatRoom = await this.conversationClient.getConversationBySid(this.activeChat);
      setInterval(async () => {
        this.unread = await this.chatRoom.getUnreadMessagesCount();
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/media.scss";

.footer-welcome-room {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.test-video-content,
#testVideo {
  max-width: 600px;
  width: 100%;

  @include for-true-phone {
    max-width: 400px;
    height: 150px;
    max-height: 200px;
    height: 100%;
    width: 100%;
  }
}

.test-video-content {
  @include for-true-phone {
    margin-top: auto;
    margin-bottom: auto;

    max-height: initial;
    margin-left: 0;
    justify-content: flex-start;
    padding: 10px 10px;
  }
}

#testVideo video {
  max-width: 600px;
  width: 100%;

  @include for-true-phone {
    max-width: 400px;
    height: 150px;
    max-height: 200px;
    height: 100%;
  }
}

.mobileWrapper {
  width: 100%;
  padding: 0 1rem;

  @include for-true-phone {
    flex-direction: column;
    height: 100%;
    justify-content: normal;
    align-items: center;
    padding: 10px 0;
  }
}
</style>

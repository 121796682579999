<template>
  <modal
    name="profile-picture-modal"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white w-modal overflow-y-scroll px-8 py-6 flex flex-col justify-start items-start rounded-lg">
      <div class="flex justify-between items-center w-full mb-4" :class="{ 'flex-row-reverse': arabic }">
        <p class="text-18 font-semibold">{{ $t("booking_flow.choose_picture") }}</p>
        <div class="cursor-pointer px-1 py-1" @click="$modal.hide('profile-picture-modal')">
          <IconCloseGray />
        </div>
      </div>
      <!--        toggle-->
      <div class="mx-auto p-1 rounded-20px bg-bgLight mb-4">
        <span
          class="px-3 py-1 rounded-20px text-base cursor-pointer"
          :class="activeGender === 'female' ? 'bg-ayadi-900 text-white' : 'text-lightGray'"
          @click="activeGender = 'female'"
        >
          {{ $t("booking_flow.female") }}
        </span>
        <span
          class="px-3 py-1 rounded-20px text-base cursor-pointer"
          :class="activeGender === 'male' ? 'bg-ayadi-900 text-white' : 'text-lightGray'"
          @click="activeGender = 'male'"
        >
          {{ $t("booking_flow.male") }}
        </span>
      </div>
      <!--        pictures-->
      <div v-if="activeGender === 'female'" class="flex flex-row justify-center flex-wrap">
        <div
          class="cover-size flex justify-center items-end rounded-12px shadow-img cursor-pointer"
          :class="{ 'mr-4 mb-4': i !== 8 }"
          v-for="i in 8"
          @click="chosePicture('female', i)"
          :key="i"
        >
          <img class="sm:hidden object-cover img-size" :src="getImgUrl(i)" :alt="`female-${i}`" />
          <img class="sm:h-16 sm:w-16 object-cover hidden sm:block" :src="getImgUrl(i)" :alt="`female-${i}`" />
        </div>
      </div>

      <div v-else class="flex flex-row justify-center flex-wrap">
        <div
          class="cover-size flex justify-center items-end rounded-12px shadow-img cursor-pointer"
          :class="{ 'mr-4 mb-4': i !== 8 }"
          v-for="i in 8"
          :key="i"
          @click="chosePicture('male', i)"
        >
          <img class="sm:hidden object-cover img-size" :src="getImgUrl(i)" :alt="`male-${i}`" />
          <img class="sm:h-16 sm:w-16 object-cover hidden sm:block" :src="getImgUrl(i)" :alt="`male-${i}`" />
        </div>
      </div>
      <SecondaryButton @click="goBack" :label="$t('booking_flow.back')" medium />
    </div>
  </modal>
</template>

<script>
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import IconCloseGray from "@/components/icons/IconCloseGray";
import storageClient from "@/services/storageClient";

export default {
  name: "ProfilePictureModal",
  components: { SecondaryButton, IconCloseGray },

  data() {
    return {
      activeGender: "female"
    };
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    goBack() {
      this.$modal.hide("profile-picture-modal");
      this.$modal.show("booking-options-modal");
    },

    getImgUrl(index) {
      const images = require.context("../../../assets/images/avatars", false, /\.png$/);
      return images(`./${this.activeGender}-${index}.png`);
    },

    chosePicture(gender, id) {
      storageClient.setRegistrationFlowPicture(`${gender}-${id}`);

      this.$modal.hide("profile-picture-modal");
      this.$modal.show("chose-name-modal");
    },
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style scoped>
.rounded-20px {
  border-radius: 20px;
}

.img-size {
  width: 7.5rem;
  height: 7.5rem;
}

.cover-size {
  width: 152px;
  height: 132px;
}

@media (max-width: 639px) {
  .cover-size {
    width: calc(152px / 2);
    height: calc(132px / 2);
  }
}

.shadow-img {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.0669441);
}
</style>

<template>
  <div class="font-semibold text-sm text-ayadi-900 bg-textGray-100 rounded-3xl px-4 py-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TherapistInformationChips",
  props: {
    isOdd: Boolean
  }
};
</script>

<style scoped></style>

import dayjs from "dayjs";
import axios from "axios";
import storageClient from "@/services/storageClient";

export class AnalyticsTrackData {
  static getStandardSessionProperties(order) {
    return {
      "Device type": "Web",
      "User type": "Client",
      pricing: order.session.price_in_kwd_object.amount / order.session.price_in_kwd_object.divider,
      "Order ID": order.id,
      "Slot ID": order.session.id,
      "Session date": order.session.session_start,
      "Hours until session": dayjs(order.session.session_start).diff(dayjs(), "hour"),
      "session type": order.session.type
    };
  }

  static getStandardSlotProperties(slot, price) {
    return {
      "Device type": "Web",
      "User type": "Client",
      pricing: price,
      "Slot ID": slot.session_id,
      "Session date": slot.start,
      "Hours until session": dayjs(slot.start).diff(dayjs(), "hour"),
      "session type": slot.type
    };
  }

  static getStandardTherapistProperties(therapistProfile) {
    return {
      "Device type": "Web",
      "User type": "Client",
      "Therapist name": therapistProfile.name_en,
      "Therapist speciality": therapistProfile.skill_en || "",
      "Therapist video price":
        therapistProfile.video_price_in_kwd_object.amount / therapistProfile.video_price_in_kwd_object.divider,
      "Therapist video small price":
        therapistProfile.video_small_price_in_kwd_price_object.amount /
        therapistProfile.video_small_price_in_kwd_price_object.divider,
      "Therapist years of experience": therapistProfile.experience,
      "Therapist education": therapistProfile.qualification_en,
      "Therapist language": therapistProfile.languages.join(", "),
      "Therapist gender": therapistProfile.gender,
      "Has intro video": !!(therapistProfile?.video_preview_ar || therapistProfile?.video_preview_en)
    };
  }

  static async sendKochavaId(kochava_device_id) {
    const { data } = await axios.post(`/api/client/tracking-devices`, { kochava_device_id });

    if (data && data.id) {
      storageClient.setKochavaDeviceId(data.id);
    }
    return data;
  }
}

<template>
  <div>
    <a href="" class="hidden" target="_blank" ref="link" id="link"></a>
    <modal
      @before-open="getBookedSlot"
      name="card-modal"
      width="430"
      height="auto"
      :adaptive="true"
      :clickToClose="false"
      @opened="blockScroll"
      @closed="unblockScroll"
    >
      <div
        class="bg-white w-modal max-h-90 p-6 flex flex-col justify-center items-center rounded-lg"
        :dir="arabic ? 'rtl' : 'ltr'"
      >
        <!--      <div v-if="isLoading" class="py-20 h-80 flex justify-center items-center w-full h-full bg-white">-->
        <!--        <img class="w-20 animate-bounce" src="../../../assets/images/ayadi-loader.svg" alt="Ayadi" />-->
        <!--      </div>-->
        <div class="w-full overflow-y-auto">
          <div class="flex justify-between items-start w-full mb-4">
            <p class="text-18 font-semibold w-full" :class="arabic ? 'text-right' : 'text-left'">
              {{ $t("payments.payment_type") }}
            </p>
            <div class="cursor-pointer px-1 py-1" @click="onCloseModal">
              <IconCloseGray />
            </div>
          </div>

          <div class="px-5 flex flex-col justify-start items-start">
            <p class="text-textGray-500 mb-2 font-semibold">{{ $t("payments.supported_cards") }}</p>
            <SupportedCards />

            <InputWrapper
              :error-message="numberErrors[0]"
              class="my-4"
              :class="{ 'items-start': arabic }"
              :label="$t('payments.card_number')"
            >
              <input
                @focus="numberErrors = []"
                class="cc-number"
                maxlength="19"
                name="credit-number"
                pattern="\d*"
                placeholder="XXXX XXXX XXXX XXXX"
                type="tel"
                :dir="arabic ? 'rtl' : 'ltr'"
                slot="input"
                v-model="card.number"
                v-cardformat:formatCardNumber
              />
            </InputWrapper>

            <div class="flex justify-between items-start">
              <InputWrapper
                :error-message="expiryDateErrors[0]"
                :class="{ 'items-start text-right ml-4': arabic, 'mr-4': !arabic }"
                :label="$t('payments.expiry_date')"
              >
                <input
                  @focus="expiryDateErrors = []"
                  :dir="arabic ? 'rtl' : 'ltr'"
                  slot="input"
                  type="text"
                  v-model="card.expiry_date"
                  maxlength="7123"
                  placeholder="MM/YY"
                  v-cardformat:formatCardExpiry
                />
              </InputWrapper>
              <InputWrapper
                :error-message="cvvErrors[0]"
                :class="{ 'items-start': arabic }"
                :label="$t('payments.security_code')"
              >
                <input
                  @focus="cvvErrors = []"
                  :dir="arabic ? 'rtl' : 'ltr'"
                  slot="input"
                  type="text"
                  v-model="card.cvv"
                  placeholder="CVV"
                  maxlength="4"
                  v-cardformat:formatCardCVC
                />
              </InputWrapper>
            </div>
            <div class="flex justify-center items-center w-full mt-4 mb-6">
              <img
                :class="{ 'ml-8': arabic, 'mr-8': !arabic }"
                class="h-28px"
                src="../../../assets/images/visa.png"
                alt="visa"
              />
              <img class="h-28px" src="../../../assets/images/mastercard.png" alt="visa" />
            </div>
          </div>
          <div class="flex justify-between w-full items-center">
            <SecondaryButton @click="goBack" medium :label="$t('auth.go_back')" />
            <MainButton
              :disabled="haveError || !card.cvv || !card.expiry_date || !card.number"
              type="medium"
              id="pay_now"
              @click="tokenizeCard"
            >
              {{
                addCardMode
                  ? $t("booking_flow.continue")
                  : $t("payments.pay_price", { price: coupon ? getCouponPrice : getPrice })
              }}
            </MainButton>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import storageClient from "@/services/storageClient";
import SupportedCards from "@/components/therapist-profile/SupportedCards";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import sessionsClient from "@/services/sessionsClient";
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  name: "CardModal",
  components: {
    SecondaryButton,
    MainButton,
    SupportedCards,
    IconCloseGray,
    InputWrapper
  },
  data() {
    return {
      addCardMode: false,
      isLoading: false,
      slot: null,
      haveError: false,
      card: {
        number: "",
        cvv: "",
        expiry_date: ""
      },
      numberErrors: [],
      cvvErrors: [],
      expiryDateErrors: [],
      onAddingCard: null,
      onBackModal: ""
    };
  },

  watch: {
    card: {
      handler() {
        if (this.card.expiry_date.length > 7) {
          this.card.expiry_date = this.card.expiry_date.slice(0, this.card.expiry_date.length - 1);
        }
      },
      deep: true
    }
  },

  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),

    ...mapState("sessions", ["order", "coupon"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    getPrice() {
      if (!this.slot) {
        return "";
      }

      return this.slot.type === "video"
        ? this.therapistProfile.video_price_object.amount / this.therapistProfile.video_price_object.divider +
            " " +
            this.therapistProfile.video_price_object.currency
        : this.therapistProfile.video_small_price_object.amount /
            this.therapistProfile.video_small_price_object.divider +
            " " +
            this.therapistProfile.video_small_price_object.currency;
    },
    getCouponPrice() {
      const price = this.coupon.value_object;
      return price.amount / price.divider + " " + price.code_localized;
    }
  },

  methods: {
    getBookedSlot(value) {
      this.addCardMode = false;
      if (value.params) {
        this.addCardMode = value.params?.addingCard;
        this.onAddingCard = value.params.cb;
        this.onBackModal = value.params?.onBackModal;
        return;
      }

      this.slot = storageClient.getBookingSlot();
    },
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      this.card = {
        number: "",
        cvv: "",
        expiry_date: ""
      };
    },

    enterCard() {
      this.$modal.hide("payment-modal");
      this.$modal.show("card-modal");
    },

    async tokenizeCard() {
      const today = dayjs();

      if (today.format("YYYY") > "20" + this.card.expiry_date[5] + "" + this.card.expiry_date[6]) {
        this.expiryDateErrors.push(this.$t("payments.invalid_date"));
        return;
      }

      if (today.format("YYYY") === "20" + this.card.expiry_date[5] + "" + this.card.expiry_date[6]) {
        if (today.format("MM") > this.card.expiry_date[0] + "" + this.card.expiry_date[1]) {
          this.expiryDateErrors.push(this.$t("payments.invalid_date"));
          return;
        }
      }

      let numberLength = this.card.number.replaceAll(" ", "").length;

      if (this.card.expiry_date.length < 7 || this.card.cvv.length < 3 || numberLength < 15) {
        if (this.card.expiry_date.length < 7) {
          this.expiryDateErrors.push(this.$t("payments.invalid_date"));
        }

        if (this.card.cvv.length < 3) {
          this.cvvErrors.push(this.$t("payments.invalid_cvv"));
        }

        if (numberLength < 15) {
          this.numberErrors.push(this.$t("payments.invalid_number"));
        }
        return;
      }

      this.$modal.show("loader-modal");
      const resp = await fetch(process.env.VUE_APP_CHECKOUT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.VUE_APP_CHECKOUT_API_KEY
        },
        body: JSON.stringify({
          type: "card",
          number: this.card.number.replaceAll(" ", ""),
          expiry_month: this.card.expiry_date[0] + "" + this.card.expiry_date[1],
          expiry_year: "20" + this.card.expiry_date[5] + "" + this.card.expiry_date[6],
          cvv: this.card.cvv
        })
      });
      const data = await resp.json();
      if (!this.addCardMode) {
        this.$modal.hide("card-modal");
      }

      if (!this.addCardMode) {
        const payload = {
          session_id: this.slot.session_id + "",
          type: "card_token",
          token: data.token
        };

        if (this.coupon) {
          payload.promo_code = this.coupon.code;
        }

        const beResp = await sessionsClient.createOrder(payload);

        const link = document.getElementById("link");

        link.href = beResp.data.url;
        link.click();

        await this.checkOrderStatus(beResp.data.id);
      } else {
        this.$modal.hide("loader-modal");
        this.$modal.hide("card-modal");
        if (this.onAddingCard) {
          await this.onAddingCard(data);
        } else {
          this.$modal.show("proceed-to-payment-package-modal", data);
        }
      }
    },

    async checkOrderStatus(id) {
      const checkOrderStatus = setInterval(async () => {
        await this.$store.dispatch("sessions/getOrder", id);

        if (this.order && this.order?.status === "failed") {
          this.$modal.hide("loader-modal");
          this.$modal.show("payment-failed");
          clearInterval(checkOrderStatus);
        }

        if (this.order && this.order?.status === "ready") {
          this.$modal.hide("loader-modal");
          await this.$router.push({ name: "PaymentSuccess", query: { orderId: id + "" } }).catch(() => {});
          clearInterval(checkOrderStatus);
        }
      }, 5000);
    },

    onCloseModal() {
      this.$modal.hide("card-modal");
      this.$modal.hide("loader-modal");
    },

    goBack() {
      this.$modal.hide("card-modal");
      this.$modal.show(this.onBackModal);
    }
  }
};
</script>

<style>
.h-28px {
  height: 1.75rem;
}

#iframe {
  position: absolute;
  z-index: 9999;
  width: 600px;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<template>
  <modal
    @before-open="beforeOpen"
    name="proceed-to-payment-package-modal"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div
      class="bg-white w-modal max-h-90 p-6 flex flex-col justify-center items-center rounded-lg"
      :dir="arabic ? 'rtl' : 'ltr'"
    >
      <div v-if="isLoading" class="py-20 h-80 flex justify-center items-center w-full h-full bg-white">
        <img class="w-20 animate-bounce" src="../../../assets/images/ayadi-loader.svg" alt="Ayadi" />
      </div>
      <div v-else class="w-full overflow-y-auto">
        <div v-if="selectedPackage">
          <div class="flex justify-between items-start w-full mb-4">
            <p class="text-18 font-semibold w-full text-center">{{ $t("booking_package_flow.title") }}</p>
            <div class="cursor-pointer px-1 py-1" @click="$modal.hide('proceed-to-payment-package-modal')">
              <IconCloseGray />
            </div>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img
              class="img-size rounded-lg mt-6 mb-2"
              :src="therapistProfile.avatar"
              :style="{ 'background-color': therapistProfile.avatar_bg_color }"
              alt="avatar"
            />
            <p class="mb-6 font-semibold text-2xl">{{ therapistProfile.full_name }}</p>
          </div>
          <div class="grid grid-cols-2 w-full">
            <div class="col-span-1 flex flex-col justify-start" :class="arabic ? 'pr-16 sm:pr-4' : 'pl-16 sm:pl-4'">
              <p class="text-lightGray text-base mb-3">{{ $t("booking_package_flow.sessions") }}</p>
              <p class="text-lightGray text-base mb-3">{{ $t("booking_package_flow.duration") }}</p>
              <p class="text-lightGray text-base mb-3">{{ $t("booking_package_flow.total") }}</p>
              <p class="text-lightGray text-base mb-3">{{ $t("booking_package_flow.validity") }}</p>
            </div>
            <div class="col-span-1">
              <p class="font-semibold text-base mb-3">{{ selectedPackage.value }}</p>
              <p class="font-semibold text-base mb-3">
                {{ $t("booking_package_flow.duration_per") }}
              </p>
              <div class="font-semibold text-base mb-3 flex priceWrapper">
                <div class="price discount">{{ displayPrice(selectedPackage.oldPrice, selectedPackage.currency) }}</div>
                <div class="price">{{ displayPrice(selectedPackage.price, selectedPackage.currency) }}</div>
              </div>
              <p class="font-semibold text-base mb-3">
                {{ $t("booking_package_flow.months", { quantity: getValidity() }) }}
              </p>
            </div>
          </div>

          <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mt-6 mb-4">
            <div class="flex flex-col">
              <div class="benefitsTitle">
                {{ $t("booking_package_flow.benefits.title") }}
              </div>
              <div class="benefitsItem">
                <img src="../../../assets/images/IconCheck.svg" alt="icon" />
                {{ $t("booking_package_flow.benefits.p-1") }}
              </div>
              <div class="benefitsItem">
                <img src="../../../assets/images/IconCheck.svg" alt="icon" />
                {{ $t("booking_package_flow.benefits.p-2") }}
              </div>
              <div class="benefitsItem">
                <img src="../../../assets/images/IconCheck.svg" alt="icon" />
                {{ $t("booking_package_flow.benefits.p-3") }}
              </div>
            </div>
          </div>
          <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mb-4">
            <p class="text-center font-semibold">
              {{ $t("booking_package_flow.last_day", { date: getLastDay() }) }}
            </p>
          </div>
          <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mb-4">
            <p class="text-center font-semibold">
              {{ $t("booking_package_flow.non-refundable") }}
              <a class="text-ayadi-900 no-underline cursor-pointer" @click="viewTerms" target="_blank">
                {{ $t("booking_package_flow.read_more") }}
              </a>
            </p>
          </div>
          <div class="px-4 py-3 rounded-2xl bg-bgLight w-full mb-4">
            <p class="text-center font-semibold">
              {{ $t("booking_package_flow.tip") }}
              <a class="text-ayadi-900 no-underline cursor-pointer" @click="viewPolicy" target="_blank">
                {{ $t("booking_package_flow.privacy_policy") }}
              </a>
            </p>
          </div>
          <div class="footerNavigation">
            <SecondaryButton @click="onBack()" medium :label="$t('main.close')"></SecondaryButton>
            <MainButton @click="selectPayment" id="proceed_to_payment">
              {{ $t("booking_package_flow.pay", { price: selectedPackage.price, currency: selectedPackage.currency }) }}
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from "vue";
import sessionsClient from "@/services/sessionsClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";
import MainButton from "@/components/ui-kit/MainButton";
import { checkAvailabilityPackage } from "@/utils/packages";
import { getMonthDuration } from "../../../utils/date";
import { sendAllEvents, getBookingOptionName, getTherapistProperties, getUTMProperties } from "@/utils/events";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import { calculatePrice } from "@/utils/price";

export default {
  name: "ProceedToPaymentPackageModal",
  components: { MainButton, SecondaryButton, IconCloseGray },

  data() {
    return {
      isLoading: false,
      paymentDetails: null,
      validPackages: null
    };
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("common", ["currentIPData"]),
    ...mapState("therapistSlots", ["createdOrder"]),
    ...mapState("sessions", ["selectedPackage", "bookedPackages"]),
    ...mapState("auth", ["user"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    async beforeOpen(value) {
      if (this.user) {
        this.validPackages = await checkAvailabilityPackage(this.therapistProfile.id, this.bookedPackages);

        if (this.validPackages) {
          await this.$store.dispatch("sessions/setBookedPackagesForTherapist", this.validPackages);
          this.$modal.hide("proceed-to-payment-package-modal");
          this.$modal.show("therapist-availability");
          return;
        }

        if (this.selectedPackage && this.therapistProfile) {
          const trackData = {
            "Booking option": getBookingOptionName(this.selectedPackage),
            "Price in KWD": calculatePrice(
              this.selectedPackage.price_in_kwd_object.amount,
              this.selectedPackage.price_in_kwd_object.divider
            ),
            ...getUTMProperties(this.$route.query),
            ...getTherapistProperties(this.therapistProfile)
          };
          sendAllEvents({ event_name: "package_reviewOrder_view", track_data: trackData });
        }
      }
      if (value.params?.paymentDetails) {
        this.paymentDetails = value.params.paymentDetails;
      }
    },

    displayPrice(value, currency) {
      return `${value} ${currency}`;
    },

    getValidity() {
      return getMonthDuration(this.selectedPackage.validity);
    },

    getLastDay() {
      return dayjs(this.selectedPackage.last_day_to_book).format("DD MMM YYYY");
    },

    viewTerms() {
      if (this.selectedPackage && this.therapistProfile) {
        const trackData = {
          "Booking option": getBookingOptionName(this.selectedPackage),
          "Price in KWD": calculatePrice(
            this.selectedPackage.price_in_kwd_object.amount,
            this.selectedPackage.price_in_kwd_object.divider
          ),
          ...getTherapistProperties(this.therapistProfile)
        };
        sendAllEvents({ event_name: "package_viewTerms_click", track_data: trackData });
      }
      if (this.arabic) {
        window.open("https://ayadihealth.co/ar/privacy-policy/", "_blank");
      } else {
        window.open("https://ayadihealth.co/privacy-policy/", "_blank");
      }
    },

    viewPolicy() {
      if (this.arabic) {
        window.open("https://ayadihealth.co/ar/privacy-policy/", "_blank");
      } else {
        window.open("https://ayadihealth.co/privacy-policy/", "_blank");
      }
    },

    async selectPayment() {
      await this.$store.dispatch("sessions/getAvailabilityPaymentMethods", this.selectedPackage.price);

      this.$modal.hide("proceed-to-payment-package-modal");
      this.$modal.show("selection-payment-modal", {
        addingCard: true,
        cb: (data) => this.createOrder(data),
        price: this.selectedPackage.price,
        currency: this.selectedPackage.currency
      });
    },

    async createOrder(paymentMethod) {
      if (paymentMethod === "tabby_link") {
        const order = {
          therapist_id: this.therapistProfile.id,
          package_type: this.selectedPackage.product_identifier,
          type: "tabby_link",
          ...(this.user?.initial_matching_id && {
            first_matching_id: this.user.initial_matching_id
          })
        };

        try {
          const { data: response } = await sessionsClient.createPackageOrder(order);

          if (response) {
            if (this.selectedPackage && this.therapistProfile) {
              const trackData = {
                "Booking option": getBookingOptionName(this.selectedPackage.product_identifier),
                "Price in KWD": calculatePrice(
                  this.selectedPackage.price_in_kwd_object.amount,
                  this.selectedPackage.price_in_kwd_object.divider
                ),
                ...getTherapistProperties(this.therapistProfile)
              };

              sendAllEvents({ event_name: "package_proceedToPayment_click", track_data: trackData });
            }
            storageClient.seShowSuccessPackageModal(true);
          }

          window.location.assign(response.url);
        } catch (e) {
          Vue.notify({
            group: "errors",
            text: "Something went wrong",
            classes: "toast-error"
          });
          this.$modal.hide("loader-modal");
          this.$modal.hide("card-modal");
          this.$modal.hide("proceed-to-payment-package-modal");
        }
      } else {
        if (!this.user?.payment_method_source?.payment_id && !this.paymentDetails?.token) {
          this.$modal.hide("loader-modal");
          this.$modal.hide("proceed-to-payment-package-modal");
          this.$modal.show("card-modal", {
            addingCard: true,
            cb: (data) => this.continueOrder(data),
            onBackModal: "selection-payment-modal"
          });
          return;
        }

        const order = {
          therapist_id: this.therapistProfile.id,
          package_type: this.selectedPackage.product_identifier,
          type: this.user?.payment_method_source?.payment_id ? "source" : "card_token",
          ...(this.user?.payment_method_source?.payment_id && { token: this.user.payment_method_source.payment_id }),
          ...(this.paymentDetails?.token && { token: this.paymentDetails?.token }),
          ...(this.user?.initial_matching_id && {
            first_matching_id: this.user.initial_matching_id
          })
        };

        try {
          const { data: response } = await sessionsClient.createPackageOrder(order);

          if (response) {
            if (this.selectedPackage && this.therapistProfile) {
              const trackData = {
                "Booking option": getBookingOptionName(this.selectedPackage.product_identifier),
                "Price in KWD": calculatePrice(
                  this.selectedPackage.price_in_kwd_object.amount,
                  this.selectedPackage.price_in_kwd_object.divider
                ),
                ...getTherapistProperties(this.therapistProfile)
              };

              sendAllEvents({ event_name: "package_proceedToPayment_click", track_data: trackData });
            }
            storageClient.seShowSuccessPackageModal(true);
          }
          window.location.assign(response.url);
        } catch (e) {
          Vue.notify({
            group: "errors",
            text: "Something went wrong",
            classes: "toast-error"
          });
          this.$modal.hide("loader-modal");
          this.$modal.hide("card-modal");
          this.$modal.hide("proceed-to-payment-package-modal");
        }
      }
    },

    async continueOrder(data) {
      this.paymentDetails = data;
      this.$modal.hide("card-modal");
      this.$modal.show("loader-modal");
      await this.createOrder();

      return;
    },

    onBack() {
      this.$modal.hide("proceed-to-payment-package-modal");
      this.$modal.show("booking-options-modal", { selectedOption: this.selectedPackage });
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style lang="scss" scoped>
.footerNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: white;
  width: 100%;
  padding-top: 1rem;
}
.priceWrapper {
  gap: 0.5rem;
}
.img-size {
  width: 7.5rem;
  height: 7.5rem;
}

.benefitsTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #434a57;
}

.benefitsItem {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #434a57;
  font-size: 14px;
  margin-top: 1rem;
  line-height: 20px;
}

.price {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #354052;

  &.discount {
    text-decoration-line: line-through;
    color: #939aa8;
    margin-right: 8px;
  }
}
</style>

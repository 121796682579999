import { numberTimeToString, timeStringToNumber } from "./date";
import { convertUTCDateToLocalDateToHM } from "./utcDateToLocalDate";
import axios from "axios";
import dayjs from "dayjs";
import QueryString from "qs";

export const getNamesFilterOptions = (list, selectedItems) => {
  const result = [];

  for (let i = 0; i < selectedItems.length; i++) {
    const foundItem = list.find((item) => item?.value || item?.id === selectedItems[i]);
    if (foundItem) {
      result.push(foundItem.label);
    }
  }

  return result;
};

export const getNamesFilterOptionsForRequest = (list, selectedItems) => {
  const result = [];

  for (let i = 0; i < selectedItems.length; i++) {
    const foundItem = list.find((item) => item?.id.toString() === selectedItems[i].toString());
    if (foundItem) {
      result.push(foundItem.title_en);
    }
  }

  return result;
};

export const getSortText = (sort_order, sort_type) => {
  if (sort_order == "DESC" && sort_type === "video_price") {
    return "Price high to low";
  }
  if (sort_order == "ASC" && sort_type === "video_price") {
    return "Price low to high";
  }
  if (sort_order == "DESC" && sort_type === "experience") {
    return "Experience high to low";
  }
  if (sort_order == "ASC" && sort_type === "experience") {
    return "Experience low to high";
  }
  return "Best match";
};

export const convertIntTimeToStringUTC = (time) => {
  const hours = timeStringToNumber(time);
  const date = dayjs().hour(hours).minute(0).second(0);
  const hourInUTC = convertUTCDateToLocalDateToHM(date);
  const result = hourInUTC.toString().replace(":", "");
  return result;
};

export const fetchTherapistByFiler = async (state, page) => {
  const params = {};
  const filters = getDifferentFilters(state.filterOptions, state.defaultValues);

  const isTimeFilter = (key) => {
    if (key === "preferred_time") return true;
  };

  const isRangeFilter = (key) => {
    if (key === "price") return true;
  };

  const isSingleChoice = (filter) => {
    if (filter.component === "DynamicSingleChoice") return true;
  };

  for (let i = 0; i < filters.length; i++) {
    if (isTimeFilter(filters[i].key)) {
      params[`${filters[i].key}_min`] = convertIntTimeToStringUTC(filters[i].value[0]);
      params[`${filters[i].key}_max`] = convertIntTimeToStringUTC(filters[i].value[1]);
    } else if (isRangeFilter(filters[i].key)) {
      params[`${filters[i].key}_min`] = filters[i].value[0];
      params[`${filters[i].key}_max`] = filters[i].value[1];
    } else if (isSingleChoice(filters[i])) {
      params[filters[i].key] = filters[i].value[0];
    } else {
      params[filters[i].key] = filters[i].value;
    }
  }

  const { data } = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/client/search/therapists`, {
    params: {
      page: page || 1,
      page_size: 7,
      ...(state.sort_by && { sort_by: state.sort_by }),
      ...(state.sort_order && { sort_order: state.sort_order }),
      ...(state.search && { q: state.search }),
      ...params
    },
    paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: "repeat" })
  });
  const result = { data, page: page };
  return result;
};

export const getFilterEvents = (filter) => {
  const activeFilters = {};

  const activeFiltersArray = getDifferentFilters(filter.filterOptions, filter.defaultValues);

  if (activeFiltersArray.length > 0) {
    activeFiltersArray.forEach((item) => {
      if (item.data.kind === "price_range") {
        activeFilters[`${item.key}_min`] = item.value[0];
        activeFilters[`${item.key}_max`] = item.value[1];
      } else if (item.data.kind === "time_range") {
        activeFilters[`${item.key}_min`] = convertIntTimeToStringUTC(item.value[0]);
        activeFilters[`${item.key}_max`] = convertIntTimeToStringUTC(item.value[1]);
      } else if (item.data.kind === "multiple_choice") {
        const selectedItems = item.data.choices;
        const selectedArray = [];

        item.value.forEach((selectedItem) => {
          const foundItem = selectedItems.find((choiceItem) => choiceItem.id.toString() === selectedItem.toString());
          if (foundItem) {
            selectedArray.push(foundItem.slug);
          }
        });

        if (selectedArray.length > 0) {
          activeFilters[item.key] = selectedArray;
        }
      } else if (item.data.kind === "single_choice") {
        const selectedItems = item.data.choices;
        const selectedArray = [];

        const foundItem = selectedItems.find((choiceItem) => choiceItem.id.toString() === item.value[0].toString());

        const selectedChoice = foundItem.slug || "";

        if (selectedArray) {
          activeFilters[item.key] = selectedChoice;
        }
      } else {
        activeFilters[item.key] = item.value;
      }
    });
  }

  const listOfEvents = {
    ...activeFilters,
    ...(filter.search && { search_query: filter.search }),
    results_count: filter.totalTherapists,
    sort_by: filter?.sort_by,
    sort_order: filter?.sort_order
  };

  return listOfEvents;
};

export const getDifferentFilters = (currentFilters, basicFilters) => {
  const appliedFilters = [];

  for (let i = 0; i < currentFilters.length; i++) {
    if (basicFilters[i].value instanceof Array) {
      if (JSON.stringify(currentFilters[i].value) !== JSON.stringify(basicFilters[i].value)) {
        appliedFilters.push(currentFilters[i]);
      }
    } else if (currentFilters[i].value !== basicFilters[i].value) {
      appliedFilters.push(currentFilters[i]);
    }
  }

  return appliedFilters;
};

export const getFilterComponent = (filter) => {
  const { kind, has_expanded_view } = filter;

  if (kind === "single_choice") {
    if (has_expanded_view) {
      return "DynamicSingleChoiceModal";
    }

    return "DynamicSingleChoice";
  } else if (kind === "boolean") {
    return "DynamicCheckbox";
  } else if (kind === "multiple_choice") {
    if (has_expanded_view) {
      return "DynamicMultipleChoiceModal";
    }

    return "DynamicMultipleChoice";
  } else if (kind === "price_range") {
    return "DynamicPriceRangeSlider";
  } else if (kind === "time_range") {
    return "DynamicTimeRangeSlider";
  }

  return null;
};

export const getFilterInitialValue = (filter) => {
  const { kind } = filter;
  if (kind === "single_choice") {
    return [];
  } else if (kind === "boolean") {
    return false;
  } else if (kind === "multiple_choice") {
    return [];
  } else if (kind === "price_range") {
    return [filter.range_params.min, filter.range_params.max];
  } else if (kind === "time_range") {
    return [numberTimeToString(filter.range_params.min), numberTimeToString(filter.range_params.max)];
  }

  return null;
};

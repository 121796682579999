<template>
  <modal
    width="360"
    name="sign-up-select-auth-modal"
    height="auto"
    @before-open="beforeOpenModal"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <SignUpComponent
      :isModal="true"
      @toSignIn="toSignIn"
      @signUpViaEmail="signUpViaEmail"
      @onSignUp="onSignUp"
      presentedFrom="Therapist Profile"
    />
  </modal>
</template>

<script>
import { setTrackingDevice } from "@/axios";
import SignUpComponent from "@/components/forms/SignUpComponent";
import storageClient from "@/services/storageClient";
import { mapState } from "vuex";

export default {
  name: "SignUpSelectAuthModal",
  components: { SignUpComponent },

  data() {
    return {
      additionalInfo: null,
      showPackagesModal: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("therapistSlots", ["showNextModal"])
  },

  methods: {
    beforeOpenModal(value) {
      if (value.params) {
        if (value.params.showPackagesModal) {
          this.showPackagesModal = value.params.showPackagesModal;
          return;
        }
        this.additionalInfo = value.params;
      }
    },

    toSignIn() {
      this.$modal.hide("sign-up-select-auth-modal");
      this.$modal.show("sign-in-modal", this.additionalInfo);
    },
    signUpViaEmail() {
      this.$modal.hide("sign-up-select-auth-modal");
      this.$modal.show("sign-up-via-email-modal", this.additionalInfo);
    },

    async toNextModal() {
      await setTrackingDevice();

      if (this.showNextModal?.modal) {
        this.$modal.show(this.showNextModal?.modal, this.showNextModal?.additionalInfo);
        return;
      }
      this.$modal.show("proceed-to-payment-modal");
    },

    async onSignUp() {
      await this.$store.dispatch("sessions/selectPackageOption", this.additionalInfo);
      await this.$modal.hide("sign-up-select-auth-modal");

      if (this.user && this.user?.show_basic_details_prompt === true) {
        this.$modal.show("additional-user-info-modal", this.additionalInfo);
      } else {
        this.toNextModal();
      }

      const signUpFrom = await storageClient.getSingUpFrom();
      const presentedFrom = await storageClient.getPresentedFrom();

      const trackData = {
        presented_from: presentedFrom || "",
        signup_from: signUpFrom || ""
      };

      this.$mixpanel.track("singup_userdata_view", trackData);
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      this.name = storageClient.getRegistrationFlowName();
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style lang="scss" scoped>
.authBtnWrapper {
  .authBtn {
    border: 2px solid #354052;
    filter: drop-shadow(0px 7px px 20px rgba(0, 0, 0, 0.07));
    border-radius: 4px;

    display: flex;
    justify-content: center;
    padding: 0.75em 1.5em 0.75em 3.5em;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    img {
      position: absolute;
      left: 24px;
      width: 21px;
      height: 21px;
    }
  }
}
</style>

<template>
  <div class="w-full">
    <input class="hidden inp-cbx" :type="type" :name="name" :value="value" :id="id" v-model="model" />
    <label
      :for="id"
      class="cbx block text-textBlue text-base px-6 py-4 mb-3 mx-1 custom-shadow rounded-12px select-none cursor-pointer transition-all duration-200 border border-white hover:border-ayadi-900 hover:bg-ayadi-300"
      :class="{ 'text-right': arabic }"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
export default {
  name: "FeedbackQuestion",
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    id: Number,
    name: String,
    value: String,
    checked: null,
    label: String,
    type: {
      default: "radio",
      type: String
    }
  },

  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
        this.$emit("toggle");
      }
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  }
};
</script>

<style lang="scss" scoped>
.inp-cbx:checked + .cbx {
  @apply border-ayadi-900 bg-ayadi-300;
}
</style>

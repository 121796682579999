import storageClient from "@/services/storageClient";

export default {
  beforeRouteEnter(to, from, next) {
    if (storageClient.getToken()) {
      return next();
    }
    return next({ name: "SignIn" });
  }
};

<template functional>
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2997 0.709971C12.9097 0.319971 12.2797 0.319971 11.8897 0.709971L6.99973 5.58997L2.10973 0.699971C1.71973 0.309971 1.08973 0.309971 0.699727 0.699971C0.309727 1.08997 0.309727 1.71997 0.699727 2.10997L5.58973 6.99997L0.699727 11.89C0.309727 12.28 0.309727 12.91 0.699727 13.3C1.08973 13.69 1.71973 13.69 2.10973 13.3L6.99973 8.40997L11.8897 13.3C12.2797 13.69 12.9097 13.69 13.2997 13.3C13.6897 12.91 13.6897 12.28 13.2997 11.89L8.40973 6.99997L13.2997 2.10997C13.6797 1.72997 13.6797 1.08997 13.2997 0.709971Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconToastClose"
};
</script>

<style scoped></style>

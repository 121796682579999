<template>
  <portal-target name="additional-portal-for-filter" multiple> </portal-target>
</template>
<script>
export default {
  name: "AdditionalFilterModal",

  data() {
    return {
      nameErrorText: "",
      genderErrorText: "",
      birthDateError: "",
      name: "",
      birthDate: null,
      gender: null
    };
  }
};
</script>

<style lang="scss" scoped>
.size-30 {
  width: 7.5rem;
  height: 7.5rem;
}

.clear-date {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.datePicker-error {
  border-color: rgba(240, 74, 67, var(--border-opacity));
}
</style>

<style>
.vd-picker__input-icon {
  margin-top: 0 !important;
  height: 100%;
  align-self: center;
}
</style>

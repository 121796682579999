<template>
  <modal
    width="360"
    name="not-available-modal"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white px-8 py-6 flex flex-col justify-center items-center rounded-lg">
      <div class="flex justify-between items-start w-full mb-4">
        <p class="text-18 font-semibold">We'll be available in your country soon!</p>
        <div class="cursor-pointer px-1 py-1" @click="$modal.hide('not-available-modal')">
          <IconCloseGray />
        </div>
      </div>

      <img class="my-4" src="../../../assets/images/NotAvailable.svg" alt="not available" />

      <p class="mb-3">
        Ayadi is pioneering accessible mental health in the Gulf region, but is unable to offer its services in your
        country at the moment.
      </p>

      <p class="mb-4">
        We're working on bringing our platform to you soon, so please follow us to stay updated on our journey.
      </p>
      <div class="flex flex-row justify-between items-start w-full">
        <SecondaryButton @click="$modal.hide('not-available-modal')" label="Close" medium />
        <MainButton type="medium"><a class="text-white no-underline" href="">Follow Ayadi on Instagram</a></MainButton>
      </div>
    </div>
  </modal>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
export default {
  name: "NotAvailableModal",
  components: { SecondaryButton, MainButton, IconCloseGray },
  methods: {
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style scoped></style>

<template>
  <modal
    name="additional-user-info-modal"
    height="auto"
    :width="430"
    :adaptive="true"
    :clickToClose="false"
    @before-open="beforeOpenModal"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white w-modal px-8 py-6 flex flex-col justify-center items-center rounded-lg">
      <div class="flex justify-center w-full mb-4" :class="{ 'flex-row-reverse': arabic }">
        <p class="text-18 font-semibold text-black">{{ $t("booking_flow.additional_info_title") }}</p>
      </div>

      <InputWrapper
        :label="$t('booking_flow.name')"
        :placeholder="$t('booking_flow.real_name')"
        :error-message="nameErrorText"
      >
        <input
          :dir="arabic ? 'rtl' : 'ltr'"
          autofocus
          type="text"
          slot="input"
          @focus="nameErrorText = ''"
          v-model="name"
        />
      </InputWrapper>

      <div class="w-full mb-6">
        <BaseInput :label="$t('auth.birthday')" class="w-full" :inputError="!!birthDateError" is-date-picker>
          <div
            slot="datepicker"
            class="border w-full my-1 rounded border-borderGray relative"
            :class="{ 'datePicker-error': birthDateError }"
          >
            <span v-if="birthDate" @click.prevent="birthDate = null" class="clear-date">
              <IconClose class="cursor-pointer clear-date absolute z-20" />
            </span>

            <VueDatePicker
              :value="new Date(Date.now())"
              v-model="birthDate"
              format="YYYY-M-D"
              format-header=""
              color="#44A4A5"
              :max-date="new Date()"
              :visible-years-number="80"
              placeholder=""
              @onChange="clearBirthDateError"
              @onOpen="changed = true"
            />
          </div>
        </BaseInput>
        <p class="text-ayadiRed text-xs font-normal w-full" v-if="birthDateError">{{ birthDateError }}</p>
      </div>
      <div class="w-full mb-8">
        <p class="text-base font-semibold text-black" :class="{ 'text-red-700': genderErrorText }">
          {{ $t("booking_flow.i_identify_as") }}
        </p>
        <div class="flex justify-between">
          <button
            @click="selectGender('female')"
            :class="{ 'bg-gray-200': gender === 'female' }"
            class="bg-white flex justify-between hover:bg-gray-100 focus:outline-none text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow"
          >
            <img src="../../../assets/images/Authorization/Female.png" class="w-6 mr-2 sm:hidden" alt="female" />
            {{ $t("booking_flow.female") }}
          </button>
          <button
            @click="selectGender('male')"
            :class="{ 'bg-gray-200': gender === 'male' }"
            class="bg-white flex justify-between hover:bg-gray-100 focus:outline-none text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow"
          >
            <img src="../../../assets/images/Authorization/Male.png" class="w-6 mr-2 sm:hidden" alt="male" />
            {{ $t("booking_flow.male") }}
          </button>
          <button
            @click="selectGender('unspecified')"
            :class="{ 'bg-gray-200': gender === 'unspecified' }"
            class="bg-white hover:bg-gray-100 focus:outline-none text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow"
          >
            {{ $t("booking_flow.other") }}
          </button>
        </div>
        <p v-if="genderErrorText" class="text-ayadiRed text-xs font-normal w-full mt-1">
          {{ genderErrorText }}
        </p>
      </div>

      <MainButton @click="updateProfile" class="w-full signup_method" id="updateProfile">{{
        $t("booking_flow.continue")
      }}</MainButton>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import { setTrackingDevice } from "@/axios";
import BaseInput from "@/components/ui-kit/BaseInput";
import storageClient from "@/services/storageClient";
import IconClose from "@/components/icons/IconClose";
import InputWrapper from "@/components/ui-kit/InputWrapper";
import MainButton from "@/components/ui-kit/MainButton";
import getClientAvatarByGender from "@/utils/getClientAvatarByGender";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

export default {
  name: "AdditionalUserInfoModal",
  components: { MainButton, IconClose, InputWrapper, VueDatePicker, BaseInput },

  data() {
    return {
      nameErrorText: "",
      genderErrorText: "",
      birthDateError: "",
      name: "",
      birthDate: null,
      gender: null,
      additionalInfo: null
    };
  },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    ...mapState("auth", ["user"]),
    ...mapState("sessions", ["selectedPackageOption"]),
    ...mapState("therapistSlots", ["showNextModal"])
  },

  methods: {
    beforeOpenModal(value) {
      if (value.params) {
        this.additionalInfo = value.params;
      }
      if (this.selectedPackageOption) {
        this.additionalInfo = this.selectedPackageOption;
      }
    },

    clearBirthDateError() {
      this.birthDateError = "";
    },

    selectGender(value) {
      this.genderErrorText = "";
      this.gender = value;
    },

    async toNextModal() {
      if (this.showNextModal?.modal) {
        this.$modal.show(this.showNextModal?.modal, this.showNextModal?.additionalInfo);
        return;
      }
      this.$modal.show("proceed-to-payment-modal");
    },

    async updateProfile() {
      if (this.name.length < 2) {
        this.nameErrorText = this.$t("auth.name_required");
        return;
      }
      if (!this.birthDate) {
        this.birthDateError = this.$t("auth.date_of_birth_required");
        return;
      }
      if (!this.gender) {
        this.genderErrorText = this.$t("auth.gender_required");
        return;
      }

      try {
        await this.$store.dispatch("auth/updateProfile", {
          name: this.name,
          full_name: this.name,
          name_type: "REAL",
          gender: this.gender,
          birth_date: this.birthDate,
          avatar_id: getClientAvatarByGender(this.gender),
          time: dayjs().unix()
        });

        const signUpFrom = await storageClient.getSingUpFrom();

        const trackData = {
          "Device type": "Web",
          signup_from: signUpFrom || ""
        };

        window.webengage.track("successfull_Registration_userdata", trackData);
        this.$mixpanel.track("successfull_Registration_userdata", trackData);
        this.$intercom.trackEvent("successfull_Registration_userdata", trackData);

        this.$kochava.sendEvent("Registration Complete", {
          name: "sign_up",
          user_id: this.user?.id || null,
          now_date: new Date()
        });

        await setTrackingDevice();

        this.$modal.hide("additional-user-info-modal");
        this.toNextModal();
      } catch (e) {
        console.error(e);
      }
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      if (this.user && this.user.gender) {
        this.gender = this.user.gender;
      }
      if (this.user && this.user.name) {
        this.name = this.user.name;
      }
      if (this.user && this.user.birth_date) {
        this.birthDate = this.user.birth_date;
      }
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style lang="scss" scoped>
.size-30 {
  width: 7.5rem;
  height: 7.5rem;
}

.clear-date {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.datePicker-error {
  border-color: rgba(240, 74, 67, var(--border-opacity));
}
</style>

<style>
.vd-picker__input-icon {
  margin-top: 0 !important;
  height: 100%;
  align-self: center;
}
</style>

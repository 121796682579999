<template>
  <div v-if="showFeedback" class="w-full h-full absolute top-0 left-0 z-999 flex justify-center items-center modal">
    <!-- Stars modal -->
    <div
      v-if="step === 1"
      class="bg-ayadi-900 px-6 py-16 relative w-540px flex flex-col justify-center items-center rounded-12px"
    >
      <img
        @click="close"
        class="absolute top-6 right-6 cursor-pointer"
        src="../assets/images/IconClose.svg"
        alt="close"
      />
      <img src="../assets/images/QuestionAyadi.svg" alt="Question Ayadi" />
      <p class="text-18 font-semibold text-white text-center" :dir="direction">
        {{ $t("feedback.how_was", { name: name }) }}
      </p>
      <RatingStars :value="value" @click="setRating" class="mt-4" :class="{ 'flex-row-reverse': arabic }" />
    </div>

    <!--    Questions modal -->

    <div
      v-if="step === 2"
      class="bg-white px-6 relative w-540px h-500px overflow-y-scroll flex flex-col justify-center items-start rounded-12px relative"
    >
      <div class="sticky top-0 bg-white pt-6 pb-2 w-full">
        <div class="w-full flex flex-row justify-between items-start" :class="{ 'flex-row-reverse': arabic }">
          <p class="text-18 font-semibold text-textBlue" :class="{ 'w-full text-right': arabic }" :dir="direction">
            {{ $t("feedback.expectation", { name: name }) }}
          </p>
          <img @click="close" class="cursor-pointer" src="../assets/images/IconCloseDark.svg" alt="close" />
        </div>
        <p class="text-left text-lightGray py-4" :class="{ 'w-full text-right': arabic }">
          {{ $t("feedback.how_it_went") }}
          <!--          , or skip this question if you don’t want to answer.-->
        </p>
      </div>

      <div class="w-full overflow-y-scroll">
        <!--        :class="{ 'border-ayadi-900 bg-ayadi-300': isActive(question.question) }"-->
        <FeedbackQuestion
          v-for="question in questions"
          :key="question.id"
          :id="question.id"
          :value="question.question"
          :label="question.question"
          v-model="answer"
        >
        </FeedbackQuestion>
      </div>

      <div
        class="flex flex-row justify-between items-center w-full sticky bottom-0 bg-white pt-4 pb-6"
        :class="{ 'flex-row-reverse': arabic }"
      >
        <SecondaryButton @click="step = 1" :label="$t('feedback.back')" large />
        <MainButton @click="selectQuestion" :disabled="!answer" type="large">{{ $t("feedback.continue") }}</MainButton>
      </div>
    </div>

    <div v-if="step === 3" class="bg-white p-6 relative w-540px flex flex-col justify-center items-start rounded-12px">
      <div class="w-full flex flex-row justify-between items-center mb-4" :class="{ 'flex-row-reverse': arabic }">
        <p class="text-18 font-semibold text-textBlue">{{ $t("feedback.improve") }}</p>
        <img @click="close" class="cursor-pointer" src="../assets/images/IconCloseDark.svg" alt="close" />
      </div>
      <!--      <p class="text-left text-lightGray mb-2">Kindly share any thoughts on your session experience.</p>-->
      <textarea-autosize
        :dir="direction"
        autofocus
        :placeholder="$t('feedback.tell_more')"
        id="input"
        class="w-full rounded py-2 mb-8 bg-white text-sm text-textBlue border border-tileOutline resize-none outline-none"
        :class="{ 'pl-3 pr-10': !arabic, 'pl-10 pr-3': arabic }"
        autocomplete="off"
        v-model="text"
        :min-height="180"
        rows="1"
        :max-height="180"
      />
      <div class="flex flex-row justify-between items-center w-full" :class="{ 'flex-row-reverse': arabic }">
        <SecondaryButton @click="step = 2" :label="$t('feedback.back')" large />
        <MainButton @click="sendComment" :disabled="disabled" type="large">{{ $t("feedback.submit") }}</MainButton>
      </div>
    </div>
  </div>
</template>

<script>
import RatingStars from "@/components/RatingStars";
import axios from "axios";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import MainButton from "@/components/ui-kit/MainButton";
import { mapState } from "vuex";
import FeedbackQuestion from "@/components/ui-kit/FeedbackQuestion";
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";
export default {
  name: "AfterSessionFeedback",
  components: { FeedbackQuestion, MainButton, SecondaryButton, RatingStars },
  data() {
    return {
      step: 1,
      value: 0,
      text: "",
      selectedQuestion: null,
      answer: "",
      showFeedback: false
    };
  },
  props: {
    id: Number,
    isChat: Boolean,
    name: String,
    lastSession: null
  },

  watch: {
    step() {
      if (this.step === 2) {
        // this.analytics();
        if (this.user) {
          const trackData = {
            Nickname: this.user?.name || "",
            Gender: this.user?.gender || "",
            Language: storageClient.getLanguage(),
            email: this.user?.email || "",
            Availability: this.user?.has_any_sessions || "",
            "Therapist Name": this.lastSession?.therapist?.full_name || "",
            "Session Type": this.lastSession?.type || "",
            "Session Price": this.lastSession?.price || "",
            "Audio Video Rating": this.value,
            time: dayjs().unix()
          };

          window.webengage.track("session_feedback_view2", trackData);
          this.$mixpanel.track("session_feedback_view2", trackData);
          this.$intercom.trackEvent("session_feedback_view2", trackData);
        }
      }

      if (this.step === 3) {
        // this.analytics();
        if (this.user) {
          const trackData = {
            Nickname: this.user?.name || "",
            Gender: this.user?.gender || "",
            Language: storageClient.getLanguage(),
            email: this.user?.email || "",
            Availability: this.user?.has_any_sessions || "",
            "Therapist Name": this.lastSession?.therapist?.full_name || "",
            "Session Type": this.lastSession?.type || "",
            "Session Price": this.lastSession?.price || "",
            "Audio Video Rating": this.value || "",
            "Session Expectation": this.answer || "",
            time: dayjs().unix()
          };

          window.webengage.track("session_feedback_view3", trackData);
          this.$mixpanel.track("session_feedback_view3", trackData);
          this.$intercom.trackEvent("session_feedback_view3", trackData);
        }
      }
    }
  },

  computed: {
    disabled() {
      let trimmed = this.text.replace(/^\s+/, "").replace(/\s+$/, "");
      return this.text.length === 0 || !trimmed;
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    ...mapState("sessions", ["questions"]),
    ...mapState("auth", ["user", "notifications"])
  },

  methods: {
    async setRating(value) {
      this.value = value;
      // this.analytics();

      const trackData = {
        Nickname: this.user.name,
        Gender: this.user.gender,
        Language: storageClient.getLanguage(),
        email: this.user.email,
        Availability: this.user.has_any_sessions,
        "Therapist Name": this.lastSession.therapist.full_name,
        "Session Type": this.lastSession.type,
        "Session Price": this.lastSession.price,
        "Audio Video Rating": value,
        time: dayjs().unix()
      };

      window.webengage.track("audio_video_rated", trackData);
      this.$mixpanel.track("audio_video_rated", trackData);
      this.$intercom.trackEvent("audio_video_rated", trackData);

      await axios.post(`/api/client/sessions/${this.id}/feedback`, { stars: value });
      setTimeout(() => {
        if (this.questions.length > 0) {
          this.step = 2;
        } else {
          this.step = 3;
        }
      }, 300);
    },

    async sendComment() {
      // this.analytics();

      const trackData = {
        Nickname: this.user.name,
        Gender: this.user.gender,
        Language: storageClient.getLanguage(),
        email: this.user.email,
        Availability: this.user.has_any_sessions,
        "Therapist Name": this.lastSession.therapist.full_name,
        "Session Type": this.lastSession.type,
        "Session Price": this.lastSession.price,
        "Audio Video Rating": this.value,
        "Session Expectation": this.answer,
        time: dayjs().unix()
      };

      window.webengage.track("feedback_submitted", trackData);
      this.$mixpanel.track("feedback_submitted", trackData);
      this.$intercom.trackEvent("feedback_submitted", trackData);

      await axios.post(`/api/client/sessions/${this.id}/feedback`, { text: this.text });
      await this.$store.dispatch("sessions/getPastSessions", 0);
      this.$notify({
        group: "success",
        text: this.$t("feedback.feedback_submitted"),
        classes: "toast-error"
      });
      this.step = 1;
    },

    async selectQuestion() {
      // this.analytics();
      const trackData = {
        Nickname: this.user.name,
        Gender: this.user.gender,
        Language: storageClient.getLanguage(),
        email: this.user.email,
        Availability: this.user.has_any_sessions,
        "Therapist Name": this.lastSession.therapist.full_name,
        "Session Type": this.lastSession.type,
        "Session Price": this.lastSession.price,
        "Audio Video Rating": this.value,
        "Session Expectation": this.answer,
        time: dayjs().unix()
      };

      window.webengage.track("session_expectation_feedback", trackData);
      this.$mixpanel.track("session_expectation_feedback", trackData);
      this.$intercom.trackEvent("session_expectation_feedback", trackData);

      await axios.post(`/api/client/sessions/${this.id}/feedback`, {
        question: this.answer.length > 1 ? this.answer : ""
      });
      this.step = 3;
    },

    async close() {
      if (this.user) {
        const trackData = {
          Nickname: this.user.name,
          Gender: this.user.gender,
          Language: storageClient.getLanguage(),
          email: this.user.email,
          Availability: this.user.has_any_sessions,
          "Therapist Name": this.lastSession.therapist.full_name,
          "Session Type": this.lastSession.type,
          "Session Price": this.lastSession.price,
          "Audio Video Rating": this.value,
          "Session Expectation": this.answer,
          time: dayjs().unix()
        };
        window.webengage.track("feedback_closed", trackData);

        this.$mixpanel.track("feedback_closed", trackData);
        this.$intercom.trackEvent("feedback_closed", trackData);
      }
      if (this.step === 1) {
        await axios.post(`/api/client/sessions/${this.id}/feedback`, {});
      }
      await this.$store.dispatch("sessions/getPastSessions", 1);
    }
  },

  async created() {
    await this.$store.dispatch("sessions/getFeedbackQuestions");

    if (!this.user) {
      await this.$store.dispatch("auth/getProfile");
    }
    await this.$store.dispatch("auth/getNotifications");
    this.$nextTick(() => {
      this.showFeedback = true;
    });

    if (this.user) {
      const trackData = {
        Nickname: this.user?.name || "",
        Gender: this.user?.gender || "",
        Language: storageClient.getLanguage(),
        email: this.user?.email || "",
        Availability: this.user?.has_any_sessions || "",
        "Therapist Name": this.lastSession?.therapist?.full_name || "",
        "Session Type": this.lastSession?.type || "",
        "Session Price": this.lastSession?.price || "",
        time: dayjs().unix()
      };

      window.webengage.track("session_feedback_view1", trackData);
      this.$mixpanel.track("session_feedback_view1", trackData);
      this.$intercom.trackEvent("session_feedback_view1", trackData);
    }
  }
};
</script>

<style lang="scss" scoped>
.z-999 {
  z-index: 999;
}
.top-6 {
  top: 1.5rem;
}

.right-6 {
  right: 1.5rem;
}
.w-540px {
  width: 540px;
}
.h-500px {
  height: 500px;
}

.custom-shadow {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.0669441);
}
</style>

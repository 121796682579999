import Vue from "vue";
import VueRouter from "vue-router";
import dayjs from "dayjs";
require("dayjs/locale/ar");
require("dayjs/locale/en");
// import components
import Auth from "@/views/Auth";
import SignIn from "@/components/auth/SignIn";
import Video from "@/views/Video";
import HTTPError from "@/views/HTTPError";
import SignUp from "@/components/auth/SignUp";
import ForgotPassword from "@/components/auth/ForgotPassword";
import NewPassword from "@/components/auth/NewPassword";
import NoUpcomingSessions from "@/components/video/NoUpcomingSessions";
import Chat from "@/components/chat/Chat";
import TherapistProfile from "@/views/TherapistProfile";
import NotAvailableInCountry from "@/components/auth/NotAvailableInCountry";
import PaymentSuccess from "@/views/PaymentSuccess";
import PaymentByPackageSuccess from "@/views/PaymentByPackageSuccess";
import i18n from "@/i18n";
import storageClient from "@/services/storageClient";
import CircleAuth from "@/views/CircleAuth";
import TherapistList from "@/views/TherapistList";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Auth",
    component: Auth,
    meta: { requiresGuest: true },
    redirect: {
      name: "SignIn"
    },
    children: [
      { path: "/apple/sign-in/apple-redirect", component: SignIn, meta: { requiresGuest: true } },
      { path: "/sign-in", name: "SignIn", props: true, component: SignIn, meta: { requiresGuest: true } },
      { path: "/sign-up", name: "SignUp", component: SignUp, meta: { requiresGuest: true } },
      { path: "/forgot-password", name: "ForgotPassword", component: ForgotPassword, meta: { requiresGuest: true } },
      {
        path: "/reset-password",
        props: (route) => ({ token: route.query.token, email: route.query.email }),
        name: "NewPassword",
        component: NewPassword,
        meta: { requiresGuest: true }
      },
      { path: "/not-available", name: "NotAvailable", component: NotAvailableInCountry, meta: { requiresGuest: true } }
    ]
  },
  {
    path: "/video",
    name: "Video",
    component: Video,
    meta: {
      requiresAuth: true
    }
  },
  { path: "/oauth-circle", name: "CircleAuth", component: CircleAuth },
  {
    path: "/therapists/:id",
    name: "ProfileRedirect",
    redirect: (to) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        return `/en/therapists/${to.params.id}`;
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        return `/ar/therapists/${to.params.id}`;
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return `/en/therapists/${to.params.id}`;
    }
  },
  {
    path: "/therapists/:id/review",
    name: "ProfilePackageReviewRedirect",
    redirect: (to) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        return `/en/therapists/${to.params.id}/review`;
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        return `/ar/therapists/${to.params.id}/review`;
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return `/en/therapists/${to.params.id}/review`;
    }
  },
  {
    path: "/therapists/:id/products",
    name: "ProfilePackageProductsRedirect",
    redirect: (to) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        return `/en/therapists/${to.params.id}/products`;
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        return `/ar/therapists/${to.params.id}/products`;
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return `/en/therapists/${to.params.id}/products`;
    }
  },
  {
    path: "/therapists/:id/package_order_success",
    name: "ProfilePackageSuccessModalRedirect",
    redirect: (to) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        return `/en/therapists/${to.params.id}/package_order_success`;
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        return `/ar/therapists/${to.params.id}/package_order_success`;
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return `/en/therapists/${to.params.id}/package_order_success`;
    }
  },
  {
    path: "/therapists/:id/package_order_failed",
    name: "ProfilePackageFailedModalRedirect",
    redirect: (to) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        return `/en/therapists/${to.params.id}/package_order_failed`;
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        return `/ar/therapists/${to.params.id}/package_order_failed`;
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return `/en/therapists/${to.params.id}/package_order_failed`;
    }
  },
  {
    path: "/therapists/:id/session_order_failed",
    name: "ProfileSessionFailedModalRedirect",
    redirect: (to) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        return `/en/therapists/${to.params.id}/session_order_failed`;
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        return `/ar/therapists/${to.params.id}/session_order_failed`;
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return `/en/therapists/${to.params.id}/session_order_failed`;
    }
  },
  {
    path: "/:locale/therapists/:id",
    name: "TherapistProfile",
    component: TherapistProfile,
    props: (route) => ({
      orderId: route.query.orderId,
      type: route.query.type,
      count: route.query.count,
      paymentStatus: route.query.paymentStatus
    }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      dayjs.locale(locale);
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        dayjs.locale(locale);
        return next(`/en/therapists/${to.params.id}`);
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/:locale/therapists/:id/review",
    name: "TherapistProfileReview",
    component: TherapistProfile,
    props: (route) => ({
      typeModal: "review",
      type: route.query.type,
      count: route.query.count,
      session_id: route.query.session_id
    }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      dayjs.locale(locale);
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        dayjs.locale(locale);
        return next(`/en/therapists/${to.params.id}/review`);
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/:locale/therapists/:id/products",
    name: "TherapistProfileProducts",
    component: TherapistProfile,
    props: (route) => ({
      typeModal: "products",
      type: route.query.type,
      count: route.query.count,
      session_type: route.query.session_type
    }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      dayjs.locale(locale);
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        dayjs.locale(locale);
        return next(`/en/therapists/${to.params.id}`);
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/:locale/therapists/:id/package_order_success",
    name: "PackagesComplete",
    component: TherapistProfile,
    props: (route) => ({
      packagesOrderId: route.query.orderId
    }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        return next(`/en/therapist/${to.params.id}`);
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/:locale/therapists/:id/package_order_failed",
    name: "PackagesFailed",
    component: TherapistProfile,
    props: (route) => ({
      packagesOrderId: route.query.orderId
    }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        return next(`/en/therapist/${to.params.id}`);
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/:locale/therapists/:id/session_order_failed",
    name: "SessionFailed",
    component: TherapistProfile,
    props: (route) => ({
      orderId: route.query.orderId
    }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        return next(`/en/therapist/${to.params.id}`);
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/therapists/:id/packages_complete",
    name: "PackagesCompleteNotLocale",
    component: TherapistProfile,
    props: (route) => ({ sessionType: route.query.type }),
    beforeEnter: async (to, from, next) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        if (to.query?.type) {
          return next(`/en/therapists/${to.params.id}/packages_complete`);
        }
        return next(`/en/therapists/${to.params.id}/packages_complete`);
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        if (to.query?.type) {
          return next(`/ar/therapists/${to.params.id}/packages_complete`);
        }
        return next(`/ar/therapists/${to.params.id}/packages_complete`);
      }

      if (to.query?.type) {
        return next(`/en/therapists/${to.params.id}/packages_complete`);
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return next(`/en/therapists/${to.params.id}/packages_complete`);
    }
  },
  {
    path: "/:locale/therapists/:id/sessions_availability",
    name: "SessionAvailability",
    component: TherapistProfile,
    props: (route) => ({ sessionType: route.query.type }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      if (!supported_locales.includes(locale)) {
        dayjs.locale("en");
        i18n.locale = "en";
        storageClient.setLanguage("en");
        return next(`/en/therapist/${to.params.id}`);
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/therapists/:id/sessions_availability",
    name: "SessionAvailabilityNotLocale",
    component: TherapistProfile,
    props: (route) => ({ sessionType: route.query.type }),
    beforeEnter: async (to, from, next) => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        if (to.query?.type) {
          return next(`/en/therapists/${to.params.id}/sessions_availability?type=${to.query?.type}`);
        }
        return next(`/en/therapists/${to.params.id}/sessions_availability`);
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        if (to.query?.type) {
          return next(`/ar/therapists/${to.params.id}/sessions_availability?type=${to.query?.type}`);
        }
        return next(`/ar/therapists/${to.params.id}/sessions_availability`);
      }

      if (to.query?.type) {
        return next(`/en/therapists/${to.params.id}/sessions_availability?type=${to.query?.type}`);
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return next(`/en/therapists/${to.params.id}/sessions_availability`);
    }
  },
  {
    path: "/:locale/therapists/:id/confirmation",
    name: "CheckConfirmation",
    component: TherapistProfile,
    props: (route) => ({ sessionId: route.query.session_id, therapistId: route.params.id }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        dayjs.locale("en");
        return next(`/en/therapist/${to.params.id}`);
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale;
        dayjs.locale(locale);
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/therapists/:id/confirmation",
    name: "CheckConfirmationNotLocale",
    component: TherapistProfile,
    props: (route) => ({ sessionId: route.query.session_id, therapistId: route.params.id }),
    beforeEnter: async (to, from, next) => {
      const locale = storageClient.getLanguage();
      dayjs.locale(locale);
      if (locale === "en") {
        i18n.locale = "en";
        if (to.query?.session_id) {
          return next(`/en/therapists/${to.params.id}/confirmation?session_id=${to.query?.session_id}`);
        }
        return next(`/en/therapists/${to.params.id}/confirmation`);
      } else if (locale === "ar") {
        i18n.locale = "ar";
        if (to.query?.session_id) {
          return next(`/en/therapists/${to.params.id}/confirmation?session_id=${to.query?.session_id}`);
        }
        return next(`/ar/therapists/${to.params.id}/confirmation`);
      }

      if (to.query?.session_id) {
        return next(`/en/therapists/${to.params.id}/confirmation?session_id=${to.query?.session_id}`);
      }

      i18n.locale = "en";
      storageClient.setLanguage("en");
      return next(`/en/therapists/${to.params.id}/confirmation`);
    }
  },
  {
    path: "/therapists",
    name: "TherapistListRedirect",
    redirect: () => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        i18n.locale = "en";
        dayjs.locale("en");
        return "/en/therapists";
      } else if (locale === "ar") {
        dayjs.locale("ar");
        i18n.locale = "ar";
        return "/ar/therapists";
      }
      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return "/en/therapists";
    }
  },
  {
    path: "/:locale/therapists",
    name: "TherapistList",
    component: TherapistList,
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      if (supported_locales.includes(locale)) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
        return next();
      } else {
        dayjs.locale("en");
        i18n.locale = "en";
        storageClient.setLanguage("en");
        return next(`/en/therapists`);
      }
    }
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/no-upcoming-sessions",
    name: "NoUpcomingSessions",
    component: NoUpcomingSessions
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    props: (route) => ({ orderId: route.query.orderId }),
    component: PaymentSuccess
  },
  {
    path: "/:locale/session_order_success",
    name: "PaymentSessionSuccess",
    component: PaymentSuccess,
    props: (route) => ({
      orderId: route.query.orderId
    }),
    beforeEnter: async (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales = ["en", "ar"];
      if (!supported_locales.includes(locale)) {
        i18n.locale = "en";
        storageClient.setLanguage("en");
        return next("/en/session_order_success");
      }
      if (i18n.locale !== locale) {
        dayjs.locale(locale);
        i18n.locale = locale;
        storageClient.setLanguage(locale);
      }
      return next();
    }
  },
  {
    path: "/session_order_success",
    name: "PaymentSessionSuccessRedirect",
    redirect: () => {
      const locale = storageClient.getLanguage();
      if (locale === "en") {
        dayjs.locale(locale);
        i18n.locale = "en";
        return "/en/session_order_success";
      } else if (locale === "ar") {
        dayjs.locale(locale);
        i18n.locale = "ar";
        return "/ar/session_order_success";
      }

      dayjs.locale("en");
      i18n.locale = "en";
      storageClient.setLanguage("en");
      return "/en/session_order_success";
    }
  },

  {
    path: "/payment-package-success",
    name: "PaymentByPackageSuccess",
    props: (route) => ({
      order: route.params.order,
      therapistProfile: route.params.therapistProfile,
      packages: route.params.packages
    }),
    component: PaymentByPackageSuccess
  },
  {
    path: "/oops",
    name: "HTTPError",
    props: (route) => ({ errorStatus: route.query.errorStatus }),
    beforeEnter: async (to, from, next) => {
      if (to && to?.params && to?.params?.pathMatch && to?.params?.pathMatch?.includes("//")) {
        window.location.href = "/oops";
      }

      return next();
    },
    component: HTTPError
  },
  {
    path: "*",
    beforeEnter: async (to, from, next) => {
      return next();
    },
    redirect: {
      name: "HTTPError"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router;

<template>
  <div class="flex flex-col items-center">
    <div class="flex justify-center">
      <img class="mb-1" :class="arabic ? 'ml-2' : 'mr-2'" src="../../assets/images/icons/gift_green.svg" alt="gift" />
      <p class="text-success font-semibold">{{ $t("coupons.coupon_applied") }}</p>
    </div>
    <p class="font-semibold text-success" v-if="coupon.max_uses > 1 && coupon.remaining_uses > 1">
      {{ $t("coupons.coupon_more_use", { uses: coupon.remaining_uses - 1, date: getCouponExpiryDate }) }}
    </p>
    <p class="font-semibold text-success" v-if="coupon.max_uses > 1 && coupon.remaining_uses === 1">
      {{ $t("coupons.coupon_last_use", { uses: coupon.max_uses }) }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";

export default {
  name: "CouponState",
  computed: {
    ...mapState("sessions", ["coupon"]),
    getCouponExpiryDate() {
      return dayjs(this.coupon.ends_at).format("DD MMM YYYY");
    },
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  }
};
</script>

<style scoped></style>

<template>
  <div class="h-screen max-h-screen bg-gradient flex flex-col justify-between items-center text-white">
    <transition name="fade">
      <Messenger
        v-if="isChatOpen"
        @close="isChatOpen = false"
        :active-chat="latestChat"
        :therapist-avatar="getTherapistAvatar"
        :therapist-name="getTherapistName"
      />
    </transition>
    <!--    todo computed for props-->
    <AfterSessionFeedback
      :id="lastSession ? lastSession.id : null"
      :is-chat="false"
      :name="therapistName"
      :last-session="lastSession"
      v-if="!haveFeedback"
    />
    <LogoLight class="mt-16" />
    <SecondaryButton class="logout" medium @click="logout" :label="$t('main.logout')" />
    <div class="flex flex-col justify-center items-center">
      <h2 class="text-2xl mb-6 sm:px-4">{{ $t("no_sessions.no_upcoming") }}</h2>

      <SecondaryButton medium @click="bookSession" :label="$t('profiles.book')" />
      <div v-if="latestChat" class="flex justify-center items-center flex-col">
        <div class="my-6 h-px w-40 bg-divider"></div>
        <p class="text-base font-semibold text-white mb-4 sm:px-4">
          {{ $t("messenger.want_to_check") }}
        </p>
        <SecondaryButton @click="openChat" medium :label="$t('messenger.open_conversation')">
          <span class="mr-2" slot="icon">
            <MessageIcon :have-unread="haveUnread > 0" button />
          </span>
        </SecondaryButton>
      </div>
    </div>
    <AdditionalProfileUserInfoModal />
    <FooterInfo class="mb-6" />
  </div>
</template>

<script>
import requiresAuth from "@/mixins/requiresAuth";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import FooterInfo from "@/components/FooterInfo";
import { mapState } from "vuex";
import store from "@/store";
import AfterSessionFeedback from "@/components/AfterSessionFeedback";
import LogoLight from "@/components/ui-kit/LogoLight";
import AdditionalProfileUserInfoModal from "@/components/modals/AdditionalProfileUserInfoModal";
import Messenger from "@/components/chat/Messenger";
import axios from "axios";
import MessageIcon from "@/components/chat/MessageIcon";
import storageClient from "@/services/storageClient";
import { Client as ConversationsClient } from "@twilio/conversations";
import dayjs from "dayjs";

export default {
  name: "NoUpcomingSessions",
  components: {
    MessageIcon,
    Messenger,
    AdditionalProfileUserInfoModal,
    AfterSessionFeedback,
    FooterInfo,
    SecondaryButton,
    LogoLight
  },
  head: {
    title() {
      return {
        inner: this.arabic ? "أيادي" : "Ayadi",
        separator: "|",
        complement: this.arabic ? "الصفحة الرئيسية" : "Home"
      };
    }
  },

  data() {
    return {
      isChatOpen: false,
      latestChat: null,
      activeChat: null,
      haveUnread: 0,
      conversationClient: null
    };
  },
  computed: {
    ...mapState("sessions", ["activeSession", "lastSession"]),
    ...mapState("auth", ["user"]),
    ...mapState("therapistProfiles", ["therapistProfile"]),
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    haveFeedback() {
      return this.lastSession ? this.lastSession.client_feedback : true;
    },
    therapistName() {
      if (this.lastSession) {
        return this.lastSession.therapist ? this.lastSession.therapist.full_name || "Anonymous" : "Anonymous";
      } else {
        return null;
      }
    },

    getTherapistName() {
      return this.therapistProfile?.full_name;
    },

    getTherapistAvatar() {
      return this.therapistProfile?.avatar;
    }
  },
  mixins: [requiresAuth],
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },

    bookSession() {
      if (this.lastSession) {
        window.location.href = `http://go.ayadihealth.co/${this.arabic ? "/ar" : "/en"}/therapists/${
          this.lastSession.therapist.id
        }--${this.lastSession.therapist.name_en}`;
      } else {
        if (this.arabic) {
          window.location.href = "http://go.ayadihealth.co/ar/therapists";
        } else {
          window.location.href = "http://go.ayadihealth.co/en/therapists";
        }
      }
    },

    openChat() {
      this.isChatOpen = true;
      window.webengage.track("home_inbox_click", {
        "Device type": "Web",
        "Notification count": this.haveUnread || "0"
      });
      this.$mixpanel.track("home_inbox_click", {
        "Device type": "Web",
        "Notification count": this.haveUnread || "0",
        time: dayjs().unix()
      });
      this.$intercom.trackEvent("home_inbox_click", {
        "Device type": "Web",
        "Notification count": this.haveUnread || "0"
      });
    }
  },

  async created() {
    await store.dispatch("sessions/fetchSessions");
    await store.dispatch("auth/getProfile");

    let session = await store.getters["sessions/getActiveSession"];
    if (session) {
      return this.$router.push({ name: "Video" }).catch(() => {});
    }

    await store.dispatch("sessions/getPastSessions");

    if (this.user && this.user?.show_basic_details_prompt === true) {
      this.$modal.show("additional-profile-user-info-modal");
      const signUpFrom = await storageClient.getSingUpFrom();
      const presentedFrom = await storageClient.getPresentedFrom();

      const trackData = {
        presented_from: presentedFrom || "",
        signup_from: signUpFrom || ""
      };

      this.$mixpanel.track("singup_userdata_view", trackData);
    }

    const { data } = await axios.get("/api/client/chat-rooms-latest");

    if (data) {
      this.latestChat = data.sid;
      await this.$store.dispatch("therapistProfiles/getTherapistProfile", data.therapist_id);
      if (this.chatToken) {
        this.conversationClient = await ConversationsClient.create(this.chatToken);
        this.activeChat = await this.conversationClient.getConversationBySid(this.latestChat);

        setInterval(async () => {
          this.haveUnread = await this.activeChat.getUnreadMessagesCount();
        }, 1000);
      }
    } else {
      this.latestChat = null;
    }
    if (this.user) {
      this.$intercom.boot({
        user_id: this.user?.id || "",
        name: this.user.name,
        email: this.user.email,
        video_session_count: this.user.video_orders_count,
        chat_session_count: this.user.chat_orders_count,
        gender: this.user.gender,
        language: storageClient.getLanguage(),
        user: "patient"
      });
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.logout {
  position: absolute;
  top: 24px;
  right: 24px;
}
</style>

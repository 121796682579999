<template>
  <modal
    name="payment-failed-modal"
    width="432"
    height="auto"
    @before-open="beforeOpen"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white px-8 py-6 flex flex-col justify-center items-center rounded-lg">
      <div class="flex justify-between items-start w-full mb-4" :class="{ 'flex-row-reverse': arabic }">
        <p class="text-18 font-semibold">{{ $t("payment_error.failed") }}</p>
        <div class="cursor-pointer px-1 py-1" @click="closePaymentFailed">
          <IconCloseGray />
        </div>
      </div>

      <div class="w-full flex flex-col justify-center items-center">
        <img src="../../../assets/images/illustrations/looking-for-results.svg" alt="search" />
        <div v-if="order">
          <p class="mt-4 text-base" v-if="order.failed_reason">{{ $t("payment_error.bank") }}</p>
          <p v-if="order.failed_reason" class="text-base my-2 font-semibold">“{{ order.failed_reason }}”</p>
        </div>

        <p class="mb-6 text-base">{{ $t("payment_error.try_or_know") }}</p>
        <MainButton @click="tryAgain" class="mb-2 w-full" type="medium">{{ $t("payment_error.try_again") }}</MainButton>
        <SecondaryButton @click="talkToCare" class="w-full" :label="$t('payment_error.talk_to_customer')" medium />
      </div>
    </div>
  </modal>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";

import MainButton from "@/components/ui-kit/MainButton";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";

export default {
  name: "PaymentFailedModal",
  components: { MainButton, SecondaryButton, IconCloseGray },

  data() {
    return {
      order: null
    };
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("therapistSlots", ["createdOrder"]),
    ...mapState("sessions", ["bookedPackagesForTherapist"]),
    ...mapState("auth", ["user"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    async beforeOpen(value) {
      if (value.params) {
        this.order = value.params?.order;
        return;
      }
    },

    tryAgain() {
      this.$modal.hide("payment-failed-modal");
      this.$modal.show("booking-options-modal");

      this.actionAfterFailed = "Try again";

      window.webengage.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$mixpanel.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$intercom.trackEvent("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
    },

    talkToCare() {
      if (this.order?.failed_reason) {
        this.$intercom.showNewMessage(
          this.$t("payment_error.payment_error_message", { error: this.order.failed_reason })
        );
      } else {
        this.$intercom.showNewMessage();
      }

      this.$modal.hide("payment-failed");

      this.actionAfterFailed = "Talk to customer care";

      window.webengage.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$mixpanel.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$intercom.trackEvent("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
    },

    closePaymentFailed() {
      this.$modal.hide("payment-failed-modal");
      this.actionAfterFailed = "Close";

      window.webengage.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$mixpanel.track("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
      this.$intercom.trackEvent("booking_paymentFailedAction_select", this.paymentFailedActionTrackData);
    },

    blockScroll() {
      console.log("open :>> ");
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style lang="scss" scoped>
.footerNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: white;
  width: 100%;
  padding-top: 1rem;
}
.priceWrapper {
  gap: 0.5rem;
}
.img-size {
  width: 7.5rem;
  height: 7.5rem;
}

.benefitsTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #434a57;
}

.benefitsItem {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #434a57;
  font-size: 14px;
  margin-top: 1rem;
  line-height: 20px;
}

.price {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #354052;

  &.discount {
    text-decoration-line: line-through;
    color: #939aa8;
    margin-right: 8px;
  }
}
</style>

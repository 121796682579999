import Vue from "vue";
import { setupAxios } from "@/axios";
import Notifications from "vue-notification";
import VModal from "vue-js-modal";
import Tooltip from "vue-directive-tooltip";
import VueIntercom from "vue-intercom";
import TextareaAutosize from "vue-textarea-autosize";
import i18n from "./i18n";
import VueHead from "vue-head";
import GAuth from "vue-google-oauth2";
import mixpanel from "mixpanel-browser";
import Clipboard from "v-clipboard";
import VueCardFormat from "vue-credit-card-validation";
import Rollbar from "rollbar";
import { Kochava } from "kochava";
import PortalVue from "portal-vue";

// local styles must be last style import
import App from "./App.vue";
import IconToastClose from "@/components/icons/IconToastClose";
import IconToastExclamation from "@/components/icons/IconToastExclamation";
import router from "./router";
import store from "./store";
import "./assets/style/style.scss";

Vue.use(Notifications);
Vue.use(TextareaAutosize);
Vue.component("close-toast", IconToastClose);
Vue.component("exclamation-toast", IconToastExclamation);
Vue.use(VModal);
Vue.use(Tooltip);
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID });
Vue.use(Clipboard);

Vue.use(VueHead, {
  separator: "",
  complement: ""
});

Vue.use(VueCardFormat);

mixpanel.init(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN, {});

Vue.prototype.$mixpanel = mixpanel;

const kochava = Kochava.createForVue();

kochava.startWithAppGuid(process.env.VUE_APP_KOCHAVA_APP_ID);

Vue.prototype.$kochava = kochava;

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_APP_ID_DEV,
  scope: "profile email",
  prompt: "select_account"
};

Vue.use(GAuth, gauthOption);

setupAxios(Vue);

Vue.config.productionTip = false;

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.VUE_APP_ROLLBAR_ENV,
  payload: {
    code_version: "1.0.0",
    custom_data: "client_web"
  }
});

Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err;
};

Vue.use(PortalVue);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");

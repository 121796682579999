<template>
  <div class="min-h-screen w-full bg-gradient overflow-hidden grid grid-rows-5 select-none">
    <div class="flex flex-col justify-center items-center row-span-1"></div>
    <div class="row-span-3">
      <router-view class="flex justify-center items-center" />
    </div>
    <FooterInfo />
  </div>
</template>

<script>
import FooterInfo from "@/components/FooterInfo";
import requiresGuest from "@/mixins/requiresGuest";

export default {
  name: "Auth",
  components: { FooterInfo },
  mixins: [requiresGuest],

  async created() {
    if (window.localStorage.getItem("isVideoOn")) {
      window.localStorage.removeItem("isVideoOn");
      return this.$router.go(0);
    }

    await this.$store.dispatch("common/getWhiteList");
  }
};
</script>

<style>
.logo-position {
  left: 50%;
  transform: translate(-50%, -2rem);
  margin: 0;
  top: -4rem;
}
</style>

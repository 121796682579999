<template>
  <modal
    @opened="blockScroll"
    @closed="unblockScroll"
    @before-open="beforeOpenModal"
    width="100%"
    height="auto"
    :adaptive="true"
    name="therapist-availability"
  >
    <div
      class="bg-white w-modal flex flex-col justify-start items-start rounded-lg max-h-animation overflow-y-scroll mx-auto w-full"
      :class="isLoading ? 'max-h-60' : 'max-h-90'"
      :dir="direction"
    >
      <div class="sticky top-0 left-0 w-full bg-white px-6 pt-6 pb-2">
        <div class="flex justify-between items-center w-full mb-4">
          <p class="text-18 font-semibold">{{ therapistProfile.full_name }} {{ $t("profiles.availability") }}</p>
          <div class="cursor-pointer px-1 py-1" @click="$modal.hide('therapist-availability')">
            <IconCloseGray />
          </div>
        </div>

        <div v-if="isShowTabs" class="flex justify-start items-end pr-6">
          <!--          <IconInfo :class="arabic ? 'ml-2' : 'mr-2'" />-->
          <!--          <p class="text-textGray-500">-->
          <!--            {{ $t("profiles.earliest_options", { name: therapistProfile.full_name }) }}-->
          <!--          </p>-->
          <!--          -if="therapistProfile.is_video_session_offered"-->
          <div
            class="text-sm font-semibold cursor-pointer pb-2 transition-all duration-300"
            :class="{
              'active-tab': activeTab === 'video',
              'ml-4': arabic,
              'mr-4': !arabic,
              'arabic-active-tab': activeTab === 'video' && arabic
            }"
            @click="activeTab = 'video'"
          >
            {{ $t("booking_flow.50min") }} ({{
              therapistProfile.video_price_object.amount / therapistProfile.video_price_object.divider +
              " " +
              therapistProfile.video_price_object.currency
            }})
          </div>
          <div
            class="ml-4 text-sm font-semibold cursor-pointer pb-2 transition-all duration-300"
            :class="{
              'active-tab': activeTab === 'video_small',
              'ml-4': arabic,
              'mr-4': !arabic,
              'arabic-active-tab': activeTab === 'video_small' && arabic
            }"
            @click="activeTab = 'video_small'"
          >
            {{ $t("booking_flow.25min") }} ({{
              therapistProfile.video_small_price_object.amount / therapistProfile.video_small_price_object.divider +
              " " +
              therapistProfile.video_small_price_object.currency
            }})
          </div>
        </div>
      </div>
      <div v-if="sessionsIsLoading" class="py-20 h-80 flex justify-center items-center w-full h-full bg-white">
        <img class="w-20 animate-bounce" src="../../../assets/images/ayadi-loader.svg" alt="Ayadi" />
      </div>
      <div v-if="!sessionsIsLoading && activeTab === 'video' && videoSlots.length !== 0" class="p-6 rounded-lg w-full">
        <SlotsInfo />
        <div class="mb-6" :class="{ 'pr-6': arabic }" v-for="(day, index) in videoSlots" :key="index">
          <div class="w-full flex justify-between">
            <p class="mb-2 font-semibold text-base">{{ getSlotsDay(day.date) }}</p>
            <p
              class="text-ayadi-900 cursor-pointer font-semibold"
              @click="openIntercomTime('50', getSlotsDay(day.date))"
            >
              {{ $t("intercom_request.request_another_time") }}
            </p>
          </div>
          <div class="w-full flex justify-start items-center flex-wrap">
            <div v-for="(slot, index) in day.sessions" class="mr-2 mb-2" :key="slot.id">
              <AvailabilitySlot
                class="slot_time"
                @click="
                  bookSession(
                    {
                      type: 'video',
                      start: slot.session_start,
                      session_id: slot.id,
                      properties: slot
                    },
                    index + 1
                  )
                "
                v-if="showSlot(slot.session_start)"
              >
                {{ getSlotsTime(slot.session_start) }}
                <span class="text-textGray-500 ml-1 text-sm">{{ getPostfix(slot.session_start) }}</span>
              </AvailabilitySlot>
            </div>
          </div>
        </div>

        <p
          class="text-center text-ayadi-900 cursor-pointer text-base font-semibold w-full"
          v-if="startVideo"
          @click="getNewSlots('video')"
        >
          {{ $t("profiles.load_more") }}
        </p>
        <div
          v-else
          class="w-2/3 mt-16 bg-hintBg px-4 py-8 rounded-6px flex flex-col justify-center items-center mx-auto text-center relative"
        >
          <span class="hint-logo">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M46.5158 11.6739C43.9302 7.36857 39.2035 4.78535 34.0323 5.23638C33.9515 5.23638 33.8303 5.23638 33.7495 5.27739C33.6687 5.27739 33.5475 5.27739 33.4667 5.31839C30.6387 5.56441 28.0935 6.79452 26.1139 8.63967C31.2851 10.6078 34.7191 15.8973 34.1535 21.7198C33.8707 24.9591 32.3759 27.8293 30.1943 29.8795C31.2447 30.2895 32.3759 30.5766 33.5071 30.6586C33.9111 30.6996 34.0727 31.1506 33.8303 31.4786C31.5679 34.1849 28.0127 36.03 25.7908 36.9731C25.0636 37.2601 24.498 37.5061 24.1748 37.5882C23.9728 37.6702 23.8516 37.8752 23.8516 38.0802L24.7808 48.085L24.8212 48.7411C24.8212 48.7411 25.0636 48.6591 25.4676 48.4951C26.5987 48.044 29.0227 46.978 31.9719 45.2558C36.9815 42.3446 43.3242 37.6292 46.839 30.9866C51.485 22.2118 48.3338 14.7902 46.5158 11.6739Z"
                fill="#D6EAE3"
              />
              <path
                d="M31.0894 51.3335L31.6304 45.1113C28.8478 46.8771 26.4903 47.9702 25.4082 48.4326C28.6932 50.4086 31.0894 51.3335 31.0894 51.3335Z"
                fill="#78CEC9"
              />
              <path
                d="M26.0155 8.67889C24.9513 8.27323 23.8463 8.02983 22.7003 7.90813C22.6185 7.90813 22.4957 7.90814 22.4138 7.86757C22.332 7.86757 22.2092 7.827 22.1273 7.827C16.8886 7.38078 12.1409 9.977 9.48063 14.1958C7.67981 17.2789 4.48744 24.6213 9.19414 33.3024C12.8776 40.0769 19.5898 44.8637 24.7058 47.7033L23.7644 37.8052C23.7235 37.6024 23.8872 37.3995 24.0919 37.3184C24.4193 37.1967 24.9923 36.9939 25.729 36.7099C24.5011 35.8986 23.3142 34.925 22.332 33.7892C22.0864 33.5052 22.291 33.0184 22.6594 32.9779C25.5243 32.7345 28.1028 31.5175 30.1492 29.692C32.3593 27.6637 33.8736 24.8241 34.1601 21.6194C34.7331 15.859 31.2542 10.626 26.0155 8.67889Z"
                fill="#78CEC9"
              />
              <path
                opacity="0.3"
                d="M10.17 21.2591C10.0895 21.2591 10.009 21.2591 9.92846 21.2168C9.48571 21.0478 9.24421 20.5406 9.36496 20.0756C11.2567 13.9469 14.1547 12.1295 14.2755 12.0449C14.678 11.7913 15.2012 11.9604 15.4427 12.3831C15.6842 12.8057 15.5232 13.3552 15.1207 13.6088C15.0805 13.6511 12.6252 15.2572 10.975 20.6251C10.8945 21.0055 10.5322 21.2591 10.17 21.2591Z"
                fill="white"
              />
            </svg>
          </span>
          <p class="font-semibold text-base">{{ $t("intercom_request.bottom_empty_1") }}</p>
          <p class="my-2">{{ $t("intercom_request.bottom_empty_2", { name: therapistProfile.full_name }) }}</p>
          <MainButton @click="openIntercomDay" type="medium">{{
            $t("intercom_request.request_another_day")
          }}</MainButton>
        </div>
      </div>

      <div
        v-if="!isLoading && activeTab === 'video' && videoSlots.length === 0"
        class="flex flex-col justify-center items-center w-full"
      >
        <img class="w-2/5 mt-10" src="../../../assets/images/illustrations/no-results.svg" alt="no results" />
        <p class="mb-6 font-semibold text-base text-textGray-500">
          {{ $t("intercom_request.fully_booked", { name: therapistProfile.full_name, time: "50" }) }}
        </p>
        <MainButton @click="openIntercom" type="medium">{{ $t("intercom_request.request") }}</MainButton>
      </div>

      <div v-if="!isLoading && activeTab === 'video_small' && videoSmallSlots.length !== 0" class="p-6 rounded-lg">
        <SlotsInfo />
        <div class="mb-6" :class="{ 'pr-6': arabic }" v-for="(day, index) in videoSmallSlots" :key="index">
          <div class="w-full flex justify-between">
            <p class="mb-2 font-semibold text-base">{{ getSlotsDay(day.date) }}</p>
            <p
              class="text-ayadi-900 cursor-pointer font-semibold"
              @click="openIntercomTime('25', getSlotsDay(day.date))"
            >
              {{ $t("intercom_request.request_another_time") }}
            </p>
          </div>

          <div class="w-full flex justify-start items-center flex-wrap">
            <div v-for="slot in day.sessions" class="mr-2 mb-2" :key="slot.id">
              <AvailabilitySlot
                class="slot_time"
                v-if="showSlot(slot.session_start)"
                @click="
                  bookSession({
                    type: 'video_small',
                    start: slot.session_start,
                    session_id: slot.id,
                    price: slot.price
                  })
                "
              >
                {{ getSlotsTime(slot.session_start) }}
                <span class="text-textGray-500 ml-1 text-sm">{{ getPostfix(slot.session_start) }}</span>
              </AvailabilitySlot>
            </div>
          </div>
        </div>

        <p
          class="text-center text-ayadi-900 cursor-pointer text-base font-semibold w-full"
          v-if="startVideoSmall"
          @click="getNewSlots('video_small')"
        >
          {{ $t("profiles.load_more") }}
        </p>
        <div
          v-else
          class="w-2/3 mt-16 bg-hintBg px-4 py-8 rounded-6px flex flex-col justify-center items-center mx-auto text-center relative"
        >
          <span class="hint-logo">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M46.5158 11.6739C43.9302 7.36857 39.2035 4.78535 34.0323 5.23638C33.9515 5.23638 33.8303 5.23638 33.7495 5.27739C33.6687 5.27739 33.5475 5.27739 33.4667 5.31839C30.6387 5.56441 28.0935 6.79452 26.1139 8.63967C31.2851 10.6078 34.7191 15.8973 34.1535 21.7198C33.8707 24.9591 32.3759 27.8293 30.1943 29.8795C31.2447 30.2895 32.3759 30.5766 33.5071 30.6586C33.9111 30.6996 34.0727 31.1506 33.8303 31.4786C31.5679 34.1849 28.0127 36.03 25.7908 36.9731C25.0636 37.2601 24.498 37.5061 24.1748 37.5882C23.9728 37.6702 23.8516 37.8752 23.8516 38.0802L24.7808 48.085L24.8212 48.7411C24.8212 48.7411 25.0636 48.6591 25.4676 48.4951C26.5987 48.044 29.0227 46.978 31.9719 45.2558C36.9815 42.3446 43.3242 37.6292 46.839 30.9866C51.485 22.2118 48.3338 14.7902 46.5158 11.6739Z"
                fill="#D6EAE3"
              />
              <path
                d="M31.0894 51.3335L31.6304 45.1113C28.8478 46.8771 26.4903 47.9702 25.4082 48.4326C28.6932 50.4086 31.0894 51.3335 31.0894 51.3335Z"
                fill="#78CEC9"
              />
              <path
                d="M26.0155 8.67889C24.9513 8.27323 23.8463 8.02983 22.7003 7.90813C22.6185 7.90813 22.4957 7.90814 22.4138 7.86757C22.332 7.86757 22.2092 7.827 22.1273 7.827C16.8886 7.38078 12.1409 9.977 9.48063 14.1958C7.67981 17.2789 4.48744 24.6213 9.19414 33.3024C12.8776 40.0769 19.5898 44.8637 24.7058 47.7033L23.7644 37.8052C23.7235 37.6024 23.8872 37.3995 24.0919 37.3184C24.4193 37.1967 24.9923 36.9939 25.729 36.7099C24.5011 35.8986 23.3142 34.925 22.332 33.7892C22.0864 33.5052 22.291 33.0184 22.6594 32.9779C25.5243 32.7345 28.1028 31.5175 30.1492 29.692C32.3593 27.6637 33.8736 24.8241 34.1601 21.6194C34.7331 15.859 31.2542 10.626 26.0155 8.67889Z"
                fill="#78CEC9"
              />
              <path
                opacity="0.3"
                d="M10.17 21.2591C10.0895 21.2591 10.009 21.2591 9.92846 21.2168C9.48571 21.0478 9.24421 20.5406 9.36496 20.0756C11.2567 13.9469 14.1547 12.1295 14.2755 12.0449C14.678 11.7913 15.2012 11.9604 15.4427 12.3831C15.6842 12.8057 15.5232 13.3552 15.1207 13.6088C15.0805 13.6511 12.6252 15.2572 10.975 20.6251C10.8945 21.0055 10.5322 21.2591 10.17 21.2591Z"
                fill="white"
              />
            </svg>
          </span>
          <p class="font-semibold text-base">{{ $t("intercom_request.bottom_empty_1") }}</p>
          <p class="my-2">{{ $t("intercom_request.bottom_empty_2", { name: therapistProfile.full_name }) }}</p>
          <MainButton @click="openIntercomDayShort" type="medium">{{
            $t("intercom_request.request_another_day")
          }}</MainButton>
        </div>
      </div>

      <div
        v-if="!isLoading && activeTab === 'video_small' && videoSmallSlots.length === 0"
        class="flex flex-col justify-center items-center w-full"
      >
        <img class="w-2/5 mt-10" src="../../../assets/images/illustrations/no-results.svg" alt="no results" />
        <p class="mb-6 font-semibold text-base text-textGray-500">
          {{ $t("intercom_request.fully_booked", { name: therapistProfile.full_name, time: "25" }) }}
        </p>
        <MainButton @click="openIntercomShort" type="medium">{{ $t("intercom_request.request") }}</MainButton>
      </div>

      <div class="stickyBottom">
        <SecondaryButton @click="onBack" medium :label="$t('main.close')"></SecondaryButton>
      </div>
    </div>
  </modal>
</template>

<script>
import IconCloseGray from "@/components/icons/IconCloseGray";
import AvailabilitySlot from "@/components/therapist-profile/AvailabilitySlot";
import { mapState } from "vuex";
import storageClient from "@/services/storageClient";
import dayjs from "dayjs";
import SlotsInfo from "@/components/therapist-profile/SlotsInfo";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import MainButton from "@/components/ui-kit/MainButton";
import { checkAvailabilityPackage } from "@/utils/packages";
import { getTherapistProperties } from "@/utils/events";
import { getHoursUntilSession } from "../../../utils/events";

export default {
  name: "TherapistSlotsModal",
  components: { MainButton, SecondaryButton, SlotsInfo, IconCloseGray, AvailabilitySlot },

  data() {
    return {
      isLoading: true,
      activeTab: "video",
      packagesInfo: null,
      validPackage: null
    };
  },

  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),
    ...mapState("auth", ["user"]),
    ...mapState("sessions", ["selectedPackage", "bookedPackages", "bookedPackagesForTherapist"]),
    ...mapState("therapistSlots", [
      "videoSlots",
      "videoSmallSlots",
      "startVideo",
      "startVideoSmall",
      "sessionsIsLoading"
    ]),

    //todo: add locale module

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    isShowTabs() {
      if (this.validPackage === true) {
        return false;
      }

      if (this.selectedPackage) {
        return false;
      }
      return true;
    },

    intercomRequestTrackData() {
      return {
        "Device type": "Web",
        "Therapist name": this.therapistProfile?.full_name,
        "Has intro video": !!(this.therapistProfile?.video_preview_ar || this.therapistProfile?.video_preview_en),
        "Therapist speciality": this.therapistProfile?.skill_en,
        "Therapist video price":
          this.therapistProfile?.video_price_in_kwd_object?.amount /
          this.therapistProfile?.video_price_in_kwd_object?.divider,
        "Therapist video small price":
          this.therapistProfile?.video_small_price_in_kwd_price_object?.amount /
          this.therapistProfile?.video_small_price_in_kwd_price_object?.divider,
        "Therapist years of experience": this.therapistProfile?.experience,
        "Therapist education": this.therapistProfile?.qualification_en,
        "Therapist language": this.therapistProfile?.languages.join(", "),
        "Therapist gender": this.therapistProfile?.gender
      };
    }
  },

  methods: {
    //booking_slotRequest_click

    async beforeOpenModal(value) {
      if (this.user) {
        const hasValidPackages = await checkAvailabilityPackage(this.therapistProfile.id, this.bookedPackages);
        if (hasValidPackages) {
          await this.$store.dispatch("sessions/setBookedPackagesForTherapist", hasValidPackages);
          this.validPackage = true;
        }
      }
      if (value.params) {
        if (value.params?.validPackage) {
          this.validPackage = true;
          return;
        }
        if (value.params?.sessionType === "one_session") {
          if (value.params.value === 25) {
            this.activeTab = "video_small";
          } else {
            this.activeTab = "video";
          }
        } else {
          this.activeTab = "video";
        }

        this.packagesInfo = value.params;
      }
    },

    openIntercom() {
      this.$modal.hide("therapist-availability");
      this.$intercom.showNewMessage(
        this.$t("intercom_request.request_anything", { name: this.therapistProfile.full_name, time: "50" })
      );
      //

      window.webengage.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request anything"
      });
      this.$mixpanel.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request anything"
      });
      this.$intercom.trackEvent("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request anything"
      });
    },
    openIntercomShort() {
      this.$modal.hide("therapist-availability");
      this.$intercom.showNewMessage(
        this.$t("intercom_request.request_anything", { name: this.therapistProfile.full_name, time: "25" })
      );

      window.webengage.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request anything"
      });
      this.$mixpanel.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request anything"
      });
      this.$intercom.trackEvent("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request anything"
      });
    },

    openIntercomDay() {
      this.$modal.hide("therapist-availability");
      this.$intercom.showNewMessage(
        this.$t("intercom_request.request_day", { name: this.therapistProfile.full_name, time: "50" })
      );
      window.webengage.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another day"
      });
      this.$mixpanel.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another day"
      });
      this.$intercom.trackEvent("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another day"
      });
    },

    openIntercomDayShort() {
      this.$modal.hide("therapist-availability");
      this.$intercom.showNewMessage(
        this.$t("intercom_request.request_day", { name: this.therapistProfile.full_name, time: "25" })
      );
      // Intercom('showNewMessage', 'pre-populated content');
      window.webengage.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another day"
      });
      this.$mixpanel.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another day"
      });
      this.$intercom.trackEvent("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another day"
      });
    },

    openIntercomTime(time, day) {
      this.$modal.hide("therapist-availability");
      this.$intercom.showNewMessage(
        this.$t("intercom_request.request_time", { name: this.therapistProfile.full_name, time: time, date: day })
      );

      window.webengage.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another time"
      });
      this.$mixpanel.track("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another time"
      });
      this.$intercom.trackEvent("booking_slotRequest_click", {
        ...this.intercomRequestTrackData,
        "Slot request type": "Request another time"
      });
    },

    getSlotsDay(day) {
      return dayjs(day).format("ddd, MMM DD");
    },

    getSlotsTime(date) {
      return dayjs(date).format("hh:mm");
    },

    getPostfix(date) {
      return dayjs(date).format("A");
    },

    showSlot(date) {
      return dayjs(date) > dayjs().add(1, "day");
    },

    trackPage() {
      if (this.therapistProfile) {
        this.$kochava.sendEvent("View", {
          name: "view_therapist_products",
          user_id: this.user?.id || null,
          now_date: new Date(),
          content_type: "therapist",
          content_id: this.therapistProfile.id
        });
      }
    },

    onBack() {
      this.$modal.hide("therapist-availability");

      if (this.packagesInfo?.sessionType) {
        this.$modal.show("booking-options-modal", this.packagesInfo);
      }
    },

    async fetchAllSlots() {
      if (this.videoSlots.length < 1) {
        await this.getNewSlots("video");
      }
      if (this.videoSmallSlots.length < 1) {
        await this.getNewSlots("video_small");
      }

      if (this.videoSlots.length === 0 && !this.bookedPackagesForTherapist) {
        this.activeTab = "video_small";
      }
      this.isLoading = false;
    },

    async getNewSlots(type) {
      await this.$store.dispatch("therapistSlots/getAvailableSlots", {
        id: this.therapistProfile.id,
        type: type,
        more: true
      });
    },

    async blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
      await this.fetchAllSlots();
      const trackData = { ...getTherapistProperties(this.therapistProfile), "Booking flow type": "session" };
      window.webengage.track("appointment_view", trackData);
      this.$mixpanel.track("appointment_view", trackData);
      this.$intercom.trackEvent("appointment_view", trackData);

      this.isLoading = false;
      this.trackPage();
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      this.isLoading = true;
    },

    async bookSession(slot, index) {
      slot.price = slot.type === "video" ? this.therapistProfile.video_price : this.therapistProfile.video_small_price;

      const trackData = {
        "Hours until session": getHoursUntilSession(slot.start),
        "Position in list": index
      };

      window.webengage.track("therapistProfile_availableSlot_click", trackData);
      this.$mixpanel.track("therapistProfile_availableSlot_click", trackData);
      this.$intercom.trackEvent("therapistProfile_availableSlot_click", trackData);

      storageClient.setBookingSlot(slot);

      this.$modal.hide("therapist-availability");

      if (this.user) {
        if (this.bookedPackagesForTherapist) {
          this.$modal.show("book-session-by-packages-modal", slot.properties);
          return;
        }

        if (this.packagesInfo.sessionType === "one_session") {
          this.$modal.show("proceed-to-payment-modal", slot.properties);
        } else {
          this.$modal.show("proceed-to-payment-package-modal", slot.properties);
        }
      } else {
        this.$modal.show("sign-up-select-auth-modal");
      }
    }
  }
};
</script>

<style lang="scss">
.max-h-90 {
  max-height: 90vh;
}

.max-h-animation {
  transition: max-height 0.2s ease-in;
}

.max-h-60 {
  max-height: 60vh;
}

.h-80 {
  height: 20rem;
}

.w-modal {
  width: 90%;
  max-width: 880px;
  margin: 0 auto;
}

.active-tab {
  @apply relative text-ayadi-900;
  &:before {
    @apply bg-ayadi-900 absolute bottom-0 left-0 h-1 w-8;
    content: "";
  }
}

.arabic-active-tab {
  &:before {
    right: 0;
  }
}

.hint-logo {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.stickyBottom {
  position: sticky;
  bottom: 0;
  background: white;
  width: 100%;
  padding: 1.5rem;
}
</style>

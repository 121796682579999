<template>
  <modal
    name="enable-mic-video-modal"
    height="auto"
    :width="500"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <div class="bg-white w-modal px-8 py-6 flex flex-col justify-left items-center rounded-lg">
      <div class="flex justify-left w-full mb-3" :class="{ 'flex-row-reverse': arabic }">
        <p class="text-18 font-semibold text-black">{{ $t("enable_mic_and_video.title") }}</p>
      </div>
      <div>
        <div class="mb-3">{{ $t("enable_mic_and_video.to_do") }}</div>

        <img src="../../assets/images/video/enable.png" class="mb-3 w-full" alt="Enable mic and video" />

        <div class="mb-4">{{ $t("enable_mic_and_video.not_work") }}</div>
        <div class="font-semibold">{{ $t("enable_mic_and_video.for_mac.title") }}</div>
        <div class="mb-3">{{ $t("enable_mic_and_video.for_mac.description") }}</div>
        <div class="font-semibold">{{ $t("enable_mic_and_video.for_windows.title") }}</div>
        <div class="mb-3">{{ $t("enable_mic_and_video.for_windows.description") }}</div>
        <div class="mb-3 h-px w-full bg-divider"></div>
        <div class="mb-6 font-semibold">{{ $t("enable_mic_and_video.after") }}</div>
      </div>

      <MainButton @click="reloadPage" class="self-end" id="reloadPage">{{
        $t("enable_mic_and_video.reload_page")
      }}</MainButton>
    </div>
  </modal>
</template>
<script>
import storageClient from "@/services/storageClient";
import MainButton from "@/components/ui-kit/MainButton";

export default {
  name: "EnableMicAndVideoModal",
  components: { MainButton },

  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    async reloadPage() {
      return window.location.reload();
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<template>
  <div class="flex flex-row justify-evenly">
    <RatingStar :full="rating > 0" @mouseenter="full = 1" @mouseleave="full = 0" @click="$emit('click', 1)" />
    <RatingStar :full="rating > 1" @mouseenter="full = 2" @mouseleave="full = 0" @click="$emit('click', 2)" />
    <RatingStar :full="rating > 2" @mouseenter="full = 3" @mouseleave="full = 0" @click="$emit('click', 3)" />
    <RatingStar :full="rating > 3" @mouseenter="full = 4" @mouseleave="full = 0" @click="$emit('click', 4)" />
    <RatingStar :full="rating > 4" @mouseenter="full = 5" @mouseleave="full = 0" @click="$emit('click', 5)" />
  </div>
</template>

<script>
import RatingStar from "@/components/RatingStar";
export default {
  name: "RatingStars",
  components: { RatingStar },
  props: {
    value: null
  },
  data() {
    return {
      full: 0
    };
  },

  computed: {
    rating() {
      if (this.value > 0) {
        return this.value;
      } else {
        return this.full;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <a :href="wpLink">
      <img class="h-10" v-if="!arabic" src="../../assets/images/LogoDark.svg" alt="Ayadi" />
      <img class="h-10" v-else src="../../assets/images/LogoDarkArabic.svg" alt="Ayadi" />
    </a>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";

export default {
  name: "LogoLight",
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    wpLink() {
      let link = "https://ayadihealth.co/";
      if (this.arabic) {
        link = "https://ayadihealth.co/ar/";
      }
      return link;
    }
  }
};
</script>

<style lang="scss" scoped></style>

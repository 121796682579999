import axios from "axios";

export default class sessionsClient {
  static async getVideoRoom(id) {
    return await axios.get("/api/client/rooms/" + id);
  }

  static async getChatRoom(id) {
    return await axios.get("/api/client/rooms/" + id);
  }

  static async getAllSessions() {
    return await axios.get("/api/client/sessions");
  }

  static async getPastSessionsPaginated(page) {
    return await axios.get("/api/client/sessions/past?status=booked&page=" + page);
  }

  static async getFeedbackQuestions() {
    return await axios.get("/api/feedback-questions");
  }

  static async createOrder(order) {
    return await axios.post("/api/client/v2/orders", order);
  }

  static async getOrder(id) {
    return await axios.get(`/api/client/v2/orders/${id}`);
  }

  static async checkCoupon(sessionID, coupon) {
    return await axios.get(`/api/client/v2/session/${sessionID}/coupon?promo_code=${coupon}`);
  }

  static async getPricePackages(id) {
    return await axios.get(`/api/client/therapists/${id}/product-prices`);
  }

  static async createPackageOrder(order) {
    return await axios.post("api/client/package-orders", order);
  }

  static async checkPackages() {
    return await axios.get("api/client/package-orders");
  }

  static async bookSessionByPackage(session) {
    return await axios.post("api/client/v2/session-package-orders", session);
  }

  static async getOrderPackages(id) {
    return await axios.get(`/api/client/package-orders/${id}`);
  }

  static async getAvailablePayments(amount) {
    return await axios.get(`/api/client/payment-modes?amount=${amount}`);
  }
}

<template>
  <div>
    <modal
      name="tabby-information-modal"
      @before-open="beforeOpenModal"
      width="600px"
      height="auto"
      :adaptive="true"
      :clickToClose="false"
      @opened="blockScroll"
      @closed="unblockScroll"
    >
      <div
        class="bg-white max-h-90 py-6 flex flex-col justify-center items-center rounded-lg"
        :dir="arabic ? 'rtl' : 'ltr'"
      >
        <div class="w-full overflow-y-auto">
          <div class="flex justify-between items-start mx-6 mb-4">
            <div class="cursor-pointer px-1 py-1" @click="closeModal">
              <IconCloseGray />
            </div>
          </div>
          <iframe class="iframe" name="tabbyIframe" :src="link"></iframe>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import storageClient from "@/services/storageClient";
import IconCloseGray from "@/components/icons/IconCloseGray";
import { mapState } from "vuex";

export default {
  name: "TabbyInformationModal",
  components: {
    IconCloseGray
  },
  data() {
    return {
      onBack: null,
      price: null,
      currency: null,
      link: null
    };
  },
  computed: {
    ...mapState("therapistProfiles", ["therapistProfile"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },
    tabbyLink() {
      const link = `https://checkout.tabby.ai/promos/product-page/installments/${this.arabic ? "ar" : "en"}/?price=${
        this.price
      }&currency=${this.currency}`;

      return link;
    }
  },
  methods: {
    beforeOpenModal(value) {
      if (value.params) {
        this.onBack = value.params.onBack;
        this.price = value.params.price;
        this.currency = value.params.currency;
        this.link = value.params.link;
        return;
      }
    },

    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    },

    closeModal() {
      this.onBack();
      this.$modal.hide("tabby-information-modal");
    }
  }
};
</script>

<style scoped lang="scss">
.iframe {
  width: 100%;
  height: 600px;
}
</style>

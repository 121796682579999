<template>
  <div
    class="slot-shadow rounded-2xl px-6 py-3 bg-white flex justify-start items-center cursor-pointer"
    @click="$emit('click')"
  >
    <p dir="ltr" class="font-semibold text-18 w-24" :class="arabic ? 'ml-10' : 'mr-10'"><slot></slot></p>
    <IconCircleArrowRight />
  </div>
</template>

<script>
import IconCircleArrowRight from "@/components/icons/IconCircleArrowRight";
import storageClient from "@/services/storageClient";
export default {
  name: "AvailabilitySlot",
  components: { IconCircleArrowRight },
  computed: {
    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  }
};
</script>

<style scoped>
.slot-shadow {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.0669441);
}
</style>

import authClient from "@/services/authClient";
import storageClient from "@/services/storageClient";
import router from "@/router";
import AuthClient from "@/services/authClient";

export const namespaced = true;
export const state = {
  user: null,
  notRegisteredFromSocial: false,
  notifications: null,
  isLoading: false
};
export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },

  REMOVE_USER(state) {
    state.user = null;
  },

  NOT_REGISTERED_FROM_SOCIAL(state, option) {
    state.notRegisteredFromSocial = option;
  },

  SET_NOTIFICATIONS(state, option) {
    state.notifications = option;
  },

  SET_LOADING(state, value) {
    state.isLoading = value;
  }
};

export const actions = {
  async login({ commit, dispatch }, user) {
    let { data } = await authClient.login(user);
    storageClient.setToken(data.token.access_token);
    let notifications = await authClient.notificationSettings();
    commit("SET_NOTIFICATIONS", notifications.data);
    commit("SET_USER", data.data);

    if (!data.data?.currency) {
      dispatch("updateProfile", { currency: storageClient.getCurrency() });
    } else {
      storageClient.setCurrency(data?.data?.currency || "KWD");
    }
  },

  async signUp({ commit, dispatch }, user) {
    const { data } = await AuthClient.signUp(user);

    storageClient.setToken(data.token.access_token);
    const notifications = await authClient.notificationSettings();

    commit("SET_NOTIFICATIONS", notifications.data);

    commit("SET_USER", data.data);

    if (!data.data.currency) {
      dispatch("updateProfile", { currency: storageClient.getCurrency() });
    } else {
      storageClient.setCurrency(data?.data?.currency || "KWD");
    }
  },

  notRegisteredFromSocial({ commit }, option) {
    commit("NOT_REGISTERED_FROM_SOCIAL", option);
  },

  setLoading({ commit }, value) {
    commit("SET_LOADING", value);
  },

  logout({ commit }) {
    storageClient.removeToken();
    storageClient.setKochavaDeviceId("");
    commit("REMOVE_USER");
    return router.push({ name: "SignIn" }).catch(() => {});
  },

  async getProfile({ commit, dispatch }) {
    let { data } = await authClient.getProfile();
    commit("SET_USER", data);

    if (!data?.currency) {
      dispatch("updateProfile", { currency: storageClient.getCurrency() });
    } else {
      storageClient.setCurrency(data?.currency || "KWD");
    }
  },

  async updateProfile({ commit }, profile) {
    const { data } = await authClient.updateProfile(profile);
    commit("SET_USER", data);

    if (data?.currency) {
      storageClient.setCurrency(data?.currency || "KWD");
    }
  },

  //todo: remove duplicate

  async socialAuth({ commit, dispatch }, options) {
    let { data } = await authClient.socialAuth(options.driver, options.token, options.lang);
    storageClient.setToken(data.token.access_token);
    let notifications = await authClient.notificationSettings();
    commit("SET_NOTIFICATIONS", notifications.data);
    commit("SET_USER", data.data);

    if (!data.data?.currency) {
      dispatch("updateProfile", { currency: storageClient.getCurrency() });
    } else {
      storageClient.setCurrency(data?.data?.currency || "KWD");
    }
  },

  async getNotifications({ commit }) {
    let { data } = await authClient.notificationSettings();
    commit("SET_NOTIFICATIONS", data);
  }
};

export const getters = {
  getUser: (state) => state.user
};

<template>
  <label
    class="flex flex-col justify-center block items-start relative w-full transition-all duration-200 font-semibold"
    :class="{ 'text-disabledColor': disabled, 'input-error': inputError, 'flex flex-col items-end text-right': arabic }"
  >
    <!--    -->
    <div class="mt-1 font-normal font-semibold" :class="{ 'flex flex-row-reverse': arabic }">
      {{ label }}
      <span v-if="required" class="text-ayadi-900 ml-1" :class="{ 'text-disabledColor': disabled, 'mr-1 ml-0': arabic }"
        >*</span
      >
    </div>

    <input
      :dir="direction"
      v-if="!isSubmit && !isPhoneNumber && !isSelect && !isDatePicker && !isRadio && !isTextarea && !social"
      :type="type"
      :placeholder="placeholder"
      :pattern="pattern"
      :value="value"
      @input="handleInput($event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      class="rounded bg-white border border-borderGray border-solid my-1 p-2 focus:border-ayadi-900 transition-all duration-200"
      :class="inputClass ? 'w-1/3' : 'w-full'"
      :disabled="disabled"
    />

    <slot name="datepicker"></slot>
    <slot name="select"></slot>
    <slot></slot>

    <p class="text-ayadiRed text-xs font-normal" v-if="inputError">{{ errorMessage }}</p>
    <p v-if="caption" class="text-borderGray font-light text-xs">{{ $t("account_setup.caption") }}</p>
    <p v-if="customCaption" class="text-borderGray text-xs font-light">{{ customCaption }}</p>
  </label>
</template>

<script>
import storageClient from "@/services/storageClient";
export default {
  name: "BaseInput",
  props: {
    label: String,
    type: String,
    submitValue: String,
    required: Boolean,
    caption: Boolean,
    customCaption: String,
    isPhoneNumber: Boolean,
    isSelect: Boolean,
    isDatePicker: Boolean,
    selectOptions: Array,
    isRadio: Boolean,
    radioValues: Array,
    inputValue: String,
    isTextarea: Boolean,
    isForNow: Boolean,
    placeholder: {
      type: String
    },
    characters: {
      type: Number,
      default: 600
    },
    multiple: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    inputClass: Boolean,
    disabled: null,
    value: null,
    selected: String,
    textareaValue: String,
    inputError: Boolean,
    errorMessage: String,
    pattern: String,
    isPassword: Boolean,
    height: String,
    social: Boolean,
    col: Boolean
  },
  data() {
    return {
      isOpen: false,
      date: null,
      isFocused: false
    };
  },

  computed: {
    isSubmit() {
      return this.type === "submit";
    },
    arabic() {
      return storageClient.getLanguage() === "ar";
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  min-height: 100px;
}

input:focus,
input:active,
textarea:focus,
textarea:active {
  @apply border-ayadi-900 text-textBlue bg-white;
  outline: none;
}

label:focus-within {
  @apply text-ayadi-900;
  input {
    @apply text-textBlue;
  }
}

input[type="submit"] {
  @apply bg-ayadi-900 text-white border-none font-semibold cursor-pointer transition-all duration-200 font-semibold text-base;
}

input[type="submit"]:hover {
  @apply bg-ayadi-800 text-white;
}

input[type="submit"]:focus,
input[type="submit"]:active {
  @apply bg-ayadi-800 border border-ayadi-800 text-white;
}

input:disabled {
  @apply border-disabledColor;
}

input[type="submit"]:disabled {
  @apply bg-disabledColor border-disabledColor;
  &:hover {
    @apply bg-disabledColor border-disabledColor;
  }
}

.disabled-button,
.disabled {
  @apply bg-disabledColor border-disabledColor;
}

.eye {
  position: absolute;
  right: 0.5rem;
  top: 2.55rem;
}

.eye-arabic {
  position: absolute;
  top: 2.55rem;
  left: 0.5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

svg path {
  transition: all 0.2s;
}

svg:hover path {
  fill: #44a4a5;
}

.focused-svg div svg path {
  fill: #44a4a5;
}
</style>

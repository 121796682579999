<template>
  <button :disabled="disabled" @click="emitClick" class="secondary-button no-underline" :class="getStyle">
    <slot name="icon"></slot>
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "SecondaryButton",

  props: {
    large: {
      type: Boolean
    },
    medium: {
      type: Boolean
    },
    small: {
      type: Boolean
    },
    label: {
      type: String
    },

    disabled: Boolean
  },

  methods: {
    emitClick() {
      this.$emit("click");
    }
  },

  computed: {
    getStyle() {
      if (this.large) {
        return "px-6 py-3";
      }

      if (this.medium) {
        return "px-4 py-2";
      }

      return "px-2 py-1";
    }
  }
};
</script>

<style scoped>
button:disabled {
  @apply bg-formStroke text-white border-none;
}
</style>

import therapistClient from "@/services/therapistClient";

export const namespaced = true;

export const state = {
  therapistProfile: null
};

export const mutations = {
  SET_THERAPIST_PROFILE(state, profile) {
    state.therapistProfile = profile;
  }
};

export const actions = {
  async getTherapistProfile({ commit }, id) {
    const response = await therapistClient.getTherapistProfile(id);

    commit("SET_THERAPIST_PROFILE", response?.data || null);
  }
};

<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center text-lg font-semibold" :class="{ 'flex-row-reverse': arabic }">
      {{ data.data.title }}
    </div>
    <div class="flex" :class="{ 'flex-row-reverse descriptionText': arabic }">
      {{ data.data.description }}
    </div>
    <div class="flex flex-row flex-wrap mb-8" :class="{ 'justify-start flex-row-reverse': arabic }">
      <MultipleChoice
        v-for="item in data.data.choices"
        :key="`${item.id}_${data.key}`"
        :id="`${item.id}_${data.key}`"
        :name="data.key"
        v-model="data.value"
        :value="item.id"
        @change="onChange"
        @toggle="
          () => {
            changed = true;
          }
        "
      >
        <p class="capitalize">{{ item.title }}</p>
      </MultipleChoice>
    </div>
  </div>
</template>

<script>
import MultipleChoice from "@/components/ui-kit/MultipleChoice";
import storageClient from "@/services/storageClient";
import { getFilterEvents } from "../../utils/filter";

export default {
  name: "DynamicMultipleChoice",
  components: {
    MultipleChoice
  },

  props: {
    data: {
      type: Object
    }
  },
  computed: {
    arabic() {
      return storageClient.getLanguage() === "ar";
    },
    direction() {
      return this.arabic ? "rtl" : "ltr";
    }
  },
  methods: {
    async onChange(value) {
      await this.$store.dispatch("therapistFilterDynamic/setFilterOptionValue", {
        key: this.data.key,
        value: value
      });
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapistsDynamic", 1);

      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_change", trackData);
      this.$mixpanel.track("therapistFilters_change", trackData);
      this.$intercom.trackEvent("therapistFilters_change", trackData);
    }
  }
};
</script>

<style lang="scss" scoped></style>

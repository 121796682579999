<template>
  <modal
    name="sign-up-via-email-modal"
    @before-open="beforeOpenModal"
    height="auto"
    :width="430"
    :adaptive="true"
    :clickToClose="false"
    @opened="blockScroll"
    @closed="unblockScroll"
  >
    <SignInViaEmailForm :isModal="true" @goBack="goBack" @onSignUp="onSignUp" />
  </modal>
</template>
<script>
import { mapState } from "vuex";
import SignInViaEmailForm from "@/components/forms/SignInViaEmailForm";
import { setTrackingDevice } from "@/axios";

export default {
  name: "SignUpViaEmailModal",
  components: { SignInViaEmailForm },

  data() {
    return {
      additionalInfo: null
    };
  },

  computed: {
    ...mapState("therapistSlots", ["showNextModal"])
  },

  methods: {
    beforeOpenModal(value) {
      if (value.params) {
        this.additionalInfo = value.params;
      }
    },

    goBack() {
      this.$modal.hide("sign-up-via-email-modal");
      this.$modal.show("sign-up-select-auth-modal");
    },

    async toNextModal() {
      if (this.showNextModal?.modal) {
        this.$modal.show(this.showNextModal?.modal, this.showNextModal?.additionalInfo);
        return;
      }
      this.$modal.show("proceed-to-payment-modal");
    },

    async onSignUp() {
      await setTrackingDevice();
      this.$modal.hide("sign-up-via-email-modal");
      this.toNextModal();
    },
    blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    unblockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }
};
</script>

<style lang="scss" scoped>
.size-30 {
  width: 7.5rem;
  height: 7.5rem;
}

.clear-date {
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.datePicker-error {
  border-color: rgba(240, 74, 67, var(--border-opacity));
}
</style>

<style>
.vd-picker__input-icon {
  margin-top: 0 !important;
  height: 100%;
  align-self: center;
}
</style>

<template>
  <modal
    name="therapist-filter-modal-dynamic"
    @opened="blockScroll"
    @closed="unblockScroll"
    height="auto"
    :width="650"
    :adaptive="true"
    id="filterModal"
  >
    <div
      class="bg-white w-modal flex flex-col justify-start items-start rounded-lg max-h-animation filterModalContainer"
      :class="{ hidden: isHidden }"
    >
      <div class="filterWrapper">
        <div class="flex justify-between items-center w-full px-6 py-6" :dir="direction">
          <div class="text-lg font-semibold">{{ $t("filter.title") }}</div>
          <div class="font-semibold reset-filters-btn" @click="resetFilter()">{{ $t("filter.reset") }}</div>
        </div>
        <div class="overflow-y-scroll mx-auto w-full modalContainer px-6 py-6" ref="filter">
          <div class="flex flex-col w-full">
            <div class="flex items-center mb-4 text-lg font-semibold" :dir="arabic ? 'rtl' : 'ltr'">
              {{ $t("filter.show_expert") }}
            </div>
            <div class="flex flex-col items-left" :class="{ 'flex-row-reverse': arabic }">
              <div v-if="filterOptions.length < 0" class="flex justify-center w-full mt-6 mb-6">
                <img class="w-20 animate-bounce" src="../../assets/images/ayadi-loader.svg" alt="Ayadi" />
              </div>
              <component
                v-else
                v-for="filterOption in filterOptions"
                :key="filterOption.q_param"
                :is="filterOption.component"
                :data="filterOption"
                @hideParentModal="toggleFilterModal(true)"
                @showParentModal="toggleFilterModal(false)"
              ></component>
            </div>
          </div>
        </div>
        <div
          class="flex flex-row w-full justify-between px-6 py-6 bottomBtnSection"
          :class="{ 'flex-row-reverse': arabic }"
        >
          <SecondaryButton @click="closeFilter()" class="mt-4 w-20" :label="$t('feedback.back')" />
          <MainButton @click="applyFilter()" :disabled="selectedNewFilter === 0" class="mt-4"
            >{{ $t("filter.select") }}
            <span v-if="selectedNewFilter !== 0" class="ml-1">({{ totalTherapistsDynamic }})</span></MainButton
          >
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";
import "vue-slider-component/theme/default.css";

import storageClient from "@/services/storageClient";
import AyadiCheckbox from "@/components/ui-kit/AyadiCheckbox";
import DynamicCheckbox from "@/components/dynamicFilters/DynamicCheckbox";
import DynamicSingleChoice from "@/components/dynamicFilters/DynamicSingleChoice";
import DynamicMultipleChoice from "@/components/dynamicFilters/DynamicMultipleChoice";
import DynamicPriceRangeSlider from "@/components/dynamicFilters/DynamicPriceRangeSlider";
import DynamicTimeRangeSlider from "@/components/dynamicFilters/DynamicTimeRangeSlider";
import DynamicMultipleChoiceModal from "@/components/dynamicFilters/DynamicMultipleChoiceModal";
import DynamicSingleChoiceModal from "@/components/dynamicFilters/DynamicSingleChoiceModal";
import SingleCheckbox from "@/components/ui-kit/SingleCheckbox";
import SecondaryButton from "@/components/ui-kit/SecondaryButton";
import MainButton from "@/components/ui-kit/MainButton";
import { getFilterEvents } from "../../utils/filter";

export default {
  name: "TherapistFilterModalDynamic",
  components: {
    SingleCheckbox,
    AyadiCheckbox,
    SecondaryButton,
    MainButton,
    DynamicCheckbox,
    DynamicSingleChoice,
    DynamicMultipleChoice,
    DynamicPriceRangeSlider,
    DynamicTimeRangeSlider,
    DynamicMultipleChoiceModal,
    DynamicSingleChoiceModal
  },

  data() {
    return {
      scrollPosition: 0,
      isLoading: false,
      isHidden: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("common", ["currencies"]),
    ...mapState("therapistFilterDynamic", ["filterOptions", "filterOptionsApplied", "totalTherapistsDynamic"]),

    arabic() {
      return storageClient.getLanguage() === "ar";
    },

    direction() {
      return this.arabic ? "rtl" : "ltr";
    },

    getCurrency() {
      return this.currency;
    },

    selectedNewFilter() {
      return this.checkNewSelectedFilter();
    }
  },

  methods: {
    getDateNow() {
      return dayjs(new Date()).format("HH:mm");
    },
    async closeFilter() {
      this.$modal.hide("therapist-filter-modal-dynamic");
      await this.$store.dispatch("therapistFilterDynamic/setToSelectedFilters");
    },
    toggleFilterModal(value) {
      this.isHidden = value;
    },

    checkNewSelectedFilter() {
      let count = 0;

      for (let i = 0; i < this.filterOptions.length; i++) {
        if (this.filterOptionsApplied[i]?.value instanceof Array || this.filterOptions[i]?.value instanceof Array) {
          if (JSON.stringify(this.filterOptions[i]?.value) !== JSON.stringify(this.filterOptionsApplied[i]?.value)) {
            count++;
          }
        } else if (this.filterOptions[i]?.value !== this.filterOptionsApplied[i]?.value) {
          count++;
        }
      }

      return count;
    },

    async applyFilter() {
      await this.$store.dispatch("therapistFilterDynamic/applyFilters");
      await this.$store.dispatch("therapistFilterDynamic/setActiveFilters");
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapists", 1);

      this.$kochava.sendEvent("Search", {
        name: "search",
        user_id: this.user?.id || null,
        now_date: new Date()
      });

      const trackData = getFilterEvents(this.$store.state.therapistFilterDynamic);

      window.webengage.track("therapistFilters_submit", trackData);
      this.$mixpanel.track("therapistFilters_submit", trackData);
      this.$intercom.trackEvent("therapistFilters_submit", trackData);

      this.$modal.hide("therapist-filter-modal-dynamic");
    },

    async blockScroll() {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";

      window.webengage.track("therapistFilters_view");
      this.$mixpanel.track("therapistFilters_view");
      this.$intercom.trackEvent("therapistFilters_view");
    },

    async unblockScroll() {
      await this.$store.dispatch("therapistFilterDynamic/setToSelectedFilters");
      document.getElementsByTagName("body")[0].style.overflow = "auto";

      if (this.$refs.filter) {
        this.$refs.filter.removeEventListener("scroll", this.scrollHandle);
      }
    },

    scrollHandle() {
      if (this.$refs.filter) {
        let modal = this.$refs.filter;
        setTimeout(() => {
          this.showScrollToBottom = !(modal.scrollHeight - modal.scrollTop - modal.clientHeight <= 200);
        }, 0);
      }
    },

    async resetFilter() {
      window.webengage.track("therapistFilters_reset_click");
      this.$mixpanel.track("therapistFilters_reset_click");
      this.$intercom.trackEvent("therapistFilters_reset_click");
      await this.$store.dispatch("therapistFilterDynamic/resetFilters");
      await this.$store.dispatch("therapistFilterDynamic/fetchTherapists", 1);
    }
  },

  async created() {
    this.isLoading = true;

    this.isLoading = false;
  },

  beforeDestroy() {
    if (this.$refs.filter) {
      this.$refs.filter.removeEventListener("scroll", this.scrollHandle);
    }
  }
};
</script>

<style lang="scss" scoped>
.filterWrapper {
  width: 100%;
}

.descriptionText {
  text-align: end;
}

.bottomBtnSection {
  z-index: 100;
  padding: 10px 24px;
  width: 100%;
  left: 0;
}
.modalContainer {
  max-height: calc(90vh - 200px);
}
.reset-filters-btn {
  color: #44a4a5;
  cursor: pointer;
}

.color-gray {
  color: #6f7786;
}

.selected-time-wrapper {
  span {
    color: #44a4a5;
    margin: 0 5px;
  }
}

.selectedWrapper {
  span {
    color: #939aa8;
  }
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 8px 0 4px 0;
}

.clearSelections {
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  color: #44a4a5;
  font-weight: 600;
}

.choose-btn {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #f8f9fa;
  border: 1px solid #354052;
  border-radius: 4px;
  padding: 14px 16px;
  min-width: 225px;
  cursor: pointer;

  &:hover {
    background: #f5f7f9;
  }

  &.small {
    min-width: 202px;
  }

  &.specialty {
    min-width: 195px;
  }
  &.workingStyleBtn {
    min-width: 205px;
  }
  &.workingStyle {
    min-width: 153px;
  }
}
</style>

<style>
.vue-slider-process {
  background-color: #44a4a5;
}

.vue-slider-dot-handle {
  background-color: #44a4a5;
}

.vue-slider-dot-tooltip-inner {
  background-color: #44a4a5;
  border-color: #44a4a5;
}
</style>
